import * as React from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import SelectRider from '../components/SelectRider';
import axios from 'axios';
import { Alert, Grid, Stack, Typography, Chip } from '@mui/material';
import SjJudgeScores from '../components/SjJudgeScores';
import Panel from '../../components/containers/Panel';
import { isInt, translateSjScore } from '../../utils';
import { clearSjCommentsAndStatus, getShortCutHandler, hasSjRefusal, sjscorebuttons } from '../components/scorebuttons';

export default function ShowJumpingScoring() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [entry, setEntry] = useState();
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState("");
  const [fences, setFences] = useState("");

  const entrySelected = (e) => {
    setError("");
    console.log(e);
    if(!e) {
      return;
    }
    if(e.Eventclass.sjjumps == "" || e.Eventclass.sjexptime == 0) {
      setError("The event class for this entry has not been initialised for show jumping");
      setEntry(null);
      // setEntry(null);
    } else {
      if(e.sjjumpscores.length == 0) {
        e.sjjumpscores = initialiseSjFences(e);
      } else {
        e.sjjumpscores = e.sjjumpscores.split(",");
        const fencelist = e.Eventclass.sjjumps.split(",");
        setFences(fencelist);
      }
      
      setEntry(e);
    }

  }

  const initialiseSjFences = (e) => {
    const fencelist = e.Eventclass.sjjumps.split(",");
    const scores = [];
    for(let i = 0; i<fencelist.length;i++) {
      scores.push(0);
    }
    setFences(fencelist);
    return scores;
  }

  const cloneEntry = () => {
    return JSON.parse(JSON.stringify(entry));
  }
  
  // const hasSjRefusal = (update) => {
  //   let has = -1;
  //   update.sjjumpscores.map((f, index) => {
  //     if(f == '1R' || f == '2R' || f == '1R+4') {
  //       has = index;
  //     }
  //   });
  //   return has;
  // }

  // const clearSjCommentsAndStatus = (update) => {
  //   // only one refusal and all other scores are numeric
  //   console.log(update.sjjumpscores);
  //   let canclear = 0;
  //   update.sjjumpscores.map((f, index) => {
  //     if(!isInt(f) && f != '1R') {
  //       console.log("Not int: " + f);
  //       canclear = 2;
  //     }
  //     if(f == '1R') {
  //       canclear++;
  //     }
  //   });
  //   return canclear;
  // }

  const onScoreChange = (f, v) => {
    // v=parseFloat(v);
    console.log(f, v);
    let newEntry = cloneEntry();
    let score = v;
    if(v != 0 && !isInt(v)) {
      const handler = getShortCutHandler(score, sjscorebuttons);
      if(handler != null) {
        newEntry = handler(newEntry, fences, f);
      }
      // switch(v) {
      //   case '1R':
      //     // check has another refusal already
      //     const refusal = hasSjRefusal(newEntry);
      //     if(refusal != -1) {
      //       newEntry.sjcomments = `Eliminated after 2 refusals, one at fence ${fences[refusal]} and one at fence ` + fences[f];
      //       newEntry.sjreason = 'R';
      //       newEntry.sjstatus = 'EL';
      //     } else {
      //       newEntry.sjcomments = '';
      //       newEntry.sjreason = '';
      //       newEntry.sjstatus = 'OK';  
      //     }

      //     break;
      //   case '2R':
      //     newEntry.sjcomments = 'Eliminated with 2 refusals at fence ' + fences[f];
      //     newEntry.sjreason = 'R';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case 'FH':
      //     newEntry.sjcomments = 'Eliminated with fall of horse at fence ' + fences[f];
      //     newEntry.sjreason = 'FH';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case 'FR':
      //     newEntry.sjcomments = 'Eliminated with fall of rider at fence ' + fences[f];
      //     newEntry.sjreason = 'FR';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case '1RR':
      //     newEntry.sjcomments = 'Had 1 refusal and then retired at fence ' + fences[f];
      //     newEntry.sjreason = 'R';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case '1RFR':
      //     newEntry.sjcomments = 'Had 1 refusal and then rider fell at ' + fences[f];
      //     newEntry.sjreason = 'FR';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case '1RFH':
      //     newEntry.sjcomments = 'Had 1 refusal and then horse fall at fence ' + fences[f];
      //     newEntry.sjreason = 'FH';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case 'RET':
      //     newEntry.sjcomments = 'Retired at fence ' + fences[f];
      //     newEntry.sjreason = '';
      //     newEntry.sjstatus = 'RET';
      //     break;
      //   case 'MJ':
      //     newEntry.sjcomments = 'Eliminated, missed fence ' + fences[f];
      //     newEntry.sjreason = 'OT';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case 'EL':
      //     newEntry.sjcomments = 'Eliminated at fence ' + fences[f];
      //     newEntry.sjreason = 'OT';
      //     newEntry.sjstatus = 'EL';
      //     break;
      //   case 'WD':
      //     newEntry.sjcomments = 'Withdrawn';
      //     newEntry.sjreason = '';
      //     newEntry.sjstatus = 'WD';
      //     break;
      // } 
    } else {
      score = parseInt(v)
    }

    newEntry.sjjumpscores[f] = score;

    if (entry.Eventclass.phaseorder == 0) {
      const totalscore = getSjJumppen(newEntry);
      if(totalscore >= 20) {
        newEntry.sjcomments = 'Eliminated because 20 SJ faults with SJ before XC';
        newEntry.sjreason = 'OT';
        newEntry.sjstatus = 'EL';  
      }
    }

    // check if need to clear anything
    if(clearSjCommentsAndStatus(newEntry) <= 1) {
      newEntry.sjcomments = '';
      newEntry.sjreason = '';
      newEntry.sjstatus = 'OK';  
    }

    setEntry(newEntry);
    setChanged(true);
  }

  const onCommentChange = (newComment) => {
    let newEntry = cloneEntry();
    newEntry.sjcomments = newComment;
    setEntry(newEntry);
    setChanged(true);
  }

  const onTimeChange = (sjtime) => {
    let newEntry = cloneEntry();
    newEntry.sjtime = sjtime;
    setEntry(newEntry);
    setChanged(true);
  }

  const onStatusChange = (newStatus) => {
    let newEntry = cloneEntry();
    newEntry.sjstatus = newStatus;
    setEntry(newEntry);
    setChanged(true);
  }

  const onPublishedStatusChange = (newStatus) => {
    if(newStatus == entry.sjpublishstatus) {
      return;
    }
    let newEntry = cloneEntry();
    newEntry.sjpublishstatus = newStatus;
    setEntry(newEntry);
    setChanged(true);
  }
  const onCancel = () => {
    setError("");
    setEntry(null);
    setChanged(false);
  }

  const onReasonChange = (newReason) => {
    let newEntry = cloneEntry();
    newEntry.sjreason = newReason
    setEntry(newEntry);
    setChanged(true);
  }

  const getSjTimepen = (update=null) => {
    if(!update) {
      update = entry;
    }
    if(update.sjtime <= update.Eventclass.sjexptime) {
      return 0;
    }

    const secondsOver = (update.sjtime - update.Eventclass.sjexptime)*0.4;
    return parseFloat((Math.round(secondsOver * 10) / 10).toFixed(1));
  }

  const getSjJumppen = (update=null) => {
    if(!update) {
      update = entry;
    }
    let score = 0;
    for(let i = 0; i < update.sjjumpscores.length; i++) {
      let fscore = translateSjScore(update.sjjumpscores[i])
      score += fscore;
    }
    return parseInt(score);
  }

  const getSjScore = () => {
    return getSjJumppen() + getSjTimepen();
  }

  const onPublishClear = () => {
    const newEntry = cloneEntry();
    newEntry.sjtime = newEntry.sjtime === 0 ? entry.Eventclass.sjexptime : newEntry.sjtime;
    newEntry.sjreason = '';
    for(let i = 0; i < entry.sjjumpscores.length; i++) {
      newEntry.sjjumpscores[i] = 0;
    }
    newEntry.sjstatus = 'OK';
    // setEntry(newEntry);
    onSave(1, newEntry);
  }
  

  const onSave = (publish, update=null) => {
    setError("");
    // const callSetEntry = update==null;
    if(!update) {
      update = cloneEntry();
    }

    const jumppen = getSjJumppen(update);
    const timepen = getSjTimepen(update);
    let data = {
      resultid: update.resultid,
      sjjumpscores: update.sjjumpscores.join(","),
      sjreason: update.sjreason,
      sjscore: jumppen + timepen,
      sjtimepen: timepen,
      sjjumppen: jumppen,
      sjstatus: update.sjstatus,
      sjtime: update.sjtime,
      sjcomments: update.sjcomments,
    };

    axios.put("/admin/entrantresults/showjumping?publish="+publish, data).then(response => {
        if (response.data.error) {
          enqueueSnackbar(response.data.message, { variant: 'error'})
          return;
        }
        enqueueSnackbar("Show Jumping results updated", { variant: 'success'});
        // onPublishedStatusChange(publish == 1);
        setChanged(false);
        update.sjpublishstatus = (publish == 1);
        setEntry(update);
      })
      .catch(error => {
        setError("Error saving scores: " + (error.message ? error.message : ""));
      });        

  }

  if(id < 9080) {
    return (
      <Panel><Alert severity="error">Scoring tool does not support older events </Alert></Panel>    
    )
  }
  return (
    <>
      <SelectRider 
        id={id} 
        phase="Show Jumping" 
        onSelect={(e) => entrySelected(e)}   
        changed={changed} 
        onChange={() => onSave(0)}
        onChangeAndPublish={() => onSave(1)}
        onCancel={onCancel}
      >
        {entry && <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
                <Stack direction="row">
                    <Typography sx={{pr:1, fontWeight: 'bold'}}>Score: </Typography>
                    <Typography>{getSjScore()}</Typography>
                    <Typography sx={{ml:3, pr:1, fontWeight: 'bold'}}>Status: </Typography>
                    {entry.sjpublishstatus == false ?   <Chip label="Not Pubished" color="error" />:   <Chip label="Published" color="success" />}
                </Stack>

            </Grid>                      
        </Grid>}
      </SelectRider> 

      {error != "" && <Alert severity="error" sx={{ my:1}}>{error}</Alert>}
      {entry && <SjJudgeScores 
          entry={entry}
          onReasonChange={onReasonChange}
          onStatusChange={onStatusChange}
          onCommentChange={onCommentChange}
          onTimeChange={onTimeChange}
          onScoreChange={onScoreChange}
          fences={fences}
          allowedTime={entry.Eventclass.sjexptime}
          onPublishClear={onPublishClear}
      />}
    </>
  )
}