export const MarkOrderPaidFields = [
      {
        attribute: "uniqueref",
        label: "Worldnet Unique Reference",
        col: {
          // Here you can specify how many Grid columns the field should take for the corresponding breakpoints
          sm: 12,
        },
        validationType: "string",
        validations: {
          required: true,
          max: 256,
        },
        props: {
        }
      }
];
