import * as React from 'react';
import { useNavigate } from 'react-router';
import useLocalStorage from '../../app/useLocalStorage';
import Panel from '../../components/containers/Panel';
import { SelectTeamMember } from "../../app/form/Definitions/SelectTeamMember";
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import Title from "../../containers/Title";
import Description from "../../containers/Description";
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import Primary from "../../components/buttons/Primary";
import { Alert } from '@mui/material';
import axios from "axios";
const store = sessionStorage;


export default function AssignTeamMember() {
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState("");
    const navigate = useNavigate();

    const updateForm = (updates) => {
        setError("");
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };

    const assignTeamMember = () => {
        if(form['teammemberid'] == null) {
            return setError("Please select the rider/ horse combination to assign to this team");
        }

        const queryParameters = new URLSearchParams(window.location.search)

        // fetch the class type
        axios.put("/admin/teammember", {
            id: +form['teammemberid'],
            teamid: queryParameters.get("teamid")
        })
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return setError("Problem assigning team member: " + response.data.error)
            } 
            navigate(-1);
        })
        .catch(error => {
            setError("Error assigning to team: ", error);
        });

    }     

    const getFieldDefinition = () => {
        return   [{
            attribute: "teammemberid",
            label: "Select Combination",
            component: 'autocomplete-remote',
            validationType: "string",
            validations: {
              required: true,
            },
            optionDef: {
                type: "server-search",
                name: "name",
                url: "/admin/teammember/auto",
                operation: "like",
                min: 3,
                filter: {
                    eventid: store.getItem('current-event-id')
                }
            },
            optionConfig: {
              key: "id",
              value: "id",
              label: "name",
            },
            col: {
              sm: 12,
            },
          }];
    }

    return (
        <Panel>
            <Title>Type Riders Name</Title>
            <Description>Please Please select the rider horse combindation you want to add to this team, note: if you start typing the name then list will autocomplete.</Description>
            <FormBuilder fields={getFieldDefinition()} form={form} updateForm={(updates) => updateForm(updates)} />
            {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
            {<Primary sx={{ mt: 2 }} startIcon={<DynamicFormIcon/>} title={"Add To Team"} onClick={() => { assignTeamMember() }}/>}
        </Panel>
    );
}