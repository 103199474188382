import React, { useState } from "react";
import _ from "lodash";
import { Alert } from '@mui/material';
import ResponsiveDialog from "./ResponsiveDialog";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FileUpload from 'react-material-file-upload';

export default function FileUploadDialog({
    title,
    onConfirm,
    open,
    confirmLabel,
    description,
    loading,
    loadingMessage,
    onCancel,
    uploadButtonText
}) {    
    const [files, setFiles] = useState([]);
    const [error, setError] = useState();

    const confirm = () => {
        setError();
        if(files.length === 0) {
            return setError("You have not selected a file to upload");
        }
        onConfirm(files);
        setFiles([]);
    }

    const cancel = () => {
        setError();
        setFiles([]);
        onCancel();
    }

    const fileSelected = (file) => {
        setFiles(file);
        setError();
    }
    return (
        <ResponsiveDialog 
            title={title} 
            onPrimaryClick={confirm} 
            primaryButtonIcon={<ThumbUpAltIcon/>} 
            primaryButtonLabel={ confirmLabel || "Upload"}
            open={open}
            description={description}
            loading={loading} 
            onCloseClick={cancel}
            loadingMessage={loadingMessage ? loadingMessage : null}
            >
            {error && <Alert sx={{my: 1}}  severity="error" >{error}</Alert>}
            <FileUpload buttonText={uploadButtonText || "Upload File"} title="Select file to upload" value={files} onChange={(f) => fileSelected(f)} />
        </ResponsiveDialog>
    )
}