import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { green} from "./colors";
import { Theme } from '@mui/material/styles';

// declare module '@mui/styles' {
//   interface DefaultTheme extends Theme {}
// }


const GlobalStyles = {
  appbar: {
    background : 'white',
    color: "black",
    borderLeft: 0
  },
  scheduler: {
    appbar: {
      background : '#2a9461',
      color: "white",
      borderLeft: 0
    },  
  },
  scoring: {
    appbar: {
      background : '#2a9461',
      color: "white",
      borderLeft: 0
    },  
  }
};

// A custom theme for this app
const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: green[300],
      dark: green[500]
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  
}));


export { theme, GlobalStyles};
