import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../../containers/Title';
import Description from '../../containers/Description';
import { Divider } from '@mui/material';

export default function Fieldset(props:any) {
    return (
        <Grid container sx={{pb:2}} {...props}>
            <Grid item xs={12}>
                    {props.title && <Title >{props.title}</Title>}
                    {props.description && <Description>{props.description}</Description>}
                    {props.children}
                    <Divider sx={{pt:2}}/>
            </Grid>
        </Grid>
    )
}