import { createStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import loginSlice  from './loginSlice';
import messageSlice  from './messageSlice';

const persistConfig = {
    key: 'root',
    storage,
  }

const reducer = combineReducers({
    login: loginSlice,
    message: messageSlice
});

const persistedReducer = persistReducer(persistConfig, reducer);

let store = createStore(persistedReducer)
let persistor = persistStore(store)

export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;