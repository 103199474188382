export const LinkAccountFields = [    
  {
    attribute: "lastmodifiedbyuserid",
    label: "Select Parent Account",
    component: 'autocomplete-remote',
    validationType: "string",
    validations: {
      required: true,
    },
    optionDef: {
      type: "server-search",
      name: "fullname",
      url: "/admin/user/auto",
      operation: "like",
      min: 2,
      filter: {
        archived: 0
      }
    },
    optionConfig: {
      key: "id",
      value: "id",
      label: "name",
    },
    col: {
      sm: 12,
    },
  }
];
