import { Routes, Route } from 'react-router';
import { AuthProvider, RequireAuth } from "./auth/AuthProvider";
import Layout from './containers/Layout';
import SchedulerLayout from './scheduler/SchedulerLayout';
import ScoringLayout from './scoring/ScoringLayout';
import Dashboard from './containers/Dashboard';
import LoginPage from './auth/LoginPage';
import { SnackbarProvider } from 'notistack';
import axios from 'axios';
import { API_ROOT } from './app/constants';
import { selectRefreshToken, loggedin, tokenexpired, selectUser } from './app/loginSlice';
import { store } from './app/store';
import { routes, schedulerroutes, scoringroutes } from './routes';
import PageNotFound from './containers/PageNotFound';

axios.defaults.baseURL = API_ROOT;

const instance = axios.create({
  baseURL: API_ROOT,
  headers: {
    "Content-Type": "application/json",
  },
});

axios.interceptors.response.use(response => {
  return response;
}, async(err) => {  
  const originalConfig = err.config;

  if (originalConfig.url !== "/auth/login" && err.response) {
    // Access Token was expired
    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const state = store.getState();
        const refreshtoken = selectRefreshToken(state);
        const user = selectUser(state);

        const result = await instance.post("/auth/token", {
          refreshtoken: refreshtoken,
          username: user.username
        });
        axios.defaults.headers.common['x-access-token'] = result.data.token;
        originalConfig.headers['x-access-token'] = result.data.token;
        store.dispatch(loggedin({token: result.data.token, user: result.data.user, refreshtoken: result.data.refreshtoken, isLoggedIn: true}));

        return instance(originalConfig);
      } catch (_error) {
        store.dispatch(tokenexpired());
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(err.response);
});

// axios.interceptors.response.use(response => {
//   return response;
// }, error => {  

//   console.debug(error);
//   if (error.response.status === 401) {
//       console.debug("401 ");
//       store.dispatch(tokenexpired());
//   }
//   return Promise.reject(error);
//   // return error;
// });

export default function App() {
  return (
    <SnackbarProvider 
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpassword" element={<PageNotFound />} />
          <Route element={<Layout />}>
            {routes.map((route, idx) =>
                 <Route
                  path={route.path}
                  key={idx} 
                  element={<RequireAuth><route.component/></RequireAuth>}>
                </Route>
            )}          
          </Route>
          
          <Route element={<SchedulerLayout />}>
            {schedulerroutes.map((route, idx) =>
                 <Route
                  path={"/schedule" +route.path}
                  key={idx} 
                  element={<RequireAuth><route.component/></RequireAuth>}>
                </Route>
            )}          
          </Route>

          <Route element={<ScoringLayout />}>
            {scoringroutes.map((route, idx) =>
                 <Route
                  path={"/scoring" +route.path}
                  key={idx} 
                  element={<RequireAuth><route.component/></RequireAuth>}>
                </Route>
            )}          
          </Route>
        </Routes>
      </AuthProvider>
    </SnackbarProvider>
  );
}
