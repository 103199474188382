import * as React from 'react';
import { useParams, useNavigate } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import Primary from '../../components/buttons/Primary';
import Back from '../../components/buttons/Back';
import { Grid, Typography } from "@mui/material";
import ConfirmationDialog from '../../components/collections/ConfirmationDialog';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useRef } from 'react';
import OrderDetails from './OrderDetails';
import ResponsiveDialog from "../../components/containers/ResponsiveDialog";
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import { MarkOrderPaidFields } from "../../app/form/Definitions/MarkOrderPaidFields";
import EditIcon from '@mui/icons-material/Edit';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
export default function ViewOrderDetails() {
    const { id } = useParams();
    const [form, setForm] = React.useState({});
    const {enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState("");
    const navigate = useNavigate();

    React.useEffect(() => {
        getData();
    }, []);


    const updateForm = (updates) => {
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };

    const received = () => {
    
        axios.post(`/admin/payment/${order.paymentid}/received` )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return enqueueSnackbar(response.data.message, { variant: 'error'});
            }   
            updateFormField("wasrecieved", 1);         
            enqueueSnackbar("Payment marked as received", { variant: 'success'});
        })
        .catch(error => {
            console.debug(error);
            return enqueueSnackbar("Error marking payment as received: " + error, { variant: 'error'})
        });
    }

    const updateFormField = (name, value) => {
        formRef.current.updateFormValue(name, value);
    }

    const notreceived = () => {
        axios.post(`/admin/payment/${order.paymentid}/notreceived` )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return enqueueSnackbar(response.data.message, { variant: 'error'});
            }            
            updateFormField("wasrecieved", 0);
            enqueueSnackbar("Payment marked as not received", { variant: 'success'});
        })
        .catch(error => {
            console.debug(error);
            return enqueueSnackbar("Error marking payment as not received: " + error, { variant: 'error'})
        });

    }

    const markpaid = () => {
        // call remove role on the server
        let uniqueref = form['uniqueref'];
        uniqueref = uniqueref ? uniqueref.trim() : "";
        setLoading(true);
        if(uniqueref == "") {
            setError("You must provide the unique reference of the worldnet payment to associate with this order.");
            return;
        }
        setError("");
        axios.post(`/admin/order/markpaid/${order.id}`, {
            uniqueref,
            reconciliationid: -1
        } )
        .then(response => {
            if (response.data.error) {
                setLoading(false);
                return setError(response.data.message);                
            }
            setLoading(false);
            setShowConfirm(false);
            enqueueSnackbar("Order marked as paid", { variant: 'success'});
            navigate(0);
        })
        .catch(error => {
            setLoading(false);
            return enqueueSnackbar("Error marking order as paid: " + error, { variant: 'error'})
        });
     }
     

    const dataUsed = (orderDetails) => {
        setOrder(orderDetails);
    }

    async function getData() {
        axios.get('/admin/order/id/' + id)
            .then(response => {
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                setOrder(response.data.record);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }

    const formRef = useRef()

    return (
        <>
        <Panel>
            <Form 
                id={id} 
                objtype={"order"} 
                readonly={true} 
                showbuttons={false} 
                onData={dataUsed}
                ref={formRef}                 
            />       
            {order && order.paymenttype == 'CQ' && order.wasrecieved == 0 && <Grid container spacing={2} justifyContent="flex-end"><Grid item><Primary title="Mark Received" onClick={received}/></Grid></Grid>}     
            {order && order.paymenttype == 'CQ' && order.wasrecieved == 1 && <Grid container spacing={2} justifyContent="flex-end"><Grid item><Primary title="Mark Not Received" onClick={notreceived}/></Grid></Grid>}     
        </Panel>
        {order != null && <OrderDetails id={id} order={order} withdraw={false}/>}
        {order && order.orderstatusid == 4 &&
            <Primary title="Mark Order As Paid" onClick={() => setShowConfirm(true)}/>
        }{order && order.orderstatusid == 4 && showConfirm &&
            <ResponsiveDialog title="Please Confirm Your Request" 
                onPrimaryClick={markpaid} 
                onCloseClick={() => setShowConfirm(false)}
                primaryButtonIcon={<EditIcon/>} 
                primaryButtonLabel="Mark Order Paid" 
                open={showConfirm}
                description="Please provide the Worldnet Unique Reference code for the payment used to pay for this order."
                loading={saving} 
                error={error}>
                <FormBuilder fields={MarkOrderPaidFields} form={form} updateForm={(updates) => updateForm(updates)} />
            </ResponsiveDialog>                            
                }
        </>
    )
}