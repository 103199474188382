import * as React from 'react';
import {
    useLocation,
    Navigate,
} from 'react-router';
import axios from 'axios';
import { JwtAuthProvider } from './JwtAuthProvider';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoggedIn, loggedin, loggedout} from "../app/loginSlice";
import { setMessage } from '../app/messageSlice';
import { useSnackbar } from 'notistack';
import { MetaStore } from '../app/MetaStore';

interface AuthContextType {
    isIn: () => boolean;
    signin: (user: string, password: string, callback: (result: any) => any) => void;
    signout: (userid: number, callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // let [user, setUser] = React.useState<any>(null);
    const isLoggedIn = useSelector(selectIsLoggedIn)

    let signin = (newUser: string, password: string, callback: Function) => {
        return JwtAuthProvider.signin({username: newUser, password: password }, (result: any) => {
            // make sure the login worked
            if (!result.auth) {
                if(result.error.data.message) {
                    enqueueSnackbar(result.error.data.message, { variant: 'error'})    
                } else {
                    enqueueSnackbar("Invalid user name or password", { variant: 'error'})
                }
                axios.defaults.headers.common['x-access-token'] = '';
                return callback(result);
            }

            // make sure this username has access to the admin area
            if(result.user.permissions.length == 0) {
                enqueueSnackbar("You do not have access to the Administration area", { variant: 'error'})
                axios.defaults.headers.common['x-access-token'] = '';
                return callback({
                    auth: false,                    
                });
            }
            console.debug("User " + newUser + " logged in using pwd " + password);
            axios.defaults.headers.common['x-access-token'] = result.token;
            console.debug(axios.defaults);
            dispatch(loggedin({token: result.token, user: result.user, refreshtoken: result.refreshtoken, isLoggedIn: true}));
            dispatch(setMessage({message: 'logged in', type: 'success'}));
            MetaStore.initialiseStore(callback, result);
            // callback(result);
        });
    };

    let signout = (userid: number, callback: Function) => {        
        return JwtAuthProvider.signout(userid, (result: any) => {
            if (result.error) {
                enqueueSnackbar(result.error, { variant: 'error'})
                return callback(result);
            }
            dispatch(loggedout());
            enqueueSnackbar("Logged Out", { variant: 'success'})
            callback(result);
        });
    };

    let isIn = () => {
        return isLoggedIn;
    };

    let value = { isIn, signin, signout };

    return (
            <AuthContext.Provider value={value}>{children}</AuthContext.Provider> 
    );
}

function useAuth() {
    return React.useContext(AuthContext);
}


function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();


    if (!auth.isIn()) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        console.debug("Redirectig to login page " + location.pathname);
        return <Navigate to="/login" state={{ from: location.pathname }} />;
    }

    return children;
}




export { AuthProvider, useAuth, RequireAuth };
