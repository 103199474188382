import * as React from 'react';
import { useState } from 'react';
import {
    useNavigate, useLocation,
} from 'react-router';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet } from "react-router";
import { GlobalStyles } from '../theme';
import { useAuth } from '../auth/AuthProvider';
import { withSnackbar } from 'notistack';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NumbersIcon from '@mui/icons-material/Numbers';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser, selectToken } from "../app/loginSlice";
import axios from 'axios';
import { Container } from '@mui/material';
import useLocalStorage from '../app/useLocalStorage';
import { Copyright } from '../containers/Layout';
import { useParams } from 'react-router';
import ScoringAppMenu  from './components/ScoringAppMenu';
import HMobiledataIcon from '@mui/icons-material/HMobiledata';
import RuleIcon from '@mui/icons-material/Rule';

const drawerWidth: number = 200;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    backgroundColor: 'blue',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function getSelectedIndex(location: any) {
    console.debug("Getting selected index for", location.pathname);
    if (location.pathname.indexOf('/results') != -1) {
        return 1;
    }
    if (location.pathname.indexOf('/dressage') != -1) {
        return 2;
    }
    if (location.pathname.indexOf('/showjumping') != -1) {
        return 3;
    }
    if (location.pathname.indexOf('/crosscountry') != -1) {
        return 4;
    }
    return 0;
}

function ScoringLayout(props: any) {
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = React.useState(getSelectedIndex(location));
    const [open, setOpen] = useLocalStorage(true);
    const [event, setEvent] = useState({id: 0, name: ''});
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);
    const { id } = useParams();

    console.debug(location);
    let navigate = useNavigate();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    let auth = useAuth();
    axios.defaults.headers.common['x-access-token'] = token;

    const handleListItemClick = (event: any, index: React.SetStateAction<number>) => {
        setSelectedIndex(index);
    };

    React.useEffect(() => {
        console.debug("Id: " + id);
        // get the details for this event 
        axios.get("/admin/event/id/" + id)
            .then(response => {
            if (response.data.error) {
                return;
            }
            console.debug("Fetched data", response.data);
            setEvent(response.data.record);
        }).catch(error => {
            console.debug(error);
        });

    }, [id]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open} style={GlobalStyles.scoring.appbar}>
                <Toolbar variant="dense"
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {event && `Scoring: ${event.name}`}
                    </Typography>


                    <Typography
                        component="div"
                        variant="subtitle1"
                        color="inherit"
                        noWrap
                        align="right"
                        sx={{ flexGrow: 1 }}
                    >
                        {user && user.name } |
                    </Typography>

                    <ScoringAppMenu />
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar variant="dense"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer} size="large">
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar >
                <Divider />
                <List>
                    <div>
                    <ListItem button component={RouterLink} to={'/scoring/event/' + id}
                            selected={selectedIndex === 0}
                            onClick={(event: any) => handleListItemClick(event, 0)}
                            title={"View Scoring Summary Page for this event"}
                        >
                            <ListItemIcon>
                                <DashboardIcon color={selectedIndex === 0 ? "success" : "inherit"} />
                            </ListItemIcon>
                            <ListItemText primary="Summary" />
                        </ListItem>
                        <ListItem button component={RouterLink} to={'/scoring/results/' + id}
                            selected={selectedIndex === 1}
                            onClick={(event: any) => handleListItemClick(event, 1)}
                            title={"View Full scores for the event"}
                        >
                            <ListItemIcon>
                                <NumbersIcon color={selectedIndex === 1 ? "success" : "inherit"} />
                            </ListItemIcon>
                        <ListItemText primary="Results" />
                        </ListItem>
                        {/* <ListItem sx={!open ? { display: 'none' } : {}} >
                            <ListItemText primary="Score Discipline" />
                        </ListItem>
                        <ListItem button component={RouterLink} to={'/scoring/' + id + '/dressage'}
                            selected={selectedIndex === 2}
                            onClick={(event: any) => handleListItemClick(event, 2)}
                            title={"Score the dressage competition"}
                        >
                            <ListItemIcon>
                                <FormatShapesIcon color={selectedIndex === 2 ? "success" : "inherit"} />
                            </ListItemIcon>
                            <ListItemText primary="Dressage" />
                        </ListItem>
                        <ListItem button component={RouterLink} to={'/scoring/' + id + '/showjumping'}
                            selected={selectedIndex === 3}
                            onClick={(event: any) => handleListItemClick(event, 3)}
                            title={"Score the show jumping competition"}
                        >
                            <ListItemIcon>
                                <HMobiledataIcon color={selectedIndex === 3 ? "success" : "inherit"} />
                            </ListItemIcon>
                            <ListItemText primary="Show Jumping" />
                        </ListItem>
                        <ListItem button component={RouterLink} to={'/scoring/' + id + '/crosscountry'}
                            selected={selectedIndex === 4}
                            onClick={(event: any) => handleListItemClick(event, 4)}
                            title={"Score the cross country competition"}
                        >
                            <ListItemIcon>
                                <RuleIcon color={selectedIndex === 4 ? "success" : "inherit"} />
                            </ListItemIcon>
                            <ListItemText primary="Cross Country" />
                        </ListItem> */}
                    </div>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    marginLeft: 0,
                    paddingLeft: 0
                }}
            >
                <Toolbar />

                <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }} style={{ maxWidth: '100vw' }}>
                    <Outlet />
                </Container>

            </Box>

        </Box>
    );
}

// const mapStateToProps = (state:any) => ({
//     message: state.message.message
//   });

// const SchedulerLayout = connect(mapStateToProps)(withSnackbar(SchedulerLayout))
export { Copyright };
export default withSnackbar(ScoringLayout)