import * as React from 'react';
import { TextField } from '@mui/material';



const DrMovementScore = React.forwardRef((
    { movement, onSpace, space, onChange }, ref) => {
    const inputRef = React.useRef(); 

    const getBackground = () => {
        if(movement.score == '') {
            return "rgb(255,255,230)";
        }
        if(movement.score < 3 || movement.score > 9) {
            return "rgb(255,228,225)";
        }
        return "rgb(255,255,255)";
    }

    const getColor = () => {
        if(movement.score == '') {
            return "rgb(255,255,230)";
        }
        if(movement.score < 3 || movement.score > 9) {
            return "rgb(255,228,225)";
        }
        return "rgb(255,255,255)";
    }


    return (
        <TextField
            // variant={variant}
            // type="tel"
            inputRef={(el) => { 
                if (el && ref) {
                    // el.focus = () =>  inputRef.current.focus();
                    ref(el)
                }
                inputRef.current = el;
            }} 
            autoFocus={movement.number == 1}
            inputProps={{ min: 0, style: { textAlign: 'center', background: getBackground() }}} 
            sx={{
                width: 50
            }}
            size="small"
            value={movement.score}
            helperText={`#${movement.number} ${movement.isdouble ? ' (x2)' : ''}`}            
            onKeyDown={(e) => {
                // console.log("Key Down: " + e.keyCode);
                if(e.keyCode === 8) {
                    onChange(movement, "");
                    onSpace(false);
                }
                if(e.keyCode == 32) {
                    onSpace(true);
                }
            }}
            onKeyUp={(e) => {
                // console.log("Key Up: " + e.keyCode);
                if(e.keyCode == 32) {
                    onSpace(false);
                }
            }}
            onKeyPress={(e) => {
                console.log(e.key);
                if (e.key === " ") {
                    // console.log("Enter space pressed");
                    e.preventDefault();
                    return
                } 
                let v = e.key;
                
                if((e.key >= 0 && e.key <= 10) || v == '!' ) {
                    // console.log("Score: " + e.key);
                    if(space) {
                        v = `${e.key}.5`;
                    }      
                    if(v == '!') {
                        v = 10;
                    }               
                    // setValue(v);
                    onChange(movement, v);
                }

            }}
        />
    );
});

export default DrMovementScore ;
