import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

export default function Save(props:any) {
    return (
        <LoadingButton
        color="primary"
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
        {...props}
      >
        {props.title ? props.title : "Save"  }
      </LoadingButton>
    )
}


