// import * as React from 'react';

interface PagePropertyDef {
    id: string;
    title: string;
    filter?: any;
    defaultFilter?: any;
    canCreate?: boolean;

}


// interface PageProperty {
//     name: PagePropertyDef;
// }
// { eventrole: { title: string; filter: { archived: { id: string; name: string; }[]; }; }; }

export const pages: Array<PagePropertyDef> = [
    {
        id: 'eventrole',
        title: "Manage Event Roles",
        canCreate: true,
        defaultFilter: { archived: "0"},
        filter: [
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
    },
    {
        id: 'shop',
        title: "Shop Items",
        canCreate: true,
        defaultFilter: { archived: "0"},
        filter: [
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
    },
    {
        id: 'team',
        title: "Teams",
        canCreate: true,
        defaultFilter: { archived: "0"},
        filter: [
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
    },
    {
        id: 'sponsor',
        title: "Manage Sponsors",
        canCreate: true,
        defaultFilter: { archived: "0"},
        filter: [
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
    },
    {
        id: 'dayticket',
        title: "Manage Day Tickets",
        canCreate: false,
        defaultFilter: { archived: "0"},
        filter: [
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
    },
    {
        id: 'membertype',
        title: "Manage Member Types",
        canCreate: false,
        defaultFilter: { archived: "0"},
        filter: [
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
    },
    {
        id: 'ridingoption',
        title: "Manage Rider Options",
        canCreate: false,
        defaultFilter: { archived: "0"},
        filter: [
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
    },
    {
        id: 'phrase',
        title: "Dressage Test Phrases",
        canCreate: true,
        defaultFilter: { },
        filter: []
    }
];
