import * as React from 'react';
  
import { Grid, Typography, TextField, ToggleButtonGroup, ToggleButton, Tooltip } from "@mui/material";
import Panel from '../../components/containers/Panel';
import DrTotal from './DrTotal';
import DrMovementScore from './DrMovementScore';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
const statii = [
    'OK', 'EL', 'WD', 'RET', 'DNS', 'TEL', 'WDBHI', 'WDHI', 'ELHI'
];

const drreasons = [
    '', 'OT', 'FR'
];

export default function DrJudgeMovements(
    { scores, test, judgename, onScoreChange, onPenaltyChange, 
        onDrReasonChange, onChangeStatus, onCommentChange, onOtherErrorChange}) {
    const inputRefs = React.useRef([test.nummoves+1]); 
    const [spacePressed, setSpacePressed] = React.useState(false);

    // const movements = () => {
    //     const m = [];
    //     for(let i = 1; i <= test.nummoves; i++) {
    //         m.push(i);
    //     }
    //     return m;
    // }

    const onSpace = (pressed) => {
        setSpacePressed(pressed);
    }

    const onChange = (m, v) => {
        // console.log("Change value: " + v);
        onScoreChange(m.number, v);
        if(inputRefs.current[m.number+1]) {
            setTimeout(() => {
                inputRefs.current[m.number+1].focus();
              }, "50");
        }
    }      

    const setInputRef = (index, ref) => {
        inputRefs.current[index] = ref;
    }

    return (
        <>            
            <Typography variant='h6'>Judge: {judgename} </Typography>
            <Grid container spacing={1}>
                <Grid item sm={9}>
                    <Panel>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                            <Grid container spacing={1}>
                            {scores.DressageMovementScores.map((m, index) => {
                                return (
                                    <Grid item key={`drmovement-${index}`}>
                                        <DrMovementScore 
                                            movement={m}
                                            onChange={onChange}
                                            value={scores[m.number-1]}
                                            ref={(ref) => {setInputRef(m.number, ref)}} 
                                            space={spacePressed}
                                            onSpace={onSpace}
                                        />
                                    </Grid>
                                )
                            })}
                            </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Typography sx={{pr:1, fontWeight: 'bold'}}>Comment: </Typography>
                                <TextField
                                    size="small"
                                    label="Comment"
                                    sx={{width: "100%"}}
                                    value={scores.comment}
                                    multiline
                                    rows={2}
                                    onChange={(v) => onCommentChange(v.target.value)}
                                />
                            </Grid>                        
                            <Grid item md={3}>
                                <Typography sx={{pr:1, fontWeight: 'bold'}}>Dressage Error: 
                                    <Tooltip placement="top-end" title="Errors of test that can result in elimination">
                                        <HelpOutlineRoundedIcon />
                                    </Tooltip>
                                </Typography>
                                <TextField
                                    type="number"
                                    size="small"
                                    InputProps={{ inputProps: { min: 0, max: 3 } }}                            
                                    value={scores.penalty}
                                    sx={{
                                        width: "100%"
                                    }}
                                    onChange={(v) => onPenaltyChange(v.target.value)}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Typography sx={{pr:1, fontWeight: 'bold'}}>Other Error: 
                                    <Tooltip placement="top-end" title="Other errors that do not result in elimination and are 2 points each">
                                        <HelpOutlineRoundedIcon />
                                    </Tooltip>
                                </Typography>
                                <TextField
                                    type="number"
                                    size="small"
                                    InputProps={{ inputProps: { min: 0, max: 3 } }}                            
                                    value={scores.othererror}
                                    sx={{
                                        width: "100%"
                                    }}
                                    onChange={(v) => onOtherErrorChange(v.target.value)}
                                />
                            </Grid>
                            <Grid item md={9}>
                                <Typography sx={{pr:1, fontWeight: 'bold'}}>Status: </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={scores.status}
                                    exclusive
                                    onChange={((event, newStatus) => onChangeStatus(newStatus))}
                                    aria-label="drstatus"
                                    sx={{ flexWrap: "wrap"}}
                                    >
                                        {
                                            statii.map((stat, i) => {
                                                return (
                                                    <ToggleButton key={`stat-${i}`} value={stat}>{stat}</ToggleButton> 
                                                )
                                            })
                                        }                    
                                </ToggleButtonGroup> 
                            </Grid>
                            <Grid item md={3}>
                                {scores.status != 'OK' && <Typography sx={{pr:1, fontWeight: 'bold'}}>Reason: </Typography>}
                                {scores.status != 'OK' && <ToggleButtonGroup
                                    color="primary"
                                    value={scores.reason}
                                    exclusive
                                    onChange={(e, newReason) => onDrReasonChange(newReason)}
                                    aria-label="drreasons"
                                    sx={{ flexWrap: "wrap"}}
                                    >
                                        {
                                            drreasons.map((dr, i) => {
                                                return (
                                                    <ToggleButton key={`dr-${i}`} value={dr}>{dr}</ToggleButton> 
                                                )
                                            })
                                        }                    
                                </ToggleButtonGroup>}
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Panel>
                        <DrTotal 
                            test={test} 
                            scores={scores} 
                            penalty={scores.penalty}
                            onPenaltyChange={onPenaltyChange}
                            reason={scores.reason}
                            onReasonChange={onDrReasonChange}
                        />
                    </Panel>
                    <Typography sx={{m:2}}>Note: use shift+1 to enter a score of 10</Typography>

                </Grid>
            </Grid>
        </>
        
    );
}
