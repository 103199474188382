import * as React from 'react';
import { useSnackbar } from 'notistack';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Welcome from './Welcome';
import Summary from './Summary';
import axios from 'axios';
import Panel from '../components/containers/Panel';
import Loading from '../components/Loading';

function DashboardContent() {
    const [summary, setSummary] = React.useState({numusers: 0, events: []});
    const [busy, setBusy] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();


    const ping = () => {
        setBusy(true);
        axios.get("/admin/user/ping")
          .then(response => {
            setBusy(false);
          }).catch(error => {
            setBusy(false);
          });
    };

    React.useEffect(() => {
        ping();
    }, []);

    if(busy) {
        return (
          <Panel>
            <Loading message="Please wait while we load summary details"/>
          </Panel>
        )
      }
    return (
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 240,
                        }}
                    >
                        <Welcome summary={summary}/>
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            
                        }}
                    >
                        <Summary summary={summary}/>
                    </Paper>
                </Grid>
            </Grid>

    );
}

export default function Dashboard() {
    return <DashboardContent />;
}


/*
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                       <Orders />
                    </Paper>
                </Grid>

*/