import PropTypes from 'prop-types';
import { LinearProgress, Typography, Box } from '@mui/material';


CardLoading.propTypes = {
    message: PropTypes.string,
};
export default function CardLoading({message}) {
    return (
        <>
            {message && <Box sx={{ display: 'flex', width: '100%', position: 'relative', top: '75px', justifyContent: 'center' }}>
                <Typography>{message}</Typography>
            </Box>}
            <Box sx={{ justifyContent: 'center', m: 5, p: 5 }}>
                <LinearProgress />
            </Box>
        </>
    )
}