import * as React from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import axios from 'axios';

import SelectRider from '../components/SelectRider';
import Panel from '../../components/containers/Panel';
import { Alert, Grid, Stack, Typography, Chip } from '@mui/material';
import { isInt, isTimeFormat, minutesToDuration, toSeconds, translateXcScore } from '../../utils';
import XcJudgeScores from '../components/XcJudgeScores';
import { getShortCutHandler, xcscorebuttons, clearXcCommentsAndStatus } from '../components/scorebuttons';


export default function CrossCountryScoring() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [entry, setEntry] = useState();
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState("");
  const [fences, setFences] = useState("");

  const entrySelected = (e) => {
    console.log(e);
    if(!e) {
      return;
    }
    if(e.Eventclass.xcjumps == "" || e.Eventclass.xcexptime == 0) {
      setError("The event class for this entry has not been initialised for cross country");
    } else {
      if(e.xcfences.length == 0 || e.xcfences.indexOf('_') != -1) {
        e.xcfences = initialiseFences(e);
      } else {
        const fencelist = e.Eventclass.xcjumps.split(",");
        setFences(fencelist);
        e.xcfences = e.xcfences.split(",");
      }
      
      setEntry(e);
    }

  }

  const initialiseFences = (e) => {
    const fencelist = e.Eventclass.xcjumps.split(",");
    const scores = [];
    for(let i = 0; i<fencelist.length;i++) {
      scores.push(0);
    }
    setFences(fencelist);
    return scores;
  }

  const cloneEntry = () => {
    return JSON.parse(JSON.stringify(entry));
  }

  const onScoreChange = (f, v, isrefusal=false) => {
    console.log(f, v);
    let newEntry = cloneEntry();
    let score = v;
    if((v != 0 && !isInt(v)) || v == 11 || v == 15) {
      const handler = getShortCutHandler(score, xcscorebuttons);
      if(handler != null) {
        newEntry = handler(newEntry, fences, f);
      }
    } else {
      score = parseInt(v)
    }
    newEntry.xcfences[f] = score;

    const canclear = clearXcCommentsAndStatus(newEntry);
    console.log("Canclear " + canclear);
    if(canclear <= 2) {
      newEntry.xccomments = '';
      newEntry.xcreason = '';
      newEntry.xcstatus = 'OK';  
    } else if(canclear == 3 ) {
      newEntry.xccomments = 'Eliminated with 3 refusals';
      newEntry.xcreason = 'R';
      newEntry.xcstatus = 'EL'; 
    } 


    setEntry(newEntry);
    setChanged(true);

    // let newEntry = cloneEntry();
    // let score = isrefusal ? `${v}R` : v;
    // newEntry.xcfences[f] = score;
    // setEntry(newEntry);
    // setChanged(true);
  }

  const onCommentChange = (newComment) => {
    let newEntry = cloneEntry();
    newEntry.xccomments = newComment;
    setEntry(newEntry);
    setChanged(true);
  }

  const onStatusChange = (newStatus) => {
    let newEntry = cloneEntry();
    newEntry.xcstatus = newStatus;
    setEntry(newEntry);
    setChanged(true);
  }

  const onPublishedStatusChange = (newStatus) => {
    if(newStatus == entry.xcpublishstatus) {
      return;
    }
    let newEntry = cloneEntry();
    newEntry.xcpublishstatus = newStatus;
    setEntry(newEntry);
    setChanged(true);
  }
  const onCancel = () => {
    setError("");
    setEntry(null);
    setChanged(false);
  }

  const onReasonChange = (newReason) => {
    let newEntry = cloneEntry();
    newEntry.xcreason = newReason
    setEntry(newEntry);
    setChanged(true);
  }

  const getXcTimepen = (update=null) => {
    // console.log("Get XC Time Pen", update)
    if(!update) {
      update = entry;
    }
    
    const expectedtime = toSeconds(entry.Eventclass.xcexptime);
    
    // let timeXc = (finish - start) - held;
    let timeXc = getXcSeconds();
    if(timeXc <= expectedtime) {
      return 0;
    }
    console.log("expectedtime", entry.Eventclass.xcexptime, expectedtime);
    // console.log("finish", finish, "start", start, "held", held, " = ", timeXc)
    const secondsOver = (timeXc - expectedtime);
    console.log("Seconds over", secondsOver);
    return parseFloat((Math.round((secondsOver*.4) * 10) / 10).toFixed(1));

  }

  const getXcJumppen = (update=null) => {
    if(!update) {
      update = entry;
    }
    let score = 0;
    for(let i = 0; i < update.xcfences.length; i++) {
      let fscore = translateXcScore(update.xcfences[i])
      score += fscore;
    }
    return parseInt(score);
  }

  const onTimeChange = (fieldname, value) => {
    console.log(`Time Change ${fieldname} = ${value}`);
    let newEntry = cloneEntry();
    newEntry[fieldname] = value;
    newEntry.xctime = getXcTime(newEntry);
    setEntry(newEntry);
    setChanged(true);
  }

  const getXcTime = (update=null) => {

    let timeXc = getXcSeconds(update);
    return minutesToDuration(timeXc);
    
  }

  const getTimeDelta = (update=null) => {
    if(update == null) {
      update = entry;
    }

    const xctime = getXcSeconds(update);
    const expectedtime = toSeconds(entry.Eventclass.xcexptime);
    return xctime - expectedtime ;
  }

  const getXcSeconds = (update=null) => {
    if(update == null) {
      update = entry;
    }
    const expectedtime = toSeconds(entry.Eventclass.xcexptime);
    const start = toSeconds(update.xcstarttime);
    let finish = toSeconds(update.xcfinishtime);
    if(isTimeFormat(update.xcstarttime) && isTimeFormat(update.xcfinishtime)) {
      if(finish < start) {
        finish += 3600;
      }
    }
    let held = toSeconds(update.xcheldtime);

    if(!held) {
      held = 0;
    }
    // if(!start || !finish) {
    //   return 0;
    // }

    return (finish - start) - held;
    
  }


  const getXcScore = () => {
    const jumppen = getXcJumppen();
    const timepen = getXcTimepen();

    return jumppen + timepen;
  }

  const validate = () => {
    // TODO make sure each fence has a score
  }

  const onSave = (publish, update=null) => {
    setError("");
    // const callSetEntry = update==null;
    if(!update) {
      update = cloneEntry();
    }

    const jumppen = getXcJumppen(update);
    const timepen = getXcTimepen(update);
    let data = {
      resultid: update.resultid,
      xcfences: update.xcfences.join(","),
      xcreason: update.xcreason,
      xcscore: jumppen + timepen,
      xctimepen: timepen,
      xcjumppen: jumppen,
      xcstatus: update.xcstatus,
      xctime: update.xctime,
      xcstarttime: update.xcstarttime,
      xcfinishtime: update.xcfinishtime,
      xcheldtime: update.xcheldtime,
      xccomments: update.xccomments,
    };

    axios.put("/admin/entrantresults/crosscountry?publish="+publish, data).then(response => {
        if (response.data.error) {
          enqueueSnackbar(response.data.message, { variant: 'error'})
          return;
        }
        enqueueSnackbar("Cross Country results updated", { variant: 'success'});
        // onPublishedStatusChange(publish == 1);
        update.xcpublishstatus = publish == 1;
        setChanged(false);
        // if(callSetEntry) {
          setEntry(update);
        // }
      })
      .catch(error => {
        setError("Error saving scores: " + (error.message ? error.message : ""));
      });        

  }
  if(id < 9080) {
    return (
      <Panel><Alert severity="error">Scoring tool does not support older events </Alert></Panel>
    )
  }
  return (
      <>
        <SelectRider 
          id={id} phase="Cross Country" 
          onSelect={(e) => entrySelected(e)}   
          changed={changed} 
          onChange={() => onSave(0)}
          onChangeAndPublish={() => onSave(1)}
          onCancel={onCancel}
        >
          {entry && <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                  <Stack direction="row">
                      <Typography sx={{pr:1, fontWeight: 'bold'}}>Score: </Typography>
                      <Typography>{getXcScore()}</Typography>
                      <Typography sx={{ml:3, pr:1, fontWeight: 'bold'}}>Status: </Typography>
                      {entry.xcpublishstatus == false ?   <Chip label="Not Pubished" color="error" />:   <Chip label="Published" color="success" />}
                  </Stack>

              </Grid>                      
          </Grid>}
        </SelectRider>
        {error != "" && <Alert severity="error" sx={{ my:1}}>{error}</Alert>}
        {entry && <XcJudgeScores 
            entry={entry}
            onReasonChange={onReasonChange}
            onStatusChange={onStatusChange}
            onCommentChange={onCommentChange}
            onTimeChange={onTimeChange}
            onScoreChange={onScoreChange}
            fences={fences}
            allowedTime={entry.Eventclass.xcexptime}
            timeDelta={getTimeDelta()}
            timePen={getXcTimepen()}
        />}
      </>
  )
}