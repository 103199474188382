import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import LoadingButton from '@mui/lab/LoadingButton';
import draftToHtml from 'draftjs-to-html'; {/* new */ }

import axios from "axios";
import { useSnackbar } from 'notistack';
import SaveIcon from '@mui/icons-material/Save';


export default function EventDescription(props: any) {
    const [editorState, setEditorState] = React.useState<EditorState>();
    const [savedEditorState, setSavedEditorState] = React.useState<EditorState>();
    const [changed, setChanged] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        console.debug("Effect");
        let description = props.description ? props.description : "";
        let es = htmlToDraftBlocks(description);
        setEditorState(es);
        setSavedEditorState(es);
    }, []);

    const htmlToDraftBlocks = (html: string) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const es = EditorState.createWithContent(contentState);
        return es;
    }

    const hasChanged = (newEditorState: EditorState) => {
        if (!savedEditorState) {
            return false;
        }
        const currentContent = savedEditorState.getCurrentContent()
        const newContent = newEditorState.getCurrentContent()

        return currentContent !== newContent;
    }

    // const html = () => {
    //     if(editorState) {
    //         const rawContentState = convertToRaw(editorState.getCurrentContent());
    //         console.debug(rawContentState);
    //         const newHtml = draftToHtml(rawContentState);
    //         return newHtml;
    //     }    
    //     return "";
    // }

    const toHtml = () => {
        if (editorState) {
            let newhtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            return newhtml;
        }
        return "";
    }

    const saveHtml = () => {
        // call save on server
        setSaving(true);
        axios.put("/admin/event", {
            notes: toHtml(),
            id: props.id
        })
            .then(response => {
                console.debug(response);
                if (response.data.error) {
                    enqueueSnackbar("Problem updating event description" + response.data.error, { variant: 'error' })
                } else {
                    enqueueSnackbar("Description saved", { variant: 'success' });
                }
                setSavedEditorState(editorState);
                setSaving(false);
                setChanged(false);

            })
            .catch(error => {
                enqueueSnackbar("Error returned updating event description" + error, { variant: 'error' })
                setSaving(false);
                setChanged(false);

            });
    }

    const onEditorStateChange = (es: any) => {
        // save html
        // setSavedHtml(toHtml(editorState));
        if (hasChanged(es)) {
            setChanged(true);
        } else {
            setChanged(false);
        }
        setEditorState(es);
    };

    return (
        <React.Fragment>
            <LoadingButton
                sx={{ mb: 1 }}
                color="primary"
                loadingPosition="start"
                variant="contained"
                startIcon={<SaveIcon />}
                loading={saving}
                disabled={!changed}
                onClick={() => { saveHtml() }}
            >Save Changes</LoadingButton>
            <Editor
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorState={editorState}
            />
        </React.Fragment>
    )
}