import * as React from 'react';
import { MetaTable } from '../components/table/MetaTable';
import Panel from '../components/containers/Panel';

export default function OldDressageTests() {
    const filterDef = () => {
        return [            
            {
                id: "dressagetestlevelid",
                type: "togglegroup",
                options: [
                    {
                        id: '0',
                        name: '0'
                    },
                    {
                        id: '1',
                        name: '1'
                    },
                    {
                        id: '2',
                        name: '2'
                    },
                    {
                        id: '3',
                        name: '3'
                    },
                    {
                        id: '4',
                        name: '4'
                    }
                ]
            },
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }
        ]
        }

    return (
        <Panel>
            <MetaTable type="olddressagetest" 
                canCreate={false}
                filters={filterDef()}
                defaultFilter={{ archived: "0"}}
                rememberSearch="dressage-tests-table" 
                createLabel={"Add Dressage Test"}
            />
        </Panel>
    )
}
