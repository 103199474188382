import * as React from 'react';
import { Grid} from "@mui/material";
import ChangeRider from '../collections/ChangeRider';
import ChangeHorse from '../collections/ChangeHorse';
import ChangeClass from '../collections/ChangeClass';
import ChangeNote from '../collections/ChangeNote';
import ChangeEntryStatus from '../collections/ChangeEntryStatus';
import AssignToTeam from '../collections/AssignToTeam';
import Fieldset from '../containers/Fieldset';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router';
import Primary from "../buttons/Primary";
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';


export default function EntryActions(props) {
    const [rider, setRider] = React.useState(props.entry.User.firstname + ' ' + props.entry.User.lastname);
    const [horse, setHorse] = React.useState(props.entry.Horse.name);
    const [team, setTeam] = React.useState(props.entry.EvententrantResult.RefTeam && props.entry.EvententrantResult.RefTeam.name ? props.entry.EvententrantResult.RefTeam.name : "");
    const [status, setStatus] = React.useState(props.entry.EvententrantResult ? props.entry.EvententrantResult.resultstatus : "");
    const [eventclass, setEventclass] = React.useState(props.entry.Eventclass.Classtype.name);
    const [notes, setNotes] = React.useState(props.entry.EvententrantExtra.competitorrequests);
    const [entry, setEntry] = React.useState(props.entry);
    const navigate = useNavigate();

    const canWithdraw = () => {
        return entry.status == 'ENTRYCOMPLETED';
    }

    const newRider = (name) => {
        setRider(name);
    }
    
    const newHorse = (name) => {
        setHorse(name);
    }
    
    const newClass = (name) => {
        setEventclass(name);
    }

    const newTeam = (name) => {
        setTeam(name);
    }

    const newStatus = (name) => {
        setStatus(name);
    }
    
    return (
        <Grid container spacing={1}>
            <Grid item md={8} >
                <Fieldset title="Entry" description="From here you can perform certain changes to this entry.">
                    <Grid container spacing={1}>
                        <Grid item md={6} >
                            <TextField
                                sx={{ml:1, mb: 2}}
                                fullWidth
                                id="outlined-read-only-input"
                                label="Rider"
                                value={rider}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"
                            />
                        </Grid>
                        {canWithdraw() && <Grid item md={6} >
                            <ChangeRider id={entry.id} fullWith
                                rider={rider} 
                                horse={horse}
                                class={eventclass}
                                eventclassid={entry.Eventclass.id}
                                update={newRider}
                            />
                        </Grid>}
                        <Grid item md={6} >
                            <TextField
                                sx={{ml:1, mb: 2}}
                                fullWidth
                                id="outlined-read-only-input"
                                label="Horse"
                                value={horse}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"

                            />
                        </Grid>
                        {canWithdraw() && <Grid item md={6} >
                             <ChangeHorse id={entry.id} fullWith
                                rider={rider} 
                                horse={horse}
                                class={eventclass}
                                classtypeid={entry.Eventclass.Classtype.id}
                                update={newHorse}

                            />
                        </Grid>}
                        <Grid item md={6} >
                            <TextField
                                sx={{ml:1, mb: 2}}
                                fullWidth
                                id="outlined-read-only-input"
                                label="Class"
                                value={eventclass}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="standard"

                            />                   
                        </Grid>
                        {canWithdraw() && <Grid item md={6} >
                            <ChangeClass id={entry.id} fullWith
                                    rider={rider} 
                                    horse={horse}
                                    class={eventclass}
                                    classtypeid={entry.Eventclass.Classtype.id}
                                    riderid={entry.User.id}
                                    horseid={entry.Horse.id}
                                    eventid={entry.Eventclass.eventid}
                                    update={newClass}
                                />
                        </Grid>}
                        <Grid item md={6} >
                            <TextField
                                sx={{ml:1, mb: 2}}
                                fullWidth
                                id="outlined-read-only-input"
                                label="Note"
                                value={notes}
                                InputProps={{
                                    readOnly: true,
                                }}
                                rows={3}
                                multiline
                                variant="standard"

                            />                   
                        </Grid>
                        {canWithdraw() && <Grid item md={6} >
                            <ChangeNote id={entry.id} fullWith
                                    notes={notes} 
                                    update={(n) => setNotes(n)}                                    
                                />
                        </Grid>}
                    </Grid> 
                </Fieldset>    
            </Grid>
            <Grid item md={4} >
                {canWithdraw() && <Fieldset title="Assign To Team" description="You can also assign this rider to a specific team by clicking the button below.">
                 <TextField
                    sx={{ml:1, mb: 2}}
                    fullWidth
                    id="outlined-read-only-input"
                    label="Team"
                    value={team}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                />
                <AssignToTeam id={entry.id} fullWith
                    rider={entry.User.firstname + " " + entry.User.lastname} 
                    horse={entry.Horse.name}
                    class={entry.Eventclass.Classtype.name}
                    team={team}
                    classtypeid={entry.Eventclass.Classtype.id}
                    riderid={entry.User.id}
                    horseid={entry.Horse.id}
                    eventid={entry.Eventclass.eventid}
                    update={newTeam}
                />
                </Fieldset>}
                <Fieldset title="Change Entry Status" description="Use the option below to change the status of this entry">
                <TextField
                   sx={{ml:1, mb: 2}}
                   fullWidth
                   id="outlined-read-only-input"
                   label="Current Status"
                   value={status}
                   InputProps={{
                       readOnly: true,
                   }}
                   variant="standard"
               />
               <ChangeEntryStatus id={entry.id} fullWith
                   rider={entry.User.firstname + " " + entry.User.lastname} 
                   horse={entry.Horse.name}
                   class={entry.Eventclass.Classtype.name}
                   classtypeid={entry.Eventclass.Classtype.id}
                   status={entry.EvententrantResult.resultstatus}
                   riderid={entry.User.id}
                   horseid={entry.Horse.id}
                   eventid={entry.Eventclass.eventid}
                   update={newStatus}
               />
               </Fieldset>
                
            </Grid>
            {canWithdraw() && <Grid item md={12} >
                <Fieldset title="Entry Withdrawal" description="You can choose to withdraw this entry and also provide a refund, if so then use the option below. If you just want to withdraw the entry without a refund then use the Change Entry Status instead.">
                    {/*<WithdrawEntry id={props.id} fullWith
                        rider={entry.User.firstname + " " + entry.User.lastname} 
                        horse={entry.Horse.name}
                        class={entry.Eventclass.Classtype.name}
                />*/}
                    <Primary sx={{ ml: 2 }} startIcon={<CreditCardOffIcon/>} onClick={() => { navigate('/entry/withdraw/'+entry.id) }} title={"Withdraw Entry With Refund"} />
                
                </Fieldset>

            </Grid>}
        </Grid>
    )
}