import * as React from 'react';

import { Grid, ButtonGroup, Button, Typography, TextField, ToggleButtonGroup, ToggleButton, MenuItem, Select, Tooltip } from "@mui/material";
import Panel from '../../components/containers/Panel';
import SjScore from './SjScore';
import { useHotkeys } from 'react-hotkeys-hook';
import useOutsideAlerter from '../../util/useOutsideAlerter';
import { sjscorebuttons, getSjShortCutAction, sjshortcuts, canScoreSjFence, hotkeyTooltip } from './scorebuttons';

const statii = [
    'OK', 'EL', 'WD', 'RET', 'DNS', 'TEL'
];

const reasons = [
    {
        id: 'FH',
        name: 'Fall Of Horse'
    },
    {
        id: 'FR',
        name: 'Fall Of Rider'
    },
    {
        id: 'R',
        name: 'Refusal'
    },
    {
        id: 'C',
        name: 'Compulsary Retirement'
    },
    {
        id: 'OT',
        name: 'Other, error of course, incorrect behaviour, etc'
    }
];

export default function SjJudgeScores(
    { entry, onScoreChange, onReasonChange,
        onStatusChange, onCommentChange, onTimeChange,
        fences, allowedTime, onPublishClear }) {
    const [focusFence, setFocusFence] = React.useState();
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setFocusFence();
    });
        
    // const fence = (fenceindex) => {
    //     if(entry.Eventclass) {
    //         const jumps = entry.Eventclass.sjjumps.split(",");
    //         return jumps[fenceindex];
    //     }
    //     return "";
    // }
    // useHotkeys('a', () => console.log('Key a was pressed'))

    // const hotkey = useHotkeys('a', () => console.log('Key a was pressed'))

    // useHotkeys('r', () => {
    //     if(!focusFence) {
    //         return;
    //     }
    //     console.log("f1");
    //     onScoreChange(focusFence, '1R');
    // });
    // useHotkeys('4', () => onChange(fenceindex, parseInt(value)+4))
    // const hk = useHotkeys(['F1', "1"], () => console.log('Key a was pressed ' + focusFence));
    const hk = useHotkeys(sjshortcuts()/*['F1', 'ctrl+1', 'F2', 'ctrl+2', 'F3', 'ctrl+3', 'F5', 'ctrl+5', 
                            'F6', 'ctrl+6', 'F7', 'ctrl+7', 'F9', 'ctrl+9',
                            'alt+9', 'alt+3', 'alt+5', 'alt+6', 'alt+8', 'ctrl+0', 'f10' ]*/,
        (_, handler) => {
            const key = handler.keys.join('');
            const action = getSjShortCutAction(handler);
            if(action) {
                console.log("Shortcut Action: " + action.shortname);
                return onScoreChange(focusFence, action.score, action);
            }
            console.log("No action found for key handler", handler);           
        },
        {
            enableOnFormTags: ['input']
        }
        );

    const onChange = (f, v) => {
        // console.log("Change value: " + v);
        onScoreChange(f, v);
    }

    
    return (
        <>
            {/* <Typography variant='h6'>Show Jumping Results </Typography> */}
            <Grid container spacing={1} ref={hk}>
                <Grid item sm={9}>
                    <Panel>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                            <div ref={wrapperRef}>
                                <Grid container spacing={1} sx={{justifyContent: 'center', display: 'flex'}}>
                                    {entry.sjjumpscores.map((f, index) => {
                                        return (
                                            <Grid item key={index}>
                                                <SjScore
                                                    fenceindex={index}
                                                    fence={fences[index]}
                                                    onChange={onChange}
                                                    value={entry.sjjumpscores[index]}
                                                    onFocus={() => setFocusFence(index)}
                                                    hasFocus={focusFence == index}
                                                />
                                            </Grid>
                                        )
                                    })}
                                    {focusFence != null && <>
                                    <Grid item xs={12} sx={{justifyContent: 'center', display: 'flex'}}>
                                        <Typography variant="h5"  display="block">Score for Fence {fences[focusFence]}:</Typography>
                                    </Grid>
                                    <Grid item lg={12} sx={{justifyContent: 'center', display: 'flex'}}>
                                        <ButtonGroup style={{ flexWrap: "wrap" }}>
                                            <Button disabled={!canScoreSjFence(entry, focusFence)} onClick={() => onChange(focusFence, parseInt(entry.sjjumpscores[focusFence])+4)} size="small" variant='outlined'>+4</Button>
                                            <Button disabled={!canScoreSjFence(entry, focusFence) || entry.sjjumpscores[focusFence] == 0} onClick={() => onChange(focusFence, parseInt(entry.sjjumpscores[focusFence])-4)} size="small" variant='outlined'>-4</Button>
                                            {sjscorebuttons.map((b, index) => {
                                                return (
                                                    <Tooltip key={`sjbutton-${index}`} title={b.name + hotkeyTooltip(b)} variant="soft"><Button onClick={() => onChange(focusFence, b.score)} size="small" variant='outlined'>{b.shortname}</Button></Tooltip>        
                                                )
                                            })}                                    
                                        </ButtonGroup>   
                                    </Grid></>}
                                    
                                </Grid>   
                                </div>                         
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Comment: </Typography>
                                <TextField
                                    size="small"
                                    // label="Comment"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "100%" }}
                                    value={entry.sjcomments}
                                    onChange={(v) => onCommentChange(v.target.value)}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Status: </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={entry.sjstatus}
                                    exclusive
                                    onChange={((event, newStatus) => onStatusChange(newStatus))}
                                    aria-label="sjstatus"
                                    sx={{ flexWrap: "wrap" }}
                                >
                                    {
                                        statii.map((stat, i) => {
                                            return (
                                                <ToggleButton key={`stat-${i}`} value={stat}>{stat}</ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                                {(entry.sjstatus != 'OK' && entry.sjstatus != '') && <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Reason: </Typography>}
                                {(entry.sjstatus != 'OK' && entry.sjstatus != '') && <Select
                                    fullWidth
                                    value={entry.sjreason}
                                    label="Reason"
                                    onChange={(e) => onReasonChange(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        reasons.map((sj, i) => {
                                            return (
                                                <MenuItem key={`sj-${i}`} value={sj.id}>{sj.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>}
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Panel>
                        <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Allowed Time (Seconds): </Typography>
                        <Typography sx={{ pr: 1 }}>{allowedTime}</Typography>
                        <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Time (Seconds): </Typography>
                        <TextField
                            type="number"
                            size="small"
                            InputProps={{ inputProps: { min: 0 } }}
                            value={entry.sjtime}
                            sx={{
                                width: "100%"
                            }}
                            onChange={(v) => onTimeChange(v.target.value)}
                        />
                        <Button sx={{ mt: 2 }} variant="contained" onClick={onPublishClear} >Mark As Clear and Publish</Button>
                    </Panel>
                </Grid>
            </Grid>
        </>

    );
}
