import * as React from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { Box } from '@mui/material';
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import Panel from '../../components/containers/Panel';
import Title from "../Title";
import Description from "../Description";
import Primary from "../../components/buttons/Primary";
import { Alert } from '@mui/material';
import axios from "axios";
import Back from '../../components/buttons/Back';
import ConfirmDialog from '../../components/ConfirmDialog';
import { useSnackbar } from 'notistack';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { MetaTable } from '../../components/table/MetaTable';

export default function SplitEventClass() {
    const { id } = useParams();
    const [error, setError] = React.useState();
    const [form, setForm] = React.useState({});
    const [eventClass, setEventclass] = React.useState();
    const [openConfirm, setOpenConfirm] = React.useState();
    const {enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [entries, setEntries] = React.useState([]);

        
    React.useEffect(() => {         
        getEventclass();
    },[]);

    const getFormFields = () => {
        let classname = eventClass ? (eventClass.sponsortext || eventClass.Classtype.name) : "";
        return [    
            {
              attribute: "eventclassname1",
              label: "First Class Name",
              component: "text-field",
              defaultValue: eventClass && `${classname} A`,
              validations: {
                required: true,
              },
              col: {
                xs: 6
            }
            },      
            {
                attribute: "eventclassname2",
                label: "Second Class Name",
                component: "text-field",
                defaultValue: eventClass && `${classname} B`,
                validations: {
                  required: true,
                },
                col: {
                    xs: 6
                }
              }
          ];
    }

    const entrySelectedHandler = (rows) => {
        if (rows.length > 1) {
            console.log("All selected");
            setEntries(rows);
            return;
        }

        console.log("Selected individual entry", rows);
        let selectedRows = JSON.parse(JSON.stringify(entries));
        selectedRows.push(rows[0]);
        console.log(selectedRows.length + " rows selected");
        setEntries(selectedRows);
    }

    const entryDeselectedHandler = (rows) => {
        if (rows.length > 1) {
            console.log("All deselected");
            setEntries([]);
            return;
        }
        console.log("Deselected entries", rows);
        // remove row from list
        let selectedRows = [];
        for (var i = 0; i < entries.length; i++) {
            // console.log("Checking " + selectedRows[i].name + " == " + rows[0].name)
            if (entries[i].id != rows[0].id ) {
                selectedRows.push(entries[i]);
            }
        }
        setEntries(selectedRows);
    }
    const getEventclass = () => {
        axios.get("/admin/eventclasses/id/"+id)
        .then(response => {           
            setForm({
                eventclassname1: `${response.data.record.sponsortext || response.data.record.Classtype.name} A`,
                eventclassname2: `${response.data.record.sponsortext || response.data.record.Classtype.name} B`,
            })
    
            setEventclass(response.data.record);

        })
        .catch(error => {
            setError("Problem getting event entry details: " + error);
        });
    }
     
    const getDescription = () => {
        if(!eventClass) {
            return "";
        }
        return `Please confirm that you want to split the ${eventClass.Classtype.name} class into two classes, ${form['eventclassname1']} with ${numEntriesLeft()} entries and ${form['eventclassname2']} with ${entries.length}`;
    }

    const numEntriesLeft = () => eventClass.numentries - entries.length;

    const confirm = () => {
        // make sure the info has been provided
        setError();
        if(!form['eventclassname1'] || !form['eventclassname2']) {
          return setError("Please fill out both class name fields");
        }

        if(form['eventclassname1'] === !form['eventclassname2']) {
            return setError("The 2 classes have the same name, please correct this");
        }

        if(entries.length == 0) {
            return setError("You have not selected the entries you want to add to the second class")
        }

        if(numEntriesLeft() == 0) {
            return setError("You have selected all entries for the second class and left nothing in the first class")
        }

        setOpenConfirm(true);

    }
    
const confirmed = () => {
        axios.post("/admin/eventclasses/"+id+"/split", {
          details: [
              {
                  name: form['eventclassname1'],
              },
              {
                name: form['eventclassname2'],
                entries: entries.map(e => e.id)
            }
          ]
        })
        .then(response => {
            if (response.data.error) {
                return setError("Problem splitting class: " + response.data.message);
            } 
            enqueueSnackbar("Class Split", { variant: 'success'})
            navigate(-1);
        })
        .catch(error => {
            setError("Error getting class type: " + error);
        });
    }
    const updateForm = (updates) => {
        setError();
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    }

    return (
        <Panel>
                <Title>Provide details for splitting class</Title>
                <Description>Please provide the names of the two new classes, and please select (i.e. click the checkbox on the left) those entries in the table below that are to move to the second class. 
                All other entries not selected will stay in the first class. When you have made
                your selections click the &quot;Apply Split&quot; button to confirm the splitting
                of this class or click cancel to go back.
                </Description>
                {eventClass && <FormBuilder fields={getFormFields()} form={form} updateForm={(updates) => updateForm(updates)} />}
                {error && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
                <Box sx={{display: 'flex', mt:2}} justifyContent="flex-end"><Back/><Primary sx={{ ml: 2 }} startIcon={<AltRouteIcon width={24} height={24} />} title={"Apply Split"} onClick={() => { confirm() }}/></Box>
                {eventClass && <MetaTable 
                    type={"evententrantsplit"}
                    dataUrl={"/admin/eventclasses/entries/"+id}
                    selectable={true}
                    rowsPerPage={100}
                    title={"Select Entries For Class 2"}
                    onSelect={entrySelectedHandler}
                    onDeselect={entryDeselectedHandler}
                    multiselect={true}
                />}

                <ConfirmDialog 
                    open={openConfirm} 
                    onConfirm={() => confirmed()}
                    onCancel={() => setOpenConfirm(false)}
                    title="Confirm Splitting Of Class"
                    description={getDescription()}
                />
        </Panel>
    )
}