import * as React from 'react';
import { Button } from '@mui/material';

export default function Secondary(props:any) {
    return (
        <Button
        color="primary"
        variant="outlined"
        {...props}
      >
        {props.title}
      </Button>
    )
}




