import React, { forwardRef, Fragment, useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, Select, TextField } from "@mui/material";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useValidation } from "../../Hooks/useValidation";
import { Title } from "../Widgets/Title";
import { shuffleArray } from "../Utils/helpers";

const getValue = (value, field, updateForm) => {
  if (value === null || value === undefined) {
    // if(field.defaultValue) {
    //   updateForm({ [field.attribute]: field.defaultValue });
    // }
    return field.defaultValue || "";
  }
  return value;
};

const getDisplayValue = (value, options) => {
  if (value === null || value === undefined) {
    return "";
  }
  console.debug(value);
  if(!options) {
    return "";
  }
  for(let i =0; i < options.length; i++) {
    if (options[i].id == value) {
       return options[i].name;
    }
  }
  return "";
};

const StandardSelect = forwardRef((props, ref) => {
  const { field, value, updateForm, showTitle, readonly } = props;
  const { errors, validate, reset } = useValidation("string", field.validations);

  const optionConfig = useMemo(
    () => (option) => {
      const config = {
        key: option,
        value: option,
        label: option,
      };

      if (!field.optionConfig) {
        return config;
      }

      config.key = field.optionConfig.key
        ? get(option, field.optionConfig.key)
        : config.key;
      config.value = field.optionConfig.value
        ? get(option, field.optionConfig.value)
        : config.value;
      config.label = field.optionConfig.label
        ? String(get(option, field.optionConfig.label))
        : config.label;

      return config;
    },
    [field]
  );

  const componentProps = (field) => {
    return {
      id: field.id || field.attribute,
      native: true,
      size: "small",
      inputProps: {
        name: field.attribute,
        id: field.id || field.attribute,
      },
      value: readonly ? getDisplayValue(value, field.options) : getValue(value, field, updateForm),
      onChange: (event) =>
        updateForm({ [field.attribute]: event.target.value }),
      onBlur: () => validate(value),
      label: field.label,
      ...field.props,
    };
  };

  const componentPropsReadOnly = (field) => {
    return {
      id: field.id || field.attribute,
      fullWidth: true,
      size: "small",
      inputProps: {
        name: field.attribute,
        id: field.id || field.attribute,
      },
      value: getDisplayValue(value, field.options),
      label: field.label,
    };
  };

  const options = useMemo(() => {
    if (field.randomizeOptions) {
      return shuffleArray(field.options || []);
    }
    return field.options || [];
  }, [field.options]);

  if(readonly) {
    return (
      <Fragment>
      {showTitle && field.title && <Title field={field} />}
      <TextField
        InputProps={{
          readOnly: true,
        }}
        {...componentPropsReadOnly(field)}
        variant="standard"
      />
    </Fragment>
    )
  }

  return (
    <Fragment>
      {showTitle && field.title && <Title field={field} />}
      <FormControl variant="outlined" fullWidth error={typeof error != 'undefined' && error.length > 0}>
        <InputLabel size="small" htmlFor={field.id || field.attribute}>
          {field.label}
        </InputLabel>
        <Select
          inputRef={(el) => {
            if (el && ref) {
              el.validate = (value) => validate(value);
              el.reset = () => reset();
              ref(el);
            }
          }}
          {...componentProps(field)}
        >
          <option aria-label="None" value="" />
          {options.map((option) => (
            <option
              key={optionConfig(option).key}
              value={optionConfig(option).value}
            >
              {optionConfig(option).label}
            </option>
          ))}
        </Select>
        <FormHelperText error={true}>{errors[0]}</FormHelperText>
      </FormControl>
    </Fragment>
  );
});

StandardSelect.displayName = "StandardSelect";

StandardSelect.defaultProps = {
  updateForm: () => {},
  showTitle: true,
};

StandardSelect.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateForm: PropTypes.func,
  showTitle: PropTypes.bool,
};

export { StandardSelect };
