import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import {Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import CardLoading from '../../scheduler/components/CardLoading';
import TabPanel from '../../components/containers/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Panel from '../../components/containers/Panel';
import useLocalStorage from '../../app/useLocalStorage';
import ReportGroup from '../components/ReportGroup';

function DashboardContent() {
    const [reportGroups, setReportGroups] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [value, setValue] = useLocalStorage('view-report-tab', 0);

    React.useEffect(() => {
        // get the details for this event 
        setIsLoading(true);
        axios.get("/admin/report")
            .then(response => {
            if (response.data.error) {
                enqueueSnackbar("Unable to fetch report data " + response.data.message, { variant: 'error'});
                return;
            }
            console.debug("Fetched data", response.data);
            setReportGroups(response.data.records);
            setIsLoading(false);
        }).catch(error => {
            enqueueSnackbar("Error while fetching report data " + error, { variant: 'error'});
        });

    }, []);

    const a11yProps = (index) => {
        return {
          id: `report-tab-${index}`,
          'aria-controls': `report-tabpanel-${index}`,
        };
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(isLoading) {
        return <CardLoading message="Loading reports..."/>
    }

    return (
        <React.Fragment>
        <Panel>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                    variant="scrollable" 
                    scrollButtons
                    value={value}   
                    onChange={handleChange} 
                    aria-label="event details"
                >
                    {reportGroups.map((group, index) => {
                        return (
                            <Tab key={"tab-"+index} label={group.name} {...a11yProps(index)} />
                        )
                    }) }
                </Tabs>
            </Box>
            {reportGroups.map((group, index) => {
                return (
                    <TabPanel key={"tappanel-"+index} value={value} index={index} >
                        <ReportGroup group={group} />
                    </TabPanel>
                )
            }) }         
        </Panel>              
        
    </React.Fragment>

    );
}

export default function ReportsDashboard() {
    return <DashboardContent />;
}