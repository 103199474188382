import * as React from 'react';
import { MetaTable } from '../components/table/MetaTable';
import Panel from '../components/containers/Panel';
import OrdersTable from '../components/eventing/OrdersTable';

export default function ReconciliationLog(props:any) { 

    return (
        <Panel>
            <MetaTable 
                title="Reconciliation Log"
                stickyHeader={true}
                type="reconciliationlog" 
                canCreate={false}
                rememberSearch="reconciliationlog-table" 
        />
        </Panel>
    )
}
