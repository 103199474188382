import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { Grid, Typography, Button, Stack, TextField } from '@mui/material';
import axios from 'axios';
import Panel from '../components/containers/Panel';
// import Loading from '../components/Loading';
// import EntriesTable from '../components/eventing/EntriesTable';
import { MetaTable } from '../components/table/MetaTable';
import Back from "../components/buttons/Back";
import ConfirmationDialog from '../components/collections/ConfirmationDialog';
import EnhancedTable from '../components/table/EnhancedTable';
import { getDisplayValue } from '../utils';


const tableFields = [
  {
      id: "horsename",
      label: "Horse",
      properties: {
          component: "text-field",
      }
  },
  {
      id: "ridername",
      label: "Rider",
      properties: {
          component: "text-field"
      }
  },
  {
      id: "refundstatus",
      label: "Refund Status",
      format: getDisplayValue,
      properties: {
          component: "select"
      }
  },
  {
      id: "result",
      label: "?",
      properties: {
          component: "text-field"
      }
  }
];

export default function BulkRefund() {
    const { id } = useParams();
    const [deduction, setDeduction] = React.useState(0);
    const [refunding, setRefunding] = React.useState(false);
    const [cancelled, setCancelled] = React.useState(false);
    const [busy, setBusy] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [eventclass, setEventclass] = React.useState(null);
    const [entries, setEntries] = React.useState(null);
    const [showConfirm, setShowConfirm] = React.useState(false);

    React.useEffect(() => {
        setBusy(true);
        axios.get("/admin/eventclass/id/"+id)
          .then(response => {
            if(response.data.error) {
              return enqueueSnackbar("Problem getting event class details, not such id", { "variant": "error" });
            }
            setEventclass(response.data.record);
            setBusy(false);
          }).catch(error => {
            setBusy(false);
          });
    }, [id]);

    const canrefund = () => {
      return eventclass && eventclass.numentries > 0 && !refunding;
    };
      
    const cancel = () => {
      setRefunding(false);
      setCancelled(true);
    }

    const finished = () => {
      console.log("Checking finished", entries );
      const todo = entries.filter(e => e.refundstatus == 'Not Refunded');
      return todo.length === 0;
    }

   
    // const refundEntries = (classentries) => {
    //   let entry;
    //   if(classentries) {
    //     entry = classentries.find(e => e.refundstatus == 'Not Refunded');
    //   } else {
    //     entry = entries.find(e => e.refundstatus == 'Not Refunded');
    //   }
      
    //   if(!entry) {
    //     enqueueSnackbar("All entries are refunded", { "variant": "success" });
    //     setFinished(true);
    //     return;
    //   }
    //   refundEntry(entry);
    // }

    React.useEffect(() => {
      if(!entries) {
        return;
      }
      const entry = entries.find(e => e.refundstatus == 'Refunding');
      if(!entry) {
        return;
      }

      axios.post("/admin/evententrant/"+entry.id+"/refund", { deduction: deduction})
        .then(response => {
        if(response.data.error) {
          entry.result = response.data.message;
          entry.refundstatus = 'Failed';          
        } else {
          entry.result = response.data.message;
          entry.refundstatus = 'Refunded';
        }
        setEntries(getNextEntry(entries, entry));
      });
    }, [entries]);


    const loadEntries = () => {
      setRefunding(true);
      axios.get("/admin/eventclass/"+id+"/entriestorefund")
      .then(response => {
        if(response.data.error) {
          return enqueueSnackbar("Problem getting entry details, no such id", { "variant": "error" });
        }
        let classentries = response.data.records.map(l => {
          return {
            ...l,
            refundstatus: 'Not Refunded',
            result: ''
          }
        });
        // start the ball rolling with the first entry to be refunded
        setEntries(getNextEntry(classentries));

      }).catch(error => {
        setBusy(false);
      });
    }

    const getNextEntry = (list, currententry) => {
      let found = false;      
      list = list.map(e => {
        if(currententry && e.id == currententry.id) {
          e.refundstatus = currententry.refundstatus;
          e.result = currententry.result;
        } else if(!found && e.refundstatus == 'Not Refunded') {
          e.refundstatus = 'Refunding';
          found = true;
        }
        return e;
      });
      return list;
    }

    // const refundEntry = (entry) => {
    //   entry.result = 'Refunding';
    //   updateEntryState(entry);
      
    // }

    const refundConfirmed = () => {
      setShowConfirm(false);
      setCancelled(false);
      loadEntries();      
    }

    const currency = () => {
      if(eventclass.currency == 'pound') {
        return '£'
      }
      return '€'
    }

    return (
           <><Panel>
            {eventclass && <Grid container justifyContent="flex" >
              <Grid item >
                  <Typography color={"primary"}  noWrap variant="h6" component="h3">{"Bulk Refund: " + eventclass.Classtype.name}</Typography>
                  <Typography variant="body2" sx={{mb:3}}>To start refunding the entries listed below click the refund button, otherwise click Back to return to the previous screen.</Typography>
                  <TextField type="number" size="small" label="Deduct From Entry Fee" value={deduction} onChange={(event) => setDeduction(event.target.value) }></TextField>
              </Grid>
                  
              <Grid item xs={12}>
                  <Stack sx={{pl:5}} direction="row" justifyContent="flex-end" spacing={2} >
                      <>
                          {canrefund() && <Button onClick={() => setShowConfirm(true)} variant="contained" color="success">Refund</Button>}
                          {entries && !finished() && !cancelled && <Button onClick={() => cancel()} variant="contained" color="success">Cancel</Button>}
                      </>
                      {!refunding && <Back/>}
                  </Stack>
              </Grid>
            </Grid>}             
            </Panel>
            <Panel>

            {eventclass && !cancelled && !refunding &&
              <MetaTable 
                dataUrl={`/admin/eventclass/${id}/entriestorefund`} 
                type="evententrantrefund" 
                idvalue={id}
                idname="eventclassid"
            />}

            {entries && 
                <EnhancedTable 
                  columns={tableFields} 
                            rows={entries} 
                            noHeader={true}
                            rowsPerPage={100} 
                            placeholder={""} 
                            selectable={false}
                        />}
            
           </Panel>
           {showConfirm && 
              <ConfirmationDialog title={"Please Confirm"} 
                description={"Are you sure you want to refund all these entries?" + (deduction > 0 ? ` Note: ${currency()}${deduction} will be deducted from the entry fee`: "") }
                        onConfirm={refundConfirmed} open={showConfirm}
                        onCancel={() => { setShowConfirm(false)}} 
              />}

           </>

    );
}

