import React, { forwardRef, Fragment, useCallback, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";
import PropTypes from "prop-types";
import { useValidation } from "../../Hooks/useValidation";
import { Title } from "../Widgets/Title";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Schedule } from "@mui/icons-material";
import {
  DesktopTimePicker,
  LocalizationProvider,
  MobileTimePicker,
  
} from "@mui/x-date-pickers";


const StandardTimePicker = forwardRef((props, ref) => {
  const { field, value, updateForm, showTitle, readonly } = props;
  const { errors, validate, reset } = useValidation("date", field.validations);
  const [open, setOpen] = useState();
  const [error, setError] = useState();
  let timeFormat = "HH:mm:ss";
  
  const component = useCallback(
    (props) => {
      // if (field.keyboard) {
        return <DesktopTimePicker {...props} />;
      // }
      // return <MobileTimePicker {...props} />;
    },
    [field.keyboard]
  );

  const onChange = (value, field, inputformat) => {
    setError();
    // format the value for storing in the form
    try{
      updateForm({ [field.attribute]: format(value, inputformat )});
    } catch(e) {
      // an invalid date string
      updateForm({ [field.attribute]: value});
    }
    
  }

  const onError = (reason, value) => {
    if (reason === "minTime") {
      setError(
        "Time should not be before " +
          format(field.props.minTime, TIME_FORMAT)
      );
    }
    if (reason === "maxTime") {
      setError(
        "Time should not be after " +
          format(field.props.maxTime, TIME_FORMAT)
      );
    }
  }

  const commonProps = (field, inputformat, mask, view) => {
    timeFormat = format;
    return {
      id: field.id || field.attribute,
      sx: {
        my: 0,
      },
      ampm: false,
      label: field.label,
      inputFormat: inputformat,
      mask: mask,
      view: view,
      onChange: (value) => onChange(value, field, inputformat),
      onError: (reason, value) => onError(reason, value),
      // open: !!open,
      onClose: () => setOpen(),
      renderInput: (params) => (
        <TextField
          fullWidth
          size="small"
          {...params}
          onClick={() => setOpen(true)}
          error={!!error || errors.length > 0}
          helperText={error || errors[0]}
        />
      ),
      value: getValue(value, inputformat),
      // InputProps: {
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       <IconButton aria-label="open time picker" size="large">
      //         <Schedule />
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      //   style: {
      //     paddingRight: 0,
      //   },
      // },
      onBlur: () =>
        validate(value),
      onKeyDown: (event) => {
        if (event.which === 13) {
          validate(value);
        }
      },
    }
  };

  const defaultTimePicker = (field) => {
    let props = {
      ...(commonProps(field, "HH:mm", "__:__", ['hours', 'minutes'])),
      // value: value ? parse(value, "HH:mm", new Date()) : null,
    }
    console.log("HH:mm", props);
    return props;
  }

  const fullTimePickerWithSeconds = (field) => {
    let props = {
      ...(commonProps(field, "HH:mm:ss", "__:__:__", ['hours', 'minutes', 'seconds'])),
      // value: value ? parse(value, "HH:mm:ss", new Date()) : null,
    }
    console.log("HH:mm:ss", props);
    return props;
  }

  const noTimeHoursPicker = (field) => {
    let props = {
      ...(commonProps(field, "mm:ss", "__:__", ['minutes', 'seconds'])),
      value: value ? parse(value, "mm:ss", new Date()) : null,
    }
    console.log("mm:ss", props);
    return props;
  }

  const componentProps = (field) => {
    if(field.TIME_FORMAT && field.TIME_FORMAT == "HH:mm:ss") {
      return fullTimePickerWithSeconds(field);
    }

    if(field.TIME_FORMAT && field.TIME_FORMAT == "mm:ss") {
      return noTimeHoursPicker(field);
    }

    return defaultTimePicker(field);
  };

  const getFormat = () => {
    return field.TIME_FORMAT || "HH:mm";
  }

  const getValue = (value, inputformat) => {
    if(!inputformat) {
      inputformat = getFormat();
    }
    console.log("Type of value", typeof value);
    if(typeof value === 'object') {
      return value;
    }
    if(typeof value === 'string') {
      return parse(value, inputformat, new Date())
    }

    return value;
  }

  if(readonly) {
    return (
      <Fragment>
      {showTitle && field.title && <Title field={field} />}
      <TextField
        InputProps={{
          readOnly: true,
        }}
        // {...componentProps(field)}
        label={field.label}
        variant="standard"
        value={value}
      />
    </Fragment>
    )
  }
  
  return (
    <Fragment>
      {showTitle && field.title && <Title field={field} />}
      <div
        ref={(el) => {
          if (el && ref) {
            el.validate = (value) => {
              console.log("Validate", value);
              value = getValue(value);
              // validate(value ? parse(value, timeFormat, new Date()) : value);
              return validate(value);
            }
            el.reset = () => reset(); 
            ref(el);
          }
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {component(componentProps(field))}
        </LocalizationProvider>
      </div>
    </Fragment>
  );
});

StandardTimePicker.displayName = "StandardTimePicker";

StandardTimePicker.defaultProps = {
  updateForm: () => {},
  showTitle: true,
};

StandardTimePicker.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  updateForm: PropTypes.func,
  showTitle: PropTypes.bool,
};

export { StandardTimePicker };
