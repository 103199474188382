import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { format } from "date-fns";
import { getDisplayValue } from '../utils';

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function Summary(props:any) {

  if(!props.summary || props.summary.numusers == 0) {
    return (
      <></>
    )
  }
  return (
    <React.Fragment>

      {props.summary.events.length > 0 && <><Title small={true}>Latest Updated Event</Title>
      <Typography sx={{pb:2}} component="p" >{props.summary.events[0].name}</Typography>
      </>}
      {props.summary.events.length > 0 && <><Title small={true}>Latest Updated Date/ Time</Title>
      <Typography sx={{pb:2}} component="p" >{format(new Date(props.summary.events[0].updatedAt), "d MMM yyyy HH:mm")}</Typography>
      </>}
      {props.summary.events.length > 0 && <><Title small={true}>Status</Title>
      {getDisplayValue(props.summary.events[0].status, "status")}
      </>}

      <Title small={true}>Number of Users</Title>
      <Typography sx={{pb:2}} component="p"  >
        {props.summary.numusers}
      </Typography>
      <Title  small={true}>Number of Events</Title>
      <Typography sx={{pb:2}}  component="p" >
        {props.summary.events.length}
      </Typography>

    </React.Fragment>
  );
}

/*
<Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2022
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View
        </Link>
      </div>
*/