import React from "react";
import _ from "lodash";
import axios from "axios";
import FormDialog from "../form/FormDialog";
import EditNoteIcon from '@mui/icons-material/EditNote';

export default function ChangeApplicationNote(props:any) {

    const changeNotesFields = [
        {
            attribute: "applicationnotes",
            label: "Application Note",
            component: 'text-field',
            validationType: "string",
            validations: {
              required: true,
            },
            col: {
              sm: 12,
            },
            props: {
              multiline: true,
              rows: 6
            }
          }
    ];

    const change = (form:any, callback:any) => {
        axios.put("/admin/evententrant", { 
            id: props.id,
            applicationnotes: form['applicationnotes']
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: "Problem changing application notes: " + response.data.message});
            }
            props.update(form['applicationnotes']);
            callback({ error: false, message: "Application Note changed " });
        })
        .catch(error => {
            return callback({ error: true, message: "Error chaning application note: " + error});
        });

        
    };


    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Application Note" 
            title="Application Note Text"
            description={"Enter the updated application notes text below" }
            saveLabel="Update Note"
            fields={changeNotesFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<EditNoteIcon/>)}
            isSecondaryButton={true}
            form={{ applicationnotes: props.applicationnotes }}
       />
    )
}