import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import axios from "axios";

import Panel from '../../components/containers/Panel';
import Title from '../Title';
import Cancel from '../../components/buttons/Cancel';
import Primary from '../../components/buttons/Primary';
import Description from '../Description';

export default function MigrateUnderAge() {
    const { enqueueSnackbar } = useSnackbar();    
    const navigate = useNavigate();

    const apply = () => {
        axios.post("/admin/user/updateunderage")
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return enqueueSnackbar("Problem update under age riders " + response.data.message, { variant: 'error'});
            }
            enqueueSnackbar("Under age riders updated", { variant: 'success'});
            navigate(-1);
        })
        .catch(error => {
            enqueueSnackbar("Problem update under age riders " + error, { variant: 'error'});
        });

        
    };

    return (
        <Panel>
            <Title>Migrate Under Age</Title>
            <Description >
                Please click the Update Membership button below to confirm this request.
                Once clicked all Pony riders who are no longer at pony level will be changed to Junior,
                all Junior riders no longer at that level will be changed to Young Riders and all over age
                Young Riders will be changed to Senior Riders.
            </Description>

            <Primary  sx={{mr:2, mt:2}} title="Update Memberships" onClick={() => { apply() }}/>
            <Cancel sx={{mt:2}} />
        </Panel>
    )
}