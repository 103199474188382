import React from "react";
import _ from "lodash";
import axios from "axios";
import FormDialog from "../form/FormDialog";
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function ChangeEntryStatus(props:any) {

    const changeStatusFields = [
        {
            attribute: "resultstatus",
            label: "Select new status",
            component: 'select',
            validationType: "string",
            validations: {
              required: true,
            },
            options: [
              {
                  id: "WITHDRAWN",
                  name: "WITHDRAWN"
              },
              {
                  id: "CURRENT",
                  name: "CURRENT"
              },
              {
                  id: "PENDING",
                  name: "PENDING"
              },
              {
                  id: "HC",
                  name: "HC"
              }
            ],
            optionConfig: {
              key: "id",
              value: "id",
              label: "name",
            },
            col: {
              sm: 12,
            },
          }
    ];

    const change = (form:any, callback:any) => {
        axios.post("/admin/evententrant/"+props.id+"/changestatus", { 
            resultstatus: form['resultstatus']
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: "Problem changing entry status: " + response.data.message });
            }
            props.update(response.data.record.name);
            callback({ error: false, message: "Status changed" });
        })
        .catch(error => {
            return callback({ error: true, message: "Error changing entry status: " + error});
        });        
    }

    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        if (!form['resultstatus'] || form['resultstatus'] == "") {
          return "Please select a new status or click cancel";
        }

        if(props.status == form['resultstatus']) {
          return "You have not changed the status";
        }
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Change Entry Status" 
            title="Select New Status For This Entry"
            description={"Please select the new status for this enrtry, i.e. " + props.rider + " on " + props.horse + " for the " + props.class +" class."}
            saveLabel="Apply Status Change"
            fields={changeStatusFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<AutorenewIcon/>)}
            isSecondaryButton={true}
       />
    )
}