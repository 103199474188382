import * as React from 'react';
import { Grid, Stack, Typography, ToggleButtonGroup, ToggleButton, TextField, Chip } from "@mui/material";
import { getOverallScore } from '../../utils';

// /*
// | OK       |
// | ELM      |
// | WDR      |
// | RET      |
// | EL       |
// | WD       |
// | DNF      |
// | WDBHI    |
// | WDHI     |
// | ELHI     |
// | 0        |
// | NULL     |
// | DNS      |
// | 29.3     |
// | 4        |
// | TEL      |
// */

// const statii = [
//     'OK', 'EL', 'WD', 'RET', 'DNS', 'TEL', 'WDBHI', 'WDHI', 'ELHI'
// ];

// const drreasons = [
//     '', 'OT', 'FR'
// ];

// OK, EL, WD, RET, DNS, TEL, WDBHI, WDHI, ELHI, Other
export default function DrSummary( { scores, test, status }    ) {
   

    return (
        <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
                <Stack direction="row">
                    <Typography sx={{mb:1, mr: 1, fontWeight: 'bold'}}>Test: </Typography>
                    <Typography>{test && test.name}</Typography>
                </Stack>
                <Stack direction="row">
                    <Typography sx={{pr:1, fontWeight: 'bold'}}>Score: </Typography>
                    <Typography>{scores && getOverallScore(scores, test)}</Typography>
                    <Typography sx={{ml:3, pr:1, fontWeight: 'bold'}}>Status: </Typography>
                    {status == "" ?   <Chip label="Not Pubished" color="error" />:   <Chip label="Published" color="success" />}
                </Stack>

            </Grid>           
            {/* <Stack direction="row" spacing={1}>
                <Stack direction="column">
                    {status != 'OK' && <Typography sx={{pr:1, fontWeight: 'bold'}}>DR Comment: </Typography>}
                    {status != 'OK' && <TextField
                        size="small"
                        value={comments}
                        onChange={(v) => setComments(v.target.value)}
                />}
                </Stack>
                <Stack direction="column">
                    {status != 'OK' && <Typography sx={{pr:1, fontWeight: 'bold'}}>DR Comment: </Typography>}
                    {status != 'OK' && <TextField
                        size="small"
                        value={comments}
                        onChange={(v) => setComments(v.target.value)}
                />}
                </Stack>
            </Stack> */}
            {/* </Grid> */}
        </Grid>
    );
}
