import React from "react";
import _ from "lodash";
import axios from "axios";
import FormDialog from "../form/FormDialog";
import SwapVertIcon from '@mui/icons-material/SwapVert';

export default function ChangeHorse(props:any) {

    const changeHorseFields = [
        {
            attribute: "horseid",
            label: "New Horse",
            component: 'autocomplete-remote',
            validationType: "string",
            validations: {
              required: true,
            },
            optionDef: {
              type: "server-search",
              name: "name",
              url: "/admin/horse/auto",
              operation: "like",
              min: 2,
              filter: {
                classtypeid: props.classtypeid
              }
            },
            optionConfig: {
              key: "id",
              value: "id",
              label: "name",
            },
            col: {
              sm: 12,
            },
          }
    ];

    const change = (form:any, callback:any) => {
        axios.post("/admin/evententrant/"+props.id+"/changehorse", { 
            horseid: form['horseid']
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: "Problem changing horse: " + response.data.message});
            }
            props.update(response.data.record.name);
            callback({ error: false, message: "Horse changed " });
        })
        .catch(error => {
            return callback({ error: true, message: "Error changing horse: " + error});
        });

        
    };


    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        if (!form['horseid'] || form['horseid'] == "") {
          return "Please select the new horse";
        }
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Change Horse" 
            title="Select New Horse"
            description={"Please select a new horse to replace " + props.horse }
            saveLabel="Apply Change"
            fields={changeHorseFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<SwapVertIcon/>)}
            isSecondaryButton={true}
       />
    )
}