import * as React from 'react';
import { useNavigate } from 'react-router';

import { Typography, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Lastcrumb from '../../components/Lastcrumb';

export default function Report({ report }) {
    let navigate = useNavigate();    

    const handleClick = ()  => {
        Lastcrumb.name = report.title;
        navigate("/view/report/"+ report.id);
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">{report.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                    {report.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={handleClick}>Generate Report</Button>
            </CardActions>
        </Card>
    )
}