import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';

import {Paper } from '@mui/material';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';


import { MetaTable } from '../../components/table/MetaTable';

export default function ScoringResults() {
    const { id } = useParams();
    const [event, setEvent] = useState();


//     const getClasses = () => {
//         return event.Eventclasses.map(ec => {
//             return ec.Classtype;
//         });
//    }

    const getClassFilter = () => {
        let classoptions = [];

        event.Eventclasses.map((c) => {
            classoptions.push({ id: c.Classtype.id, name: c.Classtype.name});
        });

        return [
            {
                id: "class",
                type: "togglegroup",
                options: classoptions
            }
        ]
    }

   React.useEffect(() => {
        console.debug("Id: " + id);
        // get the details for this event 
        axios.get("/admin/event/id/" + id)
            .then(response => {
            if (response.data.error) {
                return;
            }
            console.debug("Fetched data", response.data);
            setEvent(response.data.record);
            // reset the filters
        }).catch(error => {
            console.debug(error);
        });

    }, [id]);

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
        {event && 
            <MetaTable type="eventscoringresults" 
            canExport
            canCreate={false}
            title="Results"
            dataUrl={`/admin/event/${id}/results`}
            filters={getClassFilter()}         
            rememberSearch="scoring"   
            showResetFilter={true}
            />
        }
        </Paper>
    );
}

