import * as React from 'react';
import { Grid } from "@mui/material";
import Arenaday from './Arenaday';
import Title from "../../containers/Title";

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightgrey" : "",
    // padding: grid,
    // width: 250
  });
  
const getItemStyle = (isDragging, draggableStyle) => ({
// some basic styles to make the items look a bit nicer
userSelect: "none",
// padding: grid * 2,
margin: 3,

// change background colour if dragging
background: isDragging ? "grey" : "white",

// styles we need to apply on draggables
...draggableStyle
});

export default function Arena(props) {
    return (
        <Grid item xs key={"arena-" + props.arena.id}>
            <Title >{props.arena.name}</Title>
            {props.arena.Arenadays.map((arenaday, index) => {
                return (
                    <Arenaday busy={props.busy} arenaday={arenaday} numdays={props.arena.Arenadays.length} key={"arenaday-"+index}/>
                )
            })}
        </Grid>
        
    );
}