import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import ConfirmationDialog from '../../components/collections/ConfirmationDialog';
import { useSnackbar } from 'notistack';
import axios from "axios";
import EnhancedTable from '../../components/table/EnhancedTable';
import TabPanel from '../../components/containers/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RightButton from '../../components/buttons/RightButton';
import ResponsiveDialog from "../../components/containers/ResponsiveDialog";
import SaveIcon from '@mui/icons-material/Save';
import { StandardAutocompleteRemote } from "../../app/form/Components/Forms/StandardAutocompleteRemote";
import HorseResults from "../../components/eventing/HorseResults";
import useLocalStorage from '../../app/useLocalStorage';
import { useNavigate } from "react-router-dom";
import Description from '../../containers/Description';
import { MetaTable } from '../../components/table/MetaTable';


const fields = [
    {
        // Default component is Material UI's TextField
        attribute: "ownerid",
        label: "New Owner",
        col: {
            // Here you can specify how many Grid columns the field should take for the corresponding breakpoints
            sm: 12,
        },
        component: 'autocomplete-remote',
        validationType: "string",
        validations: {
            required: true,
        },
        optionDef: {
            type: "server-search",
            url: "/admin/user/auto",
            name: "fullname",
            operation: "startswith",
            min: 3
        },
        optionConfig: {
            key: "id",
            value: "id", 
            label: "name",
        }        
    }
]
export default function ViewHorseDetails() {
    const { id } = useParams();
    const [horse, setHorse] = React.useState();
    const {enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useLocalStorage('view-horse-tab', 0);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [row, setRow] = React.useState();
    const [showAddOwner, setShowAddOwner] = React.useState(false);
    const [error, setError] = React.useState("");
    const [form, setForm] = React.useState({});
    const navigate = useNavigate();

   
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    const updateForm = (updates) => {
        console.debug(updates);
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };

    const dataUsed = (horse) => {
        setHorse(horse);
    }   

    const tableFields = [
        {
            id: "firstname",
            label: "First Name",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "lastname",
            label: "Last Name",
            properties: {
                component: "text-field"
            }
        }
    ];

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    };
      
    const menu = [
        {
            icon: "delete",
            name: "Remove Owner",
            handler: function(row) {
                // window.location.href = API_ROOT + 'event/'+id+"/download";
                // navigate("/events", { replace: true });    
                setShowConfirm(true)            
                setRow(row);
                console.debug("Remove Owner");
            }
        } ];

    const removeOwner = () => {
        setLoading(true);

        // call refund on the server
        axios.post(`/admin/horse/${id}/removeowner`, { id: row.id} )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                setLoading(false);
                return enqueueSnackbar(response.data.message, { variant: 'error'});
            }
            let newOwners = [];
            for (let i = 0; i < horse.Users.length; i++) {
                if(horse.Users[i].userid != row.id) {
                    newOwners.push(horse.Users[i]);
                }
            }         
            let newhorse = JSON.parse(JSON.stringify(horse));
            newhorse.Users = newOwners;
            setHorse(newhorse);
            enqueueSnackbar("Owner removed", { variant: 'success'});
            setLoading(false);
            setShowConfirm(false);
            navigate(0);


        })
        .catch(error => {
            console.debug(error);
            setLoading(false);
            return enqueueSnackbar("Error refunding payment: " + error, { variant: 'error'})
        });
    }

    const addOwner = () => {
        // pop up confirmation question
        console.debug("Add Owner");
        setShowAddOwner(true);
    }
    
    const onCancel = () => {
        // pop up confirmation question
        setShowConfirm(false);
    }

    const addOwnerClicked = () => {
        setError("");

        // call change password top the server
        setLoading(true);
        axios.post(`/admin/horse/${id}/addowner`, { id: form.ownerid} )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                setLoading(false);
                return enqueueSnackbar(response.data.message, { variant: 'error'})
            }
            setLoading(false);
            setShowAddOwner(false);
            let newhorse = JSON.parse(JSON.stringify(horse));
            enqueueSnackbar("Owner Added", { variant: 'success'});
            navigate(0);

        })
        .catch(error => {
            console.debug(error);
            setLoading(false);
            return enqueueSnackbar("Error adding owner: " + error, { variant: 'error'})
        });

        
    };

    /**
     * Foaled 2007 Height 163cm Colour Chestnut Total Points = 2
     * Owned By Harry Rowcliffe Bred By Mrs Pippa Wiegersma Gender Gelding
     * Sire Graf Quidam Dam Mazey Day
     */
    const getHorseSummary = (h) => {
        if(!h) {
            return "Unknown";
        }
        return `${h.name}, Foaled ${h.datefoaled ? h.datefoaled.substring(0, 4) : ""}, Points ${h.pointstotal}`;
    }

    return (
        <React.Fragment>
            <Panel>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="scrollable" 
                    scrollButtons
                    value={value} onChange={handleChange} aria-label="horse details">
                    <Tab label="Horse Details" {...a11yProps(0)} />
                    <Tab label="Owners" {...a11yProps(1)} />
                    <Tab label="Results" {...a11yProps(2)} />
                    <Tab label="Documents" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                    <Form id={id} objtype={"horse"} readonly={true} showbuttons={true} onData={dataUsed} noTitle={true} reloadOnUpdate={true}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                    {horse && <EnhancedTable columns={tableFields} 
                            rows={horse.Users} orderBy={"firstname"}
                    noHeader={true}
                    rowsPerPage={10} 
                    placeholder={""} 
                    menu={menu}
                    selectable={false}
                />}
                <RightButton title={"Add Owner"} onClick={addOwner} />
                {showConfirm && <ConfirmationDialog title={"Please Confirm"} description={"Are you sure you want to remove this owner" }
                            onConfirm={removeOwner} open={showConfirm}
                            onCancel={onCancel} loading={loading}
                            loadingMessage={"Please wait while we remove this owner"}/>}
                {showAddOwner &&  <ResponsiveDialog title="Add Owner" onPrimaryClick={addOwnerClicked} onCloseClick={() => {setLoading(false); setShowAddOwner(false);}}
                    primaryButtonIcon={<SaveIcon/>} primaryButtonLabel="Add Owner" open={showAddOwner}
                    description="Please select the name of the owner from the list provided, you can start typing the name and the list should autocomplete"
                        loading={loading} error={error}>
                <StandardAutocompleteRemote field={fields[0]} form={form} updateForm={(updates) => updateForm(updates)} />
            </ResponsiveDialog>}          
            </TabPanel>
            <TabPanel value={value} index={2} lazy={false}>
                {horse && <HorseResults id={id} name={getHorseSummary(horse)}/>}
            </TabPanel>
            <TabPanel value={value} index={3} lazy={false}>
                    <Description>Below are the documents that were provided with this horse as part of the registration.</Description>
                    <MetaTable type="document" 
                        canCreate={false}
                        dataUrl={`/admin/document?relatedid=${id}&type=horse`}
                  />
                </TabPanel>
            </Panel>              
            
        </React.Fragment>
    )
}