import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MetaTable } from '../../components/table/MetaTable';
import { useSelector } from 'react-redux';
import { selectUser } from '../../app/loginSlice';
import Panel from '../../components/containers/Panel';
import { isAffiliate } from '../../utils';
import Primary from '../../components/buttons/Primary';
import Secondary from '../../components/buttons/Secondary';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router';
import { List, ListItem, ListItemText, Grid, Card } from '@mui/material';
import Loading from '../../components/Loading';
import axios from 'axios';
import { Alert } from '@mui/material';
import { MetaStore } from '../../app/MetaStore';

const steps = ['Select Event', 'Select Classes', 'Confirm Import'];

const initialEvent = {
    ref: 0,
    name: ''
};

export default function ImportEvent() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [event, setEvent] = React.useState(initialEvent);
    const [classes, setClasses] = React.useState(Array());
    const [next, setNext] = React.useState(false);
    const [error, setError] = React.useState("");
    const [importing, setImporting] = React.useState(false);
    const navigate = useNavigate();

    const isStepOptional = (step: number) => {
        return false;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const eventSelectedHandler = (rows: any) => {
        console.debug("Selected row", rows);
        setNext(true);
        setEvent(rows[0]);
    }

    const eventDeselectedHandler = (rows: any) => {
        console.debug("Deselected row", rows);
        setNext(false);
        setEvent(initialEvent);
    }

    const classSelectedHandler = (rows: any) => {
        if (rows.length > 1) {
            console.debug("All selected");
            setClasses(rows);
            setNext(true);
            return;
        }

        console.debug("Selected individual class", rows);
        let selectedRows = JSON.parse(JSON.stringify(classes));
        selectedRows.push(rows[0]);
        console.debug(selectedRows.length + " rows selected");
        setClasses(selectedRows);
        setNext(true);
    }

    const classDeselectedHandler = (rows: any) => {
        if (rows.length > 1) {
            console.debug("All deselected");
            setClasses([]);
            setNext(false);
            return;
        }
        console.debug("Deselected class", rows);
        // remove row from list
        let selectedRows = [];
        for (var i = 0; i < classes.length; i++) {
            // console.debug("Checking " + selectedRows[i].name + " == " + rows[0].name)
            if (classes[i].name != rows[0].name) {
                selectedRows.push(classes[i]);
            }
        }
        setClasses(selectedRows);
        setNext(selectedRows.length > 0);
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if(activeStep == 2) {
            return importEntries();
        }        

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNext(false);
        setSkipped(newSkipped);
    };

    const getClassIds = () => {
        return classes.map(c => c.ref).join(",");
    };

    const importEntries = () => {
        setImporting(true);
        setNext(false);


        // call import on the server, and then display the results when completed.
        axios.post("ei/event/import", {
            id: event.ref,
            classes: getClassIds()
        })
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                setError(response.data.message);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setImporting(false);
                return;
            }
            // invalidate venues in meta store
            MetaStore.refreshOptions("event", (updte:any) => {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setImporting(false);    
            })
        })
        .catch(error => {
            console.warn(error);
            setError(error);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setImporting(false);
        });

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        navigate('/events');
    };

    return (
        <>
            <Panel title={"Import Event From Eventing Ireland System"}></Panel>
            <Panel>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                            
                            {error != "" && <Alert severity="error">{error}</Alert>}
                            {error == "" && <Alert severity="success">Event Imported</Alert>}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Return To Events</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                        <>
                            {activeStep == 0 &&
                                <>
                                    <Typography sx={{ ml: 1, mt: 2, mb: 1 }}>Please select the event you would like to import from the list below and click NEXT</Typography>
                                    <MetaTable type="events"
                                        baseUrl={"ei/"}
                                        selectable={true}
                                        rowsPerPage={5}
                                        title={" "}
                                        onSelect={eventSelectedHandler}
                                        onDeselect={eventDeselectedHandler}
                                    />
                                </>
                            }
                            {activeStep == 1 &&
                                <>
                                    <Typography sx={{ ml: 1, mt: 2, mb: 1 }}>Please select the classes to import for {event.name}</Typography>
                                    <MetaTable type={"classes"}
                                        baseUrl={"ei/"}
                                        dataUrl={"ei/event/classes/" + event.ref}
                                        selectable={true}
                                        rowsPerPage={25}
                                        title={" "}
                                        onSelect={classSelectedHandler}
                                        onDeselect={classDeselectedHandler}
                                        multiselect={true}
                                    />
                                </>
                            }

                            {activeStep == 2 && !importing &&
                                <>
                                    <Typography sx={{ ml: 1, mt: 2, mb: 1 }}>Click the Import Now button below to import all entries for the event {event.name} in the classes listed below:</Typography>
                                    <Grid container sx={{ pl: 3 }}>
                                        <Grid item>
                                            <List dense={true}>
                                                {classes.map((klass, index) => {
                                                    if (index < (classes.length / 2)) {
                                                        return (<ListItem key={"left-"+index}>
                                                            <ListItemText
                                                                primary={(index + 1) + ") " + klass.name}
                                                            />
                                                        </ListItem>);
                                                    }
                                                })}
                                            </List>
                                        </Grid>
                                        <Grid item>
                                            <List dense={true}>
                                                {classes.map((klass, index) => {
                                                    if (index >= (classes.length / 2)) {
                                                        return (<ListItem key={"right-"+index}>
                                                            <ListItemText
                                                                primary={(index + 1) + ") " + klass.name}
                                                            />
                                                        </ListItem>);
                                                    }
                                                })}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </>
                            }

                            {activeStep == 2 && importing &&
                                <>
                                    <Typography sx={{ ml: 1, mt: 2, mb: 1 }}>Please wait while we import the entries for this event:</Typography>
                                    <Loading/>
                                </>
                            }

                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Secondary
                                    disabled={activeStep === 0 || importing}
                                    onClick={handleBack}
                                    title={"Back"}                                    
                                    startIcon={<ArrowBackIcon />}
                                />
                                <Box sx={{ flex: '1 1 auto' }} />
                                {isStepOptional(activeStep) && (
                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                        Skip
                                </Button>
                                )}
                                <Primary startIcon={<ArrowForwardIcon />} 
                                    onClick={handleNext} 
                                    disabled={(!next && activeStep != 2) || importing} 
                                    title={activeStep === steps.length - 1 ? 'Import Now' : 'Next'} />
                            </Box>
                        </>
                    )}
            </Panel>
        </>
    );
}

