
import * as React from 'react';
import {
    useNavigate,
    useLocation,    
  } from 'react-router';
import { useAuth } from './AuthProvider'; 
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Copyright } from '../containers/Layout';
import { useSnackbar } from 'notistack';

export default function LoginPage() {
    let navigate = useNavigate();    
    const  { state } = useLocation();
    let auth = useAuth();
    const [busy, setBusy] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    console.debug(state);
    let test:any = state;

    let from = test ? test.from : "/";    

    // if (location.state && location.state.from) {
    //     from = location.state.pathname;
    // }
    // let from = location.state?.from?.pathname || "/";

    // let from = location.state.from.pathname || "/";
    // const { from } = location.state || { from: { pathname: "/" } };

    // let { from } = location.state ? location.state as LocationState : { from: {
    //         pathname: "/" } 
    //     }; 

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
      event.preventDefault();
  
      setBusy(true);
      enqueueSnackbar("Logging in please wait: ", { variant: 'success' })

      let formData = new FormData(event.currentTarget);
      let username = formData.get("username") as string;
      let password = formData.get("password") as string;
  
      auth.signin(username, password, (result) => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        // console.debug("Logged in, navigating to " + from);      
        setBusy(false);
        if(result.auth)  {
          enqueueSnackbar("Logging in successful: ", { variant: 'success' })
          navigate(from, { replace: true });    
        }
      });
    }
  
    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'inline-flex' }}>
          <img
            src={'images/ei-logo.jpg'}
            alt={"Eventing Ireland"}
            loading="lazy"
            width="200px"
          />
          </Box>
          <Typography sx={{ mt: 1 }} component="h1" variant="h4">
            Administrator Sign In
          </Typography>

          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Admin Membership No."
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              disabled={busy}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    )
    // return (
    //   <div>
    //     <p>You must log in to view the page at {from}</p>
  
    //     <form onSubmit={handleSubmit}>
    //       <label>
    //         Username: <input name="username" type="text" />
    //       </label>{" "}
    //       <button type="submit">Login</button>
    //     </form>
    //   </div>
    // );
  }
