export const CloneEventForm = [
  {
    attribute: "name",
    label: "Name *",
    component: "text-field",
    validationType: "string",
    validations: {
      required: "You must provide a name for this event",
      max: 256
    },
    col: {
      md: 12
    }
  },
  {
    attribute: "startdate",
    label: "Event Date *",
    component: "date-picker",
    validationType: "date",
    validations: {
      required: "You must select a date for this event"
    },
    col: {
      md: 6
    }
  },
  {
    attribute: "eventenddate",
    label: "Event End Date",
    component: "date-picker",
    validationType: "date",
    validations: {
      required: "You must select an end date for this event"
    },
    col: {
      md: 6
    }
  },
  {
    attribute: "openingdate",
    label: "Open Date/ Time Entries *",
    component: "date-time-picker",
    validationType: "date",
    validations: {
      required: "You must select an entries opening date/ time for this event"
    },
    col: {
      md: 6
    }
  },
  {
    attribute: "closingdate",
    label: "Closing Date/ Time Entries *",
    component: "date-time-picker",
    validationType: "date",
    validations: {
      required: "You must select an entries closing date/ time for this event"
    },
    col: {
      md: 6
    }

  },
  {
    attribute: "applclosedate",
    label: "Applications Closing Date",
    component: "date-time-picker",
    col: {
      md: 6
    }
  },
  {
    attribute: "refundcutoffdate",
    label: "Refund Cutoff Date",
    component: "date-time-picker",
    col: {
      md: 6
    }
  }
];
