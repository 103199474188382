import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';

const reloads = ['horse', 'competency', 'competencies'];
export default function ViewDetails() {
    const { id, type } = useParams();

    return (
        <Panel>
            <Form id={id} objtype={type} readonly={true} reloadOnUpdate={reloads.includes(type)}/>
        </Panel>
    )
}