import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import GroupIcon from '@mui/icons-material/Group';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import ClassIcon from '@mui/icons-material/Class';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TableChartIcon from '@mui/icons-material/TableChart';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import RememberMeIcon from '@mui/icons-material/RememberMe';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import ElderlyIcon from '@mui/icons-material/Elderly';

export const menus = [
    {
        id: "dashboard",
        name: "Dashboard",
        permission: "",
        icon: (selectedIndex:string) => (<DashboardIcon  color={selectedIndex === "dashboard" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "events",
        name: "Events",
        permission: "e",
        description: "Add and manage all your events from this section",
        icon: (selectedIndex:string) => (<EventIcon  color={selectedIndex === "events" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "users",
        name: "Users",
        permission: "m",
        description: "Manage all user accounts for members within the system",
        icon: (selectedIndex:string) => (<GroupIcon  color={selectedIndex === "users" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "horses",
        name: "Horses",
        permission: "h",
        description: "Manage the full set of horses registered within the system",
        icon: (selectedIndex:string) => (<BedroomBabyIcon  color={selectedIndex === "horses" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "orders",
        name: "Payments",
        permission: "p",
        description: "View all payments received and apply refunds if necessary ",
        icon: (selectedIndex:string) => (<AccountBalanceIcon  color={selectedIndex === "payments" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "venues",
        name: "Venues",
        permission: "v",
        description: "Add and manage the complete set of venues where your Events take place",
        icon: (selectedIndex:string) => (<PlaceIcon  color={selectedIndex === "venues" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "classtypes",
        name: "Class Types",
        permission: "c",
        description: "Manage the supported set of Class Types that can be used in your events",
        icon: (selectedIndex:string) => (<ClassIcon  color={selectedIndex === "classtypes" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "dressagetests",
        name: "Dressage Tests",
        permission: "d",
        description: "Manage the supported set of Dressage Tests that can be used in your events",
        icon: (selectedIndex:string) => (<AssignmentIcon  color={selectedIndex === "tests" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "lists",
        name: "Lists",
        permission: "l",
        description: "Manage a collection of lists used within the system",
        icon: (selectedIndex:string) => (<FormatListBulletedIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>),
        submenus: [
            // {
            //     id: "team",
            //     name: "Teams",
            //     icon: (selectedIndex:string) => (<GroupsIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)
            // },
            {
                id: "shop",
                name: "Shop Items",
                icon: (selectedIndex:string) => (<StorefrontIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)
            },
            {
                id: "sponsor",
                name: "Sponsors",
                icon: (selectedIndex:string) => (<NewspaperIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)
            },
            {
                id: "eventrole",
                name: "Event Roles",
                icon: (selectedIndex:string) => (<WorkHistoryIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)

            },
            {
                id: "dayticket",
                name: "Day Tickets",
                icon: (selectedIndex:string) => (<ConfirmationNumberIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)

            },
            {
                id: "membertype",
                name: "Member Types",
                icon: (selectedIndex:string) => (<RememberMeIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)

            },
            {
                id: "ridingoption",
                name: "Riding Options",
                icon: (selectedIndex:string) => (<ManageAccountsIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)
            },
            {
                id: "horseregtype",
                name: "Registration Types",
                icon: (selectedIndex:string) => (<RememberMeIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)

            },
            {
                id: "disciplinedefaults",
                name: "Scheduling",
                icon: (selectedIndex:string) => (<DisplaySettingsIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)
            },
            {
                id: "phrase",
                name: "DR Phrases",
                icon: (selectedIndex:string) => (<AssignmentLateIcon  color={selectedIndex === "lists" ? "primary" : "inherit"}/>)
            }
        ]
    },
    {
        id: "reports",
        name: "Reports",
        permission: "r",
        description: "Access and view reports",
        icon: (selectedIndex:string) => (<TableChartIcon  color={selectedIndex === "reports" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "auditlog",
        name: "Audit Log",
        permission: "a",
        description: "Search and view the actions of the users within the Audit Log",
        icon: (selectedIndex:string) => (<ListAltIcon  color={selectedIndex === "auditlog" ? "primary" : "inherit"}/>),
        submenus: []
    },
    {
        id: "olddressagetests",
        name: "Old Dressage Tests",
        permission: "d",
        description: "Manage the supported set of Old Dressage Tests that can be used in your events",
        icon: (selectedIndex:string) => (<ElderlyIcon  color={selectedIndex === "tests" ? "primary" : "inherit"}/>),
        submenus: []
    }

];
