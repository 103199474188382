import * as React from 'react';
import { MetaStore } from './app/MetaStore';
import { Chip, Box } from '@mui/material';
import TableCell from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import OutlinedInput from '@mui/material/OutlinedInput';
import { all } from './app/all';

const plurals = {
  entry : 'entries',
  class : 'classes'
};

export function isInt(value) {
  // console.log(`Checking value[${value}]`);
  if((value == undefined || value == "") && value !== 0) {
    console.log("Checked value no good", value);
    return false;
  }
  return /^\+?\d+$/.test(value);
}

export function isFirstCombination(fence) {
  return (fence && isCombination(fence) && fence[fence.length-1] == 'A')
}

export function isSecondCombination(fence) {
  return (fence && isCombination(fence) && fence[fence.length-1] == 'B')
}

export function isDifferentFence(fence1, fence2) {
  return getFenceNumber(fence1) != getFenceNumber(fence2);
  
}

export function isCombination(fence) {
  const isnum = /^\d+$/.test(fence);
  return !isnum;
}

export function translateSjScore(score) {
  if(isInt(score)) {
    return parseInt(score);
  }
  if(score == '1R') {
    return 4;
  }
  if(score == '1R+4' || score == '2R') {
    return 8;
  }
  return 0;
}

export function translateXcScore(score) {
  if(isInt(score)) {
    return parseInt(score);
  }
  if(score == '1R') {
    return 20;
  }
  if(score == '2R') {
    return 60;
  }

  return 0;
}

export function getCombinationLetter(fence) {
  if(!isCombination(fence)) {
    return "";
  }

  return fence.slice(-1);
}

export function getFenceNumber(fence) {
  if(!fence) {
    return null;
  }
  return fence.replace(/\D/g,'');
}

export function getOverallCollTotal(allscores, test) {
  let total = 0;
  allscores.map(score => {
    total += parseFloat(getCollTotal(score.DressageMovementScores, test))
  });
  return total;
}

export function getCollTotal(individualscores, test) {
  const movementindex = {};
  test.DressagetestMovements.map(m => {
    movementindex[m.number] = m;
  });
    
  let colltotal = 0;
  individualscores.map(score => {
    const movement = movementindex[score.number];
    if(movement.iscollective)
    colltotal += parseFloat(score.score);
  });
  return colltotal;
}


export function getTotal(allscores, drpen=0, othererror=0) {
  // console.log("All scores", allscores);
  let total = allscores.reduce((partialSum, a) => { 
    let amount = parseFloat(a.score);
    if(a.isdouble) {
      amount = amount * 2; 
    }
    return parseFloat(partialSum) + amount
  }, 0); 
  // if(test.doublepointsmove != 0 && allscores[test.doublepointsmove-1]) {
  //     total = total + parseFloat(allscores[test.doublepointsmove-1].score);
  // }
  if(othererror>0) {
    total = total -(othererror*2)
  }
  if(drpen == 0) {
    return total;  
  }  
  if(drpen == 1) {
    return total - 2;  
  }  
  if(drpen == 2) {
    return total - 6;  
  }  

  return 0;
}

export function getPercentage(allscores, test, drpen=0, othererror=0) {
  // console.log("Percentage scores", allscores);
  return (Math.round(100*parseFloat(getTotal(allscores, drpen, othererror) / test.totalpoints)*100)) / 100; 
}

export function getSingleScore(allscores, test, drpen, othererror=0) {
  // console.log("Single scores", allscores);
  return (100 - getPercentage(allscores, test, drpen, othererror)).toFixed(1);  
}

// export function getOverallScore(allscores, test) {
//   let total = 0;
//   // console.log("Overall Scores", allscores);
//   allscores.map(score => {
//     total += parseFloat(getSingleScore(score.DressageMovementScores, test, score.penalty, score.othererror))
//   });
//   return parseFloat(allscores.length == 1 ? total : (Math.round((total / allscores.length)*100) / 100).toFixed(1)); // +parseFloat(drpen);
// }

export function getOverallScore(allscores, test) {
  let totalpercentage = 0;
  allscores.map(score => {
      let singlePercentage = getPercentage(score.DressageMovementScores, test, score.penalty, score.othererror);
      singlePercentage = parseFloat(singlePercentage);
      totalpercentage += singlePercentage;
      return totalpercentage;
  });

  const percentage = totalpercentage / allscores.length;
  const score = 100 - percentage;
  const scoreRounded = (Math.round(score * 10) / 10);
  console.log(`${allscores.length} scores - total percentage: ${totalpercentage} - percentage unrounded: ${totalpercentage / allscores.length} score unrounded ${score} and to 1 decimal ${scoreRounded} `);
  return scoreRounded; 
}
export const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 46,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0, 0, 2)
}));

export const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  marginTop: 2,
  marginRight: 5,
  // margin: 'dense',
  // marginTop: 0,
  // marginBottom: 0,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: 'lightgrey',
  },
  '& input': {
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 2,
    marginBottom: 3,
  }
}));


export function hasPermission(userPermissions, permission) {
  if(permission == "") {
    return true;
  }

  let permissionParts = permission.split("-");
  let requiredobjectpermission = permissionParts[0];
  let hasObjectPermission = false;
  let matchedPermission = null;
  for (const userPermission of userPermissions) {
    if(checkHasObjectPermission(userPermission.split("-")[0], requiredobjectpermission)) {
      matchedPermission = userPermission;
      hasObjectPermission = true;
      break;
    }
  }

  // check if there is a CRUD restriction also
  if(permissionParts.length == 2) {    
    let thisUserPermissionParts = matchedPermission.split("-");
    if(thisUserPermissionParts[1] == '*') {
      // they have wildcard on this object so return true
      return true;
    }
    let requiredCrudPermission = permissionParts[1];
    return thisUserPermissionParts[1].includes(requiredCrudPermission);

  }
  return hasObjectPermission;
}

export function checkHasObjectPermission(userpermission, requiredpermission) {

  let requiredPermissions = requiredpermission.split(".");
  let userPermissions = userpermission.split(".");

  for(let i = 0; i < requiredPermissions.length; i++) {
    if(typeof userPermissions[i] == 'undefined') {
      // not enough values in users permission
      return false;
    } 
    if (userPermissions[i] == "*") {
      // the user permission has a wildcard at this point
      return true;
    }

    if (userPermissions[i] == requiredPermissions[i] && i == requiredPermissions.length-1) {
      // the user permission matches the required permission and it is the end of the required permission list
      return true;
    }
  }

  return false;
}

export function pluralise(title) {
  if(!title) {
    return "";
  }

  if(plurals[title.toLowerCase()]) {
    return capitalizeFirstLetter(plurals[title.toLowerCase()]);
  }
  if (title[title.length-1].toLowerCase() != 's') {
    return title + 's';
  }

  return title;
}

export function stripUndefined(obj) {
  if(!obj) {
    return {};
  }
  let newobj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] !== undefined) {
      newobj[key] = obj[key];
    }
  });
  return newobj;
}

export function removeTableOnlyFields(fields ) {
  let updatedFields = [];
  fields.map(f => {
    if(f.properties.component != 'table-only') {
      updatedFields.push(f);
    }
  });

  return updatedFields;
}

export function roleName(roleId) {

    return "Role " + roleId;
}

export function isAffiliate(org) {
  return isEI(org.id) || isIPS(org);
}

export function isEI(orgId) {
  return orgId == 3;
}

export function isIPS() {
  return org.orgtype == "IPS";
}

export function baseUrl(orgId) {
    return isEI(orgId) ? 'ei/' : '';
}

export function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function userStatusIdToName(value) {
  switch (value) {
    case 0:
      return "Active";
    case 1:
      return "Blocked";
  }
  return 'Unknown';
}

export function fixEnding(name) {
  if (name[name.length-1].toLowerCase() == 's' && name[name.length-2].toLowerCase() != 's' ) {
    return name.slice(0, -1);
  }
  return name;
}

export function applyPermissions(definition, user) {
  // let fields = [];
  // for (var i = 0; i < definition.fields.length; i++) {
  //     // console.debug("Checking permissions for " + user.roleId + " against read = " + definition.fields[i].permissions.read + " and write "
  //     // + definition.fields[i].permissions.write);
  //     if(user.roleId <= definition.fields[i].permissions.read) {
  //         let field = definition.fields[i];                
  //         if(user.roleId > field.permissions.write) {
  //             if(! field.properties.props) {
  //               field.properties.props = {};
  //             }
  //             field.properties.props.disabled = true;
  //         }
  //         fields.push(field);
  //     } 
  // }
  // definition.fields = fields;
  // // console.debug(definition);
  return definition;
};

export function roleIdToName(value) {
    // console.debug(value)
    switch (value) {
      case 0:
        return "Super Admin";
      case 1:
        return "Org Admin";
        case 2:
          return "Org Standard";
        case 3:
          return "Event Organiser";
          }
    return 'Unknown';
  };
  
  export function getFieldDefinition(field, id, value) {
    let obj = field.properties;
    obj.id = field.id;
    obj.attribute = field.id;
    obj.col = {
      md: obj.col ? obj.col : 6
    };
    obj.label = field.label;   
    // obj.title = field.label;   
    return addOptionConfig(obj);
  };

  function addOptionConfig(obj) {
    if(!hasOptions(obj.component) || obj.optionConfig) {
      return obj;
    }

    // deturn default options
    obj.optionConfig = {
        key: "id", // The attribute to use for the key required for each option
        value: "id", // The attribute to use to determine the value that should be passed to the form field
        label: "name", // The attribute to use to determine the label for the select option
    };
    return obj;
  }

  export function hasOptions(componenttype) {
    let has = [
      "select", 
      "chip-group", 
      "checkbox-group", 
      "radio-group",
      "autocomplete"
    ];

    return has.indexOf(componenttype) > -1;
  }

  export function formatTime(v, optionsname) {
    if(typeof v == 'string') {
      // let parts = v.split(":");
      // if(parts.length == 2) {
        return v;
      // }
      // if(parts.length == 3) {
      //   return `${parts[0]}:${parts[1]}`;    
      // }
    }
    let d = new Date(v);
    return `${pad(d.getHours(), 2)}:${pad(d.getMinutes(), 2)}`;
  }

  export function formatDate(v, optionsname) {
    if(!v || v == "0000-00-00") {
      return "";
    }    
    return new Date(v).toLocaleDateString('en-GB');


    // const formatedDate = v.substring(0, 10).split('/').reverse().join('-');
    // return new Date(formatedDate).toString()
  }

  export function formatSwitch(v, optionsname) {
    if(v == 1) {
      return "Y";
    }
    return "N";
  }

  export function passwordOk(password) {
    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    if(!strongPassword.test(password)) {
        /**
         * The password is at least 8 characters long (?=.{8,}).
         * The password has at least one uppercase letter (?=.*[A-Z]).
         * The password has at least one lowercase letter (?=.*[a-z]).
         * The password has at least one digit (?=.*[0-9]).
         * The password has at least one special character ([^A-Za-z0-9]).
         */
        return "The password must be at least 8 characters long, have at least one uppercase letter, at least one lowercase letter, at least one digit and at least one special character."
    }
    return ""
  }

  export function formatDateTime(date, optionsname) {  
    if(date==null || date=="") {
      return;
    }
    if(!date) {
      return "";    
    }
    if(typeof date !== "string") {
      date = date.toISOString();
    }
    const datepart = formatDate(date);
    const timepart = date.substring(11, 19);
    return `${datepart} ${timepart}`;

    // console.log("date", v);
    // let d = new Date(v);
    // let hours = d.getHours();
    // let minutes = d.getMinutes();
    // let seconds = d.getSeconds();
    // // let result = `${formatDate(d)} ${pad(hours, 2, '0')}:${pad(minutes, 2, '0')}:${pad(seconds, 2, '0')}`;
    // let result = `${formatDate(d)} ${pad(hours, 2, '0')}:${pad(minutes, 2, '0')}:${pad(seconds, 2, '0')}`;
    // return result;
  }

  export function fromIsoDateTime(v) {
    let d = new Date(v);
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let seconds = d.getSeconds();
    let month = d.getMonth()+1;
    let year = d.getFullYear();
    let day = d.getDate();
    let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return result;
  
  }

  export function getDisplayValue(v, optionsname, isexport=false) {
    let options = MetaStore.getField(optionsname);
    if(!options) {
      return "";
    }
    for(let i = 0; i < options.length; i++) {
      if  (options[i].id == v) {
        if (!isexport && options[i].colour) {
          return ( <Chip size="small" color={options[i].colour} label={options[i].name} variant={options[i].variant ? options[i].variant : "contained"}/>);
        } else if (!isexport && options[i].bgcolour) {
          return ( 
            <div
              style={{
                backgroundColor: options[i].bgcolour,
                borderRadius: '10px',  
                paddingLeft:'10px',
            }}
          >{options[i].name}</div>);
        } else {
          return  options[i].name;
        }
      }

    }
    return "";
  }

  
  export function getValue(row, colId) {
    let parts = colId.split(".");
    if (parts.length == 1) {
      if(row[colId] == "0000-00-00") {
        return "";
      }
      return row[colId];
    }
    let value = row;
    for(let i = 0; i < parts.length; i++) {
      value = value[parts[i]];
    }
    return value;
  }
 
  export function getSortValue(row, colId) {
    let parts = colId.split(".");
    let value;
    if (parts.length == 1) {
      value = row[colId];
    } else {
      value = row;
      for(let i = 0; i < parts.length; i++) {
        value = value[parts[i]];
      }
    }

    if(colId.includes('amount')) {
      if(value == "") {
        value = 0;
      } else {
        value = parseFloat(value);  
      }
    } 

    if(colId == 'rating') {
      if(value == "") {
        value = 7;
      } else {
        let ratingvalues = {};
        all.rating.map((r, index) => {
          ratingvalues[r.id] = index;
        });

        value = parseInt(ratingvalues[value]);  
      }
    } 

    if(colId.includes('date')) {
      if(value == "") {
        value = 7;
      } else {
        value = new Date(value);
        value = value.getTime();  
      }
    } else if(typeof value == 'string') {
      value = value.toLowerCase().trim();
    }
    return value;
  }


export function sortClassesInArena(arena) {
  arena.Arenadays.forEach(ad => {
    ad.EventclassSchedulings = sortBy(ad.EventclassSchedulings, 'ordernumber')
    ad.EventclassSchedulings.forEach(ecs => {
      ecs.Eventclass.Evententrants = sortBy(ecs.Eventclass.Evententrants, 'order');
    })
  });
  return arena;
}

export function sortBy(list, property, order='asc') {
  if(order == 'desc') {
    return list.sort((a,b)=> (getValue(a, property) < getValue(b, property) ? 1 : -1))
  }
  return list.sort((a,b)=> (getValue(a, property) > getValue(b, property) ? 1 : -1));

}

export const DISCIPLINES = [1, 2, 3];
export function getDisciplineName(id) {
  return ['', 'Show Jumping', 'Dressage', 'Cross Country'][id];
}

export function getDisciplineShortName(id) {
  return ['', 'SJ', 'DR', 'XC'][id];
}

export function getDisciplineColumn(id) {
  return ['', 'sjtime', 'drtime', 'xctime'][id];
}

export function getDisciplineBreakClassColumn(disciplineid) {
  const disciplines = ['', 'breakaftersj', 'breakafterdr', 'breakafterxc'];
  return disciplines[disciplineid];
}

export function getStartTime(evententrant, disciplineid) {
  return evententrant[getDisciplineColumn(disciplineid)];
}

export function getArenasByDiscipline(arenas) {
  let disciplines = {};
  for (let arena of arenas) {
      arena = sortClassesInArena(arena);
      if(!disciplines[arena.disciplineid]) {
        disciplines[arena.disciplineid] ={
          id: arena.disciplineid,
          name: getDisciplineName(arena.disciplineid),
          shortname: getDisciplineShortName(arena.disciplineid),
          arenas: [arena]
        }
      } else {
        disciplines[arena.disciplineid].arenas.push(arena);
      }
  }
  return Object.values(disciplines);
}

export function getEntriesByDiscipline(entries) {
  let disciplines = {
    1: {
      id: 1,
      name: getDisciplineName(1),
      shortname: getDisciplineShortName(1),
      entries: []
    },
    2: {
      id: 2,
      name: getDisciplineName(2),
      shortname: getDisciplineShortName(2),
      entries: []
    },
    3: {
      id: 3,
      name: getDisciplineName(3),
      shortname: getDisciplineShortName(3),
      entries: []
    }
  };

  for (let entry of entries) {
    console.log("Processing entry", entry);
    disciplines[1].entries.push(getEntrySummaryForDiscipline(entry, 1));
    console.log("Processing discipline 2");
    disciplines[2].entries.push(getEntrySummaryForDiscipline(entry, 2));
    console.log("Processing discipline 3");
    disciplines[3].entries.push(getEntrySummaryForDiscipline(entry, 3));
  }
  console.log("D1");
  // go through each discipline and add multiples info.
  for (let disciplineid in disciplines) {
    // go through all entries and apply multiples
    for (let i = 0; i < disciplines[disciplineid].entries.length; i++) {
      disciplines[disciplineid].entries[i].multiples = getMultiples(disciplines[disciplineid].entries, i);
    }
  }
  console.log("D2");

  return Object.values(disciplines);
}

/**
 * 0, 1, R1, H1, C1, T1, B0
 * 1, 2, R2, H2, C1, T2, B0    fromindex = 1
 * 2, 3, R3, H3, C1, T3, B1
 * 3, 4, R4, H4, C2, T4, B0
 * 4, 5, R5, H5, C2, T5, B0    toindex = 4
 * 5, 6, R6, H6, C2, T6, B0
 * 
 * 0, 1, R1, H1, C1, T1, B0
 * 1, 3, R3, H3, C1, T2, B0
 * 2, 4, R4, H4, C2, T3, B1
 * 3, 2, R2, H2, C1, T4, B0    fromindex = 1
 * 4, 5, R5, H5, C2, T5, B0    toindex = 4
 * 5, 6, R6, H6, C2, T6, B0
 * 
 */
export function moveDown(fromindex, toindex, entries, sameClass, disciplineid) {
  const breakaftercolumn = getDisciplineBreakClassColumn(disciplineid);
  let competitionnumber = entries[toindex].competitionnumber;
  let starttime = entries[toindex].starttime;
  let breakafter = entries[toindex][breakaftercolumn];
  // let lastClassProcessed = "";
    // move each of the others back up
    for(let i = toindex; i>fromindex; i--) {
      if(sameClass) {
        entries[i].competitionnumber = entries[i-1].competitionnumber;
        entries[i][breakaftercolumn] = entries[i-1][breakaftercolumn];
      }
      entries[i].starttime = entries[i-1].starttime;
    }

    if(sameClass) {
      entries[fromindex].competitionnumber = competitionnumber;
      entries[fromindex][breakaftercolumn] = breakafter;
    }
    entries[fromindex].starttime = starttime;
  
    // move entries in array
    let movedentry = entries[fromindex];
    console.debug("Moved Entry - From Index: " + fromindex, movedentry)
    for(let i = fromindex; i<toindex; i++) {
      console.debug("Moving Entry: " + i + " to " + (i+1), entries[i], entries[i+1]);
      entries[i] = entries[i+1];
    }
    console.debug("Moving Last Entry: " + fromindex + " to " + toindex, entries[toindex], movedentry );
    entries[toindex] = movedentry;

    return entries;
}

export function moveUp(fromindex, toindex, entries, sameClass, disciplineid) {
  const breakaftercolumn = getDisciplineBreakClassColumn(disciplineid);
  let competitionnumber = entries[toindex].competitionnumber;
  let starttime = entries[toindex].starttime;
  let breakafter = entries[toindex][breakaftercolumn];

  // move each of the others back up
  for(let i = toindex; i<fromindex; i++) {
    if(sameClass) {
      entries[i].competitionnumber = entries[i+1].competitionnumber;
      entries[i][breakaftercolumn] = entries[i+1][breakaftercolumn];
    }
    entries[i].starttime = entries[i+1].starttime;
   
  }

  if(sameClass) {
    entries[fromindex].competitionnumber = competitionnumber;
    entries[fromindex][breakaftercolumn] = breakafter;
  }
  entries[fromindex].starttime = starttime;

  // move entries in array
  let movedentry = entries[fromindex];
  console.debug("Moved Entry - From Index: " + fromindex, movedentry)
  for(let i = fromindex-1; i>=toindex; i--) {
    console.debug("Moving Entry: " + i + " to " + (i+1), entries[i], entries[i+1]);
    entries[i+1] = entries[i];
  }
  console.debug("Moving Last Entry: " + fromindex + " to " + toindex, entries[toindex], movedentry );
  entries[toindex] = movedentry;

  return entries;

}

export function moveEntry(fromindex, toindex, entries, disciplineid) {
  if (entries[fromindex].arena == entries[toindex].arena) {
    let sameClass = entries[fromindex].classname == entries[toindex].classname;
    // if it is just 2 neighbours swapping 
    if (fromindex < toindex) {
      entries = moveDown(fromindex, toindex, entries, sameClass, disciplineid)
    } else if (fromindex > toindex) {
      entries = moveUp(fromindex, toindex, entries, sameClass, disciplineid);
    } else {
      return entries;
    }

    let start = fromindex < toindex ? fromindex : toindex;
    let end = fromindex > toindex ? fromindex : toindex;
    for (let i = start; i <= end; i++) {
      for (let multiple of entries[i].multiples) {
        entries[multiple.entryindex].multiples = getMultiples(entries, multiple.entryindex)
      }
      entries[i].multiples = getMultiples(entries, i)
    }

    // do the to one also
    //entries[toindex].multiples = getMultiples(entries, toindex)

   
    return entries;

  }
}
  
/**
 * Get details of multiple entries for this specific entryindex in the array of all multiples
 * @param {*} entries 
 * @param {*} entryindex 
 */
export function getMultiples(entries, entryindex) {
  let multiples = [];
  if (entries[entryindex].nummultiples == 0) {
    return multiples;
  }

  // go through every other entry and if the rider name is the same, then add its details to this entry
  for (let i = 0; i < entries.length; i++) {
    if (i != entryindex && entries[entryindex].name == entries[i].name) {
        // its the same rider on another horse
        multiples.push({
          competitionnumber: entries[i].competitionnumber,
          classname: entries[i].classname,
          starttime: entries[i].starttime,
          current: false,
          timetonext: 0,
          entryindex: i,
          others: entries[i].others
        });
    }
  }

  // add the current entry to the list
  multiples.push({
    competitionnumber: entries[entryindex].competitionnumber,
    classname: entries[entryindex].classname,
    starttime: entries[entryindex].starttime,
    current: true,
    timetonext: 0,
    entryindex: entryindex,
    others: entries[entryindex].others
  });
  // sort them by time
  multiples = orderByTime(multiples);

  // go through the multiples and set their time between
  for (let i = 0; i < multiples.length-1; i++) {
    let t1 = toMinutes(multiples[i].starttime);
    let t2 = toMinutes(multiples[i+1].starttime);
    multiples[i].timetonext = t2-t1;
  }
  return multiples;
}

export function orderByTime(entries) {
  return entries.sort(function (e1, e2) {
    let t1 = toMinutes(e1.starttime);
    let t2 = toMinutes(e2.starttime);

    return t1 < t2 ? -1 : (t1 > t2 ? 1 : 0);
  });
};
export function getEntrySummaryForDiscipline(entry, disciplineid) {
  const breakaftercolumn = getDisciplineBreakClassColumn(disciplineid);
  const thisEventclassScheduling = entry.Eventclass.EventclassSchedulings.find(ecs => ecs.disciplineid == disciplineid);

  return {
    id: entry.id,
    breakafter: entry[breakaftercolumn],
    competitionnumber: entry.order,
    classname: entry.Eventclass.sponsortext || entry.Eventclass.Classtype.name,
    name: `${entry.User.firstname} ${entry.User.lastname}`,    
    horsename: entry.Horse.name,
    arena: thisEventclassScheduling && thisEventclassScheduling.Arenaday.Arena ? thisEventclassScheduling.Arenaday.Arena.name : 'Unknown',
    nummultiples: entry.multiples,
    note: `${entry.notes} / ${entry.EvententrantExtra ? entry.EvententrantExtra.competitorrequests : ''}` ,
    starttime: getStartTime(entry, disciplineid),
    eventclassSchedulingId: thisEventclassScheduling ? thisEventclassScheduling.id : 0,
    disciplinename: getDisciplineName(disciplineid),
    disciplineshortname: getDisciplineShortName(disciplineid),
    disciplineid: disciplineid,
    others: getOtherEntryTimes(entry, disciplineid)
  };  
}

export function getOtherEntryTimes(entry, disciplineid) {
  let disciplines = [1, 2, 3];
  disciplines = disciplines.filter(d => d != disciplineid);
  let others = [];
  disciplines.map(d => {
    others.push({
      disciplineid: d,
      disciplineshortname: getDisciplineShortName(d),
      starttime: getStartTime(entry, d),
      classname: entry.Eventclass.sponsortext || entry.Eventclass.Classtype.name,
      competitionnumber: entry.order
    });
  });
  return others;
}

export function getArenaForDiscipline(eventclassSchedulings, disciplineid) {
  const result = eventclassSchedulings.find(ecs => ecs.disciplineid == disciplineid);
  if (!result) {
    return 'Unknown';
  }
  return result.Arenaday.Arena.name;
}

export function getOtherDisciplineSummaries(otherEntrySub) {
  if(!otherEntrySub || otherEntrySub.length == 0) {
    return [];
  }
  return otherEntrySub.map(e => {
    return {
      disciplinename: e.SubClassScheduling.Arenaday.Arena.Discipline.name,
      disciplineshortname: e.SubClassScheduling.Arenaday.Arena.Discipline.shortname,
      starttime: e.starttime,
      disciplineid: e.SubClassScheduling.Arenaday.Arena.disciplineid
    }; 
  })
}

export function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}


export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function SortColumn(props) {
  if (typeof props == 'undefined') {
    return;
  }

  // pi_3Kb1vR2wFs4PDQOm0taSgvTE
  // key={"sort-header-label-"+props.kk}
  // return (
  //   <TableCell >
  //     <TableSortLabel 
  //       active={props.orderBy === props.columnName}
  //       direction={props.orderBy === props.columnName ? props.order : 'asc'}
  //       onClick={(event) => {
  //         props.handleRequestSort(event, props.columnName)
  //       }}
  //     >
  //       {props.headerName}
  //     </TableSortLabel>
  //   </TableCell>
  // )
  return (
    <TableCell >
        {props.headerName}
    </TableCell>
  )
};

export function isBefore(t1, t2) {
  return toMinutes(t1) < toMinutes(t2);
}

export function addMinutes(startTime, numMinutes) {
  let timeParts = startTime.split(":");
  let hours = parseInt(timeParts[0]);
  let minutes = parseInt(timeParts[1]) + parseInt(numMinutes);
  while (minutes >= 60) {
    hours++;
    minutes = minutes - 60;
  }
  return pad(hours, 2) + ":" + pad(minutes, 2);
}

export function diffInMinutes(t1, t2) {
  return Math.abs(toMinutes(t1) - toMinutes(t2));
};

export function toMinutes(time) {
  if(!time) {
    return 0;
  }

  let timeParts = time.split(":");
  let hours = parseInt(timeParts[0]);
  let minutes = parseInt(timeParts[1]);
  return parseInt(hours*60 + minutes);
};

export function isTimeFormat(timestr) {
  const regex = /^\d{1,2}:\d{2}/; 
  return regex.test(timestr);
}

export function toSeconds(time) {
  if(!time) {
    return null;
  }

  if(!isTimeFormat(time)) {
    return null;
  }

  return toMinutes(time);
};

export function minutesToDuration(numMinutes) {
  let hourDiff = parseInt(numMinutes / 60);
  let minDiff = numMinutes % 60;

  return pad(hourDiff, 2) + ":" + pad(minDiff, 2);
};

// reorder the list based on moving element from startindex to endIndex
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
};

