import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import { MetaTable } from '../../components/table/MetaTable';
import Panel from '../../components/containers/Panel';

export default function EditArenaDetails() {
    const { id, type } = useParams();

    return (
        <>
        <Panel>
            <Form id={id} objtype={"arena"} readonly={true} />
        </Panel>
        <Panel>
            <MetaTable title={"Arena Days"} type={"arenaday"} dataUrl={"/admin/arena/"+id+"/days"}/>
        </Panel>
        </>
    )
}