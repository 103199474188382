import React, { useState } from "react";
import _ from "lodash";
import { Box } from "@mui/system";
import ResponsiveDialog from "../containers/ResponsiveDialog";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';



export default function ConfirmationDialog(props:any) {

    return (
        <Box sx={{paddingTop: '10px', paddingBottom: '100px'}}>
            <ResponsiveDialog title={props.title} onPrimaryClick={props.onConfirm} 
                primaryButtonIcon={<ThumbUpAltIcon/>} primaryButtonLabel="Confirm" open={props.open}
                description={props.description}
                loading={props.loading} error={""} onCloseClick={props.onCancel}
                loadingMessage={props.loadingMessage ? props.loadingMessage : null}
                >
            </ResponsiveDialog>
        </Box>
    )
}