import * as React from 'react';
import { useParams } from 'react-router';
import { Grid } from "@mui/material";
import Panel from '../../components/containers/Panel';
import { Typography } from '@mui/material';
import Title from "../Title";
import { useNavigate } from 'react-router';
import Cancel from "../../components/buttons/Cancel";
import Primary from "../../components/buttons/Primary";
import { useSnackbar } from 'notistack';
import axios from "axios";
import { useSelector } from 'react-redux';
import { selectUser } from '../../app/loginSlice';



export default function DeleteDetails() {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const [deleting, setDeleting] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(selectUser);

    const cancel = () => {
        return navigate(-1);
    };
    
    const deleteEvent = () => {
        console.debug("Delete called");
        setDeleting(true);
        axios.delete("/admin/"+type+"/id/"+id, { orgid: user.orgId } )
            .then(response => {
                console.debug(response);
                setDeleting(false);
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'})
                }
                setDeleted(true);
                enqueueSnackbar(type + " deleted", { variant: 'success'});
            })
            .catch(error => {
                console.debug(error);
                return enqueueSnackbar("Error deleting " + type + ": " + error, { variant: 'error'})
            });
    };
    
    return (
        <Panel>
            <Title>Confirm Deletion</Title>
            <Typography>Please confirm that you would like to delete this {type} by clicking
                the Confirm Delete button below, otherwise click Cancel.
            </Typography>
            <Grid container spacing={3} sx={{pt: 2}}>
            {!deleted && <Grid item>
                    <Cancel disabled={deleting} onClick={() => { cancel() }}/>
                </Grid>}
                {!deleted && <Grid item>
                    <Primary disabled={deleting } title="Confirm Delete" onClick={() => { deleteEvent() }}/>
                </Grid>}
            {deleted && <Grid item>
                <Primary  title="Back" onClick={() => { cancel() }}/>
            </Grid>}

            </Grid>
        </Panel>
    )
}