export const EventEntryFilters = [
    {
        id: "status",
        type: "togglegroup",
        options: [
            {
                id: 'WITHDRAWN',
                name: 'Withdrawn'
            },
            {
                id: 'ENTRYCOMPLETED',
                name: 'Completed'
            },
            {
                id: 'ENTRYPENDING',
                name: 'Pending'
            },
            {
                id: 'ENTRYREFUNDED',
                name: 'Refunded'
            },
            {
                id: 'MANUAL',
                name: 'Manual'
            }
        ]
    }
];