import * as React from 'react';
import { Grid } from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Arena from './Arena';
// import { useParams } from 'react-router';

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightgrey" : "",
    // padding: grid,
    // width: 250
  });
  
const getItemStyle = (isDragging, draggableStyle) => ({
// some basic styles to make the items look a bit nicer
userSelect: "none",
// padding: grid * 2,
margin: 3,

// change background colour if dragging
background: isDragging ? "grey" : "white",

// styles we need to apply on draggables
...draggableStyle
});

export default function DisciplineClasses(props) {
    // const { id } = useParams();


    const onDragEnd = (result) => {
        console.debug("On drag end called");
        const {source, destination} = result;
    
        // dropped outside the list
        if (!destination) {
          return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;
        console.debug("Moving from arena " + sInd + " index " + source.index + " to arena " + dInd + " index " + destination.index);
        props.moveClass(sInd, dInd, source.index, destination.index, props.discipline.id);
        
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}>
              {props.discipline.arenas.map(arena => {
                  return (
                      <Arena busy={props.busy} key={"arena"+arena.id} arena={arena} />
                  )
              })}
            </Grid>
        </DragDropContext>
    );
}