import * as React from 'react';
import Grid from '@mui/material/Grid';
import { MetaTable } from '../components/table/MetaTable';
import { roleIdToName, userStatusIdToName } from '../utils';
import { Paper } from '@mui/material';
import Panel from '../components/containers/Panel';



export default function Users() {
  return (
    <Panel>
      <MetaTable type="test" canCreate={true}/>
    </Panel>
  )
}