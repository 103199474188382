import * as React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useState } from 'react';

import NumbersIcon from '@mui/icons-material/Numbers';
import useId from '@mui/material/utils/useId';
export default function SelectEntry({ onSelect }) {
    const [value, setValue] = useState();

    const handleChange = (event) => {
        // console.log(event.target.value);
        setValue(event.target.value);
    }

    return (
        <TextField
            // variant={variant}
            sx={{
                width: 120,
                mb:3
            }}
            id={"uuid-"+useId()}
            autoComplete='new-password'
            size="small"
            value={value ? value : ''}
            onChange={handleChange}
            helperText="Comp. Number"
            InputProps={{
                endAdornment: 
                    <InputAdornment position="end">
                        <NumbersIcon width={24} height={24} />
                    </InputAdornment>,
                    autoComplete: 'new-password',
                    form: {
                        autoComplete: 'off',
                    },
            }}
            onKeyPress={(e) => {
                console.log(e.key);
                if (e.key === "Enter") {
                    console.log("Enter key pressed");
                    e.preventDefault();
                    onSelect(value);
                    // write your functionality here
                }
            }}
        />
    );
}
