import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import Primary from '../../components/buttons/Primary';
import Back from '../../components/buttons/Back';
import { Grid, Typography } from "@mui/material";
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import EditIcon from '@mui/icons-material/Edit';
import ResponsiveDialog from "../../components/containers/ResponsiveDialog";
import { RefundWorldnetFields } from "../../app/form/Definitions/RefundWorldnetFields";

import { useSnackbar } from 'notistack';
// import Grid2 from '@mui/material/Unstable_Grid'; // Grid version 2
// import Grid from '@mui/material/Grid'; 
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useRef } from 'react';
import Title from '../Title';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
export default function RefundWorldnet() {
    const { id } = useParams();
    const {enqueueSnackbar } = useSnackbar();
    const [worldnet, setWorldnet] = React.useState();
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [form, setForm] = React.useState({});

    React.useEffect(() => {
        getData();
    }, []);



    const updateForm = (updates) => {
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };
    const dataUsed = (worldnetDetails) => {
        setWorldnet(worldnetDetails);
    }

    async function getData() {
        axios.get('/admin/worldnet/id/' + id)
            .then(response => {
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                setWorldnet(response.data.record);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }

    const refund = () => {

    }

    const formRef = useRef()

    return (
        <Panel>
            <Title>Confirm Refund of the Following Worldnet Payment</Title>
            <Form 
                id={id} 
                objtype={"worldnet"} 
                readonly={true} 
                showbuttons={false} 
                onData={dataUsed}
                ref={formRef}    
                noTitle={true}             
            />       
            {worldnet && 
                <Grid container spacing={3} sx={{pt: 2}}   justifyContent="flex-end">
                    <Grid item><Primary title="Refund Payment" onClick={() => setShowConfirm(true)}/></Grid>
                    <Grid item><Back/></Grid>
                </Grid>
            }
            {worldnet && showConfirm && 
                <ResponsiveDialog title="Confirm Refund Request" onPrimaryClick={refund} onCloseClick={() => setShowConfirm(false)}
                    primaryButtonIcon={<EditIcon/>} primaryButtonLabel="Confirm Refund" open={showConfirm}
                    description="If you would like an email to be sent to the member who made this payment, then select the option below and add yout text to the box underneath."
                    loading={loading} error={error}>
                    <FormBuilder fields={RefundWorldnetFields} form={form} updateForm={(updates) => updateForm(updates)} />
                </ResponsiveDialog>
            }
        </Panel>
    )
}