import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';

import {
    Typography
} from '@mui/material';
export default function EditScoreDetails() {
    const { id, type } = useParams();

    return (
        <Panel>
            <Form id={id} objtype={"eventscoringresults"} />
            <Typography variant="h6" color="error">Note: Scoring Rules</Typography>
            {/* <Typography>Please use the following codes </Typography> */}
            {/* <Typography>Set Total Score = 999999 for a DNS (No Show)</Typography> */}
            {/* <Typography>Set Place = 777 for HC (not applicable for</Typography> */}
            {/* <Typography>Set Place = 888 to mark the results as no score</Typography> */}
            <Typography>Use the DR Status, SJ Status and XC Status with a not OK value to indicate a problem in one of the disciplines and set Total to 999999</Typography>
        </Panel>
    )
}