import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Typography, Paper, Grid, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { API_ROOT } from '../../app/constants';
import ReportView from '../components/ReportView';

const filterdefinition =
    {
        filters: [{
        id: "status",
        type: "togglegroup",
        options: [
            {
                id: 'WITHDRAWN',
                name: 'Withdrawn'
            },
            {
                id: 'ENTRYCOMPLETED',
                name: 'Completed'
            },
            {
                id: 'ENTRYREFUNDED',
                name: 'Refunded'
            }
        ]
    }]
    };

export default function EventReportsPage() {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [eventName, setEventName] = useState("");

    React.useEffect(() => {
        // get the details for this event 
        axios.get("/admin/event/id/"+id)
            .then(response => {
            if (response.data.error) {
                enqueueSnackbar("Unable to fetch event details " + response.data.message, { variant: 'error'});
                return;
            }
            setEventName(response.data.record.name);
        }).catch(error => {
            enqueueSnackbar("Error while fetching report data " + error, { variant: 'error'});
        });

    }, []);


    const download = (name, urlpath, options) => {
        enqueueSnackbar("Generating file, please wait", { variant: 'warning'});
        const link = document.createElement("a");
        link.download = name;
        let query = new URLSearchParams(options).toString();
        link.href = `${API_ROOT}admin/event/${id}/${urlpath}` + (query && query.length > 0 ? `?${query}` : '');
        link.click();   
    }

    const getDataUrl = () => {
        return "/admin/report/data/23?eventid="+id;
    }

    return (
        <>            
            <Grid container justifyContent="flex" spacing={3} >
                <Grid item xs={12} md={8} lg={9}  order={{ xs: 2, sm: 1 }}>
                    <ReportView id={23} definition={filterdefinition} dataurl={getDataUrl()} title={eventName}/>
                </Grid>
                <Grid item xs={12} md={4} lg={3} order={{ xs: 1, sm: 2 }}>
                    <Paper  sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        // height: 240,
                    }}>
                    <Typography component="h3" variant="h6" color="primary" sx={{pl:2, pt:2}}>
                        Downloads</Typography>
                        <Button sx={{mt: 2}} variant="contained" onClick={() => download('DownloadLabels', 'downloadlabels', { initial: false })}>Labels</Button>
                        <Button sx={{mt: 2}} variant="outlined" onClick={() => download('DownloadRunners', 'downloadrunners', { initial: true })}>Runners (XC Commentary)</Button>
                        <Button sx={{mt: 2}} target="_blank" href={"https://results.eventingireland.com/underage.html?id="+id} variant="contained" color="primary">Under Age</Button>
                    </Paper>
                    
                </Grid>
            </Grid>
        </>
    );
}

