import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import { MetaTable } from '../../components/table/MetaTable';
import Panel from '../../components/containers/Panel';
import Primary from '../../components/buttons/Primary';
import Back from '../../components/buttons/Back';
import { Grid, Typography } from "@mui/material";
import ConfirmationDialog from '../../components/collections/ConfirmationDialog';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from "axios";
import { useRef } from 'react';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
export default function OrderDetails(props) {
    
    const [selectedItems, setSelectedItems] = React.useState(Array());
    const [canRefund, setCanRefund] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const {enqueueSnackbar } = useSnackbar();
    const [refunding, setRefunding] = React.useState(false);
    const [order, setOrder] = React.useState();

    React.useEffect(() => {
        if(!order) {
            getData();
        } else {
            setOrder(props.order);
        }
    }, []);

    const onSelect = (rows) => {
        if (rows.length > 1) {
            workoutCanRefund(rows);
            setSelectedItems(rows);
            return;
        }
        let selectedRows = JSON.parse(JSON.stringify(selectedItems));
        selectedRows.push(rows[0]);
        setSelectedItems(selectedRows);
        workoutCanRefund(selectedRows);
        console.debug(selectedItems);
    };   

    const onDeselect = (rows) => {
        if (rows.length > 1) {
            setCanRefund(false);
            setSelectedItems([]);
            return;
        }
        let selectedRows = [];
        for (var i = 0; i < selectedItems.length; i++) {
            // console.debug("Checking " + selectedRows[i].name + " == " + rows[0].name)
            if (selectedItems[i].id != rows[0].id ) {
                selectedRows.push(selectedItems[i]);
            }
        }
        setSelectedItems(selectedRows);
        workoutCanRefund(selectedRows);
        console.debug(selectedItems);
    }

    const workoutCanRefund = (rows) => {
        if(rows.length < 1) {
            return setCanRefund(false);
        }

        // if the payment is a refunded paymet then it can't be refunded
        if(order != null && order.paymenttype == 'CQ') {
            return setCanRefund(false);
        }
        let refundok = false;
        rows.map((r) => {
            if(r.iscompleted == 1 && (r.beenrefunded == null || r.beenrefunded == 0) && r.amount > 0) {
                refundok = true;
            }
        });

        setCanRefund(refundok);

    }

   
    const refund = () => {
        // pop up confirmation question
        setShowConfirm(true);
        setRefunding(false);

    }

    const onCancel = () => {
        // pop up confirmation question
        setShowConfirm(false);
    }

    

    const refundConfirmed = () => {
        // pop up confirmation question
        enqueueSnackbar("Please wait while we refund this payment", { variant: 'success'});
        // setShowConfirm(false);
        setRefunding(true);

        // call refund on the server
        axios.post(`/admin/order/${props.id}/refund`, { withdrawentry: props.withdraw, orderitems: selectedItems, memberid: order.memberid} )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return enqueueSnackbar(response.data.message, { variant: 'error'});
            }            
            enqueueSnackbar("Payment refunded", { variant: 'success'});
            setRefunding(false);
            setShowConfirm(false);
            if(props.onRefund) {
                props.onRefund(response.data);
            }

        })
        .catch(error => {
            console.debug(error);
            setRefunding(false);
            return enqueueSnackbar("Error refunding payment: " + error, { variant: 'error'})
        });
    }

    const dataUsed = (orderDetails) => {
        setOrder(orderDetails);
    }

    async function getData() {
        console.debug(props);
        axios.get('/admin/order/id/' + props.id)
            .then(response => {
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                setOrder(response.data.record);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }

    const confirmationDescription = () => {
        let total = 0.0;
        selectedItems.map(r => {
            total += parseFloat(r.amount);
        });
        let count = 0;
        return (
            <React.Fragment >
                 <Typography variant="body2" gutterBottom >
                     Please confirm that you want to refund the following items?
                </Typography>
                <Grid container spacing={2} justifyContent="flex-end">
                    {selectedItems.map((i, index) => {
                        if(i.iscompleted == 1 && (i.beenrefunded == null || i.beenrefunded == 0) && i.amount > 0) {
                        // if(i.amount != 0) {
                            count++;
                            return (
                                <React.Fragment key={"refund-item-"+index}>
                                    <Grid item xs={1}>
                                        {count}
                                    </Grid>
                                    <Grid item xs={9}>
                                        {i.description}
                                    </Grid>
                                    <Grid item xs={2}>
                                    {i.amount}
                                    </Grid>
                                </React.Fragment>
                            )    
                        }
                    })}
                    <React.Fragment key={"refund-item-total"}>
                        <Grid item xs={1}>
                            
                        </Grid>
                        <Grid item key={"total"} xs={9} justifyContent="flex-end">
                            Total Being Refunded
                        </Grid>
                        <Grid item xs={2}>
                            {total.toFixed(2)}
                        </Grid>
                    </React.Fragment>
                </Grid>
            </React.Fragment>)
          ;
    }
    return (
        <>       
        {order != null && <Panel>
            <MetaTable onSelect={onSelect} onDeselect={onDeselect} selectable={order != null && order.paymenttype == 'CC' || order.paymenttype == 'CC-RF'} 
                multiselect={true} title={"Order Items"} type={"orderitem"} dataUrl={"/admin/order/"+props.id+"/items"}/>
            <Grid container spacing={3} sx={{pt: 2}}   justifyContent="flex-end">
                {canRefund && <Grid item><Primary title="Refund" onClick={refund}/></Grid>}
                <Grid item><Back/></Grid>

            </Grid>
            {showConfirm && <ConfirmationDialog title={"Please Confirm"} description={confirmationDescription()}
                            onConfirm={refundConfirmed} open={showConfirm}
                            onCancel={onCancel} loading={refunding}
                            loadingMessage={"Please wait while we refund this payment"}/>}
        </Panel>}
        </>
    )
}