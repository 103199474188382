import * as React from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';
import Description from '../../containers/Description';
import { Divider, TextField, Typography, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Save from '../../components/buttons/Save';
import EventclassMultieselect from './EventclassMultiselect';
import LoadingButton from '@mui/lab/LoadingButton';

export default function DressageSetup(props:any) {
    const [saving, setSaving] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    const [numJudges, setNumJudges] = React.useState(props.eventclass.numjudges);
    const [judge1, setJudge1] = React.useState(props.eventclass.judge1);
    const [judge2, setJudge2] = React.useState(props.eventclass.judge2);
    const [judge3, setJudge3] = React.useState(props.eventclass.judge3);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedClasses, setSelectedClasses] = React.useState<{id: string; name: string}[]>();

    const save = () => {
        saveToServer(props.eventclass.id, numJudges, judge1, judge2, judge3);
    }

    const saveToServer = (id:string, nj:number, j1:string, j2:string, j3:string) => {
        setSaving(true);
        axios.put("/admin/eventclass?setup=dr", {
            id: id,
            numjudges: nj,
            judge1: j1,
            judge2: j2,
            judge3: j3,
        }).then(response => {
            if (response.data.error) {
                setSaving(false);
                enqueueSnackbar(response.data.message, { variant: 'error'})
                return;
            }
            enqueueSnackbar("Dressage settings updated", { variant: 'success'})
            setSaving(false);
            setChanged(false);        
        }).catch(error => {
            enqueueSnackbar(error, { variant: 'error'})
            console.debug(error);
        });
    }

    const isSetup = () => {
        return judge1 != "";
    }

    const applyToOtherClasses = () => {
        // apply the current settings in this class to the list of current selected classes.
        if(!selectedClasses ) {
            return;
        }
        selectedClasses.map((c:{ id: string; name: string }) => {
            saveToServer(c.id, numJudges, judge1, judge2, judge3);
        });
    }

    const selectedClassesChanged = (values:{ id: string; name: string }[]) => {
        setSelectedClasses(values);
    }

    const isChanged = (n:any) => {
        if(parseInt(props.eventclass.numjudges) != parseInt(n.numJudges) ||
            props.eventclass.judge1 != n.judge1 ||
            props.eventclass.judge2 != n.judge2 ||
            props.eventclass.judge3 != n.judge3
        ) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }

    const changeNumJudges = (value:string) => {
        setNumJudges(value);
        isChanged({
            numJudges: value,
            judge1,
            judge2,
            judge3
        });
    }

    const changeJudge1 = (value:string) => {
        setJudge1(value);
        isChanged({
            numJudges,
            judge1: value,
            judge2,
            judge3
        });
    }

    const changeJudge2 = (value:string) => {
        setJudge2(value);
        isChanged({
            numJudges,
            judge1,
            judge2: value,
            judge3
        });
    }

    const changeJudge3 = (value:string) => {
        setJudge3(value);
        isChanged({
            numJudges,
            judge1,
            judge2,
            judge3: value
        });
    }

    return (
        <>
            <Typography>Please provide details for the number of judges and their names</Typography>
            <Grid container sx={{pb:2}} {...props}>
                <Grid item md={6}>
                        {props.description && <Description>{props.description}</Description>}
                        {props.children}
                        <Box sx={{ maxWidth: 320, mb:5, pb:5}}>
                            <FormControl fullWidth  sx={{mt:2}}>
                                <InputLabel id="numjudges">Number of Dressage Judges</InputLabel>
                                <Select
                                labelId="numjudges"
                                id="numjudges-select"
                                value={numJudges}
                                label="Number of Dressage Judges"
                                onChange={(event) => changeNumJudges(event.target.value)}
                                >
                                <MenuItem value={1}>One</MenuItem>
                                <MenuItem value={2}>Two</MenuItem>
                                <MenuItem value={3}>Three</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth  sx={{mt:2}}>
                            <TextField id="judge1" onChange={(event) => changeJudge1(event.target.value) } value={judge1} label="Name of Judge 1" variant="standard" />
                        </FormControl>                        
                        {parseInt(numJudges) > 1 && <FormControl fullWidth  sx={{mt:2}}><TextField onChange={(event) => changeJudge2(event.target.value)} value={judge2}  id="judge2" label="Name of Judge 2" variant="standard" /></FormControl>}
                        {parseInt(numJudges) > 2 && <FormControl fullWidth  sx={{mt:2}}><TextField id="judge3" onChange={(event) => changeJudge3(event.target.value)} value={judge3} label="Name of Judge 3" variant="standard" /></FormControl>}
                        </Box>
                        <Divider sx={{my:1}}/>
                        <Save
                            disabled={!changed}
                            loading={saving}
                            onClick={save}
                        />
                </Grid>
                <Grid item md={6}>
                    <Typography>
                        Click the button below to apply these Dressage settings to other classes.
                    </Typography>
                    <EventclassMultieselect names={props.eventclass.names} onChange={(values:any) => selectedClassesChanged(values)} />
                    {selectedClasses && selectedClasses.length > 0 && <LoadingButton loading={saving} disabled={!isSetup()} variant="contained" onClick={applyToOtherClasses}>
                        Apply These Settings To Other Classes
                    </LoadingButton>}
                </Grid>
            </Grid>
            
        </>
    )
}