import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import { ListItemText } from '@mui/material';
import { MenuAction } from '../types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import TableChartIcon from '@mui/icons-material/TableChart';
import ClassIcon from '@mui/icons-material/Class';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TableViewIcon from '@mui/icons-material/TableView';
import ApprovalIcon from '@mui/icons-material/Approval';
import ContactsIcon from '@mui/icons-material/Contacts';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import NumbersIcon from '@mui/icons-material/Numbers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface MenuProps {
    menu: MenuAction[];
}

export function icon(iconname: string) {
    switch (iconname) {
      case 'clone': 
        return (<ContentCopyIcon width={24} height={24} />);
      case 'delete':
        return (<DeleteIcon width={24} height={24} />);
      case 'edit':
      case 'manage':
        return (<EditIcon width={24} height={24} />);
      case 'view':
      case 'publishresults':
          return (<VisibilityIcon width={24} height={24} />);
      case 'entry':
        return (<ListAltIcon width={24} height={24} />);
      case 'schedule':
        return (<ScheduleIcon width={24} height={24} />);
      case 'scoring':
        return (<NumbersIcon width={24} height={24} />);
      case 'reports':
        return (<TableChartIcon width={24} height={24} />);
      case 'back':
          return (<ArrowBackIcon width={24} height={24} />);
      case 'logout':
          return (<LogoutIcon width={24} height={24} />);
      case 'download':
          return (<FileDownloadIcon width={24} height={24} />);
      case 'refresh':
        return (<RefreshIcon width={24} height={24} />);
      case 'eventclass':
        return (<ClassIcon width={24} height={24} />);
      case 'results':
        return (<TableViewIcon width={24} height={24} />);
      case 'approvals':
        return (<ApprovalIcon width={24} height={24} />);
      case 'split':
            return (<AltRouteIcon width={24} height={24} />);
      case 'contacts':
        return (<ContactsIcon width={24} height={24} />);
      case 'payments':
        return (<AccountBalanceIcon width={24} height={24} />);
      case 'refundworldnet':
      case 'refund':
          case 'bulkrefund':
        return (<AssignmentReturnIcon width={24} height={24} />);
      case 'eventreports':
        return (<ListAltIcon width={24} height={24} />);
      }
  }

export function ActionMenu(props: MenuProps) {
    const ref = React.useRef(null);
    const [isOpen, setIsOpen] = React.useState(false);
    
    const onClickHandler = (e: any) => {
      let menuName = e.target.outerText;
      for (const m of props.menu) {
        if (m.name == menuName) {
          m.handler();
          setIsOpen(false);
        }
      }
    }
  
    return <>
      <IconButton
        ref={ref}
        onClick={() => setIsOpen(true)}
        style={{padding: 0}}
        size="large">
        <ArrowDropDownCircleOutlinedIcon sx={{ color: "white", marginLeft: 1}} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        onClick={onClickHandler}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {props.menu.map((m, mindex) =>
          <MenuItem sx={{ color: 'text.secondary' }} key={mindex}>
            <ListItemIcon sx={{ margin: 0, padding: 0 }} >
              {icon(m.icon)}
            </ListItemIcon>
            <ListItemText primary={m.name} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>;
  }
  