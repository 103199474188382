import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { Grid, Typography, Button, Stack, TextField } from '@mui/material';
import axios from 'axios';
import Panel from '../components/containers/Panel';
// import Loading from '../components/Loading';
// import EntriesTable from '../components/eventing/EntriesTable';
import { MetaTable } from '../components/table/MetaTable';
import Back from "../components/buttons/Back";
import ConfirmationDialog from '../components/collections/ConfirmationDialog';
import EnhancedTable from '../components/table/EnhancedTable';
import { getDisplayValue } from '../utils';
import Title from './Title';


const tableFields = [
  {
      id: "horsename",
      label: "Horse",
      properties: {
          component: "text-field",
      }
  },
  {
      id: "ridername",
      label: "Rider",
      properties: {
          component: "text-field"
      }
  },
  {
      id: "refundstatus",
      label: "Refund Status",
      format: getDisplayValue,
      properties: {
          component: "select"
      }
  },
  {
      id: "result",
      label: "?",
      properties: {
          component: "text-field"
      }
  }
];

export default function PublishClassResults() {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [eventclass, setEventclass] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        axios.get("/admin/eventclass/id/"+id)
          .then(response => {
            if(response.data.error) {
              return enqueueSnackbar("Problem getting event class details, not such id", { "variant": "error" });
            }
            setEventclass(response.data.record);
          }).catch(error => {
          });
    }, [id]);


    const publish = (publishstatus) => {
      axios.post("/admin/eventclass/"+id+"/publish", { publishstatus: publishstatus})
        .then(response => {
        if(response.data.error) {
          enqueueSnackbar("Error " + (publishstatus ? "publishing" : "unpublishing") + " results: " + response.data.message , { variant: "error"});
        } else {
          enqueueSnackbar("Results " + (publishstatus ? "Published" : "Unpublished"), { variant: "success"});
          navigate(0);
        }
      });
    };

  
    return (
           <><Panel>
            {eventclass && <Grid container justifyContent="flex" >
              <Grid item >
                  <Typography color={"primary"}  noWrap variant="h6" component="h3">{"Show / Hide Results: " + eventclass.Classtype.name}</Typography>
                  <Typography variant="body2" sx={{mb:3}}>You can show or hide the entries for this class by clicking the corresponding Publish or Unpublish button here.</Typography>
              </Grid>
                  
              <Grid item xs={12}>
                  <Stack sx={{pl:5}} direction="row" justifyContent="flex-end" spacing={2} >
                      {eventclass && <Button onClick={() => publish(true)} variant="contained" color="success">Publish (Show) Results</Button>}
                      {eventclass && <Button onClick={() => publish(false)} variant="contained" color="success">Unpublish (Hide) Results</Button>}
                      <Back/>
                  </Stack>
              </Grid>
            </Grid>}             
            </Panel>
            <Panel>
              <Title>Current Published Results</Title>
            {eventclass &&
              <MetaTable 
                dataUrl={`/admin/eventclass/${id}/results?published=true`} 
                type="eventresults" 
                idvalue={id}
                idname="eventclassid"
            />}
           </Panel></>

    );
}

