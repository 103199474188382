export const EventEntryStablingFilters = [
    {
        id: "extrastatus",
        type: "togglegroup",
        options: [
            {
                id: 'STABLINGCOMPLETED',
                name: 'Completed'
            },
            {
                id: 'STABLINGPENDING',
                name: 'Pending'
            },
            {
                id: 'STABLINGREFUNDED',
                name: 'Refunded'
            }
        ]
    }
];