import * as React from 'react';
import { useParams } from 'react-router';
import { ActionMenu } from '../../components/ActionMenu';
import { MenuAction } from '../../types';
import { useNavigate } from 'react-router';
import { useAuth } from '../../auth/AuthProvider';
import { useSelector } from 'react-redux';
import { selectUser, selectToken } from "../../app/loginSlice";

function ScoringAppMenu(props: any) {
    const { id } = useParams();
    const navigate = useNavigate();
    let auth = useAuth();
    const user = useSelector(selectUser);

    const menu: MenuAction[] = [      
        {
            icon: "back",
            name: "Back To All Events",
            handler: function() {
                navigate("/events", { replace: true });                
            }
        },
        {
            icon: "logout",
            name: "Logout",
            handler: function() {
                auth.signout(user.id, () => {
                    console.debug("Logged out");
                    navigate("/login", { replace: true });
                });
            }
        }

    ];

    return (
        <ActionMenu menu={menu} />
    );
}
export default ScoringAppMenu;