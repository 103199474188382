import React, { useState } from "react";
import _ from "lodash";
import axios from "axios";
import { AddRoleFields } from "../../app/form/Definitions/AddRoleFields";
import AddIcon from '@mui/icons-material/Add';
import FormDialog from "../form/FormDialog";
import { useNavigate } from "react-router-dom";


export default function AddRole(props:any) {
    const navigate = useNavigate();


    const change = (form:any, callback:any) => {
        axios.post("/admin/user/"+props.id+"/addrole", { 
            roleid: _.get(form, 'roleid'),
            effectivedate: _.get(form, 'effectivedate'),
            expirydate: _.get(form, 'expirydate')
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: response.data.message});
            }
            callback({ error: false, message: "Role added"});
            navigate(0);
        })
        .catch(error => {
            return callback({ error: true, message: "Error adding role: " + error});
        });

        
    };

    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        // they cannot be empty
        let roleid = _.get(form, 'roleid');
        if (!roleid || roleid == "") {
            return "Please select the new role to add";
        }
        return "";
    }

    return (
        <FormDialog 
            buttonLabel="Add Role" 
            title="Select Role To Add"
            description="Please select the role you wish to add"
            saveLabel="Add"
            fields={AddRoleFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<AddIcon/>)}
       />
    //    <Box sx={{}}>
    //         {!changing && <RightButton startIcon={<AddIcon />} onClick={() => setChanging(true)} title={"Add Role"} />}
    //         {changing &&  <ResponsiveDialog title="Add Role" onPrimaryClick={change} onCloseClick={() => setChanging(false)}
    //             primaryButtonIcon={<AddIcon/>} primaryButtonLabel="Add" open={changing}
    //             description="Please select the role to add or this user and optionally you can set an effective from and to date"
    //             loading={saving} error={error}>
    //             <FormBuilder fields={AddRoleFields} form={form} updateForm={(updates) => updateForm(updates)} />
    //         </ResponsiveDialog>}
    //     </Box>
    )
}