import * as React from 'react';
import { MetaTable } from '../components/table/MetaTable';
import Panel from '../components/containers/Panel';
import OrdersTable from '../components/eventing/OrdersTable';

export default function Orders(props:any) { 

    return (
        <Panel>
            <OrdersTable/>
        </Panel>
    )
}
