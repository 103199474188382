export const ChangePasswordFields = [
    // {
    //     // Default component is Material UI's TextField
    //     attribute: "oldpassword",
    //     label: "Old Password",
    //     col: {
    //       // Here you can specify how many Grid columns the field should take for the corresponding breakpoints
    //       sm: 12,
    //     },
    //     validationType: "string",
    //     validations: {
    //       required: true,
    //       max: 50,
    //     },
    //     props: {
    //         type: "password"
    //     }
    //   },
      {
        attribute: "newpassword",
        label: "New Password",
        col: {
          // Here you can specify how many Grid columns the field should take for the corresponding breakpoints
          sm: 12,
        },
        validationType: "string",
        validations: {
          required: true,
          max: 50,
        },
        props: {
            type: "password"
        }
      },
      {
        attribute: "newpasswordconfirmed",
        label: "New Password Confirmed",
        col: {
          // Here you can specify how many Grid columns the field should take for the corresponding breakpoints
          sm: 12,
        },
        validationType: "string",
        validations: {
          required: true,
          max: 50,
        },
        props: {
            type: "password"
        }
      },
      {
        attribute: "sendemail",
        label: "Send Email To User",
        component: "switch",
        col: {
          sm: 12,
        },
        validationType: "string",
        validations: {
        },
        props: {
        }
      }
];
