export const SelectTeamMember = [    
  {
    attribute: "teammemberid",
    label: "Select Combination",
    component: 'autocomplete-remote',
    validationType: "string",
    validations: {
      required: true,
    },
    optionDef: {
      type: "server-search",
      name: "name",
      url: "/admin/teamemember/auto",
      operation: "like",
      min: 3
    },
    optionConfig: {
      key: "id",
      value: "id",
      label: "name",
    },
    col: {
      sm: 12,
    },
  }
];
