import { useState } from 'react';

import * as React from 'react';
import { useParams } from 'react-router';
import Title from '../Title';
import Panel from '../../components/containers/Panel';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from "axios";
import Loading from '../../components/Loading';
import { MetaTable } from '../../components/table/MetaTable';
import EnhancedTable from '../../components/table/EnhancedTable';
import ConfirmDialog from "../../components/ConfirmDialog";
import Back from "../../components/buttons/Back";
import { all } from "../../app/all";
import { MetaStore } from "../../app/MetaStore";
import SwapCallsIcon from '@mui/icons-material/SwapCalls';

import {
    Alert,
    Grid,
    Stack,
    Divider,
    Typography,
    Button,
    Box,
} from '@mui/material';
import ChangeApplicationNote from '../../components/collections/ChangeApplicationNote';
import Label from '../../components/Label';
import EntriesTable from '../../components/eventing/EntriesTable';
import ChangeClass from '../../components/collections/ChangeClass';

const gender = [
    "", "Gelding", "Mare", "Stallion"
];


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ApplicationEntryDetails() {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [entry, setEntry] = React.useState();
    const [error, setError] = React.useState();
    const [approved, setApproved] = React.useState(false);
    const [inprogress, setInprogress] = React.useState(false);
    const [applicationNotes, setApplicationNotes] = React.useState(false);
    const [openConfirmDecline, setOpenConfirmDecline] = useState(false);
    const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
    const [eventclass, setEventclass] = React.useState();

    React.useEffect(() => {
        getData();
    }, []);

    const newClass = (name) => {
        setEventclass(name);
    }


    const canapprove = () => entry && entry.entry.entrystatus === "APPROVALCOMPLETED" && !approved; // && entry.entrystatus !== "ENTRYREFUNDED" && entry.isapproved === 0;

    const status = () => {
        if (!entry) {
            return "";
        }
        const result = all.status.find(s => s.id === entry.entry.entrystatus);
        if (result) {
            return result.name;
        }
        return "";
    }

    // const isvalid = () => entry && entry.entry.entrystatus === "APPROVALCOMPLETED" ; // && entry.entrystatus !== "ENTRYREFUNDED" && entry.isapproved === 0;

    async function getData() {
        axios.get('/admin/evententrant/' + id + "/application")
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return enqueueSnackbar(response.data.message, { variant: 'error' });
                }
                let e = response.data.record;
                e.datefoaled = new Date(e.datefoaled).getFullYear();
                if (e.enteredby) {
                    e.enteredby.fullname = `${e.enteredby.firstname} ${e.enteredby.lastname}`;
                    e.enteredby.address = `${e.enteredby.address1} ${e.enteredby.address2 ? e.enteredby.address2 : ''}`;
                }
                e.rider.fullname = `${e.rider.firstname} ${e.rider.lastname}`;
                e.rider.address = `${e.rider.address1} ${e.rider.address2 ? e.rider.address2 : ''}`;
                e.rider.membershiptype = ``;
                e.rider.ridingoption = ``;
                setApproved(e.entry.isapproved);
                setInprogress(e.entry.inprogress);
                setApplicationNotes(e.entry.applicationnotes)
                setEventclass(e.entry.classname);
                loadOthers(e);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error' })
            });
    }

    const getField = (name, fields) => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].id == name) {
                return fields[i];
            }
        }
        return null;
    }

    const getOption = (id, options) => {
        for (let i = 0; i < options.length; i++) {
            if (options[i].id == id) {
                return options[i].name;
            }
        }
        return 'Unknown';
    }

    const loadOthers = (e) => {
        MetaStore.get('user', function (value) {
            // set membership type
            let memberTypeField = getField('membertypeid', value.fields);
            let ridingOptionField = getField('rideroptionid', value.fields);
            e.rider.membershiptype = getOption(e.rider.membertypeid, memberTypeField.properties.options);
            e.rider.ridingoption = getOption(e.rider.rideroptionid, ridingOptionField.properties.options);
            setEntry(e);
        });
    }

    const changeInProgress = () => {

        axios.put('/admin/evententrant', {
            id,
            inprogress: inprogress ? 0 : 1
        })
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return enqueueSnackbar(response.data.message, { variant: 'error' });
                }
                enqueueSnackbar("In progress status changed", { variant: 'success' });
                setInprogress(!inprogress);
            })
            .catch(error => {
                enqueueSnackbar("Problem change progress status on entry: " + error, { variant: 'error' })
            });
    }

    const confirmApprove = () => {
        const data = getAppData(true);
        console.log(data);
        axios.post('/admin/evententrant/' + id + "/approve", data)
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return enqueueSnackbar(response.data.message, { variant: 'error' });
                }
                enqueueSnackbar("Entry has been approved and an email is sent to person who made the entry", { variant: 'success' });
                setOpenConfirmApprove(false);
                setApproved(true);
            })
            .catch(error => {
                enqueueSnackbar("Problem approving entry: " + error, { variant: 'error' })
            });
    }

    const getAppData = (isapproved) => (
        {
            isapproved,
            sendemail: isapproved ? true : false,
            firstname: entry.enteredby ? entry.enteredby.firstname : entry.rider.firstname,
            ridername: `${entry.rider.firstname} ${entry.rider.lastname}`,
            horsename: entry.horse.name,
            breedername: entry.horse.breeder,
            ownername: entry.horse.ownernametext,
            horsecolour: entry.horse.colour,
            horsegender: gender[entry.horse.gender],
            ...(entry.entry),
            email: {
                enteredby: entry.enteredby ? entry.enteredby.email : entry.rider.email,
                rider: entry.rider.email
            }
        }
    );

    const confirmDecline = () => {

        axios.post('/admin/evententrant/' + id + "/decline", getAppData(false))
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return enqueueSnackbar(response.data.message, { variant: 'error' });
                }
                enqueueSnackbar("Entry has been marked as declined", { variant: 'success' });
                setOpenConfirmDecline(false);
                setApproved(true);
            })
            .catch(error => {
                enqueueSnackbar("Problem declining entry: " + error, { variant: 'error' })
            });
    }

    const riderFields = [
        {
            id: 'fullname',
            name: 'Name'
        },
        {
            id: 'address',
            name: 'Address'
        },
        {
            id: 'county',
            name: 'County'
        },
        {
            id: 'email',
            name: 'EMail'
        },
        {
            id: 'telephone',
            name: 'Telephone'
        },
        {
            id: 'eveningphone',
            name: 'Evening Phone'
        },
        {
            id: 'mobilephone',
            name: 'Mobile Phone'
        },
        {
            id: 'memberexpirydate',
            name: 'Membership Expiry'
        },
        {
            id: 'nationality',
            name: 'Nationality'
        },
        {
            id: 'athletetype',
            name: 'Athlete Type'
        },
        {
            id: 'membershiptype',
            name: 'Membership Type'
        },
        {
            id: 'ridingoption',
            name: 'Riding Option'
        }
    ];

    const enteredbyFields = [
        {
            id: 'fullname',
            name: 'Name'
        },
        {
            id: 'address',
            name: 'Address'
        },
        {
            id: 'county',
            name: 'County'
        },
        {
            id: 'email',
            name: 'EMail'
        },
        {
            id: 'telephone',
            name: 'Telephone'
        },
        {
            id: 'eveningphone',
            name: 'Evening Phone'
        },
        {
            id: 'mobilephone',
            name: 'Mobile Phone'
        }
    ];
    const horseFields = [
        {
            id: 'registrationnumber',
            name: 'Reg. No'
        },
        {
            id: 'feipassportnumber',
            name: 'FEI Passport Number'
        },
        {
            id: 'datefoaled',
            name: 'Year Foaled'
        },
        {
            id: 'pointstotal',
            name: 'Total Points'
        },
        {
            id: 'regexpirydate',
            name: 'Registration Expiry'
        }
    ];

    const display = (fields, data) => {
        return (
            <Stack>
                {fields.map((f, index) => {
                    return (
                        <Stack key={index} direction="row"><Typography style={{ fontWeight: 'bold' }} variant="body2">{f.name}: &nbsp;</Typography> <Typography variant="body2">{data[f.id]}</Typography></Stack>
                    )
                })}
            </Stack>
        );
    }

    const scoreFields = [
        {
            id: "drscore",
            label: "DR",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "sjjumppen",
            label: "SJF",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "sjtimepen",
            label: "SJT",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "xcjumppen",
            label: "XCF",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "xctimepen",
            label: "XCT",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "totalscore",
            label: "Total Score",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "place",
            label: "Place",
            properties: {
                component: "text-field"
            }
        }
    ];
    const tableFields = [
        {
            id: "eventname",
            label: "Event Name",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "classname",
            label: "Class Name",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "horsename",
            label: "Horse",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "ridername",
            label: "Rider",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "eventdate",
            label: "Date",
            properties: {
                component: "text-field"
            }
        }
    ];
    const manualTableFields = [
        {
            id: "eventname",
            label: "Event Name",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "classname",
            label: "Class Name",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "ridername",
            label: "Rider",
            properties: {
                component: "text-field"
            }
        },
        {
            id: "horsename",
            label: "Horse",
            properties: {
                component: "text-field"
            }
        }
    ];


    // <Button onClick={() => setOpenConfirmDecline(true)} variant="contained" color="error">Decline</Button>

    const width = () => entry && entry.enteredby ? 3 : 4;
    return (
        <React.Fragment>

            {error &&
                <Alert sx={{ mb: 2 }} severity="error">{error}</Alert>}
            {!entry && !entry && <Loading />}
            {entry &&
                <React.Fragment>
                    <Panel>
                        <Grid container justifyContent="flex" >
                            <Grid item >
                                <Stack direction="column">
                                    <Typography color={"primary"} noWrap variant="h6" component="h3">
                                        {"Application: " + entry.rider.firstname + " " + entry.rider.lastname + " / " + entry.horse.name} {<Label sx={{ ml: 2 }} color={approved ? 'success' : 'error'} variant='ghost'>{approved ? 'Approved' : 'Not Approved'}</Label>}
                                    </Typography>
                                    <Typography variant="body2">
                                        {entry.entry.eventname + " (" + entry.entry.eventdate + ") - " + eventclass + `     -   Status: ${status()}`}
                                        <Label sx={{ ml: 2, mr:2 }} color={inprogress ? 'success' : 'error'} variant='ghost'>{inprogress ? 'In Progress' : 'Not In Progress'}</Label>
                                        {entry && !entry.entry.entrystatus !== 'ENTRYCOMPLETED'  && <ChangeClass
                                            id={entry.entry.id}
                                            rider={entry.rider.firstname + ' ' + entry.rider.lastname}
                                            horse={entry.horse.name}
                                            class={eventclass}
                                            classtypeid={entry.entry.classtypeid}
                                            riderid={entry.rider.id}
                                            horseid={entry.horse.id}
                                            eventid={entry.entry.eventid}
                                            update={newClass} 
                                            />}
                                        {/* <Button size="small" startIcon={<SwapCallsIcon />} sx={{ ml: 1 }} onClick={() => setChangeClass(true)} variant="outlined" color="success">Change Class</Button> */}
                                    </Typography>
                                    <Typography sx={{ mt: 1, mb: 2, fontWeight: 'bold' }} variant="body2">Note: <Typography variant="caption">{applicationNotes}</Typography></Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack sx={{ pl: 5 }} direction="row" justifyContent="flex-end" spacing={2} >
                                    {canapprove() &&
                                        <Button size="small" onClick={() => setOpenConfirmApprove(true)} variant="contained" color="success">Approve</Button>
                                    }
                                    <ChangeApplicationNote id={id} applicationnotes={applicationNotes} update={(n) => setApplicationNotes(n)} />
                                    <Button size="small" onClick={() => changeInProgress()} variant="outlined" color="success">{inprogress ? "Mark Not In Progress" : "Mark In Progress"}</Button>
                                    <Back size="small" />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Panel>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={width()}>
                            <Panel>
                                <Typography variant="h6">Horse</Typography>
                                <Divider sx={{ py: 0, mb: 0.5 }} />
                                {display(horseFields, entry.horse)}
                            </Panel>
                        </Grid>
                        <Grid item xs={12} md={width()}>
                            <Panel>
                                <Typography variant="h6">Rider</Typography>
                                <Divider sx={{ py: 0, mb: 0.5 }} />
                                {display(riderFields, entry.rider)}
                            </Panel>
                        </Grid>
                        {entry.enteredby && <Grid item xs={12} md={width()}>
                            <Panel>
                                <Typography variant="h6">Application</Typography>
                                <Divider sx={{ py: 0, mb: 0.5 }} />
                                {display(enteredbyFields, entry.enteredby)}
                            </Panel>
                        </Grid>}
                        <Grid item xs={12} md={width()}>
                            <Panel>
                                <Typography variant="h6" >ERQI Rating: {entry.equirating.title}</Typography>
                                <Stack direction="row" justifyContent="space-between">
                                    <Box
                                        sx={{
                                            minWidth: 50,
                                            minHeight: 50,
                                            width: 50,
                                            height: 50,
                                            backgroundColor: entry.equirating.bgcolour,
                                            borderRadius: '10px',
                                            mr: 1,
                                        }}
                                    />
                                    <Typography variant="subtitle2" sx={{ opacity: 0.64 }}>
                                        {entry.equirating.restricted ? "Sorry the ERQI Safety Level for this horse is not sufficient for this class" : "The ERQI Safety Level for this horse is sufficient for this class"}
                                    </Typography>

                                </Stack>
                            </Panel>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            <Grid container spacing={3} >
                <Grid item xs={12} >
                    {entry &&
                        <Panel>
                            <EnhancedTable
                                title="Selected Qualifications"
                                columns={[...tableFields, ...scoreFields, {
                                    id: "points",
                                    label: "Points",
                                    properties: {
                                        component: "text-field"
                                    }
                                }]}
                                rows={entry.qualifications.selected}
                                orderBy={"eventdate"}
                                // noHeader={true}
                                rowsPerPage={10}
                                placeholder={""}
                                selectable={false}
                            /></Panel>}
                </Grid>
                <Grid item xs={12} >
                    {entry &&
                        <Panel>
                            <EnhancedTable
                                title="Manual Qualifications"
                                columns={[...tableFields, ...scoreFields]}
                                rows={entry.qualifications.manual}
                                orderBy={"eventdate"}
                                rowsPerPage={10}
                                placeholder={""}
                                selectable={false}
                            /></Panel>}
                </Grid>
                <Grid item xs={12} >
                    {entry && <Panel>
                        <MetaTable
                            type="userresults"
                            canCreate={false}
                            title="All Results Rider"
                            dataUrl={`/admin/user/${entry.rider.id}/results`}
                        />
                    </Panel>}
                </Grid>
                <Grid item xs={12} >
                    {entry && <Panel>
                        <MetaTable
                            type="horseresults"
                            canCreate={false}
                            title="All Results Horse"
                            dataUrl={`/admin/horse/${entry.horse.id}/results`}
                        />
                    </Panel>}
                </Grid>

                <Grid item xs={12} >
                    {entry &&
                        <Panel>
                            <Title>Upcoming Entries</Title>
                            <EntriesTable dataUrl={`/admin/user/${entry.rider.id}/entries?future=true`} />
                        </Panel>}
                </Grid>
            </Grid>
            <ConfirmDialog
                open={openConfirmDecline}
                title="Confirm Decline"
                description="Please confirm that you want to decline this entry application"
                onCancel={() => setOpenConfirmDecline(false)}
                onConfirm={() => confirmDecline()}
            />
            <ConfirmDialog
                open={openConfirmApprove}
                title="Confirm Approval"
                description="Please confirm that you want to approve this entry application"
                onCancel={() => setOpenConfirmApprove(false)}
                onConfirm={() => confirmApprove()}
            />

        </React.Fragment>
    )
}

