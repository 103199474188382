import * as React from 'react';
import Grid from '@mui/material/Grid';
import { MetaTable } from '../components/table/MetaTable';
import { roleIdToName, userStatusIdToName } from '../utils';
import { Paper } from '@mui/material';
import Panel from '../components/containers/Panel';



export default function Users() {
  const filterDef = () => {
    return [
        {
            id: "gender",
            type: "togglegroup",
            options: [                
                {
                    id: "1",
                    name: "Gelding"
                },
                {
                    id: "2",
                    name: "Mare"
                },
                {
                    id: "3",
                    name: "Stallion"
                }
            ]
        },
        {
            id: "archived",
            type: "togglegroup",
            options: [
                {
                    id: "0",
                    name: "Not Archived"
                },
                {
                    id: "1",
                    name: "Archived"
                }
            ]
        },
        {
            id: "isparent",
            type: "togglegroup",
            options: [
                {
                    id: "0",
                    name: "Not Parent"
                },
                {
                    id: "1",
                    name: "Is Parent"
                }
            ]
        },
        {
            id: "type",
            type: "togglegroup",
            options: [
                {
                    id: "1",
                    name: "Horse"
                },
                {
                    id: "2",
                    name: "Pony"
                }
            ]
        },
        {
            id: "registration",
            type: "togglegroup",
            options: [
                {
                    id: "active",
                    name: "Active"
                },
                {
                    id: "elapsed",
                    name: "Elapsed"
                }
            ]
        }
    ];
};

  return (
    <Panel>
      <MetaTable 
        canExport
        type="horse" 
        canCreate={true}
        filters={filterDef()}
        defaultFilter={{ registration: "active"}}
        rememberSearch="horses-table" 
      />
    </Panel>
  )
}