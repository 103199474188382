import * as React from 'react';
import { useParams, useNavigate } from 'react-router';
import { Grid } from "@mui/material";

import axios from "axios";
import Form from '../../components/form/Form';
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import ConfirmationDialog from '../../components/collections/ConfirmationDialog';

import Panel from '../../components/containers/Panel';
import { MetaTable } from '../../components/table/MetaTable';
import Primary from '../../components/buttons/Primary';
import OrderDetails from '../../containers/details/OrderDetails';
import ResponsiveDialog from "../../components/containers/ResponsiveDialog";
import { useSnackbar } from 'notistack';
import Title from '../Title';
import EditIcon from '@mui/icons-material/Edit';
import { ChangeIssueStatusFields } from "../../app/form/Definitions/ChangeIssueStatusFields";
import Back from '../../components/buttons/Back';


export default function ViewReconciliationDetails() {
    const { id } = useParams();
    const [form, setForm] = React.useState({});
    const [reconciliation, setReconciliation] = React.useState();
    const [open, setOpen] = React.useState();
    const [order, setOrder] = React.useState();
    const [worldnet, setWorldnet] = React.useState();
    const { enqueueSnackbar } = useSnackbar();
    const [changing, setChanging] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    async function getData() {
        axios.get('/admin/reconciliations/id/' + id)
            .then(response => {
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                setReconciliation(response.data.record);
                getOrder(response.data.record.orderid);
                setOpen(response.data.record.openstatus == 'open');
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }

    async function getOrder(orderid) {
        axios.get('/admin/order/id/' + orderid)
            .then(response => {
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                setOrder(response.data.record);
                getWorldnet(orderid);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }

    const reload = () => {
        navigate(0);
    }
    async function getWorldnet(eiorderid) {
        axios.get('/admin/worldnet/eid/' + eiorderid)
            .then(response => {
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                setWorldnet(response.data.records);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }

    const getSummary = () => {
        return reconciliation ? `${reconciliation.summary} (${reconciliation.name})` : ``;
    }

    const paid = () => {
       setShowConfirm(true);
    }

    const markpaid = () => {
       // call remove role on the server
       axios.post(`/admin/reconciliations/markpaid/${order.id}`, {
        uniqueref: reconciliation.uniqueref,
        reconciliationid: reconciliation.id
       } )
       .then(response => {
           if (response.data.error) {
               setLoading(false);
               return enqueueSnackbar(response.data.message, { variant: 'error'});
           }
           setLoading(false);
           setShowConfirm(false);
           navigate(0);
       })
       .catch(error => {
           setLoading(false);
           return enqueueSnackbar("Error marking order as paid: " + error, { variant: 'error'})
       });
     }
 
    const changeStatus = () => {
        setError("");
        if(!open ) {
            return updateStatus();
        }

        setChanging(true);
    }

    const updateStatus = () => { 
        setSaving(true);
        setError("");
        axios.put('/admin/reconciliations/', {
            id: reconciliation.id,
            openstatus: open ? 'closed' : 'open',
            description: form['description']
        }).then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return;
                }
                setOpen(!open);
                setReconciliation({
                    ...reconciliation,
                    openstatus: open ? 'closed' : 'open'
                });
                setSaving(false);
                setChanging(false);
                setError("");
                reload();

            })
            .catch(error => {
                setError("Problem updating record data: " + error);
            });
    }

    const updateForm = (updates) => {
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };
    
    /**
     * Foaled 2007 Height 163cm Colour Chestnut Total Points = 2
     * Owned By Harry Rowcliffe Bred By Mrs Pippa Wiegersma Gender Gelding
     * Sire Graf Quidam Dam Mazey Day
     */
    return (
        <React.Fragment>
            <Panel>
                <Title >Reconciliation Issue: {getSummary()}</Title>
                <Form 
                    id={id} objtype={"reconciliations"} 
                    readonly={true} showbuttons={false} 
                    noTitle={true}
                    loading={!reconciliation}
                    record={reconciliation}
                />
                <Grid container spacing={3} sx={{pt: 2}}   justifyContent="flex-end">
                <Grid item>{open && !changing && <Primary title={"Mark As Closed"} onClick={changeStatus}/> }
                {open && changing &&
                    <ResponsiveDialog title="Close Issue" onPrimaryClick={updateStatus} onCloseClick={() => setChanging(false)}
                            primaryButtonIcon={<EditIcon/>} primaryButtonLabel="Update Status" open={changing}
                            description="Please provide a short description as to the outcome of this issue and why it is being closed."
                            loading={saving} error={error}>
                        <FormBuilder fields={ChangeIssueStatusFields} form={form} updateForm={(updates) => updateForm(updates)} />
                    </ResponsiveDialog>
                }
                {!open &&
                    <Primary title={"Reopen Issue"} onClick={changeStatus}/>
                }
                </Grid>
                <Grid item><Back/></Grid>
                </Grid>
            </Panel>
            {reconciliation && <Panel><Title>Worldnet Details</Title>
                <MetaTable 
                    type="worldnet" 
                    canCreate={false}    
                    dataUrl={"/admin/worldnet/eid/"+reconciliation.orderid}         
                />
            </Panel>}    
            {order && 
            <Panel>
                <Form 
                    id={order.id} 
                    objtype={"order"} 
                    readonly={true} 
                    showbuttons={false} 
                />       
                <OrderDetails id={order.id} order={order} withdraw={false}/>
                {order && order.orderstatusid == 4 && 
                    <Primary title="Mark Order As Paid" onClick={paid}/>
                }
                {order && order.orderstatusid == 4 && showConfirm && <ConfirmationDialog title={"Please Confirm"} 
                    description={"Are you sure you want to mark this order as paid" }
                            onConfirm={markpaid} open={showConfirm}
                            onCancel={() => setShowConfirm(false)} loading={loading}
                            loadingMessage={"Please wait while we mark the order as paid"}/>
                }
            </Panel>}
        
        </React.Fragment>
    )
}