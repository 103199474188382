import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { API_ROOT } from '../../app/constants';

import {Grid, Paper, Stack, Button } from '@mui/material';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import { MetaTable } from '../../components/table/MetaTable';
import InitialiseScheduling from '../components/InitialiseScheduling';
import CardLoading from '../components/CardLoading';
import Panel from '../../components/containers/Panel';
import Title from '../../containers/Title';
import Typography from '@mui/material/Typography';
import PublishButton from '../components/PublishButton';
import FileUploadDialog from '../../components/containers/FileUploadDialog';
import DownloadProgram from '../../components/collections/DownloadProgram';

function DashboardContent() {
    const { id } = useParams();
    // const [event, setEvent] = useLocalStorage("event", { status: 1, name: ""});
    const [event, setEvent] = useState();
    const [uploadOpen, setUploadOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    React.useEffect(() => {
        console.debug("Id: " + id);
        // get the details for this event 
        axios.get("/admin/event/id/" + id)
            .then(response => {
            if (response.data.error) {
                return;
            }
            console.debug("Fetched data", response.data);
            setEvent(response.data.record);
        }).catch(error => {
            console.debug(error);
        });

    }, [id]);

    const updateStatus = (status, field) => {
        setEvent({ ...event, [field]: status ? 1 : 0});
    }

    const onInitialised = (e) => {
        setEvent(e);
    }

    const uploadTimes = () => {
        setIsLoading(false);
        setUploadOpen(true);
    } 
    
    const download = (name, urlpath) => {
        enqueueSnackbar("Generating file, please wait", { variant: 'warning'});
        const link = document.createElement("a");
        link.download = name;
        link.href = `${API_ROOT}admin/event/${id}/${urlpath}`;
        link.click();   
    }

    const confirmUploadFiles = (files) => {
        setIsLoading(true);
        const formData = new FormData();
        console.log(files[0].File);
        formData.append('file', files[0]);
        axios({
            method: "post",
            url: `/admin/event/uploadtimes/${id}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(result => {
            if(result.data.error) {
                setIsLoading(false);
                return enqueueSnackbar(result.data.message, { variant: 'error'});
            }
            setIsLoading(false);
            setUploadOpen(false);
            enqueueSnackbar("Times have been uploaded", { variant: 'success'});
        });
    }

    if(!event) {
        return <CardLoading message="Loading event..."/>
    }

    if(!event.migratedid) {
        return (
          <Panel>
            <InitialiseScheduling id={id} onInitialised={onInitialised}/>
          </Panel>
        )
      }
    
    const getTotalEntries = () => {
        return event.Eventclasses.reduce((a, e) => {
            return a + e.numentries
        }, 0);
    };

    return (
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <MetaTable title={"Classes"} type={"eventclasses"} dataUrl={"/admin/eventclass?eventid="+id+"&archived=0"}/>
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            // height: 240,
                        }}
                    >
                        <Title>{`${event.name} (${event.startdate})`}</Title>
                        <Typography color="text.secondary" >
                            {"Number Of Classes: " + event.Eventclasses.length}
                        </Typography>   
                        <Typography color="text.secondary" >
                            {"Total Number Of Entries: " + getTotalEntries()}
                        </Typography>   
                        <Stack>
                            <PublishButton 
                                key="pub-1"
                                eventid={id} 
                                type="Entrants" 
                                column="publishentrants" 
                                value={event.publishentrants}
                                onChange={(value) => updateStatus(value, "publishentrants")}
                            />
                            <PublishButton 
                                key="pub-2"
                                eventid={id} 
                                type="DR Times" 
                                column="publishdrtimes" 
                                value={event.publishdrtimes}
                                onChange={(value) => updateStatus(value, "publishdrtimes")}
                            />
                            <PublishButton
                                key="pub-3" 
                                eventid={id} 
                                type="SJ Times" 
                                column="publishsjtimes" 
                                value={event.publishsjtimes}
                                onChange={(value) => updateStatus(value, "publishsjtimes")}
                            />
                            <PublishButton 
                                key="pub-4" 
                                eventid={id} 
                                type="XC Times" 
                                column="publishxctimes" 
                                value={event.publishxctimes}
                                onChange={(value) => updateStatus(value, "publishxctimes")}
                            />
                            <PublishButton 
                                key="pub-5" 
                                eventid={id} 
                                type="Notes" 
                                column="publishnotes" 
                                value={event.publishnotes}
                                onChange={(value) => updateStatus(value, "publishnotes")}
                            />
                            <PublishButton 
                                key="schedule-1"
                                eventid={id} 
                                type="Auto Schedule Update" 
                                column="disableautoscheduleupdate" 
                                value={event.disableautoscheduleupdate}
                                onChange={(value) => updateStatus(value, "disableautoscheduleupdate")}
                                enable="Disable"
                                disable="Enable"
                        />                            
                        </Stack>                          
                    </Paper>
                    <Paper
                        sx={{
                            mt: 2,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            // height: 240,
                        }}
                    >
                        <Title>Import/ Export</Title>
                        <Button sx={{mt: 2}} variant="contained" onClick={uploadTimes}>Upload Times</Button>
                        <Button sx={{mt: 2}} variant="outlined" onClick={() => download('EventTimes', 'downloadtimes')}>Download Times</Button>
                        <Button sx={{mt: 2}} variant="outlined" onClick={() => download('EventMultiples', 'downloadmultiples')}>Download Multiples</Button>
                        <Button sx={{mt: 2, mb:2}} variant="outlined" onClick={() => download('EventProgramData', 'downloadprogramdata')}>Download Program Data</Button>
                        {/* <Button sx={{mt: 2}} variant="outlined" onClick={() => download('EventProgramTemplate', 'downloadprogram')}>Download Program Template</Button> */}
                        <DownloadProgram eventid={id}/>
                    </Paper>
                    <FileUploadDialog 
                        title="Select Or Drag Drop File"
                        description="Please click below to select the times file you want to upload, or drag and drop it onto the box below."
                        open={uploadOpen}
                        confirmLabel="Upload Times"
                        uploadButtonText="Select Times File"
                        onCancel={() => setUploadOpen(false)}
                        onConfirm={confirmUploadFiles}
                        loading={isLoading}
                        loadingMessage="Please wait while we upload the file"
                    />

                </Grid>               
            </Grid>

    );
}

export default function SchedulerDashboard() {
    return <DashboardContent />;
}