import * as React from 'react';
import Panel from '../../components/containers/Panel';
import useLocalStorage from '../../app/useLocalStorage';
import { useParams, useNavigate } from 'react-router';
import InitialiseScheduling from '../components/InitialiseScheduling';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Loading from '../../components/Loading';
import { Box } from '@mui/system';
import { Button, Tab, Tabs } from '@mui/material';
import DisciplineClasses from '../components/DisciplineClasses';
import { getArenasByDiscipline } from '../../utils';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
let disc: any = [];
export default function ClassScheduling(props: any) {
  const { id } = useParams();
  const [event, setEvent] = useLocalStorage("event", { status: 1, name: ""});
  const [busy, setBusy] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [disciplines, setDisciplines] = React.useState(disc);
  const [value, setValue] = useLocalStorage("class.scheduling.selectedTab", 0);
  const navigate = useNavigate();

  const loadDisciplines = () => {
    setBusy(true);
    axios.get("/admin/event/" + id + "/arenas")
      .then(response => {
        if (response.data.error) {
          enqueueSnackbar("Problem getting arenas details: " + response.data.error, { variant: 'error' })
          setBusy(false);
          return;
        }
        console.debug("Fetched data", response.data);
        setBusy(false);
        let d = getArenasByDiscipline(response.data.records);
        console.debug(d);
        setDisciplines(d);
      }).catch(error => {
        enqueueSnackbar("Problem getting arenas details: " + error, { variant: 'error' })
        setBusy(false);
      });
  };

  const fix = () => {
    setBusy(true);
    axios.get("/admin/event/" + id + "/fixschedule")
      .then(response => {
        if (response.data.error) {
          enqueueSnackbar("Problem: " + response.data.error, { variant: 'error' })
          setBusy(false);
          return;
        }
        console.debug("Fetched data", response.data);
        setBusy(false);
        enqueueSnackbar("Class Order Updated", { variant: 'success' });
        navigate(0);
      }).catch(error => {
        enqueueSnackbar("Problem fixing arena class order: " + error, { variant: 'error' })
        setBusy(false);
      });
  };
  
  const fixNumbers = () => {
    setBusy(true);
    axios.get("/admin/event/" + id + "/correctnumbers")
      .then(response => {
        if (response.data.error) {
          enqueueSnackbar("Problem: " + response.data.error, { variant: 'error' })
          setBusy(false);
          return;
        }
        console.debug("Fetched data", response.data);
        setBusy(false);
        enqueueSnackbar("Competitor Numbers Updated", { variant: 'success' });
        navigate(0);
      }).catch(error => {
        enqueueSnackbar("Problem fixing competitor numbers : " + error, { variant: 'error' })
        setBusy(false);
      });
  };
  

  const moveClass = (fromArenaIndex:number, toArenaIndex:number, fromClassIndex:number, toClassIndex:Number, disciplineid:number) => {
    // update local arena details
    if (fromArenaIndex === toArenaIndex && fromClassIndex === toClassIndex) {
      // nothing to do 
      return;
    }

    // update server
    setBusy(true);
    axios.post("/admin/event/" + id + "/move", {
      fromArenaDay: fromArenaIndex,
      toArenaDay: toArenaIndex,
      fromClassOrderIndex: fromClassIndex,
      toClassOrderIndex: toClassIndex,
      disciplineid: disciplineid
    }).then(response => {
      if (response.data.error) {
        enqueueSnackbar(response.data.message, { variant: 'error' })
        setBusy(false);
        return;
      }
      // console.debug("Class Moved", response.data);
      // enqueueSnackbar("Class Moved", { variant: 'success' });
      // setBusy(false);
      loadDisciplines();

    }).catch(error => {
      console.debug(error);
    });

  };

  React.useEffect(() => {
    console.debug("Id: " + id);
    // get the details for this event 
    loadDisciplines();
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  if(busy) {
    return (
      <Panel>
        <Loading message="Please wait while we load the riders details"/>
      </Panel>
    )
  }

  // if(!event.migratedid) {
  //   return (
  //     <Panel>
  //       <InitialiseScheduling />
  //     </Panel>
  //   )
  // }

  return (
    <Panel>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs aria-label="basic tabs " value={value} onChange={handleChange} >
          {disciplines.map((discipline: any, index: number) => {
            return (
              <Tab label={discipline.name} {...a11yProps(index)} key={"tab-header-" + discipline.id} />
            )
          })}
          <Tab label="Fix" {...a11yProps(0)} key={"tab-header-fix"} />
        </Tabs>
      </Box>
      {disciplines.map((discipline: any, index: number) => {
        return (
          <TabPanel value={value} key={"panel-" + discipline.id} index={index} id={discipline.id} >
            <DisciplineClasses moveClass={moveClass} busy={busy} discipline={discipline} key={discipline.id} />
          </TabPanel>
        )
      })}
      <TabPanel value={value} key={"panel-fix"} index={disciplines.length} id={0} >
      <Button variant="outlined" onClick={() => fix()}>Fix Class Order</Button>
      <Button sx={{ml:1}} variant="outlined" onClick={() => fixNumbers()}>Fix Competitor Numbers</Button>
      </TabPanel>
    </Panel>
  )
}