import React from "react";
import _ from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import { LinkAccountFields } from "../../app/form/Definitions/LinkAccountFields";
import FormDialog from "../form/FormDialog";


export default function LinkAccount(props:any) {
    const navigate = useNavigate();

    const update = (form:any, callback:any) => {
        axios.post("/admin/user/"+props.id+"/link", { 
            userid: _.get(form, 'lastmodifiedbyuserid')
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: response.data.message});
            }
            navigate(0);
            callback({ error: false, message: "Account linked"});
        })
        .catch(error => {
            return callback({ error: true, message: "Error linking account: " + error});
        });

        
    };


    /**
     */
    const isValid = (form:any) => {
      
        let userid = _.get(form, 'lastmodifiedbyuserid');

        if(!userid || userid == "" ) {
            return "Select the account to link with";
        }

        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Link Account" 
            title="Link This Account To"
            description="Start typing the name of the account holder with whom you wish to link this account. When you have selected the acount the click the Link Account button below to apply this update."
            saveLabel="Link Account"
            fields={LinkAccountFields}
            isValid={isValid}
            onConfirm={update}
            icon={() => (<LockIcon/>)}
       />
    )
}