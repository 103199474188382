import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

export default function Back(props:any) {
    const navigate = useNavigate();

    return (
        <Button
        color="primary"
        startIcon={<ArrowBackIcon />}
        variant="outlined"
        onClick={() => { navigate(-1); }}
        {...props}
      >
        {props.title ? props.title : "Back"}
      </Button>
    )
}


