import * as React from 'react';
import { MetaTable } from '../table/MetaTable';


export default function EventResults(props:any) {
    const getClassFilter = () => {
        let classoptions:any = [];

        props.classes.map((c:any) => {
            classoptions.push({ id: c.id, name: c.name});
        });

        return [
            {
                id: "class",
                type: "togglegroup",
                options: classoptions
            }
        ]
    }
    return (
        <MetaTable type="eventresults" 
            canExport
            canCreate={false}
            title={props.name}
            dataUrl={`/admin/event/${props.id}/results`}
            filters={getClassFilter()}
            showResetFilter={true}
        />
    )
}