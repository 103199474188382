import * as React from 'react';
import Panel from '../../components/containers/Panel';
import Grid from '@mui/material/Grid';
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import Title from "../Title";
import Description from "../Description";
import Primary from "../../components/buttons/Primary";
import { Alert } from '@mui/material';
import axios from "axios";
import Loading from "../../components/Loading";
import { useSearchParams } from 'react-router-dom';
import Secondary from '../../components/buttons/Secondary';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Back from "../../components/buttons/Back";
import CancelIcon from '@mui/icons-material/Cancel';
import CardLoading from '../../scheduler/components/CardLoading';

const store = sessionStorage;

export default function CreateEventEntrant() {
    const [step, setStep] = React.useState(0);
    const [eventClass, setEventClass]  = React.useState();
    const [horse, setHorse] = React.useState();
    const [rider, setRider] = React.useState();
    const [equiRating, setEquiRating] = React.useState();
    const [canOverride, setCanOverride] = React.useState();
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState("");
    const [validations, setValidations] = React.useState([]);
    const [searchParams] = useSearchParams();
    const [validating, setValidating] = React.useState(false);

    React.useEffect(() => {
        if(step == 0) {
            initialise();
        }
    }, []);

    const initialise = () => {
        if(searchParams.has('eventid')) {
            // its adding an entry to an event, so need to start from step 1
            setStep(1);
        } else if (searchParams.has('eventclassid')) {
            // eventclass is already selected, so start at step 2
            getClassDetails(searchParams.get('eventclassid'));           
        }   
    }

    const getClassDetails = (ecid) => {
      // fetch the class type
      axios.get("/admin/eventclass/id/"+ecid)
      .then(response => {
          if (response.data.error) {
              return setError("Problem getting event class: " + response.data.error)
          } 

          setEventClass(response.data.record);
          setStep(2);
      })
      .catch(error => {
          setError("Error getting class type: " + error);
      });
    }

    const getEventClassFields = () => {
        return [    
            {
              attribute: "eventclassid",
              label: "Select Class To Enter",
              component: 'autocomplete-remote',
              validationType: "string",
              validations: {
                required: true,
              },
              optionDef: {
                type: "server-search",
                name: "name",
                url: "/admin/eventclass/auto",
                operation: "like",
                min: 2,
                filter: {
                  archived: 0,
                  eventid: store.getItem('current-event-id')
                }
              },
              options: !eventClass ? [] : [{id: eventClass.id, name: eventClass.Classtype.name}],
              optionConfig: {
                key: "id",
                value: "id",
                label: "name",
              },
              col: {
                sm: 12,
              },
            }
          ];
    }

    const getSelectRiderFields = () => {
        return [    
            {
              attribute: "riderid",
              label: "Select Rider To Enter",
              component: 'autocomplete-remote',
              validationType: "string",
              validations: {
                required: true,
              },
              optionDef: {
                type: "server-search",
                name: "fullname",
                url: "/admin/user/auto",
                operation: "like",
                min: 2
              },
              optionConfig: {
                key: "id",
                value: "id",
                label: "name",
              },
              col: {
                sm: 12,
              },
            }
          ];
    }

    const getSelectHorseFields = () => {
        return [                
            {
                attribute: "horseid",
                label: "Select Horse To Enter",
                component: 'autocomplete-remote',
                validationType: "string",
                validations: {
                  required: true,
                },
                optionDef: {
                  type: "server-search",
                  name: "name",
                  url: "/admin/horse/auto",
                  operation: "like",
                  min: 2
                },
                optionConfig: {
                  key: "id",
                  value: "id",
                  label: "name",
                },
                col: {
                  sm: 12,
                },
              }
          ];
    }

    const updateForm = (updates) => {
        setError("");
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    }

    const selectEventClass = () => {
        if(form['eventclassid'] == null) {
            return setError("Please select the event class to add this entry to");
        }

        getClassDetails(form['eventclassid']);
    }


    const getRider = (userid) => {
      // fetch the class type
      axios.get("/admin/user/id/"+userid)
      .then(response => {
          if (response.data.error) {
              return setError("Problem getting event rider: " + response.data.error)
          } 

          setRider(response.data.record);
          setStep(3);
      })
      .catch(error => {
          setError("Error getting class type: " + error);
      });
    }

    const selectRider = () => {
        if(form['riderid'] == null) {
            return setError("Please select the rider to enter");
        }
        getRider(form['riderid']);        
    }

    const getHorse = (horseid) => {
      // fetch the class type
      axios.get("/admin/horse/id/"+horseid)
      .then(response => {
          if (response.data.error) {
              return setError("Problem getting horse: " + response.data.error)
          } 

          setHorse(response.data.record);
          validate(response.data.record);
      })
      .catch(error => {
          setError("Error getting class type: " + error);
      });
    }

    const validate = (selectedHorse) => {
      setError("");
      setValidating(true);
      axios.post("/admin/evententrant/validate", {
        horseid: selectedHorse.id,
        riderid: rider.id,
        eventclassid: eventClass.id
      })
      .then(response => {
          if (response.data.error) {
              return setError("Problem validating  entry: " + response.data.message)
          } 
          setValidating(false);
          setValidations(response.data.record.errors);
          setEquiRating(response.data.record.equirating);
          setCanOverride(response.data.record.canoverride);
          setStep(4);
      })
      .catch(error => {
          setError("Error getting class type: " + error);
          setValidating(false);
      });
    }

    const selectHorse = () => {
        if(form['horseid'] == null) {
            return setError("Please select the horse to enter");
        }

        getHorse(form['horseid']);

    }   

    const confirm = () => {
      axios.post("/admin/evententrant/add", {
        horseid: horse.id,
        riderid: rider.id,
        eventclassid: eventClass.id
      })
      .then(response => {
          if (response.data.error) {
              return setError("Problem adding new entry: " + response.data.error)
          } 
          setStep(5);
      })
      .catch(error => {
          setError("Error getting class type: " + error);
      });
    }


    const canAdd = () => {      
      return !equiRating.rating || equiRating.rating.ok == 1 || canOverride == 1;
    }

    const getEquiRatingSeverity = () => {
      if(equiRating.restricted) {
        return 'error';
      }
      if (equiRating.title.startsWith('A')) {
        return 'success';
      }

      return 'warning';
    }

    return (
        <Panel>
            {step == 0 && <React.Fragment><Title>Loading</Title><Loading/></React.Fragment>}
            {step == 1 && <React.Fragment>
                <Title>Select Event Class To Enter</Title>
                <Description>Please select the event class to add this entry to. Note: if you start typing the name then the list will autocomplete.</Description>
                <FormBuilder fields={getEventClassFields()} form={form} updateForm={(updates) => updateForm(updates)} />
                {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
                {<Primary sx={{ mt: 2 }} startIcon={<ArrowForwardIcon/>} title={"Next"} onClick={() => { selectEventClass() }}/>}
            </React.Fragment>}
            {step == 2 && <React.Fragment>
                <Title>Select Rider for {eventClass.Classtype.name} class</Title>
                <Description>Please select the rider to enter in this class. Note: if you start typing the riders name then the list will autocomplete.</Description>
                <FormBuilder fields={getSelectRiderFields()} form={form} updateForm={(updates) => updateForm(updates)} />
                {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
                <Secondary sx={{ mt: 2, mr:2 }} startIcon={<ArrowBackIcon/>}  title="Back" onClick={() => { delete form['eventclassid']; setStep(1)}}/><Primary sx={{ mt: 2 }} startIcon={<ArrowForwardIcon/>} title={"Next"} onClick={() => { selectRider() }}/>
            </React.Fragment>}
            {step == 3 && <React.Fragment>
              {!validating && <><Title>Select Horse for {rider.firstname + ' ' + rider.lastname} in {eventClass.Classtype.name} class</Title>
                <Description>Please select the horse to enter in this class. Note: if you start typing the horses name then the list will autocomplete.</Description>
                <FormBuilder fields={getSelectHorseFields()} form={form} updateForm={(updates) => updateForm(updates)} />
                {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
                <Secondary sx={{ mt: 2, mr:2 }} title="Back" onClick={() => { delete form['riderid']; setStep(2) }}/><Primary sx={{ mt: 2 }} startIcon={<ArrowForwardIcon/>} title={"Next"} onClick={() => { selectHorse() }}/></>}
                {validating && <CardLoading message="Validting entry details, please wait..." />}
                </React.Fragment>}
            {step == 4 && <React.Fragment>
              <Title>Please Confirm</Title>
              <Description>{"Please confirm that you want to add an entry for " + rider.firstname + ' ' + rider.lastname + " riding " + horse.name 
                            + " in the " + eventClass.Classtype.name + " class."}</Description>
              {validations.map((v, i) => {
                return (
                  <Alert severity="warning" key={i} sx={{ mt:1}}>{v}</Alert>
                )
              })}
              {equiRating && <Alert severity={getEquiRatingSeverity()} sx={{ mt:1}}>{"Equi Rating: " + equiRating.title + " - Description: " + equiRating.description }</Alert>}
              {validations.length == 0 && <Alert severity="success" sx={{ mt:1}}>Entry details validated correctly</Alert>} 
              {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item col={10}>
                  <Secondary sx={{ mt: 2, mr:2 }} startIcon={<ArrowBackIcon/>} title="Back" onClick={() => {delete form['horseid']; setStep(3)}}/>
                  {canAdd() && <Primary sx={{ mt: 2, mr: 2 }} startIcon={<ThumbUpAltIcon/>} title={"Confirm"} onClick={() => { confirm() }}/>}
                </Grid>
                <Grid item col={2}>
                  <Back  style={{ align: "right" }} startIcon={<CancelIcon/>}  sx={{ mt: 2, mr:2 }} title="Cancel"/>
                </Grid>
              </Grid>
                            </React.Fragment>}
            {step == 5 && <React.Fragment><Title>Entry Added</Title>
              <Description>{"The entry for " + rider.firstname + ' ' + rider.lastname + " riding " + horse.name + " in the " + eventClass.Classtype.name + " class has been added"}</Description>
              <Back />
              </React.Fragment>}

        </Panel>
    );
   

}