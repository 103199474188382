
import axios from 'axios';
import { API_ROOT } from '../app/constants';

/**
 * Authenticate user against server.
 */

const JwtAuthProvider = {
    
    isAuthenticated: false,
    signin(user: any, callback: Function) {
        axios.post('/auth/login', user)
        .then(response => {            
            callback(response.data)
        })
        .catch(error => {
            console.error('There was an error!', error);
            callback({ auth: false, error: error});
        });
      // setTimeout(callback, 100); // fake async
    },
    signout(userid: number, callback: Function) {
      axios.post('auth/logout', {userid: userid })
      .then(response => {            
          callback(response.data)
      })
      .catch(error => {
          console.error('There was an error!', error);
          callback({ auth: false, error: error});
      });

    }
  };
  
  export { JwtAuthProvider };