import * as React from 'react';
import { Button, Grid } from '@mui/material';

export default function RightButton(props: any) {
  return (
    <Grid container spacing={3} sx={{ pt: 2 }} justifyContent="flex-end">
      <Grid item><Button
        color="primary"
        variant="contained"
        {...props}
      >
        {props.title}
      </Button>
      </Grid>
    </Grid>
  )
}




