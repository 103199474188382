import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import TabPanel from '../../components/containers/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import EventDescription from "../../components/eventing/EventDescription";
import EventResults from "../../components/eventing/EventResults";
import EventClassResults from "../../components/eventing/EventClassResults";
import useLocalStorage from '../../app/useLocalStorage';
import { MetaTable } from '../../components/table/MetaTable';
import EntriesTable from '../../components/eventing/EntriesTable';
import { EventEntryFilters }  from '../../app/form/Definitions/EventEntryFilters';
import { ApplicationsFilters }  from '../../app/form/Definitions/ApplicationsFilters';
import { ArchivedFilters }  from '../../app/form/Definitions/ArchivedFilters';

const store = sessionStorage;



export default function ViewEventClassDetails() {
    const { id } = useParams();
    const [eventclass, setEventclass] = React.useState(null);
    const [value, setValue] = useLocalStorage('view-event-class-tab', 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    const dataUsed = (eventclass) => {
        console.debug(eventclass);
        setEventclass(eventclass);
    }   

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    };      

    return (
        <React.Fragment>
            <Panel>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="event details">
                        <Tab label="Class Details" {...a11yProps(0)} />
                        <Tab label="Class Entries" {...a11yProps(4)} />
                        <Tab label="Class Results" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                        <Form id={id} objtype={"eventclass"} readonly={true} showbuttons={true} onData={dataUsed} noTitle={true}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {eventclass && <EntriesTable dataUrl={`/admin/eventclass/${id}/entries`} 
                                        type="evententrant" 
                                        idvalue={id}
                                        idname="eventclassid"
                                        classname={eventclass.Classtype.name}
                                    />}
                </TabPanel>
                <TabPanel value={value} index={2} lazy={false}>
                    {eventclass && <EventClassResults id={id} />}
                </TabPanel>
            </Panel>              
            
        </React.Fragment>
    )
}