import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Secondary from '../buttons/Secondary';
import Primary from '../buttons/Primary';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loading from '../Loading';
import { Alert } from '@mui/material';

interface ResponsiveDialogProps {
    title: string;
    onPrimaryClick: Function;
    onCloseClick?: Function;
    primaryButtonIcon: any;
    primaryButtonLabel: string;
    open: boolean;
    children: any;
    description?: string;
    loading: boolean;
    error: string;
    loadingMessage?: string;
}
export default function ResponsiveDialog(props: ResponsiveDialogProps) {
    // const [open, setOpen] = React.useState(props.open);
    // const [error, setError] = React.useState(props.error);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    
    const handleClose = () => {
        // setOpen(false);
        if(props.onCloseClick) {
            props.onCloseClick();
        }

    };

    const handlePrimary = () => {
        // setLoading(true);
        props.onPrimaryClick();
    }

    // console.debug(error);
    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"            
        >
            <DialogTitle id="responsive-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={'span'} >
                    {!props.loading && props.description && props.description}
                    {props.loading && <Loading message={props.loadingMessage ? props.loadingMessage : "Please wait while we perform the requested operation"}/>}
                    {!props.loading && props.children}
                    {props.error && <Alert sx={{mt:1}} severity="error">{props.error}</Alert>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                
                <Secondary disabled={props.loading} startIcon={<ArrowBackIcon />} title={"Cancel"} onClick={handleClose} autoFocus />
                <Primary disabled={props.loading}  title={props.primaryButtonLabel} startIcon={props.primaryButtonIcon} onClick={handlePrimary} autoFocus />
            </DialogActions>
        </Dialog>
    );
}