import axios from 'axios';
import { hasOptions } from "../utils";
import { all } from './all';

const store = sessionStorage;
const LOADED_TYPES_KEY = "meta-store-loaded-types-key";
const LOADED_FIELDS_KEY = "meta-store-loaded-fields-key";

const MetaStore = {


    get(type: string, callback: Function) {
        let objecttype = "object/"+type;

        if(!this.inStore(objecttype)) {
            return this.loadDefinition(objecttype, callback);
        }

       callback(this.getDefinition(objecttype));
    },

    getField(fieldname: any) {
        if(all[fieldname]) {
            return all[fieldname];
        }
        let fields:any = store.getItem(LOADED_FIELDS_KEY);
        fields = JSON.parse(fields);    
        return fields[fieldname];
    },
    // getField(fieldname: string) {
    //     let fields:any = store.getItem(LOADED_FIELDS_KEY);
    //     fields = JSON.parse(fields);   
    //     console.log(all);
    //     return all[fieldname];
    // },

    inStore(type: string) {
        return store.getItem(this.getKey(type)) != null;
    },

    getKey(type:string) {
        return "meta-" + type;
    },

    getDefinition(type:string){
        try {
            const item = store.getItem(this.getKey(type));
            return item ? JSON.parse(item) : {};
          } catch (error) {            
            console.error(error);
            return {};
          }
    },

    setDefinition(type: string, definition: any){
        try {
            store.setItem(this.getKey(type), JSON.stringify(definition));
          } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
          }
    },

    updateLoadedTypes(type: string) {
        try {           
            const loadedTypesString = store.getItem(LOADED_TYPES_KEY);
            let loadedTypes = loadedTypesString ? JSON.parse(loadedTypesString) : [];
            loadedTypes.push(type);
            store.setItem(LOADED_TYPES_KEY, JSON.stringify(loadedTypes));
          } catch (error) {
            console.error(error);
          }
    },

    getServerSideFieldParams(definition: any) {
        let fields = [];
        for (let index = 0; index < definition.fields.length; index++) {
            if (hasOptions(definition.fields[index].properties.component)) {
                if (definition.fields[index].properties.optionDef.type == 'server-object') {
                    fields.push(definition.fields[index].properties.optionDef);
                }
            }
        }
        return { fields: fields };
    },

    refreshOptions(type: string, callback: Function) {
        console.debug("Calling refresh on " + type);
        this.get(type, (definition:any) => {
            this.refreshServerFields(type, definition, (updateddefinition:any) => {
                callback(updateddefinition);
            });
        });
    },

    refreshServerFields(type:string, definition:any, callback:Function) {
        // if there are any server side fields then get their latest values
        let params = this.getServerSideFieldParams(definition);

        if(params.fields.length == 0) {
            this.setDefinition(type, definition);
            this.updateLoadedTypes(type);
            return callback(this.getDefinition(type));
        }
        axios.get("meta/options/several", {
            params
        }).then(response => {
            let options = response.data;
            for (let index = 0; index < definition.fields.length; index++) {
                if (hasOptions(definition.fields[index].properties.component)) {
                    if (definition.fields[index].properties.optionDef.type == 'server-object') {
                        definition.fields[index].properties.options = options[definition.fields[index].properties.optionDef.name];
                        this.addToFields(definition.fields[index].id, options[definition.fields[index].properties.optionDef.name]);
                    }
                }
            }
            this.setDefinition(type, definition);
            this.updateLoadedTypes(type);
            callback(this.getDefinition(type));

        });    

    },

    loadDefinition(type:string, callback:Function){
        console.debug("METASTORE:: loadDefinition("+type+")");
        let _this = this;
        axios.get('meta/'+type).then(
            response => {
                if(response.data.error) {
                    console.error(response.data.error);
                    return;
                }
                this.loadPossibleFields(response.data, function(definition:any) {
                    _this.refreshServerFields(type, definition, callback);
                });
            }
        ).catch(error => {
            console.error(error);
        });
    },

    addToFields(fieldname:string, options:any) {
        let fields:any = JSON.parse(store.getItem(LOADED_FIELDS_KEY) || "{}");
        fields[fieldname] = options;
        store.setItem(LOADED_FIELDS_KEY, JSON.stringify(fields));
    },

    async loadFields(){

        try {
            let fields = await axios.get('meta/field/all');
            store.setItem(LOADED_FIELDS_KEY, JSON.stringify(fields.data));

        } catch (error) {
            console.error(error);
        }

    },

    initialiseStore(callback:any, result:any) {
        let _this = this;
        this.clearStore();
        setTimeout(function() { 
            _this.loadFields();
            callback(result);
         }, 1000);
    },

    clearStore() {
        const loadedTypesString = store.getItem(LOADED_TYPES_KEY);
        let loadedTypes = loadedTypesString ? JSON.parse(loadedTypesString) : [];
        loadedTypes.forEach((type: string) => {
            store.removeItem(this.getKey(type));
        });
        store.removeItem(LOADED_TYPES_KEY);
        store.removeItem(LOADED_FIELDS_KEY);
    },

    loadPossibleFields(definition:any, callback:Function) {
        
        // step 1: load local fields and remove any server object options 
        for (let index = 0; index < definition.fields.length; index++) {
            if (hasOptions(definition.fields[index].properties.component)) {
                switch(definition.fields[index].properties.optionDef.type) {
                    case 'server-field':
                        definition.fields[index].properties.options = this.getField(definition.fields[index].properties.optionDef.name);
                        break;
                    case 'server-object':
                        definition.fields[index].properties.options = null;
                        // if (definition.fields[index].properties.optionDef.cancache) {
                        //     definition.fields[index].properties.options = this.getField(definition.fields[index].properties.optionDef.name);
                        // } else {
                        //     definition.fields[index].properties.options = this.getServerField(definition.fields[index].properties.optionDef);
                            
                        // }
                }
                
                // console.debug("Setting "+ definition.fields[index].label + " options to ", definition.fields[index].properties.options)
            }
        }
        return callback(definition);
    },

    getServerField(optionDef:any) {
        const params = { 
            idcolumn: optionDef.idcolumn, 
            namecolumn: optionDef.namecolumn,
            filter: optionDef.filter 
        };

        // if(optionDef.filter) {
        //     params['filter'] = optionDef['filter']
        // }
        // console.debug("Sending parames: ", params);
        // let options = await axios.get('meta/options/'+optionDef.name, {
        //     params
        // }).then(
        //     response => {
        //         if(response.data.error) {
        //             console.error(response.data.error);
        //             return [];
        //         }
        //         return response.data;
        //     }
        // ).catch(error => {
        //     console.error(error);
        //     return [];
        // });
        // console.debug("Returning params:", options);
        // return options;
        console.debug("Sending parames: ", params);
        // let options = await axios.get('meta/options/'+optionDef.name, {
        //     params
        // });
        let options = request('meta/options/'+optionDef.name, params);
        console.debug("Returning params:", options);
        return options;
    }

};

const request = async (url:string, params:any) => {
    let options:any = await axios.get(url, {
            params
        }).then(response => {
            return response.data;
        });    
}

export { MetaStore };