import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

export default function Create(props:any) {
    return (
        <Button
        color="primary"
        startIcon={<AddIcon />}
        variant="outlined"
        {...props}
      >
        {props.label ? props.label : "Create"} {props.object && props.object}
      </Button>
    )
}