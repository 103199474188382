import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';

const reloads = ['horse']
export default function EditDetails() {
    const { id, type } = useParams();

    return (
        <Panel>
            <Form id={id} objtype={type} reloadOnUpdate={reloads.includes(type)}/>
        </Panel>
    )
}