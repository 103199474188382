import React from "react";
import _ from "lodash";
import axios from "axios";
import FormDialog from "../form/FormDialog";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

export default function ChangeRider(props:any) {

    const changeRiderFields = [
        {
            attribute: "riderid",
            label: "New Rider",
            component: 'autocomplete-remote',
            validationType: "string",
            validations: {
              required: true,
            },
            optionDef: {
              type: "server-search",
              name: "fullname",
              url: "/admin/user/auto",
              operation: "like",
              min: 2
              // filter: {
              //   eventclassid: props.eventclassid
              // }
            },
            optionConfig: {
              key: "id",
              value: "id",
              label: "name",
            },
            col: {
              sm: 12,
            },
          }
    ];

    const change = (form:any, callback:any) => {
        axios.post("/admin/evententrant/"+props.id+"/changerider", { 
            riderid: form['riderid']
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: "Problem changing rider: " + response.data.message});
            }
            props.update(response.data.record.name);
            callback({ error: false, message: "Rider changed " });
        })
        .catch(error => {
            return callback({ error: true, message: "Error changing rider: " + error});
        });

        
    };


    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        if (!form['riderid'] || form['riderid'] == "") {
          return "Please select the new rider";
        }
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Change Rider" 
            title="Select New Rider"
            description={"Please select a new rider to replace " + props.rider + " on " + props.horse + " in " + props.class }
            saveLabel="Apply Change"
            fields={changeRiderFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<SwapHorizIcon/>)}
            isSecondaryButton={true}
       />
    )
}