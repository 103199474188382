import * as React from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

import { useSnackbar } from 'notistack';
import Description from '../../containers/Description';
import { Button, Divider, TextField, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Save from '../../components/buttons/Save';
import FenceSetup from './FenceSetup';
import { isInt, pad } from '../../utils';
import EventclassMultieselect from './EventclassMultiselect';
import LoadingButton from '@mui/lab/LoadingButton';

export default function CrossCountrySetup(props:any) {
    const [saving, setSaving] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    const [allowedTime, setAllowedTime] = React.useState(props.eventclass.xcexptime);
    const { enqueueSnackbar } = useSnackbar();
    const [numxcjumps, setNumxcjumps] = React.useState(props.eventclass.numxcjumps);
    const [xcjumps, setXcjumps] = React.useState(props.eventclass.xcjumps);
    const [selectedClasses, setSelectedClasses] = React.useState<{id: string; name: string}[]>();
    const [xcCourseDesigner, setXcCourseDesigner] = React.useState(props.eventclass.xccoursedesigner);

    const save = (fences:string, numfences:number, xccd:string, id=props.eventclass.id) => {
        setSaving(true);
        axios.put("/admin/eventclass?setup=xc", {
            id: id,
            xcexptime: allowedTime,
            numxcjumps: numfences,
            xcjumps: fences,
            xccoursedesigner: xccd
        }).then(response => {
            if (response.data.error) {
                setSaving(false);
                enqueueSnackbar(response.data.message, { variant: 'error'})
                return;
            }
            enqueueSnackbar("Cross Country settings updated", { variant: 'success'})
            setSaving(false);
            setChanged(false);
        
        }).catch(error => {
            enqueueSnackbar(error, { variant: 'error'})
            console.debug(error);
        });
    }

    const allowedTimeGet = (minutes=true) => {
        if(allowedTime == "") {
            return "";
        }
        const parts = allowedTime.split(":");
        if(parts.length != 2) {
            return "";
        }
        return parts[minutes ? 0 : 1];
    }

    const isChanged = (n:any) => {
        if(props.eventclass.xcexptime != n.xcexptime) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }

    const changeAllowedTime = (value:string, minutes=true) => {
        if(value != "" && !isInt(value)) {
            return;
        }
        const num = parseInt(value);

        if( num < 0 || num > 59) {
            return;
        }
        let newvalue = "";
        if(minutes) {
            const seconds = pad(allowedTimeGet(false), 2);
            newvalue = `${value}:${seconds}`;
        } else {
            const minutes = allowedTimeGet(true);
            newvalue = `${minutes}:${value}`;
        }
        
        setAllowedTime(newvalue);
        isChanged({
            xcexptime: newvalue
        });
    }


    const changeFences = (updatedFences:string) => {
        const fencelist = updatedFences.split(",");
        setNumxcjumps(fencelist.length);
        setXcjumps(updatedFences);   
        save(updatedFences, fencelist.length, xcCourseDesigner);
    }

    const isSetup = () => {
        return allowedTime !="";
    }

    const applyToOtherClasses = () => {
        // apply the current settings in this class to the list of current selected classes.
        if(!selectedClasses ) {
            return;
        }

        selectedClasses.map((c:{ id: string; name: string }) => {
            save(xcjumps, numxcjumps, xcCourseDesigner, c.id);
        })

    }

    const changeXcCourseDesigner = (value:string) => {
        setXcCourseDesigner(value);
        setChanged(true);
    }

    const selectedClassesChanged = (values:{ id: string; name: string }[]) => {
        console.log(values);
        setSelectedClasses(values);
    }

    return (
        <>
            <Typography>
                Please provide details of the  cross country allowed time for this class.
            </Typography>
            <Grid container sx={{pb:2}} {...props}>
                <Grid item md={6}>
                    <Grid container sx={{pb:2}} {...props}>
                        <Grid item >
                            <InputLabel sx={{mt:3}} id="numjudges">Allowed Time in Minutes and Seconds Cross Country</InputLabel>
                            <TextField
                                size="small"
                                // type="number"
                                label="Minutes (mm)"
                                id="outlined-start-adornment"
                                value={allowedTimeGet()}
                                onChange={(event) => changeAllowedTime(event.target.value, true)}
                                sx={{ m: 1, width: '15ch' }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">m</InputAdornment>,
                                }}
                                />
                            <TextField
                                size="small"
                                // type="number"
                                label="Seconds (ss)"
                                id="outlined-start-adornment"
                                sx={{ m: 1, width: '15ch' }}
                                value={allowedTimeGet(false)}
                                onChange={(event) => changeAllowedTime(event.target.value, false)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">ss</InputAdornment>,
                                }}
                                />
                        </Grid>    
                        <Grid item lg={12}>
                            <FormControl fullWidth  sx={{mt:2, pr:5}}>
                                <TextField id="xccoursedesigner" onChange={(event) => changeXcCourseDesigner(event.target.value) } value={xcCourseDesigner} label="XC Course Designer" variant="standard" />
                            </FormControl> 
                        </Grid> 
                        <Grid item >
                            <Save
                                sx={{mt:7}}
                                disabled={!changed}
                                loading={saving}
                                onClick={() => save(xcjumps, numxcjumps, xcCourseDesigner)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Typography>
                        Click the button below to apply these Cross Country settings to other classes.
                    </Typography>
                    <EventclassMultieselect names={props.eventclass.names} onChange={(values:any) => selectedClassesChanged(values)} />
                    {selectedClasses && selectedClasses.length > 0 && <LoadingButton loading={saving} disabled={!isSetup()} variant="contained" onClick={applyToOtherClasses}>
                        Apply These Settings To Other Classes
                    </LoadingButton>}
                </Grid>

            </Grid>
            <Divider sx={{mt:5, mb:2}}/>                        
            <Typography>
                Please provide details of Cross Country fences for this class.
            </Typography>
            <Grid container sx={{pb:2}} {...props}>
                <Grid item xs={12}>
                        {props.description && <Description>{props.description}</Description>}
                        {props.children}
                        <FenceSetup 
                            fences={xcjumps} 
                            onChangeFences={changeFences}
                        />
                        
                </Grid>
            </Grid>
            
        </>
    )
}