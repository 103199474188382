
import * as React from 'react';
import { Typography, Box } from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    lazy?: boolean;
  }
  
  export default function TabPanel(props: TabPanelProps) {
    const { children, value, index, lazy, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {lazy && value === index && ( 
          <Box sx={{ p: 0, pt:2 }}>
            <>{children}</>
          </Box>
        )}
        {!lazy && ( 
          <Box sx={{ p: 0, pt:2 }}>
            <>{children}</>
          </Box>
        )}

      </div>
    );
  }