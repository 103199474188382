import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import { Alert } from '@mui/material';
import EntryActions from '../../components/eventing/EntryActions';
import axios from "axios";
import Back from '../../components/buttons/Back';

/*
actions
    - Change Rider
    - Change horse
    - Change class
    - Withdraw/ change status 
    - Select team
*/
export default function EditEventEntrant() {
    const { id } = useParams();
    const [error, setError] = React.useState("");
    const [entry, setEntry] = React.useState();


    React.useEffect(() => {         
        getEntry();
    },[]);

    const getEntry = () => {
        axios.get("/admin/evententrant/id/"+id+"/details")
        .then(response => {           
            setEntry(response.data.record);

        })
        .catch(error => {
            setError("Problem getting event entry details: " + error);
        });
    }
     
    return (
        <Panel>
            {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
            {entry && <EntryActions entry={entry}/>}
            <Back/>
        </Panel>
    )
}