import PropTypes from 'prop-types';

// @mui
import {  
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

// ----------------------------------------------------------------------
ConfirmDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
};

export default function ConfirmDialog({ open, title, description, onCancel, onConfirm }) {
  return (
      <Dialog open={open} >
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent sx={{ typography: 'body2' }}> 
          <Typography variant="caption" >{description}</Typography>
        </DialogContent>  

        <DialogActions>
          <Button variant="contained"  onClick={onConfirm}>
            Confirm
          </Button>

          <Button variant="outlined" color="inherit" onClick={onCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );
}
