import * as React from 'react';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { visuallyHidden } from '@mui/utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, ListItemIcon, ListItemText} from '@mui/material';
import Title from '../../containers/Title';
import Create from '../buttons/Create';
import Export from '../buttons/Export';
import { getValue, RootStyle, SearchStyle, getSortValue } from '../../utils';
import { icon } from '../ActionMenu';
import Loading from '../Loading';
import useLocalStorage from '../../app/useLocalStorage';
import { ExportToCsv } from 'export-to-csv'; 

interface MenuAction {
  icon: string,
  name: string,
  handler: Function;
}

interface CreateMenuProps {
  action: MenuAction;
  label: string;
}

export function CreateMenu(props: CreateMenuProps) {

  const onClickHandler = (e: any) => {
    props.action.handler();
  }

  return (
    <React.Fragment>
      <Create onClick={onClickHandler} label={props.label} />
    </React.Fragment>
  )

}

interface ExportMenuProps {
  action?: Function;
  label?: string;
}

export function ExportMenu(props: ExportMenuProps) {

  const onClickHandler = (e: any) => {
    if(props.action) {
      props.action();
    }

  }

  return (
    <React.Fragment>
      <Export onClick={onClickHandler} label={props.label} />
    </React.Fragment>
  )

}

interface MoreMenuProps {
  menu: MenuAction[];
  row: any;
}

export function MoreMenu(props: MoreMenuProps) {
  const ref = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const onClickHandler = (e: any) => {
    let menuName = e.target.outerText;
    for (const m of props.menu) {
      if (m.name == menuName) {
        m.handler(props.row);
        setIsOpen(false);
      }
    }
    // if (e.target)
  }

  if(props.menu.length <= 2) {
      const m = props.menu[0];
      const sx = props.menu.length == 1 ? { color: 'text.secondary', ml:1 } : { color: 'text.secondary', ml:1, mb:1 }
      return (
        <>
        {props.menu.map((m, mindex) => {
          return (<Button key={mindex} size="small" color="inherit" variant="outlined" onClick={() => m.handler(props.row)} startIcon={icon(m.icon)} 
          sx={sx} >
            {m.name}
          </Button>)
          })}
          </>
      )
  }

  return (
    <React.Fragment>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} size="large">
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        onClick={onClickHandler}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {props.menu.map((m, mindex) =>
          <MenuItem sx={{ color: 'text.secondary' }} key={m.name + "-" + props.row.id}>
            <ListItemIcon sx={{ margin: 0, padding: 0 }} >
              {icon(m.icon)}
            </ListItemIcon>
            <ListItemText primary={m.name} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  );
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // console.log(orderBy);

  let avalue = getSortValue(b, orderBy);
  let bvalue = getSortValue(a, orderBy);

  if (bvalue < avalue) {
    return -1;
  }
  if (bvalue > avalue) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function applySortFilter<T>(array: readonly T[], comparator: (a: T, b: T) => number, query: string, columns: any) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  let results = stabilizedThis.map((el: any) => el[0]);
  if (query) {
    // return filter(array, (row:any) => row.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    return filter(results, (row: any) => filterRow(row, query, columns));
    // return filter(stabilizedThis, (row: any) => filterRow(row[0], query, columns));
  }
  // return stabilizedThis.map((el: any) => el[0]);
  return results;
}

function filterRow<T>(row: any, query: string, columns: any) {
  for (const c of columns) {
    if (c.isSearchable ) {
      let value = getValue(row, c.id);
      if (typeof value == 'string') {
        if (value.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
          return true;
        }
      } else {
        if (value == query) {
          return true;
        }  
      }
    }
  }
  return false;
}
interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
  isSearchable: boolean;
}

interface EnhancedTableHeadProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  columns: any;
  selectable: boolean;
  hasMenu: boolean;
  multiselect?:boolean;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { selectable, onSelectAllClick, multiselect, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };


  return (
    <TableHead>
      <TableRow>
        {selectable && <TableCell padding="checkbox">
          {multiselect && <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          /> }
        </TableCell>}
        {props.columns.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {props.hasMenu &&
          <TableCell align="right">
          </TableCell>
        }

      </TableRow>
    </TableHead>
  );
}

// const RootStyle = styled(Toolbar)(({ theme }) => ({
//   height: 46,
//   display: 'flex',
//   justifyContent: 'space-between',
//   padding: theme.spacing(0, 0, 0, 2)
// }));


interface EnhancedTableToolbarProps {
  numSelected: number;
  title: string;
  filterName: string,
  onFilterName: any,
  selectable: boolean,
  canExport?: boolean,
  createAction: MenuAction
  exportAction?: Function
  createLabel: string;
  exportLabel?: string;
  placeholder?: string;
  filterActions: Array<any>;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { createLabel, canExport, exportAction, createAction, exportLabel, numSelected, title, filterName, onFilterName, selectable, filterActions} = props;
  
  const addFilterActions = (actions:any) => {    
    return actions.map((f:any, index:number) => {
      return (
        <Button 
          key={`action-${index}`}
          sx={{ml:2}} 
          component={Link} 
          to={f.to} 
          variant={f.variant ? f.variant : "contained"} color="primary"
        >
              {f.label}
        </Button>
      )
    })
  }

  return (
    <RootStyle
    disableGutters={true}
    sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {title && title != "" && <Title>{title}</Title>}
      <div>
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1" align={"right"}>
            {numSelected} selected
        </Typography>
        ) : (
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder={props.placeholder ? props.placeholder : "Search ..."}
              startAdornment={
                <InputAdornment position="start">
                  <Box component={ManageSearchIcon} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
            />
          )}
        {/* {selectable && (numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        ))} */}
        {createAction && <CreateMenu action={createAction} label={createLabel} />}
        {canExport && <ExportMenu action={exportAction} label={exportLabel} />}
        {filterActions && addFilterActions(filterActions)}
      </div>

    </RootStyle>
  );
};

interface ColumnDef {
  name: string,
  id: string,
  format?: any
}

interface EnhancedTableProps {
  rows: Array<any>;
  columns: Array<ColumnDef>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  page: number;
  selectable: boolean;
  canExport?: boolean;
  title: string;
  menu: MenuAction[];
  createAction: MenuAction;
  createLabel: string;
  onSelect?: Function;
  onDeselect?: Function;
  multiselect: boolean;
  dataUrl?: string;
  placeholder?: string;
  noHeader?: boolean;
  loading?: boolean;
  rememberSearch?: string;
  rowsPerPage?: number;
  filterActions: Array<any>;
}

export default function EnhancedTable(props: any) {
  let page = props.page ? props.page : 1;
  let order = props.order ? props.order : 'asc';
  let orderBy = props.orderBy ? props.orderBy : props.columns[0].id;
  let selectable = props.selectable ? props.selectable : false;
  let title = props.title ? props.title : '';
  let menu = props.menu ? props.menu : [];
  let createAction = props.createAction ? props.createAction : null;
  const [dataUrl, setDataUrl] = React.useState(props.dataUrl);
  const [loading, setLoading] = React.useState(props.loading);

  const enrichColumns = (cols: any) => {
    return cols.map((col: any, index: number) => {
      col.disablePadding = col.disablePadding ? col.disablePadding : false;
      col.numeric = col.numeric ? col.numeric : false;
      col.isSearchable = typeof col.isSearchable != 'undefined' ? col.isSearchable : true;
      return col;
    });
  }

  const columns = enrichColumns(props.columns);

  return (
    <InternalEnhancedTable dataUrl={props.dataUrl} rows={props.rows} columns={columns} order={order}
      onRequestSort={props.onRequestSort} onSelectAllClick={props.onSelectAllClick}
      orderBy={orderBy} page={page} selectable={selectable} title={title}
      menu={menu} createAction={createAction} canExport={props.canExport} createLabel={props.createLabel} onSelect={props.onSelect}
      onDeselect={props.onDeselect} multiselect={props.multiselect} placeholder={props.placeholder}
      noHeader={props.noHeader} loading={loading} rememberSearch={props.rememberSearch}
      filterActions={props.filterActions}
    />
  );
}

function InternalEnhancedTable(props: EnhancedTableProps) {
  // const [order, setOrder] = React.useState<Order>(props.order ? props.order : 'asc');
  // const [orderBy, setOrderBy] = React.useState<string>(props.orderBy ? props.orderBy : props.columns[0].id);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = !props.rememberSearch ? React.useState(0) : useLocalStorage(props.rememberSearch+'-page', 0);
  const [dense, setDense] = React.useState(true);
  const [filterName, setFilterName] = !props.rememberSearch ? React.useState('') : useLocalStorage(props.rememberSearch, '');
  const [rowsPerPage, setRowsPerPage] = props.rowsPerPage ? React.useState(props.rowsPerPage) : useLocalStorage('rowsPerPage', 10);
  const [order, setOrder] = !props.rememberSearch ? React.useState(props.order ? props.order : 'asc') : useLocalStorage(props.rememberSearch+'-order', 'asc');
  const [orderBy, setOrderBy] = !props.rememberSearch ? React.useState(props.orderBy ? props.orderBy : props.columns[0].id) : useLocalStorage(props.rememberSearch+'-orderBy', props.columns[0].id);

  // const  rows = props.rows;
  
  const exportAction = () => {
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: exportColumns()
    };
    
    const csvExporter = new ExportToCsv(csvOptions);
    csvExporter.generateCsv(exportData());

    // console.log(props.columns);
    // console.log(exportData());
    /*
    applySortFilter(props.rows, getComparator(order, orderBy), filterName, props.columns)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map
    */
  }

  const exportColumns = () => props.columns.map((c:any) => c.label);
  const exportData = () => {
    const data = applySortFilter(props.rows, getComparator(order, orderBy), filterName, props.columns);
    return data.map((row) => {
      return props.columns.map(col => {
        if(col.format) {
            return col.format(row[col.id], col.id, true);
        }
        let v = getValue(row, col.id) ; 
        return v? v : "";
      })
    });

    // console.log(data);
    // props.rows.map({col.format ? col.format(row[col.id], col.id) : getValue(row, col.id)}
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if select all checked
    if (event.target.checked) {
      const newSelecteds = props.rows.map((n) => getRowId(n));
      if (props.onSelect) {
        props.onSelect(props.rows);
      } 
      setSelected(newSelecteds);
      return;
    }

    // select al deselected
    if (props.onDeselect) {
        props.onDeselect(selected);
    }
    setSelected([]);
  };

  const getRowId = (row:any) => {
    if(row.ref) {
      return row.ref;
    }
    if(row.id) {
      return row.id;
    }
    return row.name;
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    if (!props.selectable) {
      return;
    }
    const selectedIndex = selected.indexOf(getRowId(row));

    if (!props.multiselect) {
      console.debug("Single selected index " + selectedIndex);
      if (selectedIndex == 0) {
        // it was a deselect
        // empty list of selected
        if (props.onDeselect) {
          props.onDeselect([row]);
        }
        setSelected([]);
      } else {
        if (props.onSelect) {
          props.onSelect([row]);
        }
        setSelected([getRowId(row)]);
      }
    } else {
      console.debug("Multi selected index " + selectedIndex);
      let newSelected: readonly string[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, getRowId(row));
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      if (selectedIndex === -1) {
        if (props.onSelect) {
          props.onSelect([row]);
        } 
      } else {
        if (props.onDeselect) {
          props.onDeselect([row]);
        }
      }
      setSelected(newSelected);
    }

  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterByName = (event: any) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const getValue = (row:any, colId:any) => {  
  //   let parts = colId.split(".");
  //   if (parts.length == 1) {
  //     return row[colId];
  //   }
  //   let value = row;
  //   for(let i = 0; i < parts.length; i++) {
  //     value = value[parts[i]];
  //   }
  //   return value;
  // };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.rows.length) : 0;

  const currentrows = applySortFilter(props.rows, getComparator(order, orderBy), filterName, props.columns);
  return (
    <Box sx={{ width: '100%', p:0, m:0 }}>
      {!props.noHeader && <EnhancedTableToolbar
        numSelected={selected.length}
        title={props.title}
        filterName={filterName}
        onFilterName={handleFilterByName}
        selectable={props.selectable}
        exportAction={exportAction}
        createAction={props.createAction}
        canExport={props.canExport}
        createLabel={props.createLabel}
        placeholder={props.placeholder}
        filterActions={props.filterActions}
      />}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={'small'}
        >
          {<EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
            columns={props.columns}
            selectable={props.selectable}
            hasMenu={props.menu.length > 0}
            multiselect={props.multiselect}
          />}
          {props.loading && <Loading/>}
          {!props.loading && <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              props.rows.slice().sort(getComparator(order, orderBy)) */}
            {currentrows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: number) => {
                const isItemSelected = isSelected(getRowId(row));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={props.selectable && isItemSelected}
                    tabIndex={-1}
                    key={row.ref ? row.ref : row.id}
                    selected={props.selectable && isItemSelected}
                  >
                    {props.selectable && <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>}
                    {props.columns.map((col, colindex) => {
                      if (col.id == 'name') {
                        return (
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            key={row.name + "-" + colindex}
                          >
                            {row.name}
                          </TableCell>

                        )
                      }
                      return (
                        <TableCell align="left" key={index + "-" + colindex}>
                          {col.format ? col.format(row[col.id], col.id) : getValue(row, col.id)}
                        </TableCell>
                      )
                    })}
                    {props.menu.length > 0 &&
                      <TableCell align="right" key={index + "-actions"} style={{ margin: 0, padding: '0px !important' }}>
                        <MoreMenu menu={props.menu} row={row} />
                      </TableCell>
                    }
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={currentrows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
