import * as React from 'react';
import { useParams } from 'react-router';
import { Grid } from "@mui/material";
import Panel from '../../components/containers/Panel';
import { Typography } from '@mui/material';
import Title from "../Title";
import { useNavigate } from 'react-router';
import Cancel from "../../components/buttons/Cancel";
import Primary from "../../components/buttons/Primary";
import Loading from '../../components/Loading';
import { useSnackbar } from 'notistack';
import axios from "axios";
import { MetaTable } from '../../components/table/MetaTable';
import useLocalStorage from '../../app/useLocalStorage';



export default function RefreshEvent() {
    const { id, type } = useParams();
    const navigate = useNavigate();
    const [applying, setApplying] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [entries, setEntries] = React.useState(Array());
    const [next, setNext] = React.useState(false);
    const [event, setEvent] = useLocalStorage("event");

    const cancel = () => {
        return navigate(-1);
    };
    
    const entrySelectedHandler = (rows) => {
        if (rows.length > 1) {
            console.debug("All selected");
            setEntries(rows);
            return;
        }

        console.debug("Selected individual entry", rows);
        let selectedRows = JSON.parse(JSON.stringify(entries));
        selectedRows.push(rows[0]);
        console.debug(selectedRows.length + " rows selected");
        setEntries(selectedRows);
    }

    const entryDeselectedHandler = (rows) => {
        if (rows.length > 1) {
            console.debug("All deselected");
            setEntries([]);
            return;
        }
        console.debug("Deselected entries", rows);
        // remove row from list
        let selectedRows = [];
        for (var i = 0; i < entries.length; i++) {
            // console.debug("Checking " + selectedRows[i].name + " == " + rows[0].name)
            if (entries[i].name != rows[0].name && entries[i].horsename != rows[0].horsename ) {
                selectedRows.push(entries[i]);
            }
        }
        setEntries(selectedRows);
    }

    const refreshEvent = () => {
        console.debug("Apply Update called", entries);
        setApplying(true);
        axios.post("/event/"+id+"/applyupdates", { updates: entries } )
            .then(response => {
                console.debug(response);
                setApplying(false);
                if (response.data.error) {
                    return enqueueSnackbar(response.data.message, { variant: 'error'})
                }
                setApplying(false);
                setEntries([]);
                enqueueSnackbar("updated entries imported", { variant: 'success'});
                updateLocalEventStorage();
            })
            .catch(error => {
                console.debug(error);
                return enqueueSnackbar("Error importing latest entry details: " + error, { variant: 'error'})
            });
    };
    
    const updateLocalEventStorage = () => {
        console.debug("Id: " + id);
        // get the details for this event 
        axios.get("/event/id/" + id)
            .then(response => {
            if (response.data.error) {
                return;
            }
            console.debug("Fetched data", response.data);
            setEvent(response.data.record);
        }).catch(error => {
            console.debug(error);
        });
    };

    if(applying) {
        return (
            <Panel>
                <Title>Applying Changes</Title>
                <Typography sx={{ ml: 1, mt: 2, mb: 1 }}>Please wait while we apply the requested updates to this Event.</Typography>
                <Loading/>
            </Panel>
        )
    }
    return (
        <Panel>
            <Title>Check for Changes in Event Entries</Title>
            <Typography>            
                The table below shows the changes in this event, please select the ones that you would like
                to import into the system and then click the <strong>Apply Updates</strong> button, otherwise click cancel to go back.
            </Typography>
            <Grid container spacing={3} sx={{pt: 2}}   justifyContent="flex-end">
                {entries.length > 0 && <Grid item><Primary title="Apply Updates" onClick={() => {refreshEvent()}}/></Grid>}
                {<Grid item><Cancel onClick={() => { cancel() }}/></Grid>}
            </Grid>

            <MetaTable type={"eventrefresh"}
                baseUrl={"ei/"}
                dataUrl={"/event/"+id+"/compare"}
                selectable={true}
                rowsPerPage={100}
                title={" "}
                onSelect={entrySelectedHandler}
                onDeselect={entryDeselectedHandler}
                multiselect={true}
            />

        </Panel>
    )
}