import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import TabPanel from '../../components/containers/TabPanel';
import Description from '../../containers/Description';
import Title from '../../containers/Title';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import UserResults from "../../components/eventing/UserResults";
import UserAccess from "../../components/eventing/UserAccess";
import OrdersTable from '../../components/eventing/OrdersTable';
import EntriesTable from '../../components/eventing/EntriesTable';
import useLocalStorage from '../../app/useLocalStorage';
import { UserEntryFilters } from '../../app/form/Definitions/UserEntryFilters';
import { MetaTable } from '../../components/table/MetaTable';


export default function ViewUserDetails() {
    const { id } = useParams();
    const [user, setUser] = React.useState();
    // const [userDetails, setUserDetails] = React.useState();
    // const [userRoles, setUserRoles] = React.useState();
    // const [userAccess, setUserAccess] = React.useState();
    // const {enqueueSnackbar } = useSnackbar();
    const [tabValue, setTabValue] = useLocalStorage('view-user-tab', 0);
    // const [loading, setLoading] = React.useState(false);
    // const [error, setError] = React.useState("");

   
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };
    
    const a11yProps = (index) => {
        return {
          id: `user-tab-${index}`,
          'aria-controls': `user-tabpanel-${index}`,
        };
    };
      

    const dataUsed = (user) => {
        setUser(user);
    }   


    return (
        <React.Fragment>
            <Panel>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        variant="scrollable" 
                        scrollButtons
                        value={tabValue} onChange={handleTabChange} aria-label="horse details">
                        <Tab label="User" {...a11yProps(0)} />
                        <Tab label="Additional Details" {...a11yProps(1)} />
                        <Tab label="Access" {...a11yProps(2)} />
                        <Tab label="Results" {...a11yProps(3)} />
                        <Tab label="Entries" {...a11yProps(4)} />
                        <Tab label="Horses" {...a11yProps(5)} />
                        <Tab label="Payments" {...a11yProps(6)} />
                        <Tab label="Documents" {...a11yProps(7)} />
                        <Tab label="Competencies" {...a11yProps(8)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                        <Form id={id} objtype={"user"} readonly={true} showbuttons={true} onData={dataUsed} noTitle={true}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Form id={id} objtype={"userdetails"} readonly={true} showbuttons={true} noTitle={true}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2} lazy={false}>
                    {user && <UserAccess id={id} user={user}/>}
                </TabPanel>
                <TabPanel value={tabValue} index={3} lazy={false}>
                    {user && <UserResults id={id} name={user.firstname + " " + user.lastname + " Results"}/>}
                </TabPanel>
                <TabPanel value={tabValue} index={4} lazy={false}>
                    <EntriesTable dataUrl={`/admin/user/${id}/entries`} filter={UserEntryFilters}/>
                </TabPanel>
                <TabPanel value={tabValue} index={5} lazy={false}>
                    <MetaTable type="horse" dataUrl={`/admin/user/${id}/horses`}/>
                </TabPanel>
                <TabPanel value={tabValue} index={6} lazy={false}>
                    <OrdersTable dataUrl={`/admin/user/${id}/orders`}/>
                </TabPanel>
                <TabPanel value={tabValue} index={7} lazy={false}>
                    <Title>Members Documents</Title>
                    <Description>Below are the documents that were provided with this horse as part of the registration.</Description>
                    <MetaTable type="document" 
                        canCreate={false}
                        dataUrl={`/admin/document?relatedid=${id}&type=member`}
                  />        
                </TabPanel>
                <TabPanel value={tabValue} index={8} lazy={false}>
                    <MetaTable 
                        type="competencies" 
                        dataUrl={`/admin/competency/?userid=${id}`} 
                        canCreate={true} 
                        idname="userid"
                    />
                </TabPanel>
            </Panel>                          
        </React.Fragment>
    )
}