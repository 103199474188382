import React, { forwardRef, Fragment, useMemo } from "react";
import { Chip, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import get from "lodash/get";
import isObject from "lodash/isObject";
import PropTypes from "prop-types";
import { useValidation } from "../../Hooks/useValidation";
import { Title } from "../Widgets/Title";
import { getValidationType, shuffleArray } from "../Utils/helpers";

const StandardAutocompleteNoDrag = forwardRef((props, ref) => {
  const { field, value, updateForm, showTitle, readonly } = props;
  const { errors, validate, reset } = useValidation(
    getValidationType(field),
    field.validations
  );

  const optionConfig = useMemo(
    () => (option) => {
      const config = {
        value: option,
        label: option,
      };

      if (!field.optionConfig) {
        return config;
      }

      if (field.optionConfig.value) {
        // This is to account for the quirky behaviour of onChange returning an array
        if (field.props && field.props.multiple && Array.isArray(option)) {
          const value = [];
          for (const item of option) {
            if (isObject(item)) {
              value.push(get(item, field.optionConfig.value));
            } else {
              value.push(item);
            }
          }
          config.value = value;
        } else {
          config.value = get(option, field.optionConfig.value);
        }
      }

      if (field.optionConfig.label) {
        // This is to account for the quirky behaviour of onChange returning an array
        if (field.props && field.props.multiple && Array.isArray(option)) {
          const label = [];
          for (const item of option) {
            if (isObject(item)) {
              label.push(item);
            } else {
              label.push(get(item, field.optionConfig.label));
            }
          }
          config.label = label;
        } else {
          config.label = get(option, field.optionConfig.label);
        }
      }

      return config;
    },
    [field]
  );

  /* 
  Required to handle quirky behaviour of Autocomplete component
  where it returns the option object when opening the selection box
  and returns the option value upon selection 
  */
  function getLabel(option) {
    if (isObject(option)) {
      return String(optionConfig(option).label);
    }
    if ((field.optionConfig || {}).value) {
      const o =
        field.options?.find((o) => optionConfig(o).value === option) || {};
      return String(optionConfig(o)?.label);
    }
    return String(option);
  }

  const options = useMemo(() => {
    if (field.randomizeOptions) {
      return shuffleArray(field.options || []);
    }
    return field.options || [];
  }, [field.options]);

  const getValue = (id) => {
    let obj = options.find(o => o.id == id);
    return obj.name;
  }

  const componentProps = (field) => {
    return {
      id: field.id || field.attribute,
      size: "small",
      fullWidth: true,
      options: options,
      isOptionEqualToValue: (option, value) => {
        /* 
        Required to handle the quirky behaviour of Autocomplete component
        where it returns the value object sometimes and value value sometimes
        */
        return isObject(value)
          ? optionConfig(option).value === optionConfig(value).value
          : optionConfig(option).value === value;
      },
      getOptionLabel: (option) => getLabel(option),
      // renderInput: (params) => <TextField {...params} label={field.name} />,
      renderInput: (params) => (
        <TextField
          {...params}
          inputRef={(el) => {
            if (el && ref) {
              el.validate = (value) => validate(value);
              ref(el);
            }
          }}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            // autoComplete: "off", // disable autocomplete and autofill
          }}
          label={field.label}
          error={errors && errors.length > 0}
          helperText={errors[0]}
        />
      ),
      renderTags: (value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="outlined"
            size="small"
            label={getLabel(option)}
            {...getTagProps({ index })}
          />
        )),
      value: value || (field.props && field.props.multiple ? [] : null),
      onChange: (event, option) => {
        updateForm({
          [field.attribute]: optionConfig(option).value,
        });
      },
      onBlur: () => validate(value),
      ...field.props,
    };
  };

  const componentReadOnlyProps = (field) => {
    return {
      id: field.id || field.attribute,
      size: "small",
      fullWidth: true,
      options: options,
      // getOptionLabel: (option) => getLabel(option),
      value: getValue(value) || (field.props && field.props.multiple ? [] : null),
    };
  };

  if(readonly) {
    return (
      <Fragment>
      {showTitle && field.title && <Title field={field} />}
      <TextField
        InputProps={{
          readOnly: true,
        }}
        {...componentReadOnlyProps(field)}
        variant="standard"
      />
    </Fragment>
    )
  }
  return (
    <Fragment>
      {showTitle && field.title && <Title field={field} />}
      <Autocomplete {...componentProps(field)} />
    </Fragment>
  );
});

StandardAutocompleteNoDrag.displayName = "StandardAutocompleteNoDrag";

StandardAutocompleteNoDrag.defaultProps = {
  updateForm: () => {},
  showTitle: true,
};

StandardAutocompleteNoDrag.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  updateForm: PropTypes.func,
  showTitle: PropTypes.bool,
};

export { StandardAutocompleteNoDrag };
