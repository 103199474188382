import * as React from 'react';
import { useParams } from 'react-router';
import Panel from '../../components/containers/Panel';
import TabPanel from '../../components/containers/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useLocalStorage from '../../app/useLocalStorage';
import { useSnackbar } from 'notistack';
import axios from "axios";
import DressageSetup from '../../scoring/components/DressageSetup';
import Title from '../Title';
import ShowJumpingSetup from '../../scoring/components/ShowJumpingSetup';
import CrossCountrySetup from '../../scoring/components/CrossCountrySetup';
import Back from '../../components/buttons/Back';
import { Alert } from '@mui/material';


export default function SetupEventClassDetails( { }) {
    const { id } = useParams();
    const [eventclass, setEventclass] = React.useState(null);
    const [value, setValue] = useLocalStorage('view-event-class-tab', 0);
    const [error, setError] = React.useState('');
    const {enqueueSnackbar } = useSnackbar();

    
    React.useEffect(() => {
        getData();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    const classname = () => {
        if(eventclass) {
            return eventclass.sponsortext ? eventclass.sponsortext : eventclass.Classtype.name;
        }
        return "";
    }
    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    };      

    async function getData() {
        // setError('');
        axios.get('/admin/eventclasses/id/' + id + "?classnames=1")
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                if(response.data.record.dressagetestid < 138) {
                    setError("The Dressage Test linked to this class is an old one so cannot setup this event for scoring");
                }
                setEventclass(response.data.record);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }
    
    return (
        <React.Fragment>
            
            <Panel>
                {error && <Alert sx={{mb:3}} severity="error">{error}</Alert>}
                {!error && <><Title>Setup For {classname()} Class</Title>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="event details">
                        <Tab label="Dressage" {...a11yProps(0)} />
                        <Tab label="Show Jumping" {...a11yProps(4)} />
                        <Tab label="Cross Country" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {eventclass && <DressageSetup eventclass={eventclass}/>}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {eventclass && <ShowJumpingSetup eventclass={eventclass}/>}
                </TabPanel>
                <TabPanel value={value} index={2} lazy={false}>
                    {eventclass && <CrossCountrySetup  eventclass={eventclass}/>}
                </TabPanel></>}
            </Panel>            
            <Back/>
        </React.Fragment>
    )
}