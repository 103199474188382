import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import useLocalStorage from '../app/useLocalStorage';
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router';
import Lastcrumb from './Lastcrumb';

const rootBreadcrumbs = [{
  name: 'EI',
  url: '/'
}];


export default function EiBreadcrumbs() {
  const { id } = useParams();
  const location = useLocation();
  // const [lastCrumb, setLastCrumb] = useLocalStorage('lastcrumb');
  const previousPath = location.state ? location.state.prevPath : '';

  const breadmap = {
    '/users/migrateua': 'User Migration',
    '/events': 'Events',
    '/users': 'Users',
    '/orders': 'Payments',
    '/venues': 'Venues',
    '/horses': 'Horses',
    '/team': 'Teams',
    '/shop': 'Shop Items',
    '/reports': 'Reports',
    '/sponsor': 'Sponsors',
    '/eventrole': 'Event Roles',
    '/dayticket': 'Day Tickets',
    '/membertype': 'Member Type',
    '/ridingoption': 'Riding Option',
    '/classtypes': 'Class Types',
    '/dressagetests': 'Dressage Tests',
    '/olddressagetests': 'Old Dressage Tests',
    '/view/report/': [ { '/reports': 'Reports', [location.pathname]: 'lastcrumb'}],
    '/view/event/': [ { '/events': 'Events', [location.pathname]: 'lastcrumb'}],
    '/view/user/': [ { '/users': 'Users', [location.pathname]: 'lastcrumb'}],
    '/view/order/': [ { '/orders': 'Payments', [location.pathname]: 'lastcrumb'}],
    '/view/horse/': [ { '/horses': 'Horses', [location.pathname]: 'lastcrumb'}],
    '/view/venue/': [ { '/venues': 'Venues', [location.pathname]: 'lastcrumb'}],
    '/view/classtype/': [ { '/classtypes': 'Class Types', [location.pathname]: 'lastcrumb'}],
    '/view/dressagetest/': [ { '/dressagetests': 'Dressage Tests', [location.pathname]: 'lastcrumb'}],
    '/view/olddressagetest/': [ { '/olddressagetests': 'Old Dressage Tests', [location.pathname]: 'lastcrumb'}],
    '/view/shop/': [ { '/shop': 'Shop Items', [location.pathname]: 'lastcrumb'}],
    '/view/sponsor/': [ { '/sponsor': 'Sponsors', [location.pathname]: 'lastcrumb'}],
    '/view/eventrole/': [ { '/eventrole': 'Event Roles', [location.pathname]: 'lastcrumb'}],
    '/view/dayticket/': [ { '/dayticket': 'Day Tickets', [location.pathname]: 'lastcrumb'}],
    '/view/membertype/': [ { '/membertype': 'Member Type', [location.pathname]: 'lastcrumb'}],
    '/view/ridingoption/': [ { '/ridingoption': 'Riding Options', [location.pathname]: 'lastcrumb'}],
    '/view/team/': [ { '/team': 'Teams', [location.pathname]: 'lastcrumb'}],
    '/view/eventclass/': [ { '/events': 'Events', [previousPath]: 'lastcrumb'}],
  } 
  
  // const [breadcrumbs, setBreadcrumbs] = useLocalStorage('ei-breadcrumbs', rootBreadcrumbs);

  let matched = false;
  let breadcrumbs = JSON.parse(JSON.stringify(rootBreadcrumbs));
  for(const path in breadmap) {
    if(!matched) {
      if (path == location.pathname) {
        matched = true;
        breadcrumbs.push({
          name: breadmap[path],
          url: path
        });
      } else if(location.pathname.startsWith(path)) {
        matched = true;
        const p = breadmap[path];
        p.map(c => {
          for (const p in c) {
            breadcrumbs.push({
              name: c[p] == 'lastcrumb' ? Lastcrumb.name : c[p],
              url: p
            });  
          }
        });
      }
    }
  }
  // console.debug(breadcrumbs);
  return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs.map((b, index) => {
            return (
              <Link key={'breadcrumb-'+index} underline="hover" color="inherit" href={b.url}>
              {b.name}
            </Link>
            );
        })}      
      </Breadcrumbs>
  );
}