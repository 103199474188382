import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

// types are:
// success, error, warning, info and default
type MessageState = {
  message: string;
  type: string;
};

type MessageAction = {
  payload: MessageState;
};

const initialState = {
    message: '',
    type: 'info'
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state: MessageState, action: MessageAction) => {
      return { message: action.payload.message, type: action.payload.type };
    },
    clearMessage: (state: MessageState) => {
      return { message: "", type:  state.type};
    },
  },
});

export const selectMessage = (state: RootState ) => {
    const messageState = <MessageState>state.message;
    return messageState.message;
}

export const selectMessageType = (state: RootState ) => {
    const messageState = <MessageState>state.message;
    return messageState.type;
}

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage  } = actions
export default reducer;