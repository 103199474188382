import * as React from 'react';
import useLocalStorage from '../../app/useLocalStorage';
import InitialiseDiscipline from '../components/InitialiseDiscipline';
import Grid from '@mui/material/Grid';
import Title from "../../containers/Title";
import Description from "../../containers/Description";
import Loading from '../../components/Loading';
import axios from 'axios';
import Save from "../../components/buttons/Save";
import { useSnackbar } from 'notistack';
import { stripUndefined } from '../../utils';

const disciplinedefaults = [
    {
        name: "Show Jumping",
        "breakafterclass": 5,
        "breakeverynriders": 100,
        "minutesinriderbreak": 10,
        "numberofarenas": 2,
        "optimimumtime": 45,
        "impossiblethreshold": 30,
        "difficultthreshold": 56,
        "starttime": "10:00",
        "disciplineid": 1,
        "minutesperrider": 2,
    },
    {
        name: "Dressage",
        "breakafterclass": 10,
        "breakeverynriders": 10,
        "minutesinriderbreak": 10,
        "numberofarenas": 5,
        "optimimumtime": 45,
        "impossiblethreshold": 30,
        "difficultthreshold": 56,
        "starttime": "09:00",
        "minutesperrider": 6,
        "disciplineid": 2,
    },
    {
        name: "Cross Country",
        "breakafterclass": 5,
        "breakeverynriders": 100,
        "minutesinriderbreak": 10,
        "numberofarenas": 1,
        "optimimumtime": 45,
        "impossiblethreshold": 30,
        "difficultthreshold": 56,
        "starttime": "12:00",
        "minutesperrider": 1.5,
        "disciplineid": 3,
    }
];
const initialiseFormData = (defaults) => {
    let data = {};
    defaults.forEach(d => {
        data[d.disciplineid] = d
    })
    return data;
};

export default function InitialiseScheduling( props) {
    // const [event, setEvent] = useLocalStorage("event");
    // const [form, setForm] = React.useState(initialiseFormData(disciplinedefaults));
    const [form, setForm] = React.useState({});

    const refs = React.useRef(new Array());
    // const [disciplineDefaults, setDisciplineDefaults] = React.useState(disciplinedefaults);
    const [disciplineDefaults, setDisciplineDefaults] = React.useState([]);

    const [saving, setSaving] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [errors, setErrors] = React.useState({});

    React.useEffect(() => {
        // get the details for this event 
        axios.get("/admin/disciplinedefaults")
            .then(response => {
            if (response.data.error) {
                enqueueSnackbar(response.data.message, { variant: 'error'})
                return;
            }
            setForm(initialiseFormData(response.data.records))
            setDisciplineDefaults(response.data.records);
        }).catch(error => {
            console.log(error);
        });

    }, []);

    const updateForm = (disciplineid, updates) => {
        console.debug(disciplineid, updates);
        const copy = JSON.parse(JSON.stringify(form));
        let errorList = {};
        for (const [key, value] of Object.entries(updates)) {
            refs.current[key].validate(value).then(isInvalid => {
                if(isInvalid) {
                    errorList[key] = isInvalid[0];                    
                } 
            }
            );
            _.set(copy[disciplineid], key, value);
        }
        setErrors(errorList);
        setForm(copy);
    };

    const save = () => {
        let errorList = stripUndefined(errors);
        if(Object.keys(errorList).length > 0 ) {
            console.debug(errorList);
            return enqueueSnackbar("Please correct errors before submitting", { variant: 'error'})
        }

        enqueueSnackbar("Initialising event, please wait", { variant: 'info'})
        setSaving(true);        
        // correct minutes per rider
        // form[1].minutesperrider = `00:${form[1].minutesperrider}`;
        // form[2].minutesperrider = `00:${form[2].minutesperrider}`;
        // form[3].minutesperrider = `00:${form[3].minutesperrider}`;
        // get the details for this event 
        axios.post("/admin/event/"+props.id+"/initialise", form).then(response => {
            if (response.data.error) {
                enqueueSnackbar(response.data.message, { variant: 'error'})
                setSaving(false);
                return;
            }
            console.debug("Event initialised", response.data);
            enqueueSnackbar("Event Initialised", { variant: 'success'});
            let updatedEvent = response.data.record;
            updatedEvent.migratedid = 1;
            // setEvent(updatedEvent);
            setSaving(false);
            props.onInitialised(updatedEvent);

        }).catch(error => {
            console.debug(error);
            setSaving(false);
        });
    
    };

    const getDefaults = (disciplineId) => {
        for(let i = 0; i < disciplineDefaults.length; i++) {
            if(disciplineDefaults[i].id == disciplineId) {
                return disciplineDefaults[i];
            }
        }
        return {};
    };

    if(disciplineDefaults.length == 0 ) {
        return (
            <React.Fragment>
                <Loading />
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
        <Title>Initialise Scheduling For This Event</Title>
        <Description>Before you can start scheduling this event we just need some initial information to set
        things up. Please provide initial values for the various fields below and then click  
        the <strong>Initiliase</strong> button to start scheduling. Don't worry if you are not 100% sure about certain settings right now, you can always change 
        the settings later. </Description>
        <Grid container spacing={3}>
            {disciplineDefaults.map((discipline, index) => {
                return (
                    <Grid key={"disc"+index} item xs={12} md={4} lg={3}>
                        <InitialiseDiscipline defaults={disciplineDefaults[index]} refs={refs} title={discipline.name} disciplineid={index+1} updateForm={updateForm} />
                    </Grid>
                    );
        })}
        </Grid>
        <Grid container spacing={3} sx={{pt: 2}}   justifyContent="flex-start">
            <Grid item >
                <Save loading={saving} onClick={save} title={"Initialise"}/>
            </Grid>
        </Grid>
        </React.Fragment>
    );
}