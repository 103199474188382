import * as React from 'react';
import { Grid, Tooltip, IconButton, Typography } from "@mui/material";
import axios from "axios";
import EnhancedTable from '../../components/table/EnhancedTable';
import Form from '../form/Form';
import ConfirmationDialog from '../../components/collections/ConfirmationDialog';
import ChangePassword from '../collections/ChangePassword';
import ResetPassword from '../collections/ResetPassword';
import AddRole from '../collections/AddRole';
import Fieldset from '../containers/Fieldset';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { formatDate } from '../../utils';
import LockIcon from '@mui/icons-material/Lock';
import LinkAccount from '../collections/LinkAccount';

const tableFields = [
    {
        id: "rolename",
        label: "Role Name",
        properties: {
            component: "text-field",
        }
    },
    {
        id: "effectivedate",
        label: "Effective From",
        properties: {
            component: "date-picker"
        }
    },
    {
        id: "expirydate",
        label: "Expires On",
        properties: {
            component: "date-picker"
        }
    }
];


export default function UserAccess(props) {
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState(props.user);
    const [row, setRow] = React.useState();
    const [showConfirm, setShowConfirm] = React.useState(false);
    const {enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    console.log(user);
    // const ping = () => {
    //     setBusy(true);
    //     axios.get("/admin/user/ping")
    //       .then(response => {
    //         setBusy(false);
    //       }).catch(error => {
    //         setBusy(false);
    //       });
    // };

    // React.useEffect(() => {
    //     ping();
    // }, []);
    const menu = [
        {
            icon: "delete",
            name: "Remove Role",
            handler: function(row) {
                setRow(row);
                setShowConfirm(true);                
            }
        } ];

    const removeRole = () => {

        // call remove role on the server
        axios.post(`/admin/user/${user.id}/removerole`, { roleid: row.roleid} )
        .then(response => {
            if (response.data.error) {
                setLoading(false);
                return enqueueSnackbar(response.data.message, { variant: 'error'});
            }
            setLoading(false);
            setShowConfirm(false);
            navigate(0);

        })
        .catch(error => {
            console.debug(error);
            setLoading(false);
            return enqueueSnackbar("Error refunding payment: " + error, { variant: 'error'})
        });
    }

    const onCancel = () => {
        // pop up confirmation question
        setShowConfirm(false);
    }

    const getRoleDetails = (roles) => {
        return roles.map(role => {
            return {
                rolename: role.rolename,
                roleid: role.roleid,
                effectivedate: role.Userroles.effectivedate != null ? formatDate(role.Userroles.effectivedate) : "",
                expirydate: role.Userroles.expirydate != null ? formatDate(role.Userroles.expirydate) : ""
            }
        });
    }

    /**
     * <ResetPassword  id={props.id}/>
     */
    return (
        <Grid container spacing={1}>
            <Grid item md={6} >
                <Fieldset title="Change Users Password" description="You can change the users passport by clicking the change password button below and entering a new value which will send them an email with the new password in it. Or you can click the Reset Password button which will email them with a link where they can change the passsword themselves.">
                <ChangePassword id={props.id}/>
                </Fieldset>    
                <Tooltip title={user.p}><IconButton size="large">
                <LockIcon onClick={() => {enqueueSnackbar("copied", { variant: 'success'}); navigator.clipboard.writeText(user.p)}} />                
              </IconButton></Tooltip> <Typography variant="caption" sx={{ml:1}}>{user.username}</Typography>
                
            </Grid>
            <Grid item md={6} >
                <Fieldset  title="Lock/ Unlock Account" description="You can lock a user account from the system, or unlock a previously locked account, e.g. if they entered their password incorrectly too many times.">
                    <Form bottomOnly={true} id={props.id} showbackbutton={false} objtype={"useraccess"} readonly={true} showbuttons={true} noTitle={true}/>
                </Fieldset>            
            </Grid>
            <Grid item md={6} >
                <Fieldset title="Link Account" description="Use this option to link this account with another.">
                <Grid container spacing={1}><Grid item md={6} ><LinkAccount id={props.id}/> </Grid>
                {user.linkedAccount && <Grid item md={6} ><Typography>{user.linkedAccount}</Typography></Grid>}
                </Grid>
                </Fieldset>                    
            </Grid>
            <Grid item md={12} >
                <Fieldset title="Manage User Roles" description="You can add or remove roles from this user by using the options below">
                    {user && <EnhancedTable columns={tableFields} 
                                    rows={getRoleDetails(user.Roles)} orderBy={"rolename"}
                            noHeader={true}
                            rowsPerPage={10} 
                            placeholder={""} 
                            menu={menu}
                            selectable={false}
                        />}
                        <AddRole id={props.id}/>
                </Fieldset>
                {showConfirm && <ConfirmationDialog title={"Please Confirm"} 
                description={"Are you sure you want to remove this role from this user" }
                        onConfirm={removeRole} open={showConfirm}
                        onCancel={onCancel} loading={loading}
                        loadingMessage={"Please wait while we remove this role"}/>}

            </Grid>
        </Grid>
    );
}