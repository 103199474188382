import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../../containers/Title';

export default function Panel(props:any) {
    return (
        <Grid container >
            <Grid item xs={12}>
                <Paper sx={{ width: '100%', mb: 2, p: 2 }}>
                    {props.title && <Title >{props.title}</Title>}
                    {props.children}
                </Paper>
            </Grid>
        </Grid>
    )
}