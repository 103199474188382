import * as React from 'react';
import Typography from "@mui/material/Typography";
import { pad } from '../../utils';
const MAX_MULTIPLES = 5;
let colours = [
    { 
        backgroundColor: 'rgb(255,255,0)',
        color: 'black'
    },
    { 
        backgroundColor: 'rgb(255,215,0)',
        color: 'black'
    },
    { 
        backgroundColor: 'rgb(255,165,0)',
        color: 'white'
     },
    { 
        backgroundColor: 'rgb(255,140,0)',
        color: 'white'
    },
    { 
        backgroundColor: 'rgb(255,0,0)',
        color: 'white'
    }
];

export default function MultipleIndicator(props) {
    const getStyle = () => {
        if (props.multiples == 0) {
            return {};
        }
        let value = props.multiples ;
        if (value > MAX_MULTIPLES ) {
            value = MAX_MULTIPLES;
        }
        let defaults = {
            marginLeft: 3,
            paddingLeft: 3,
            paddingRight: 3,
            borderRadius: 6
        };
        return {...defaults, ...colours[value]};
    }

   
    return (
        <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
            style={getStyle()}
        >
              {props.multiples > 0 &&  (props.multiples+1)}
        </Typography>        
        
    );
}