import * as React from 'react';
import { Stack, TextField } from '@mui/material';


export default function  SjScore(
    { 
        fenceindex, 
        fence, onChange, 
        value, hasFocus, onFocus
    }) {


    return (
        <div  ><Stack

            alignItems="center"
            justifyContent="center" direction="column" sx={{mb:3, style: { textAlign: 'center' }}}>
            <TextField
                // ref={hk} 
                // variant={variant}
                // type="tel"
                tabIndex={fenceindex+1}
                inputProps={{ 
                    readOnly: true, 
                    min: 0, 
                    style: { 
                        textAlign: 'center', 
                        backgroundColor: hasFocus ? "green" : "",
                        color: hasFocus ? "white" : "black",
                        padding: 5
                    }
                }}                         
                sx={{
                    width: 50
                }}
                size="small"
                value={value}
                helperText={`   #${fence}`}            
                onChange={(e) => onChange(e.target.value)}
                onFocus={onFocus}
                // onBlur={() => setSelected(false)}
            />
            {/* <Box sx={{
                width: 48,
                height: 36,
                pt:0.5,
                mx:0.5,
                borderRadius: "3px",
                border: "1px solid lightgrey",
                justifyContent: "center",
                display: "flex",
                borderColor: hasFocus ? "primary.main" : "",
                backgroundColor: hasFocus ? 'primary.dark' : "",
                color: hasFocus ? 'white' : "black",
                '&:hover': {
                    borderColor: 'primary.main',
                    // opacity: [0.3, 0.3, 0.3],
                },
                '&:before': {
                    borderColor: 'orange'
                  },
                  '&:after': {
                    borderColor: 'green',
                  }
            }} onClick={onFocus}>{value}</Box>
            <Typography sx={{color: 'text.secondary', fontSize:"12px" }}>{`#${fence}`}</Typography> */}
            {/* {hasFocus && <ButtonGroup
                disableElevation
                // variant="contained"
                aria-label="Disabled elevation buttons"
                >
                <Button onClick={() => onChange(fenceindex, parseInt(value)+4)} size="small" variant='outlined'>+</Button>
                <Button disabled={value == 0} onClick={() => onChange(fenceindex, parseInt(value)-4)} size="small" variant='outlined'>-</Button>

            </ButtonGroup>}
            {hasFocus && <ButtonGroup
                disableElevation
                // variant="contained"
                aria-label="Disabled elevation buttons"
                >
                <Button onClick={() => onChange(fenceindex, '1R')} size="small" variant='outlined'>1R</Button>
                <Button onClick={() => onChange(fenceindex, '2R')} size="small" variant='outlined'>2R</Button>

            </ButtonGroup>}
            {hasFocus && <ButtonGroup
                disableElevation
                // variant="contained"
                aria-label="Disabled elevation buttons"
                >
                <Button onClick={() => onChange(fenceindex, 'FR')} size="small" variant='outlined'>FR</Button>
                <Button onClick={() => onChange(fenceindex, 'FH')} size="small" variant='outlined'>FH</Button>

            </ButtonGroup>}
            {hasFocus && <ButtonGroup
                disableElevation
                // variant="contained"
                aria-label="Disabled elevation buttons"
                >
                <Button onClick={() => onChange(fenceindex, 'RET')} size="small" variant='outlined'>RET</Button>
                <Button onClick={() => onChange(fenceindex, '1RR')} size="small" variant='outlined'>1RR</Button>

            </ButtonGroup>} */}
        </Stack>
        </div>
    );
}

