import * as React from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Alert } from '@mui/material';
import axios from 'axios';

import SelectRider from '../components/SelectRider';
import DrJudgeMovements from '../components/DrJudgeMovements';
import DrSummary from '../components/DrSummary';
import { getOverallScore, getPercentage, getOverallCollTotal } from '../../utils';
import Panel from '../../components/containers/Panel';
import ResponsiveDialog from '../../components/containers/ResponsiveDialog';


export default function DressageScoring() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [entry, setEntry] = useState();
  const [changed, setChanged] = useState(false);
  const [space, setSpace] = useState(false);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [confirm, setConfirm] = useState(false);
  

  const entrySelected = (e) => {
    console.log(e);
    setError("");
    if(!e) {
      // setError("Could not find entry with this competirot");
      setEntry(null);
      return;
    }
    if(e.drscores.length == 0) {
      setError("The event class for this entry has not been initialised for dressage");
      setEntry(null);
    } else {
      setEntry(e)
    }
  }

  const cloneEntry = () => {
    return JSON.parse(JSON.stringify(entry));
  }

  const onChangeDrPenalty = (index, pen) => {
    let newEntry = cloneEntry();
    newEntry.drscores[index].penalty = pen;
    if(pen >= 3) {
      newEntry.drscores[index].status = "EL";
    }

    setEntry(newEntry);
    setChanged(true);
  }

  const onChangeDrOtherError = (index, pen) => {
    let newEntry = cloneEntry();
    newEntry.drscores[index].othererror = pen
    setEntry(newEntry);
    setChanged(true);
  }

  const onDrReasonChange = (index, newReason) => {
    let newEntry = cloneEntry();
    newEntry.drscores[index].reason = newReason
    setEntry(newEntry);
    setChanged(true);
  }

  const onScoreChange = (j, m, v) => {
    v=parseFloat(v);
    console.log(j, m, v);
    let newEntry = cloneEntry();
    newEntry.drscores[j].DressageMovementScores[m-1].score = v;
    setEntry(newEntry);
    setChanged(true);
  }

  // const onScoreErrorChange = (j, m, v) => {
  //   v=parseFloat(v);
  //   console.log(j, m, v);
  //   let newEntry = cloneEntry();
  //   newEntry.drscores[j].DressageMovementScores[m-1].error = v;
  //   setEntry(newEntry);
  //   setChanged(true);
  // }

  const onCommentChange = (index, newComment) => {
    let newEntry = cloneEntry();
    // newEntry.drcomments = newComment;
    newEntry.drscores[index].comment = newComment;
    setEntry(newEntry);
    setChanged(true);
  }
  const onStatusChange = (newStatus) => {
    let newEntry = cloneEntry();
    // newEntry.drstatus = newStatus;
    setEntry(newEntry);
  }
  
  const onDns = () => {
    let newEntry = cloneEntry();
    newEntry.drstatus = "DNS";
    for(let i = 0; i < entry.numjudges; i++) {
      newEntry.drscores[i].status = 'DNS'; 
    }
    // newEntry.drscores =  newEntry.drscores.map(drs => {
    //   drs.status = 'DNS';
    //   return drs;
    // });
    // setEntry(newEntry);
    confirmSave(1, newEntry);
  }

  const onChangeStatus = (index, newStatus) => {
    let newEntry = cloneEntry();
    newEntry.drscores[index].status = newStatus;
    newEntry.drstatus = newStatus;
    setEntry(newEntry);
    setChanged(true);
  }

  const onCancel = () => {
    setError("");
    setEntry(null);
    setChanged(false);
  }

  const validateScores = (drscores) => {
    let w = [];
    for(let i = 0; i < drscores.DressageMovementScores.length; i+=1 ) {
      if(drscores.DressageMovementScores[i].score == 0) {
        w.push(`Movement ${drscores.DressageMovementScores[i].number} is zero`);
      } else if(drscores.DressageMovementScores[i].score < 3) {
        w.push(`Movement ${drscores.DressageMovementScores[i].number} is below 3`);
      } else if (drscores.DressageMovementScores[i].score === 10) {
        w.push(`Movement ${drscores.DressageMovementScores[i].number} is 10, is this correct`);
      }
    }
    return w;
  };

  const validateAllScores = (update) => {
    // make sure no scores less than 3
    setWarning([]);
    let w = [];
    for(let i = 0; i < update.drscores.length; i+=1 ) {
      const problems = validateScores(update.drscores[i]);
      if(problems) {
        // warning = `${warning == "" ? "" : ", "} ${problem}`;
        w = w.concat(...problems);
      }
    }
    if(w.length === 0) {
      return confirmSave(1, update);
    }
    setWarning(w)
    setConfirm(true);
    // confirmOk = confirm("Are you sure you want to publish these changes");
    // if(confirmOk) {
    //   confirmSave(1, update);
    // }
  }

  

  const onSave = (publish, update=null) => {
    if(update == null) {
      update = entry;
    }

    if(publish) {
      return validateAllScores(update);
    }
    confirmSave(publish, update);
  }

  const confirmSave = (publish, update=null) => {
    setConfirm(false);
    setError("");
    // drgoodpoints, drpercentage, colltotal
    let data = {
      resultid: update.resultid,
      drscores: update.drscores,
      coltotal: getOverallCollTotal(update.drscores, update.Eventclass.Dressagetest),
      // drcomments: entry.drcomments,
      drmovescore: getPercentage(update.drscores[0].DressageMovementScores, update.Eventclass.Dressagetest, update.drscores[0].penalty),
      drpen: update.drpen,
      drreason: update.drreason,
      drscore: getOverallScore(update.drscores, update.Eventclass.Dressagetest),
      drmovescore2: update.Eventclass.numjudges > 1 ?  getPercentage(update.drscores[1].DressageMovementScores, update.Eventclass.Dressagetest, update.drscores[1].penalty) : 0,
      drmovescore3: update.Eventclass.numjudges > 2 ?  getPercentage(update.drscores[2].DressageMovementScores, update.Eventclass.Dressagetest, update.drscores[2].penalty) : 0,
      drstatus: update.drstatus,
    };

    data['drgoodpoints'] = data.drmovescore + (update.Eventclass.numjudges > 1 ? ` / ${data.drmovescore2 }` : "") + (update.Eventclass.numjudges > 2 ? ` / ${data.drmovescore3 }` : "")
    axios.put("/admin/entrantresults/dressage?publish="+publish, data).then(response => {
        if (response.data.error) {
          enqueueSnackbar(response.data.message, { variant: 'error'})
          return;
        }
        enqueueSnackbar("Dressage results updated", { variant: 'success'});
        onStatusChange(response.data.result);
        setChanged(false);
        if(update != null) {
          setEntry(update);
        }
      })
      .catch(error => {
        setError("Error saving scores: " + error.message);
      });        

  }

  if(id < 9080) {
    return (
      <Panel><Alert severity="error">Scoring tool does not support older events </Alert></Panel>
    )
  }
  return (
    <>
      <SelectRider 
        id={id} 
        phase="Dressage" 
        onSelect={(e) => entrySelected(e)} 
        changed={changed} 
        onChange={() => onSave(0)}
        onChangeAndPublish={() => onSave(1)}
        onCancel={onCancel}
        onDns={onDns}
        >
      {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}

      {entry && entry.Eventclass && 
          <DrSummary 
            scores={entry.drscores} 
            test={entry.Eventclass.Dressagetest} 
            status={entry.drstatus}           
          />}      
      </SelectRider>
      {entry && entry.Eventclass && 
        <DrJudgeMovements 
          onScoreChange={(m, v) => onScoreChange(0, m, v)} 
          scores={entry.drscores[0]} 
          test={entry.Eventclass.Dressagetest} 
          judgename={entry.Eventclass.judge1} 
          onPenaltyChange={(p) => onChangeDrPenalty(0, p)}
          onDrReasonChange={(r) => onDrReasonChange(0, r)}
          onChangeStatus={(s) => onChangeStatus(0, s)}
          onCommentChange={(c) => onCommentChange(0, c)}
          space={space}
          onSpaceChanged={(space) => setSpace(space)}
          onOtherErrorChange={(p) => onChangeDrOtherError(0, p)}
        />}

      {entry && entry.Eventclass && entry.Eventclass.judge2 != "" &&
        <DrJudgeMovements 
          onScoreChange={(m, v) => onScoreChange(1, m, v)} 
          scores={entry.drscores[1]} 
          test={entry.Eventclass.Dressagetest} 
          judgename={entry.Eventclass.judge2} 
          onPenaltyChange={(p) => onChangeDrPenalty(1, p)}
          onDrReasonChange={(r) => onDrReasonChange(1, r)}
          onChangeStatus={(s) => onChangeStatus(1, s)}
          onCommentChange={(c) => onCommentChange(1, c)}
          space={space}
          onSpaceChanged={(space) => setSpace(space)}
          onOtherErrorChange={(p) => onChangeDrOtherError(1, p)}
        />}

      {entry && entry.Eventclass && entry.Eventclass.judge3 != "" &&
        <DrJudgeMovements 
          onScoreChange={(m, v) => onScoreChange(2, m, v)} 
          scores={entry.drscores[2]} 
          test={entry.Eventclass.Dressagetest} 
          judgename={entry.Eventclass.judge3} 
          onPenaltyChange={(p) => onChangeDrPenalty(2, p)}
          onDrReasonChange={(r) => onDrReasonChange(2, r)}
          onChangeStatus={(s) => onChangeStatus(2, s)}
          onCommentChange={(c) => onCommentChange(2, c)}
          space={space}
          onSpaceChanged={(space) => setSpace(space)}
          onOtherErrorChange={(p) => onChangeDrOtherError(2, p)}
        />}
        <ResponsiveDialog
          title="Please Confirm Publishing"
          open={confirm}
          onPrimaryClick={() => confirmSave(1, entry)}
          onCloseClick={() => setConfirm(false)}
          primaryButtonLabel="Confirm"
          error={warning && warning.join(", ")}
          loading={false}
        />
    </>
  )
}