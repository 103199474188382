import * as React from 'react';
import { MetaTable } from '../table/MetaTable';


export default function EventClassResults(props:any) {
    return (
        <MetaTable type="eventclassresults" 
            canCreate={false}
            title={props.name}
            dataUrl={`/admin/eventclass/${props.id}/results`}
    />
    )
}