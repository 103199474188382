import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import { CloneEventForm } from "../../app/form/Definitions/CloneEventForm";
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import Title from "../Title";
import Description from "../Description";
import { useSnackbar } from 'notistack';
import Primary from "../../components/buttons/Primary";
import { Alert } from '@mui/material';
import axios from "axios";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Back from '../../components/buttons/Back';


export default function CloneEvent() {
    const { id } = useParams();
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState();
    const [event, setEvent] = React.useState();
    const { enqueueSnackbar } = useSnackbar();
    const [cloning, setCloning] = React.useState(false);
    const [cloned, setCloned] = React.useState(false);


    React.useEffect(() => {
        getData();
    }, []);

    const validate = () => {
        const errors = [];

        // make sure name, event date open date andf closing date are set
        if (!form.name || form.name == "") {
            errors.push("Please specify a name for this event");
        }
        if (form.name == event.name) {
            errors.push("Please change the name of the event");
        }
        if (!form.startdate || form.startdate == "") {
            errors.push("Please specify a date for this event");
        }
        if (!form.eventenddate || form.eventenddate == "") {
            form.eventenddate = form.startdate;
        }
        if (!form.openingdate || form.openingdate == "") {
            errors.push("Please specify an opening date for this event");
        }
        if (!form.closingdate || form.closingdate == "") {
            errors.push("Please specify a closing date for this event");
        }

        return errors;
    }

    async function getData() {
        setError();
        axios.get('/admin/event/id/' + id)
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return enqueueSnackbar(response.data.message, { variant: 'error' });
                }
                initialise(response.data.record);
                // setEvent(response.data.record);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error' })
            });
    }


    const updateForm = (updates) => {
        setError("");
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };


    const createClone = () => {
        setError();
        console.log("Create clone", form);
        const errors = validate();
        if (errors.length > 0) {
            setError(errors.join(", "));
            return;
        }
        setCloning(true);
        axios.post("/admin/event/clone/" + id, form )
            .then(response => {
                if (response.data.error) {
                    setCloning(false);
                    return setError("Problem cloning event: " + response.data.message)
                }
                enqueueSnackbar("Event cloned: ", { variant: 'success' });
                setCloned(true);
            })
            .catch(error => {
                setCloning(false);
                setError("Error cloning event: " + error);
            });
    }

    // const selectClassType = () => {
    //     if(form['classtypeid'] == null) {
    //         return setError("Please select class type to use");
    //     }

    //     // fetch the class type
    //     axios.get("/admin/classtype/id/"+form['classtypeid'])
    //     .then(response => {
    //         console.debug(response);
    //         if (response.data.error) {
    //             return setError("Problem getting class type: " + response.data.error)
    //         } 

    //         initialise(response.data.record);
    //     })
    //     .catch(error => {
    //         setError("Error getting class type: " + error);
    //     });

    // }

    const initialise = (event) => {
        // fetch the event details to pick up any defaults

        let initialState = {
            name: event.name,
            closingdate: event.closingdate,
            startdate: event.startdate,
            eventenddate: event.eventenddate,
            openingdate: event.openingdate,
            applclosedate: event.applclosedate,
        };
        setForm(initialState);
        setEvent(event);

    };

    return (
        <><Panel>
            <Title>Clone event {event && event.name}</Title>
            <Description>Please change any values below before confirming the creation of the cloned event. Note: fields marked with an * are mandatory.</Description>
            {event && <FormBuilder fields={CloneEventForm} form={form} updateForm={(updates) => updateForm(updates)} />}
            {error && <Alert severity="error" sx={{ mt: 1 }}>{error}</Alert>}
            {<Primary disabled={cloning} sx={{ mt: 2 }} startIcon={<ContentCopyIcon />} title={"Create New Event"} onClick={() => { createClone() }} />}
           
        </Panel>
         {cloned && <Back />}
         </>
    )
}