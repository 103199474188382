export const DisciplineSettings = [
    {
      label: "No. of Arenas/ Tracks",
      attribute: "numberofarenas",
        component: "text-field",
        props: {
          type: "number"
        },
        validationType: "number",
        validations: {
          required: "Please enter the number of arenas for this discipline",
        },
    },
    {
        label: "Optimum Time (Minutes)",
        attribute: "optimimumtime",
        component: "text-field",
        props: {
          type: "number"
        },
        validationType: "number",
        validations: {
          required: "Please enter the optimum time needed for a multiple rider to make it from competiting on one horse to the next",
        },
    },{
      "attribute": "breakafterclass",
      "label": "Break After Class",
      "component": "text-field",
      "validationType":"number",
      "validations": {
          "required": "Please indicate the default number of minutes break to take after each class, if there is no break then enter zero"
      },
      "props": {
          "type": "number"
      }
  },
  {
      "attribute": "breakeverynriders",
      "label": "Break Every N Riders",
          "component": "text-field",
          "validationType":"number",
          "validations": {
              "required": "Please indicate the default value for the number of riders that need to compete before a break is talen, enter zero if this does not apply"
          }
  },
  {
      "attribute": "minutesinriderbreak",
      "label": "Minutes In Rider Break",
          "component": "text-field",
          "validationType":"number",
          "validations": {
              "required": "If there is a break taken after every N riders, please enter the number of minutes ot take in the break"
          }
  },
  {
      "attribute": "minutesperrider",
      "label": "Minutes Per Rider To Compete",
          "component": "text-field",
          "validationType":"number",
          "validations": {
              "required": "Please indicate the number of minutes that each rider on average should take to compete, use .5 to indicate half a minute (30 seconds)."
          },
        "defaultValue": "6",
        "TIME_FORMAT": "mm:ss"
  },
  {
      "attribute": "starttime",
      "label": "Default Start Time (HH:MM)",
      "component": "time-picker",
      "defaultValue": "09:00",
      "TIME_FORMAT": "HH:mm"

  }


];