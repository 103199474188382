import * as React from 'react';
import {
    useNavigate, useLocation,
} from 'react-router';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet } from "react-router";
import { GlobalStyles } from '../theme';
import { useAuth } from '../auth/AuthProvider';
import { withSnackbar } from 'notistack';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser, selectToken } from "../app/loginSlice";
import LogoutIcon from '@mui/icons-material/Logout';
import axios from 'axios';
import { Container, ListSubheader, Collapse } from '@mui/material';
import useLocalStorage from '../app/useLocalStorage';
import Tooltip from '@mui/material/Tooltip';
import { hasPermission } from "../utils";
import { menus } from '../util/menu';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import EiBreadcrumbs from '../components/EiBreadcrumbs';

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://eventingireland.com/">
                Eventing Ireland
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

function getSelectedIndex(location: any) {
    if(location.pathname == "/") {
        return "dashboard";
    }
    return location.pathname.substr(1);
}


function Layout(props: any) {
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = React.useState(getSelectedIndex(location));
    const [open, setOpen] = useLocalStorage(true);
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);    
    const [openMenu, setMenuOpen] = useLocalStorage("submenu");

    console.debug(location);
    let navigate = useNavigate();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    let auth = useAuth();
    axios.defaults.headers.common['x-access-token'] = token;

    const logout = () => {
        auth.signout(user.id, () => {
            console.debug("Logged out");
            navigate("/login");
        });
    };

    const settings = () => {
        navigate("/settings", { replace: true });
    };

    const handleListItemClick = (event: any, index: React.SetStateAction<string>) => {
        setSelectedIndex(index);
    };

    const handleSubListItemClick = (event: any, index: React.SetStateAction<string>) => {
        setSelectedIndex(index);
        navigate("/"+index);
        navigate(0);
    };

    const handleOpenClick = () => {
        setMenuOpen(!openMenu);
    };

    const submenus = (menu:any, submenus:any) => {
        return (
            <React.Fragment key={'submenu'}>
            <ListItem key={menu.id+"-sub"} button
                selected={selectedIndex === menu.id}
                onClick={handleOpenClick}
            >
                <ListItemIcon>
                    {menu.icon(selectedIndex)}
                </ListItemIcon>
                <ListItemText primary={" "+menu.name} />
                {openMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse key={menu.id+"-collapse"}  in={openMenu} timeout="auto" unmountOnExit>
                <List key={menu.id+"-submenulist"} component="div" disablePadding>
                {submenus.map((s:any, index:number) => {
                    return (
                        <ListItem key={s.id+"-"+index} button component={RouterLink} to={'/'+s.id}
                            selected={selectedIndex === s.id}
                            onClick={(event: any) => handleSubListItemClick(event, s.id)}
                            sx={{ pl: 4 }}
                        >
                            <ListItemIcon>
                                {s.icon(selectedIndex)}
                            </ListItemIcon>
                            <ListItemText primary={" "+s.name} />
                        </ListItem>
                    )
                })}
                </List>
            </Collapse>
            </React.Fragment>
        )
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={open} color="secondary" style={GlobalStyles.appbar}>
                <Toolbar variant="dense"
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        EI Admin
                    </Typography>
                    <EiBreadcrumbs />
                                       
                    

                    <Typography
                        component="div"
                        variant="subtitle1"
                        color="inherit"
                        noWrap
                        align="right"
                        sx={{ flexGrow: 1 }}
                    >
                        {user && user.name } | 
                    </Typography>
                   
                    <IconButton color="inherit" onClick={logout} size="large">
                        <LogoutIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar variant="dense"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer} size="large">
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar >
                <Divider />
                <List key={"mainlist"}>
                        {menus.map(menu => {
                            if(user && hasPermission(user.permissions, menu.permission)) {
                                if(menu.submenus && menu.submenus.length > 0) {
                                    return submenus(menu, menu.submenus);
                                } else {
                                    return (
                                        <ListItem key={menu.id} button component={RouterLink} 
                                            to={'/'+(menu.id == 'dashboard' ? '' : menu.id)}
                                            selected={selectedIndex === menu.id}
                                            onClick={(event: any) => handleListItemClick(event, menu.id)}
                                        >
                                            <ListItemIcon>
                                                {menu.icon(selectedIndex)}
                                            </ListItemIcon>
                                            <ListItemText primary={" "+menu.name} />
                                        </ListItem>
                                    )    
                                }
                            }
                        })}
                   
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    marginLeft: 0,
                    paddingLeft: 0
                }}
            >
                <Toolbar />

                <Container maxWidth="lg" sx={{ mt: 1, mb: 4 }} style={{maxWidth: '100vw'}}>
                    <Outlet />
                </Container>

            </Box>

        </Box>
    );
}

// const mapStateToProps = (state:any) => ({
//     message: state.message.message
//   });

// const Layout = connect(mapStateToProps)(withSnackbar(layout))
export { Copyright };
export default withSnackbar(Layout)