export const green = {
    50: "#dbece2",
    100: "#a7d0b8",
    200: "#6eb18b",
    300: "#2a9461",
    400: "#008044",
    500: "#006c27",
    600: "#006020",
    700: "#005116",
    800: "#00410b",
    900: "#002700"
  };