// type MetaField = { key: string, value: any} ;

export const all:any = {
    rating: [
        {
            id: 'E',
            name: 'E',
            bgcolour: 'rgba(239,124,124,1)'
        }, 
        {
            id: 'D',
            name: 'D',
            bgcolour: 'rgba(255,188,71,0.8)'
        }, 
        {
            id: 'C',
            name: 'C',
            bgcolour: 'rgba(97,202,49,0.15)'
        }, 
        {
            id: 'B',
            name: 'B',
            bgcolour: 'rgba(97,202,49,0.3)'
        }, 
        {
            id: 'A',
            name: 'A',
            bgcolour: 'rgba(97,202,49,0.5)'
        },
        {
            id: 'A+',
            name: 'A+',
            bgcolour: 'rgba(97,202,49,0.65)'
        },
        {
            id: 'A++',
            name: 'A++',
            bgcolour: 'rgba(97,202,49,0.8)'
        } ,
        {
            id: 'Unknown',
            name: 'Unk',
            bgcolour: 'lightgrey'            
        }        
      ],
      refunded: [
        {
            id: 0,
            name: "Paid",
            colour: "success",
            variant: "outlined"
        },
        {
            id: 1,
            name: "Refunded",
            colour: "error",
            variant: "outlined"
        }
    ],
    horsetype: [
        {
            id: 1,
            name: "Horse",
            colour: "success",
            variant: "outlined"
        },
        {
            id: 2,
            name: "Pony",
            colour: "error",
            variant: "outlined"
        }
    ],
    mer: [
        {
            id: 0,
            name: "No"
        },
        {
            id: 1,
            name: "Yes"
        },
    ],
    refundstatus: [
        {
            id: "Not Refunded",
            name: "Not Refunded",
            colour: "warning",
            variant: "outlined"
        },
        {
            id: "Refunding",
            name: "Refunding",
            colour: "success",
            variant: "outlined"
        },
        {
            id: "Refunded",
            name: "Refunded",
            colour: "success" 
        },
        {
            id: "Failed",
            name: "Failed",
            colour: "error" 
        }
    ],
    result: [
        {
            id: "notchecked",
            name: "Not Checked Yet",
            colour: "warning",
            variant: "outlined"
        },
        {
            id: "ok",
            name: "Good",
            colour: "success" 
        },
        {
            id: "notok",
            name: "Problem",
            colour: "error" 
        }
    ],
    disciplinestatus: [
        {
            id: "OK",
            name: "OK"
        },
        {
            id: "DNS",
            name: "DNS"
        },
        {
            id: "WD",
            name: "WD"
        },
        {
            id: "EL",
            name: "EL"
        },
        {
            id: "TEL",
            name: "TEL"
        },
        {
            id: "RET",
            name: "RET"
        },
        {
            id: "ELHI",
            name: "ELHI"
        }
    ],
    disciplineid: [
        {
            id: 1,
            name: "Show Jumping"
        },
        {
            id: 2,
            name: "Dressage"
        },
        {
            id: 3,
            name: "Cross Country"
        }
    ],
    competitiontypeid: [
        {
            id: 1,
            name: "Lowest Total With 3 Counting"
        }
    ],
    currency: [
        {
            id: 1,
            name: "Euro"
        },
        {
            id: 2,
            name: "Sterling"
        }
    ],
    reasongiven: [
        {
            id: -1,
            name: "Unknown"
        },
        {
            id: 2,
            name: "Was Ill"
        },
        {
            id: 3,
            name: "Other"
        }
    ],
    athletetype: [
        {
            id: "NC",
            name: "NC"
        },
        {
            id: "D",
            name: "D"
        },
        {
            id: "C",
            name: "C"
        },
        {
            id: "B",
            name: "B"
        },
        {
            id: "A",
            name: "A"
        }
    ],
    height: [
        {
            id: "138",
            name: "138"
        },
        {
            id: "139",
            name: "139"
        },
        {
            id: "140",
            name: "140"
        },
        {
            id: "141",
            name: "141"
        },
        {
            id: "142",
            name: "142"
        },
        {
            id: "143",
            name: "143"
        },
        {
            id: "144",
            name: "144"
        },
        {
            id: "145",
            name: "145"
        },
        {
            id: "146",
            name: "146"
        },
        {
            id: "147",
            name: "147"
        },
        {
            id: "148",
            name: "148"
        },
        {
            id: "149",
            name: "149"
        },
        {
            id: "150",
            name: "150"
        },
        {
            id: "151",
            name: "151"
        },
        {
            id: "152",
            name: "152"
        },
        {
            id: "153",
            name: "153"
        },
        {
            id: "154",
            name: "154"
        },
        {
            id: "155",
            name: "155"
        },
        {
            id: "156",
            name: "156"
        },
        {
            id: "157",
            name: "157"
        },
        {
            id: "158",
            name: "158"
        },
        {
            id: "159",
            name: "159"
        },
        {
            id: "160",
            name: "160"
        },
        {
            id: "161",
            name: "161"
        },
        {
            id: "162",
            name: "162"
        },
        {
            id: "163",
            name: "163"
        },
        {
            id: "164",
            name: "164"
        },
        {
            id: "165",
            name: "165"
        },
        {
            id: "166",
            name: "166"
        },
        {
            id: "167",
            name: "167"
        },
        {
            id: "168",
            name: "168"
        },
        {
            id: "169",
            name: "169"
        },
        {
            id: "170",
            name: "170"
        },
        {
            id: "171",
            name: "171"
        },
        {
            id: "172",
            name: "172"
        },
        {
            id: "173",
            name: "173"
        },
        {
            id: "174",
            name: "174"
        },
        {
            id: "175",
            name: "175"
        },
        {
            id: "176",
            name: "176"
        },
        {
            id: "177",
            name: "177"
        },
        {
            id: "178",
            name: "178"
        },
        {
            id: "179",
            name: "179"
        },
        {
            id: "180",
            name: "180"
        },
        {
            id: "181",
            name: "181"
        },
        {
            id: "182",
            name: "182"
        }
    ],
    colour: [
        {
            id: "Albino",
            name: "Albino"
        },{
            id: "Appaloosa",
            name: "Appaloosa"
        },
        {
            id: "Bay",
            name: "Bay"
        },
        {
            id: "Black",
            name: "Black"
        },
        {
            id: "Blue Roan",
            name: "Blue Roan "
        },
        {
            id: "Brown",
            name: "Brown"
        },
        {
            id: "Buckskin",
            name: "Buckskin"
        },
        {
            id: "Chestnut",
            name: "Chestnut"
        },
        {
            id: "Cream",
            name: "Cream"
        },
        {
            id: "Dun",
            name: "Dun"
        },
        {
            id: "Grey",
            name: "Grey"
        },
        {
            id: "Grey Roan",
            name: "Grey Roan"
        },
        {
            id: "Liver Chestnut",
            name: "Liver Chestnut"
        },
        {
            id: "Piebald",
            name: "Piebald"
        },
        {
            id: "Palomino",
            name: "Palomino"
        },
        {
            id: "Skewbald",
            name: "Skewbald"
        },
        {
            id: "Strawberry Roan",
            name: "Strawberry Roan"
        },
        {
            id: "UNKNWON",
            name: "UNKNOWN"
        }
    ],
    title: [
        {
            id: "",
            name: ""
        },
        {
            id: "Ms",
            name: "Ms"
        },
        {
            id: "Miss",
            name: "Miss"
        },
        {
            id: "Mrs",
            name: "Mrs"
        },
        {
            id: "Mr",
            name: "Mr"
        }
    ],
    orderstatus: [
        {
            id: 1,
            name: "Completed"
        },
        {
            id: 2,
            name: "Pending"
        },
        {
            id: 3,
            name: "Cancelled"
        },
        {
            id: 4,
            name: "Created"
        },
        {
            id: 5,
            name: "Received"
        },
        {
            id: 6,
            name: "CC Pending"
        },
        {
            id: 7,
            name: "Deleted"
        }
    ],
    contactpreferences: [
        {
            id: "",
            name: "None"
        },
        {
            id: "EMail",
            name: "EMail"
        },
        {
            id: "Phone",
            name: "Phone"
        }
    ],
    testtype: [
        {
            id: 1,
            name: "Summary Only"
        },
        {
            id: 2,
            name: "Detail Movements"
        }
    ],
    userstatus: [
        {
            id: 0,
            name: "Active"
        },
        {
            id: 1,
            name: "Blocked"
        }
    ],
    county: [{
            id: 1,
            name: "Antrim"
        },
        {
            id: 2,
            name: "Armagh"
        },
        {
            id: 3,
            name: "Carlow"
        },
        {
            id: 4,
            name: "Cavan"
        },
        {
            id: 5,
            name: "Clare"
        },
        {
            id: 6,
            name: "Cork"
        },
        {
            id: 7,
            name: "Derry"
        },
        {
            id: 8,
            name: "Donegal"
        },
        {
            id: 9,
            name: "Down"
        },
        {
            id: 10,
            name: "Dublin"
        },
        {
            id: 11,
            name: "Fermanagh"
        },
        {
            id: 12,
            name: "Galway"
        },
        {
            id: 13,
            name: "Kerry"
        },
        {
            id: 14,
            name: "Kildare"
        },
        {
            id: 15,
            name: "Kilkenny"
        },
        {
            id: 16,
            name: "Laois"
        },
        {
            id: 17,
            name: "Leitrim"
        },
        {
            id: 18,
            name: "Limerick"
        },
        {
            id: 19,
            name: "Longford"
        },
        {
            id: 20,
            name: "Louth"
        },
        {
            id: 21,
            name: "Mayo"
        },
        {
            id: 22,
            name: "Meath"
        },
        {
            id: 23,
            name: "Monaghan"
        },
        {
            id: 24,
            name: "Offaly"
        },
        {
            id: 25,
            name: "Roscommon"
        },
        {
            id: 26,
            name: "Sligo"
        },
        {
            id: 27,
            name: "Tipperary"
        },
        {
            id: 28,
            name: "Tyrone"
        },
        {
            id: 29,
            name: "Waterford"
        },
        {
            id: 30,
            name: "Westmeath"
        },
        {
            id: 31,
            name: "Wexford"
        },
        {
            id: 32,
            name: "Wicklow"
        }
    ],
    country: [
        {
            id: 3,
            name: "Afghanistan"
        }, 
        {
            id: 6,
            name: "Albania"
        }, 
        {
            id: 58,
            name: "Algeria"
        }, {
            id: 12,
            name: "American Samoa"
        }, {
            id: 1,
            name: "Andorra"
        }, {
            id: 9,
            name: "Angola"
        }, {
            id: 5,
            name: "Anguilla"
        }, {
            id: 10,
            name: "Antarctica"
        }, {
            id: 4,
            name: "Antigua and Barbuda"
        }, {
            id: 11,
            name: "Argentina"
        }, {
            id: 7,
            name: "Armenia"
        }, {
            id: 15,
            name: "Aruba"
        }, {
            id: 14,
            name: "Australia"
        }, {
            id: 13,
            name: "Austria"
        }, {
            id: 16,
            name: "Azerbaijan"
        }, {
            id: 30,
            name: "Bahamas"
        }, {
            id: 23,
            name: "Bahrain"
        }, {
            id: 19,
            name: "Bangladesh"
        }, {
            id: 18,
            name: "Barbados"
        }, {
            id: 34,
            name: "Belarus"
        }, {
            id: 20,
            name: "Belgium"
        }, {
            id: 35,
            name: "Belize"
        }, {
            id: 25,
            name: "Benin"
        }, {
            id: 26,
            name: "Bermuda"
        }, {
            id: 31,
            name: "Bhutan"
        }, {
            id: 28,
            name: "Bolivia"
        }, {
            id: 17,
            name: "Bosnia and Herzegovina"
        }, {
            id: 33,
            name: "Botswana"
        }, {
            id: 32,
            name: "Bouvet Island"
        }, {
            id: 29,
            name: "Brazil"
        }, {
            id: 99,
            name: "British Indian Ocean Territory"
        }, {
            id: 226,
            name: "British Virgin Islands"
        }, {
            id: 27,
            name: "Brunei Darussalam"
        }, {
            id: 22,
            name: "Bulgaria"
        }, {
            id: 21,
            name: "Burkina Faso"
        }, {
            id: 24,
            name: "Burundi"
        }, {
            id: 109,
            name: "Cambodia"
        }, {
            id: 44,
            name: "Cameroon"
        }, {
            id: 36,
            name: "Canada"
        }, {
            id: 49,
            name: "Cape Verde"
        }, {
            id: 116,
            name: "Cayman Islands"
        }, {
            id: 38,
            name: "Central African Republic"
        }, {
            id: 202,
            name: "Chad"
        }, {
            id: 43,
            name: "Chile"
        }, {
            id: 45,
            name: "China"
        }, {
            id: 50,
            name: "Christmas Island"
        }, {
            id: 37,
            name: "Cocos"
        }, {
            id: 46,
            name: "Colombia"
        }, {
            id: 111,
            name: "Comoros"
        }, {
            id: 39,
            name: "Congo - Brazzaville"
        }, {
            id: 1673,
            name: "Congo-Kinshasa"
        }, {
            id: 42,
            name: "Cook Islands"
        }, {
            id: 47,
            name: "Costa Rica"
        }, {
            id: 92,
            name: "Croatia"
        }, {
            id: 48,
            name: "Cuba"
        }, {
            id: 51,
            name: "Cyprus"
        }, {
            id: 52,
            name: "Czech Republic"
        }, {
            id: 55,
            name: "Denmark"
        }, {
            id: 54,
            name: "Djibouti"
        }, {
            id: 56,
            name: "Dominica"
        }, {
            id: 57,
            name: "Dominican Republic"
        }, {
            id: 211,
            name: "East Timor"
        }, {
            id: 59,
            name: "Ecuador"
        }, {
            id: 61,
            name: "Egypt"
        }, {
            id: 198,
            name: "El Salvador"
        }, {
            id: 82,
            name: "Equatorial Guinea"
        }, {
            id: 63,
            name: "Eritrea"
        }, {
            id: 60,
            name: "Estonia"
        }, {
            id: 65,
            name: "Ethiopia"
        }, {
            id: 68,
            name: "Falkland Islands"
        }, {
            id: 70,
            name: "Faroe Islands"
        }, {
            id: 67,
            name: "Fiji"
        }, {
            id: 66,
            name: "Finland"
        }, {
            id: 71,
            name: "France"
        }, {
            id: 75,
            name: "French Guiana"
        }, {
            id: 165,
            name: "French Polynesia"
        }, {
            id: 203,
            name: "French Southern Territories"
        }, {
            id: 72,
            name: "Gabon"
        }, {
            id: 79,
            name: "Gambia"
        }, {
            id: 74,
            name: "Georgia"
        }, {
            id: 53,
            name: "Germany"
        }, {
            id: 76,
            name: "Ghana"
        }, {
            id: 77,
            name: "Gibraltar"
        }, {
            id: 83,
            name: "Greece"
        }, {
            id: 78,
            name: "Greenland"
        }, {
            id: 73,
            name: "Grenada"
        }, {
            id: 81,
            name: "Guadeloupe"
        }, {
            id: 86,
            name: "Guam"
        }, {
            id: 85,
            name: "Guatemala"
        }, {
            id: 80,
            name: "Guinea"
        }, {
            id: 87,
            name: "Guinea-Bissau"
        }, {
            id: 88,
            name: "Guyana"
        }, {
            id: 93,
            name: "Haiti"
        }, {
            id: 90,
            name: "Heard and McDonald Islands"
        }, {
            id: 91,
            name: "Honduras"
        }, {
            id: 89,
            name: "Hong Kong"
        }, {
            id: 94,
            name: "Hungary"
        }, {
            id: 102,
            name: "Iceland"
        }, {
            id: 98,
            name: "India"
        }, {
            id: 95,
            name: "Indonesia"
        }, {
            id: 101,
            name: "Iran"
        }, {
            id: 100,
            name: "Iraq"
        }, {
            id: 97,
            name: "Israel"
        }, {
            id: 103,
            name: "Italy"
        }, {
            id: 41,
            name: "Ivory Coast"
        }, {
            id: 104,
            name: "Jamaica"
        }, {
            id: 106,
            name: "Japan"
        }, {
            id: 105,
            name: "Jordan"
        }, {
            id: 117,
            name: "Kazakhstan"
        }, {
            id: 107,
            name: "Kenya"
        }, {
            id: 110,
            name: "Kiribati"
        }, {
            id: 115,
            name: "Kuwait"
        }, {
            id: 108,
            name: "Kyrgyzstan"
        }, {
            id: 118,
            name: "Laos"
        }, {
            id: 127,
            name: "Latvia"
        }, {
            id: 119,
            name: "Lebanon"
        }, {
            id: 124,
            name: "Lesotho"
        }, {
            id: 123,
            name: "Liberia"
        }, {
            id: 128,
            name: "Libya"
        }, {
            id: 121,
            name: "Liechtenstein"
        }, {
            id: 125,
            name: "Lithuania"
        }, {
            id: 126,
            name: "Luxembourg"
        }, {
            id: 138,
            name: "Macau"
        }, {
            id: 134,
            name: "Macedonia"
        }, {
            id: 132,
            name: "Madagascar"
        }, {
            id: 146,
            name: "Malawi"
        }, {
            id: 148,
            name: "Malaysia"
        }, {
            id: 145,
            name: "Maldives"
        }, {
            id: 135,
            name: "Mali"
        }, {
            id: 143,
            name: "Malta"
        }, {
            id: 133,
            name: "Marshall Islands"
        }, {
            id: 140,
            name: "Martinique"
        }, {
            id: 141,
            name: "Mauritania"
        }, {
            id: 144,
            name: "Mauritius"
        }, {
            id: 233,
            name: "Mayotte"
        }, {
            id: 147,
            name: "Mexico"
        }, {
            id: 69,
            name: "Micronesia"
        }, {
            id: 131,
            name: "Moldova"
        }, {
            id: 130,
            name: "Monaco"
        }, {
            id: 137,
            name: "Mongolia"
        }, {
            id: 1674,
            name: "Montenegro"
        }, {
            id: 142,
            name: "Montserrat"
        }, {
            id: 129,
            name: "Morocco"
        }, {
            id: 149,
            name: "Mozambique"
        }, {
            id: 136,
            name: "Myanmar"
        }, {
            id: 150,
            name: "Namibia"
        }, {
            id: 159,
            name: "Nauru"
        }, {
            id: 158,
            name: "Nepal"
        }, {
            id: 156,
            name: "Netherlands"
        }, {
            id: 8,
            name: "Netherlands Antilles"
        }, {
            id: 151,
            name: "New Caledonia"
        }, {
            id: 161,
            name: "New Zealand"
        }, {
            id: 152,
            name: "Niger"
        }, {
            id: 154,
            name: "Nigeria"
        }, {
            id: 160,
            name: "Niue"
        }, {
            id: 1118,
            name: "No Country Given"
        }, {
            id: 153,
            name: "Norfolk Island"
        }, {
            id: 113,
            name: "North Korea"
        }, {
            id: 139,
            name: "Northern Mariana Islands"
        }, {
            id: 157,
            name: "Norway"
        }, {
            id: 162,
            name: "Oman"
        }, {
            id: 168,
            name: "Pakistan"
        }, {
            id: 174,
            name: "Palau"
        }, {
            id: 163,
            name: "Panama"
        }, {
            id: 166,
            name: "Papua New Guinea"
        }, {
            id: 175,
            name: "Paraguay"
        }, {
            id: 164,
            name: "Peru"
        }, {
            id: 167,
            name: "Philippines"
        }, {
            id: 171,
            name: "Pitcairn"
        }, {
            id: 169,
            name: "Poland"
        }, {
            id: 173,
            name: "Portugal"
        }, {
            id: 172,
            name: "Puerto Rico"
        }, {
            id: 176,
            name: "Qatar"
        }, {
            id: 177,
            name: "Reunion"
        }, {
            id: 178,
            name: "Romania"
        }, {
            id: 179,
            name: "Russian Federation"
        }, {
            id: 180,
            name: "Rwanda"
        }, {
            id: 84,
            name: "S. Georgia and S. Sandwich Islands"
        }, {
            id: 112,
            name: "Saint Kitts and Nevis"
        }, {
            id: 120,
            name: "Saint Lucia"
        }, {
            id: 224,
            name: "Saint Vincent and The Grenadines"
        }, {
            id: 231,
            name: "Samoa"
        }, {
            id: 192,
            name: "San Marino"
        }, {
            id: 196,
            name: "Sao Tome and Principe"
        }, {
            id: 181,
            name: "Saudi Arabia"
        }, {
            id: 193,
            name: "Senegal"
        }, {
            id: 1675,
            name: "Serbia"
        }, {
            id: 183,
            name: "Seychelles"
        }, {
            id: 191,
            name: "Sierra Leone"
        }, {
            id: 186,
            name: "Singapore"
        }, {
            id: 190,
            name: "Slovakia"
        }, {
            id: 188,
            name: "Slovenia"
        }, {
            id: 182,
            name: "Solomon Islands"
        }, {
            id: 194,
            name: "Somalia"
        }, {
            id: 235,
            name: "South Africa"
        }, {
            id: 114,
            name: "South Korea"
        }, {
            id: 197,
            name: "Soviet Union"
        }, {
            id: 64,
            name: "Spain"
        }, {
            id: 122,
            name: "Sri Lanka"
        }, {
            id: 187,
            name: "St. Helena"
        }, {
            id: 170,
            name: "St. Pierre and Miquelon"
        }, {
            id: 184,
            name: "Sudan"
        }, {
            id: 195,
            name: "Suriname"
        }, {
            id: 189,
            name: "Svalbard and Jan Mayen Islands"
        }, {
            id: 200,
            name: "Swaziland"
        }, {
            id: 185,
            name: "Sweden"
        }, {
            id: 40,
            name: "Switzerland"
        }, {
            id: 199,
            name: "Syria"
        }, {
            id: 215,
            name: "Taiwan"
        }, {
            id: 206,
            name: "Tajikistan"
        }, {
            id: 216,
            name: "Tanzania"
        }, {
            id: 205,
            name: "Thailand"
        }, {
            id: 1676,
            name: "Timor-Leste"
        }, {
            id: 204,
            name: "Togo"
        }, {
            id: 207,
            name: "Tokelau"
        }, {
            id: 210,
            name: "Tonga"
        }, {
            id: 213,
            name: "Trinidad and Tobago"
        }, {
            id: 209,
            name: "Tunisia"
        }, {
            id: 212,
            name: "Turkey"
        }, {
            id: 208,
            name: "Turkmenistan"
        }, {
            id: 201,
            name: "Turks and Caicos Islands"
        }, {
            id: 214,
            name: "Tuvalu"
        }, {
            id: 218,
            name: "Uganda"
        }, {
            id: 217,
            name: "Ukraine"
        }, {
            id: 2,
            name: "United Arab Emirates"
        }, {
            id: 219,
            name: "United Kingdom"
        }, {
            id: 221,
            name: "United States"
        }, {
            id: 1119,
            name: "UNKnown"
        }, {
            id: 222,
            name: "Uruguay"
        }, {
            id: 220,
            name: "US Minor Outlying Islands"
        }, {
            id: 227,
            name: "US Virgin Islands"
        }, {
            id: 223,
            name: "Uzbekistan"
        }, {
            id: 229,
            name: "Vanuatu"
        }, {
            id: 225,
            name: "Venezuela"
        }, {
            id: 228,
            name: "Viet Nam"
        }, {
            id: 230,
            name: "Wallis and Futuna Islands"
        }, {
            id: 62,
            name: "Western Sahara"
        }, {
            id: 232,
            name: "Yemen"
        }, {
            id: 234,
            name: "Yugoslavia"
        }, {
            id: 237,
            name: "Zaire"
        }, {
            id: 236,
            name: "Zambia"
        }, {
            id: 238,
            name: "Zimbabwe"
        }, {
            id: 1121,
            name: "Northern Ireland"
        }, {
            id: 96,
            name: "Ireland"
        }
    ],
    status: [
        {
            id: "WITHDRAWN",
            name: "WITHDRAWN"
        },
        {
            id: "ENTRYCOMPLETED",
            name: "ENTRYCOMPLETED"
        },
        {
            id: "ENTRYPENDING",
            name: "ENTRYPENDING"
        },
        {
            id: "APPROVALCOMPLETED",
            name: "Appl Completed"
        },
        {
            id: "APPROVALPENDING",
            name: "Appl Pending"
        },
        {
            id: "ENTRYREFUNDED",
            name: "Refunded"
        },
        {
            id: "MANUAL",
            name: "Manual"
        }
    ],
    jobstatus: [
        {
            id: "loading",
            name: "Loading",
            colour: "warning"
        },
        {
            id: "reconciling",
            name: "Reconciling",
            colour: "warning"
        },
        {
            id: "finished",
            name: "Finished",
            colour: "success"
        }
    ],
    type: [
        {
            id: "REFUND",
            name: "Refund",
            colour: "warning"
        },
        {
            id: "SALE",
            name: "Card Payment",
            colour: "success"
        }
    ],
    openstatus: [
        {
            id: "open",
            name: "Open",
            colour: "error"
        },
        {
            id: "closed",
            name: "Closed",
            colour: "success"
        }
    ],
    resultstatus: [
        {
            id: "NULL",
            name: ""
        },
        {
            id: "WITHDRAWN",
            name: "WITHDRAWN"
        },
        {
            id: "CURRENT",
            name: "CURRENT"
        },
        {
            id: "PENDING",
            name: "PENDING"
        },
        {
            id: "HC",
            name: "HC"
        }
    ],
    memberstatus: [
        {
            id: "1",
            name: "Current"
        },
        {
            id: "2",
            name: "Duplicate"
        },
        {
            id: "3",
            name: "Deceased"
        },
        {
            id: "4",
            name: "Suspennded"
        }
    ]
}