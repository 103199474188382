export const RefundWorldnetFields = [
  {
    component: 'switch',
    attribute: "sendemail",
    label: "Send EMail",
    col: {
      sm: 12,
    }
  },
  {
    attribute: "description",
    label: "EMail Text",
    col: {
      sm: 12,
    },
    validationType: "string",
    validations: {
      required: true,
      max: 1024,
    },
    props: {
      rows: "8",
      multiline: true
    }
  },
  {
    component: 'switch',
    attribute: "closeissue",
    label: "Close Issue",
    col: {
      sm: 12,
    }
  },
];