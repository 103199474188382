import * as React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, Typography } from '@mui/material';


export default function Loading(props:any) {
    return (
        <React.Fragment>
            {props.message && <Box sx={{ display: 'flex', width: '100%', position: 'relative', top: '75px', justifyContent: 'center' }}>
                <Typography>{props.message}</Typography>
            </Box>}
            <Box sx={{ display: 'flex', width: '100%', minHeight: '150px', position: 'relative', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        </React.Fragment>
    )
}