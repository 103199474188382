import * as React from 'react';
import Grid from '@mui/material/Grid';
import { MetaTable } from '../components/table/MetaTable';
import { roleIdToName, userStatusIdToName } from '../utils';
import { Paper } from '@mui/material';
import Panel from '../components/containers/Panel';
import Title from "./Title";
import Description from './Description';
import Back from '../components/buttons/Back';


export default function PageNotFound() {
  return (
    <Panel>
      <Title>Not Implemented</Title>
      <Description>This page is not implemented yet, possible future development</Description>
      <Back/>
    </Panel>
  )
}