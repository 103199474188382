import * as React from 'react';

import axios from 'axios';
import Grid from '@mui/material/Grid';

import { useSnackbar } from 'notistack';
import Description from '../../containers/Description';
import { Divider, TextField, Typography, Button, FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Save from '../../components/buttons/Save';
import FenceSetup from './FenceSetup';
import EventclassMultieselect from './EventclassMultiselect';
import LoadingButton from '@mui/lab/LoadingButton';


export default function ShowJumpingSetup(props:any) {
    const [saving, setSaving] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    const [allowedTime, setAllowedTime] = React.useState(props.eventclass.sjexptime);
    const { enqueueSnackbar } = useSnackbar();
    const [numsjjumps, setNumsjjumps] = React.useState(props.eventclass.numsjjumps);
    const [sjjumps, setSjjumps] = React.useState(props.eventclass.sjjumps);
    const [selectedClasses, setSelectedClasses] = React.useState<{id: string; name: string}[]>();
    const [sjjudge, setSjJudge] = React.useState(props.eventclass.sjjudge);
    const [sjCourseDesigner, setSjCourseDesigner] = React.useState(props.eventclass.sjcoursedesigner);

    const save = (fences:string, numfences:number, at:number, sjj:string, sjcd:string, id=props.eventclass.id) => {
        setSaving(true);
        axios.put("/admin/eventclass?setup=sj", {
            id: id,
            sjexptime: at,
            numsjjumps: numfences,
            sjjumps: fences,
            sjjudge: sjj,
            sjcoursedesigner: sjcd
        }).then(response => {
            if (response.data.error) {
                setSaving(false);
                enqueueSnackbar(response.data.message, { variant: 'error'})
                return;
            }
            enqueueSnackbar("Show jumping settings updated", { variant: 'success'})
            setSaving(false);
            setChanged(false);
        
        }).catch(error => {
            enqueueSnackbar(error, { variant: 'error'})
            console.debug(error);
        });
    }

    const changeAllowedTime = (value:string) => {
        setAllowedTime(value);
        // setTimeout(isChanged, 2000);
        isChanged({
            sjexptime: value,
            sjjudge
        });
    }

    const isChanged = (n:any) => {
        if(parseInt(props.eventclass.sjexptime) != parseInt(n.sjexptime)
            || props.eventclass.sjjudge != n.sjjudge) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }
   
    const changeSjJudge = (value:string) => {
        setSjJudge(value);
        setChanged(true);
        // isChanged({
        //     sjexptime: allowedTime,
        //     sjjudge: value,
        // });
    }

    const changeSjCourseDesigner = (value:string) => {
        setSjCourseDesigner(value);
        setChanged(true);       
    }


    const changeFences = (updatedFences:string) => {
        const fencelist = updatedFences.split(",");
        setNumsjjumps(fencelist.length);
        setSjjumps(updatedFences);   
        save(updatedFences, fencelist.length, allowedTime, sjjudge, sjCourseDesigner);
    }

    const applyToOtherClasses = () => {
        // apply the current settings in this class to the list of current selected classes.
        if(!selectedClasses ) {
            return;
        }

        selectedClasses.map((c:{ id: string; name: string }) => {
            save(sjjumps, numsjjumps, allowedTime, sjjudge, sjCourseDesigner, c.id);
        })
    }

    const selectedClassesChanged = (values:{ id: string; name: string }[]) => {
        console.log(values);
        setSelectedClasses(values);
    }

    const isSetup = () => {
        return allowedTime !="";
    }

    return (
        <>
            <Typography>
                Please provide details of the show jumping allowed time for this class.
            </Typography>
            <Grid container sx={{pb:2}} {...props}>
                <Grid item md={6}>
                    <Grid container sx={{pb:2}} {...props}>
                        <Grid item>
                            <InputLabel sx={{mt:3}} id="numjudges">Allowed Time in Seconds Show Jumping</InputLabel>
                            <TextField
                                type="number"
                                label="Seconds (ss)"
                                value={allowedTime}
                                onChange={(event) => changeAllowedTime(event.target.value)}
                                id="outlined-start-adornment"
                                sx={{ mt: 1, width: '15ch' }}
                                />
                            <FormControl fullWidth  sx={{mt:2}}>
                                <TextField id="judge1" onChange={(event) => changeSjJudge(event.target.value) } value={sjjudge} label="Name of SJ Judge" variant="standard" />
                            </FormControl> 
                            <FormControl fullWidth  sx={{mt:2}}>
                                <TextField id="sjcoursedesigner" onChange={(event) => changeSjCourseDesigner(event.target.value) } value={sjCourseDesigner} label="SJ Course Designer" variant="standard" />
                            </FormControl> 
                        </Grid>    
                        <Grid item sx={{mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Save
                                sx={{mt: 3}}
                                disabled={!changed}
                                loading={saving}
                                onClick={() => save(sjjumps, numsjjumps, allowedTime, sjjudge, sjCourseDesigner)}
                                />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Typography>
                        Click the button below to apply these Show Jumping settings to other classes.
                    </Typography>
                    <EventclassMultieselect names={props.eventclass.names} onChange={(values:any) => selectedClassesChanged(values)} />
                    {selectedClasses && selectedClasses.length > 0 && <LoadingButton loading={saving} disabled={!isSetup()} variant="contained" onClick={applyToOtherClasses}>
                        Apply These Settings To Other Classes
                    </LoadingButton>}
                </Grid>

            </Grid>
    
            <Divider sx={{mt:5, mb:2}}/>                        
            <Typography>
                Please provide details of show jumping fences for this class.
            </Typography>
            <Grid container sx={{pb:2}} {...props}>
                <Grid item xs={12}>
                        {props.description && <Description>{props.description}</Description>}
                        {props.children}
                        <FenceSetup 
                            fences={sjjumps} 
                            onChangeFences={changeFences}
                        />
                        
                </Grid>
            </Grid>
            
        </>
    )
}