import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import {Typography, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';

import CardLoading from '../../scheduler/components/CardLoading';
import { MetaTable } from '../../components/table/MetaTable';
import { reportdefinitions } from '../definitions/reportdefinitions';
// import Title from '../../containers/Title';

export default function ReportPage() {
    const { id } = useParams();
    const [report, setReport] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
        // get the details for this report 
        setIsLoading(true);
        axios.get("/admin/report/id/"+id)
            .then(response => {
            if (response.data.error) {
                enqueueSnackbar("Unable to fetch report data " + response.data.message, { variant: 'error'});
                return;
            }
            console.debug("Fetched data", response.data);
            setReport(response.data.record);
            setIsLoading(false);
        }).catch(error => {
            enqueueSnackbar("Error while fetching report data " + error, { variant: 'error'});
        });

    }, []);

    const getDateDefault = (f) => {
        const d = new Date();
        if(!f.default || f.default == 0) {
            return d;
        }
        const diff = Math.abs(f.default) * 24 * 60 * 60 * 1000;
        if(f.default < 0) {
            var day = d.getTime() - diff;
            d.setTime(day);
            return d;    
        }
        var day = d.getTime() + diff;
        d.setTime(day);
        return d;    
    
    }

    const getReportFilters = () => {
        if(reportdefinitions[id] && reportdefinitions[id].filters) {
            return reportdefinitions[id].filters;
        }
        return [];
    }

    const getDefaultFilter = () => {
        if(reportdefinitions[id] && reportdefinitions[id].filters) {
            let defaults = {};
            reportdefinitions[id].filters.map(f => {
                if(f.type == 'dateselector') {
                    defaults[f.id] = getDateDefault(f);
                }
            });
            return defaults;
        }
        return {};
    }

    const getDataUrl = () => {
        return "/admin/report/data/"+report.id;
    }

    if(isLoading) {
        return <CardLoading message="Loading report data..."/>
    }

    return (
        <Paper >
            <Typography component="h3" variant="h6" color="primary" sx={{pl:2, pt:2, mb:1}}>
                {report.title}</Typography>
            <MetaTable 
            canExport={true}
            type={report.name}
            canCreate={false}
            filters={getReportFilters()}
            dataUrl={getDataUrl()} 
            rememberSearch={report.id == 9 ? null : "report-"+report.id}
            defaultFilter={getDefaultFilter()}
        /></Paper>
    );
}

