export const reportdefinitions = {
    1: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    2: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    3: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    4: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    5: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    6: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    7: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    8: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -365
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    9: {
        filters: [
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    11: {
        filters: [
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    12: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -365
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    13: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -365
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    14: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -365
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            },
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },
    15: {
        filters: [
            {
                id: "eventid",
                type: "event",
                label: "Event"

            },
            {
                id: "status",
                type: "togglegroup",
                options: [
                    {
                        id: 'WITHDRAWN',
                        name: 'Withdrawn'
                    },
                    {
                        id: 'ENTRYCOMPLETED',
                        name: 'Completed'
                    },
                    {
                        id: 'ENTRYREFUNDED',
                        name: 'Refunded'
                    }
                ]
            }
        ]
    },
    16: {
        filters: [
            {
                id: "rideroptionid",
                type: "togglegroup",
                options: [                
                    {
                        id: "8",
                        name: "Pony"
                    },
                    {
                        id: "7",
                        name: "Junior"
                    },
                    {
                        id: "16",
                        name: "YR"
                    },
                    {
                        id: "6",
                        name: "U25"
                    },
                    {
                        id: "9",
                        name: "Senior"
                    },
                    {
                        id: "15",
                        name: "NR"
                    },
                    {
                        id: "13",
                        name: "OS"
                    }
                ]
            },
            {
              id: "membergrade",
              type: "togglegroup",
              options: [
                  {
                      id: "A",
                      name: "A"
                  },
                  {
                      id: "B",
                      name: "B"
                  },
                  {
                      id: "C",
                      name: "C"
                  },
                  {
                      id: "D",
                      name: "D"
                  },
                  {
                      id: "U",
                      name: "U"
                  }
              ]
            },
            {
              id: "membertypeid",
              type: "togglegroup",
              options: [
                  {
                      id: "1",
                      name: "Web"
                  },
                  {
                      id: "2",
                      name: "Full"
                  },
                  {
                      id: "3",
                      name: "Corp"
                  },
                  {
                      id: "4",
                      name: "Life"
                  }
              ]
            },
            {
                id: "membership",
                type: "togglegroup",
                options: [
                    {
                        id: "active",
                        name: "Active"
                    },
                    {
                        id: "elapsed",
                        name: "Elapsed"
                    }
                ]
            }
        ]
    },
    17: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    18: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    19: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    20: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    21: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    },
    22: {
        filters: [           
            {
                id: "eventid",
                type: "event",
                label: "Event"

            }
        ]
    },    
    23: {
        filters: [
            {
                id: "status",
                type: "togglegroup",
                options: [
                    {
                        id: 'WITHDRAWN',
                        name: 'Withdrawn'
                    },
                    {
                        id: 'ENTRYCOMPLETED',
                        name: 'Completed'
                    },
                    {
                        id: 'ENTRYREFUNDED',
                        name: 'Refunded'
                    }
                ]
            }
        ]
    },
    24: {
        filters: [
            {
                id: "memberexpirydate",
                type: "dateselector",
                label: "Membership Expiry After",
                default: 0
            },
            {
                id: "membertype",
                type: "togglegroup",
                label: 'Age Category',
                options: [
                    {
                        id: 'pony',
                        name: 'Pony'
                    },
                    {
                        id: 'junior',
                        name: 'Junior'
                    },
                    {
                        id: 'youngrider',
                        name: 'Young Rider'
                    },
                    {
                        id: 'under25',
                        name: 'Under 25'
                    }
                ]
            }
        ]
    },
    25: {
        filters: [
            {
                id: "after",
                type: "dateselector",
                label: "From",
                default: -31
            },
            {
                id: "before",
                type: "dateselector",
                label: "To",
                default: 0
            }
        ]
    }
}