import * as React from 'react';
import Typography from "@mui/material/Typography";
import { pad } from '../../utils';
import MultipleIndicator from './MultipleIndicator';
import Break from './Break';
import { getStartTime, getDisciplineBreakClassColumn } from '../../utils';

export default function ClassEntry(props) {
    console.debug("Adding classentry", props.entry);
    return (
        <React.Fragment>
        <div><Typography
        className={"MuiTypography--subheading"}
        variant={"caption"}
      >
          {pad(parseInt(props.entry.order, 10), 3) + ' - ' + getStartTime(props.entry, props.disciplineid) + ' - ' + props.entry.User.firstname + ' ' + props.entry.User.lastname}
          <MultipleIndicator multiples={props.entry.multiples} />
          {" - " + props.entry.traveldistance+" km"}
        </Typography>        
        </div>
        {props.entry[getDisciplineBreakClassColumn(props.disciplineid)] > 0 && <Break minutes={props.entry[getDisciplineBreakClassColumn(props.disciplineid)]} />}
        </React.Fragment>
    );
}