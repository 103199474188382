import * as React from 'react';
import { Stack, Typography, IconButton, Button } from "@mui/material";
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { isCombination, isDifferentFence  } from '../../utils';

export default function Fence({ number, addCombination, removeCombination, next}) {
    const canAdd = () => {
        return !isCombination(number) || isCombination(number) && isDifferentFence(number, next);
    }

    const canRemove = () => {
        return isCombination(number) && isDifferentFence(number, next);
    }


    return (
        <Stack direction="column" spacing={1}>
            <Stack direction="row">
                <Typography sx={{p:1, mr: 3, minWidth: 48, minHeight: 48, fontWeight: 'bold', backgroundColor: '#F2F2F2', borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{number} </Typography> 
                {/* {(canAdd() || canRemove()) && <Typography sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}> Combination</Typography>} */}
                {canAdd() && <Button onClick={addCombination} variant="text" startIcon={<AddIcon />} sx={{ml:3}}>
                    Add Combination
                </Button>}
                {canRemove() && <Button onClick={removeCombination} variant="text" startIcon={<RemoveIcon />} sx={{ml:3}}>
                    Remove Combination
                </Button>}     
            </Stack>

        </Stack>
    );
}
