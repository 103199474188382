// export const API_ROOT = '/api/';
var env       = process.env.REACT_APP_NODE_ENV || 'development';
var apiroot= '/api/';

console.debug("REACT_APP_NODE_ENV: " + env);
if (env == 'development') {
  apiroot= 'http://localhost:4444/';
} 
if (env == 'test') {
  apiroot= 'https://api.unbridledresults.com/';
} 
if (env == 'production') {
  apiroot= 'https://api.eventingireland.com/';
} 

if (env == 'newproduction') {
  apiroot= 'https://api.unbresults.com/';
} 

export const API_ROOT = apiroot;

export const colors = {
    scheduler: {
        appbar: {
            color: 'white',
            background: 'blue'
        }
    }
};
export const ridercolumns = [
  {
    columnName: 'competitionnumber',
    headerName: '#'
  },
  {
    columnName: 'class',
    headerName: 'Class'
  },
  {
    columnName: 'rider',
    headerName: 'Rider'
  },
  {
    columnName: 'horsename',
    headerName: 'Horse'
  },
  {
    columnName: 'arena',
    headerName: 'Arena'
  },
  {
    columnName: 'multiples',
    headerName: 'Multiple'
  }
];

export const optimumtimes = [
  40
];