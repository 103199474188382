import * as React from 'react';

import { Grid, Typography, TextField, ToggleButtonGroup, ToggleButton, 
    MenuItem, Select, Button, Chip, ButtonGroup, Tooltip } from "@mui/material";
import Panel from '../../components/containers/Panel';
import XcScore from './XcScore';
import useOutsideAlerter from '../../util/useOutsideAlerter';
import { xcscorebuttons, getXcShortCutAction, xcshortcuts, hotkeyTooltip } from './scorebuttons';
import { useHotkeys } from 'react-hotkeys-hook';
import useMediaQuery from '@mui/material/useMediaQuery';
import TimeStamp from './TimeStamp';
import { isTimeFormat } from '../../utils';

const statii = [
    'OK', 'EL', 'WD', 'RET', 'DNS', 'TEL'
];

const reasons = [
    {
        id: 'FH',
        name: 'Fall Of Horse'
    },
    {
        id: 'FHBD',
        name: 'Fall Of Horse, Broken Pin'
    },
    {
        id: 'FR',
        name: 'Fall Of Rider'
    },
    {
        id: 'FRBD',
        name: 'Fall Of Rider, Broken Pin'
    },
    {
        id: 'R',
        name: 'Refusal'
    },
    {
        id: 'BD',
        name: 'Breakable Device'
    },
    {
        id: 'AH',
        name: 'Abuse of Horse'
    },
    {
        id: 'OT',
        name: 'Other, error of course, incorrect behaviour, etc'
    }
];

export default function XcJudgeScores(
    { entry, onScoreChange, onReasonChange,
        onStatusChange, onCommentChange, onTimeChange,
        fences, allowedTime, timeDelta, timePen }) {

    const [focusFence, setFocusFence] = React.useState();
    const wrapperRef = React.useRef(null);
    const split = useMediaQuery('(max-width:1240px)');
    const splitagain = useMediaQuery('(max-width:820px)');

    useOutsideAlerter(wrapperRef, () => {
        setFocusFence();
    });

    const onChange = (f, v) => {
        // console.log("Change value: " + v);
        onScoreChange(f, v);
    }

    const timeDeltaOk = () => {
        return isTimeFormat(entry.xcstarttime) &&
            isTimeFormat(entry.xcfinishtime) &&
            isTimeFormat(entry.xcheldtime);
    }

    const buttons = () => {
        if(splitagain) {
            const onethird = Math.ceil(xcscorebuttons.length / 3); 
            return [ xcscorebuttons.slice(0, onethird), xcscorebuttons.slice(onethird, 2*onethird), xcscorebuttons.slice(2*onethird) ];
        }
        if(split) {
            const half = Math.ceil(xcscorebuttons.length / 2); 
            return [ xcscorebuttons.slice(0, half), xcscorebuttons.slice(half) ];
        }
        return [ xcscorebuttons ];
    }

    const hk = useHotkeys(xcshortcuts(),
        (_, handler) => {
            const key = handler.keys.join('');
            const action = getXcShortCutAction(handler);
            if (action) {
                console.log("Shortcut Action: " + action.shortname);
                return onScoreChange(focusFence, action.score);
            }
            console.log("No action found for key handler", handler);
        },
        {
            enableOnFormTags: ['input']
        }
    );
    return (
        <>
            {/* <Typography variant='h6'>Show Jumping Results </Typography> */}
            <Grid container spacing={1} ref={hk}>
                <Grid item sm={9}>
                    <Panel>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div ref={wrapperRef}>
                                    <Grid container spacing={1} sx={{ justifyContent: 'center', display: 'flex' }}>
                                        {entry.xcfences.map((f, index) => {
                                            return (
                                                <Grid item key={index}>
                                                    <XcScore
                                                        fenceindex={index}
                                                        fence={fences[index]}
                                                        onChange={(v) => onChange(index, v)}
                                                        value={entry.xcfences[index]}
                                                        onFocus={() => setFocusFence(index)}
                                                        hasFocus={focusFence == index}    
                                                    />
                                                </Grid>
                                            )
                                        })}
                                        {focusFence != null && <>
                                            <Grid item xs={12} sx={{justifyContent: 'center', display: 'flex'}}>
                                                <Typography variant="h5"  display="block">Score for Fence {fences[focusFence]}:</Typography>
                                            </Grid>
                                            
                                                
                                                

                                                    {buttons().map((part, i) => {
                                                        return (
                                                            <Grid item lg={12} sx={{justifyContent: 'center', display: 'flex'}}>
                                                                <ButtonGroup style={{ flexWrap: "wrap" }}>
                                                                {part.map((b, index) => {
                                                                    return (
                                                                        <Tooltip key={`xcbutton-${i}-${index}`} title={b.name + hotkeyTooltip(b)} variant="soft"><Button onClick={() => onChange(focusFence, b.score)} size="small" variant='outlined'>{b.shortname}</Button></Tooltip>        
                                                                    )
                                                                })}
                                                                </ButtonGroup>
                                                            </Grid>
                                                        )
                                                    })}

                                            </>}
                                    </Grid>
                                </div>
                            </Grid>                            
                            <Grid item xs={12}>
                                <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Comment: </Typography>
                                <TextField
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: "100%" }}
                                    value={entry.xccomments}
                                    onChange={(v) => onCommentChange(v.target.value)}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Status: </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={entry.xcstatus}
                                    exclusive
                                    onChange={((event, newStatus) => onStatusChange(newStatus))}
                                    aria-label="xcstatus"
                                    sx={{ flexWrap: "wrap" }}
                                >
                                    {
                                        statii.map((stat, i) => {
                                            return (
                                                <ToggleButton key={`stat-${i}`} value={stat}>{stat}</ToggleButton>
                                            )
                                        })
                                    }
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                                {entry.xcstatus != 'OK' && entry.xcstatus != '' && <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Reason: </Typography>}
                                {(entry.xcstatus != 'OK' && entry.xcstatus != '') && <Select
                                    fullWidth
                                    value={entry.xcreason}
                                    label="Reason"
                                    onChange={(e) => onReasonChange(e.target.value)}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        reasons.map((xc, i) => {
                                            return (
                                                <MenuItem key={`xc-${i}`} value={xc.id}>{xc.name}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>}
                            </Grid>
                        </Grid>
                    </Panel>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Panel>
                        <TimeStamp 
                            value={entry.xcstarttime}
                            sx={{
                                width: "100%"
                            }}
                            label="XC Start Time"
                            onChange={(v) => onTimeChange('xcstarttime', v)}
                        />                       
                         <TimeStamp 
                            value={entry.xcfinishtime}
                            sx={{
                                width: "100%"
                            }}
                            label="XC Finish Time"
                            onChange={(v) => onTimeChange('xcfinishtime', v)}
                        />                           
                         <TimeStamp 
                            value={entry.xcheldtime}
                            sx={{
                                width: "100%"
                            }}
                            label="XC Held Time"
                            onChange={(v) => onTimeChange('xcheldtime', v)}
                        />
                    </Panel>
                    <Panel>
                        <Typography sx={{ pr: 1, fontWeight: 'bold' }}>Allowed Time (mm:ss): </Typography>
                        <Typography sx={{ pr: 1 }}>{allowedTime}</Typography>
                        <Typography sx={{ pr: 1, fontWeight: 'bold' }}>XC Time (mm:ss): </Typography>
                        <Typography sx={{ pr: 1, mb: 2 }}>{entry.xctime}</Typography>

                        {timeDeltaOk() && (timeDelta <= 0 ? <Chip label={`Inside Time: ${timeDelta}s`} color="success" /> : <Chip label={`Outside Time: ${timeDelta}s Pen: ${timePen}`} color="error" />)}
                    </Panel>
                </Grid>
            </Grid>
        </>

    );
}
