import * as React from 'react';
import Typography from '@mui/material/Typography';

interface TitleProps {
  children?: React.ReactNode;
  small?:boolean;
  component?:any;
}

export default function Title(props: TitleProps) {
  let variant:any = props.small? "h7" : "h6";
  let component:any = props.small? "h4" : "h3";
  return (
    <Typography component={component} variant={variant} color="primary" gutterBottom sx={{ml:0, pl:0}}>
      {props.children}
    </Typography>
  );
}