export const ArchivedFilters = [    
    {
        id: "archived",
        type: "togglegroup",
        options: [
            {
                id: '0',
                name: 'Not Archived'
            },
            {
                id: '1',
                name: 'Archived'
            }
        ]
    }
];