import * as React from 'react';
import { Draggable, Droppable} from "react-beautiful-dnd";
import ClassEntry from './ClassEntry';
import { Card, Typography } from "@mui/material";
// import {useStyles} from '../../styles';
import Loading from '../../components/Loading';
import ClassHeader from './ClassHeader';


const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightgrey" : "",
    minHeight: "400px"
    // padding: grid,
    // width: 250
  });
  
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: 3,
    marginBottom: 10,
    // change background colour if dragging
    background: isDragging ? "grey" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

export default function Arenaday(props) {
    // const classes = useStyles();

    return (
        <React.Fragment >
            <Droppable
                style={{minHeight: "400px"}}
                key={props.arenaday.id}
                droppableId={`${props.arenaday.id}`}
                renderClone={(provided, snapshot, rubric) => (
                    <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                    >
                    <p
                        style={{padding: 10}}>{'Drag and drop it on its new location '}</p>
                    </div>
                )}
                >
                {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                >
                    {props.arenaday.EventclassSchedulings.map((c, cindex) => (
                    <Draggable
                        key={props.arenaday.id + '' + cindex}
                        draggableId={props.arenaday.id + "-" + cindex}
                        index={cindex}
                    >
                        {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Card
                            // className={classes.card}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            // className={(cindex % 2) == 1 ? classes.classColourBackground : ''}
                            >
                            {!props.busy && <div className={'row'} style={{padding: 5}}>
                            <ClassHeader eventclassScheduling={c} />
                            <div className={'col'} style={{textAlign: 'left'}}>
                                {c.Eventclass.Evententrants.map((e, eindex) => (
                                    <ClassEntry disciplineid={c.disciplineid} key={'entry-key-' + eindex} entry={e} key={eindex} index={eindex} />
                                ))}
                                </div>
                            </div>}
                           
                            {props.busy && <Loading />}
                        </Card>
                    </div>
                    )}

                </Draggable>
                ))}
                {provided.placeholder}
            </div>
            )}
            </Droppable>
        </React.Fragment>
        
    );
}