import * as React from 'react';
import { MetaTable } from '../components/table/MetaTable';
import Panel from '../components/containers/Panel';

export default function Venue() {
    const filterDef = () => {
        return [
            {
                id: "eventtypeid",
                type: "togglegroup",
                options: [
                    {
                        id: '1',
                        name: 'CCIO'
                    },
                    {
                        id: '2',
                        name: 'CCI'
                    },
                    {
                        id: '3',
                        name: 'CCN'
                    },
                    {
                        id: '4',
                        name: 'CIC'
                    },
                    {
                        id: '5',
                        name: 'CNC'
                    },
                    {
                        id: '6',
                        name: 'Train'
                    },
                    {
                        id: '8',
                        name: 'HP'
                    }
                ]
            },
            {
                id: "classtypelevelid",
                type: "togglegroup",
                options: [
                    {
                        id: '0',
                        name: '0'
                    },
                    {
                        id: '1',
                        name: '1'
                    },
                    {
                        id: '2',
                        name: '2'
                    },
                    {
                        id: '3',
                        name: '3'
                    },
                    {
                        id: '4',
                        name: '4'
                    },
                    {
                        id: '5',
                        name: '5'
                    },
                    {
                        id: '6',
                        name: '6'
                    }
                ]
            },
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }
        ]
        }

    return (
        <Panel>
            <MetaTable type="classtype" 
                canCreate={true}
                filters={filterDef()}
                defaultFilter={{ archived: "0"}}
                rememberSearch="classtypes-table" 
                createLabel={"Add Class Type"}
            />
        </Panel>
    )
}
