import * as React from 'react';
import Grid from '@mui/material/Grid';
import { MetaTable } from '../components/table/MetaTable';
import { roleIdToName, userStatusIdToName } from '../utils';
import { Paper } from '@mui/material';
import Panel from '../components/containers/Panel';



export default function Users() {
  const filterDef = () => {
    return [
        {
            id: "rideroptionid",
            type: "togglegroup",
            options: [                
                {
                    id: "8",
                    name: "Pony"
                },
                {
                    id: "7",
                    name: "Junior"
                },
                {
                    id: "16",
                    name: "YR"
                },
                {
                    id: "9",
                    name: "Senior"
                },
                {
                    id: "15",
                    name: "NR"
                },
                {
                    id: "13",
                    name: "OS"
                }
            ]
        },
        {
          id: "membergrade",
          type: "togglegroup",
          options: [
              {
                  id: "A",
                  name: "A"
              },
              {
                  id: "B",
                  name: "B"
              },
              {
                  id: "C",
                  name: "C"
              },
              {
                  id: "D",
                  name: "D"
              },
              {
                  id: "U",
                  name: "U"
              }
          ]
        },
        {
          id: "membertypeid",
          type: "togglegroup",
          options: [
              {
                  id: "1",
                  name: "Web"
              },
              {
                  id: "2",
                  name: "Full"
              },
              {
                  id: "3",
                  name: "Corp"
              },
              {
                  id: "4",
                  name: "Life"
              }
          ]
        },
        {
            id: "membership",
            type: "togglegroup",
            options: [
                {
                    id: "active",
                    name: "Active"
                },
                {
                    id: "elapsed",
                    name: "Elapsed"
                }
            ]
        },
        {
            id: "expiring",
            type: "togglegroup",
            options: [
                {
                    id: "true",
                    name: "Under Age Changing"
                }
            ]
        },
        {
            id: "expire",
            type: 'link',
            label: 'Apply New Year',
            to: 'migrateua',
            variant: 'text'
        }
    ];
};

  return (
    <Panel>
      <MetaTable 
        canExport
        type="user" 
        canCreate={false}
        filters={filterDef()}
        defaultFilter={{ membership: "active"}}
        rememberSearch="users-table" 
      />
    </Panel>
  )
}