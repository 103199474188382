export const UserEntryFilters = [
    {
        id: "isapplication",
        type: "togglegroup",
        options: [
            {
                id: '1',
                name: 'Is Application'
            }
        ]
    },
    {
        id: "isapproved",
        type: "togglegroup",
        options: [
            {
                id: '0',
                name: 'Not Approved'
            },
            {
                id: '1',
                name: 'Is Approved'
            }
        ]
    }
];