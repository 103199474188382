import * as React from 'react';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import axios from 'axios';
import SelectEntry from '../components/SelectEntry';
import Grid from '@mui/material/Grid';
import Panel from '../../components/containers/Panel';
import Title from "../../containers/Title";
import { Button, Stack } from '@mui/material';



export default function SelectRider({id, phase, onSelect, children, 
  changed, onChange, onChangeAndPublish, onCancel, onDns}) {
  const { enqueueSnackbar } = useSnackbar();
  const [entry, setEntry] = useState();

  const summary = () => {
    if(!entry) {
      return '';
    }
    return `${entry.ridername} / ${entry.horsename} / ${entry.Eventclass.sponsortext ? entry.Eventclass.sponsortext : entry.Eventclass.Classtype.name}`;
  }

  const loadEntry = (entrynumber) => {
    if(!entrynumber || entrynumber == "" ) {
      cancel();
      return;
    }
    const isnum = /^\d+$/.test(entrynumber);
    if(!isnum) {
      cancel();
      return;
    }

    axios.get(`/admin/entrantresults/id/${entrynumber}/result?eventid=${id}`)
          .then(response => {
          if (response.data.error) {
            setEntry();
            onSelect(null);
            enqueueSnackbar(response.data.message, { variant: 'error'})    
            return;
          }
          setEntry(response.data.record);
          onSelect(response.data.record);
          // reset the filters
      }).catch(error => {
        setEntry();
        onSelect(null);
        console.log(error);
        enqueueSnackbar(error && error.data && error.data.message ? error.data.message : "Problem fecting record", { variant: 'error'})    
        console.debug(error);
      });
  }
  

  const entrySelected = (compnum) => {
    console.log(compnum);
    loadEntry(compnum);
  }
  
  const cancel = () => {
    setEntry(null)
    onCancel();
  }

  return (
    <Panel>
      <Title>{phase} Scoring: {summary()}</Title>
        <Grid container spacing={1}>
          <Grid item sm={8}><SelectEntry onSelect={entrySelected} eventid={id} /></Grid>          
            <Grid item sm={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {entry && <Button sx={{ mt: 2, mr:2}} variant="outlined" onClick={() => cancel()}>Cancel</Button>}
              {onDns && entry && <Button sx={{ mt: 2, mr:2}} variant="outlined" onClick={() => onDns()}>Mark As DNS</Button>}
              {changed && <><Button sx={{ mt: 2}} variant="contained" onClick={onChange}>Save Results</Button>
                          {onChangeAndPublish && <Button sx={{ml: 1, mt: 2}} variant="contained" onClick={onChangeAndPublish}>Save & Publish Results</Button>}</>}
            </Grid>
          
        </Grid>
        <Stack sx={{mt:1}}>
        {children}
        </Stack>
    </Panel>
  )
}