import * as React from 'react';
import { Grid } from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Arena from './Arena';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {  ToggleButtonGroup, ToggleButton, Stack, Typography } from '@mui/material';
import { RootStyle } from '../../utils';
import Title from '../../containers/Title';
import {getComparator, SortColumn, stableSort, getDisciplineBreakClassColumn, getDisciplineShortName} from "../../utils";
import { ridercolumns } from "../../app/constants"
import CompetitorTimeDifference from "./CompetitionTimeDifference";
import {moveEntry} from "../../utils";
import axios from 'axios';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
})

export default function DisciplineRiders(props) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Name');
    const [discipline, setDiscipline] = React.useState(props.discipline);
    const [shows, setShows] = React.useState(() => []);

    let otherDisciplines = [];
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();

    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination && (result.source.index != result.destination.index)) {
        return;
      }
  
      console.debug(`dragEnd ${result.source.index} to  ${result.destination.index}`);  
      console.debug("Moving ", discipline.entries[result.source.index], "to", discipline.entries[result.destination.index]);

      if(discipline.entries[result.source.index].arena == discipline.entries[result.destination.index].arena ) {
        let updatedDisciplines = JSON.parse(JSON.stringify(discipline));
        updatedDisciplines.entries = moveEntry(result.source.index, result.destination.index, updatedDisciplines.entries, discipline.id);
        setDiscipline(updatedDisciplines);
        let start = result.source.index > result.destination.index ? result.destination.index : result.source.index;
        let end = result.source.index < result.destination.index ? result.destination.index : result.source.index;
        
        updateOnServer(updatedDisciplines.entries.slice(start, end+1));
      } else {
          enqueueSnackbar("You can only move entries within the same arena", { variant: 'error'})
      }      
    };
    
    const updateOnServer = (entries) => {
      console.debug("Update on server", entries);
      const breakcolumnname = getDisciplineBreakClassColumn(discipline.id);
      axios.post("/admin/event/" + id + "/moveentry", {
        entries: entries.map(e => {
          return {
            id: e.id,
            competitionnumber: e.competitionnumber,
            starttime: e.starttime,
            [breakcolumnname]: e.breakafter,
            disciplineid: discipline.id
          }
        })
      }).then(response => {
        if (response.data.error) {
          enqueueSnackbar("Problem updating change on server " + response.data.message, { variant: 'error' })
          return;
        }
        console.debug("Entries moved", response.data);
  
      }).catch(error => {
        console.log(error.stack);
        enqueueSnackbar("Problem updating change on server " + error, { variant: 'error' })
      });
    };

    const getMultipleInfo = (entry) => {      
      if (entry.nummultiples == 0) {
        return ;
      }

      return (
        <CompetitorTimeDifference data={entry.multiples} thresholds={props.thresholds} show={shows}/>
      )    
    };

    const getDisciplineStartTime = (disciplineid, others) => {
      for (let other of others) {
        if (other.disciplineid == disciplineid) {
          return other.starttime;
        }
      }
      return "";
    };

    const getColumns = () => {
        if(discipline.entries.length == 0) {
          return ridercolumns;
        }
        // take copy of default columns
        let columns = JSON.parse(JSON.stringify(ridercolumns));
        // add current discipline column
        columns.push({
          columnName: discipline.shortname.toLowerCase() +'_startime',
          headerName: discipline.shortname + '_Time'
        });

        otherDisciplines = discipline.entries[0].others;

        otherDisciplines.forEach(d => {
          columns.push({
            columnName: d.disciplineshortname.toLowerCase() + '_startime',
            headerName: d.disciplineshortname + '_Time'
          });
        });

        columns.push({
          columnName: 'notes',
          headerName: 'Notes / Competitor Request'
        });
        return columns;
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleShow = (event, newShows) => {
      setShows(newShows)
    }

    const toggleoption = (id) => {
      return (
          <ToggleButton key={id} value={id}>{getDisciplineShortName(id)}</ToggleButton>
      );
    }

    
    const togglegroup = (disciplineid) => {
      let disciplines = [1, 2, 3];
      disciplines = disciplines.filter(d => d != disciplineid);
    
      return (
          <RootStyle disableGutters={true}><Stack direction="row" spacing={2}>
            <Typography>Show Other Times:</Typography>
            <ToggleButtonGroup
                value={shows}
                onChange={handleShow}
                aria-label="show other disciplines"
                size="small"
                color="primary"
              >
                      {disciplines.map((d) => {
                          return toggleoption(d)
                      })}
              </ToggleButtonGroup>       
            </Stack></RootStyle>     
      );
    }

    // console.debug(getColumns());
    return (
      <React.Fragment>
      {togglegroup(discipline.id)}
      <Table size="small" aria-label="simple table">
      <TableHead>
        <TableRow key={"header-row-"+discipline.id}>
          {getColumns().map((c, index) => (
            <SortColumn
              key={c.columnName+"-"+discipline.id}
              columnName={c.columnName}
              order={order}
              orderBy={orderBy}
              headerName={c.headerName}
              handleRequestSort={handleRequestSort}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody component={DroppableComponent(onDragEnd)}>
        {stableSort(discipline.entries, getComparator(order, orderBy)).map((entry, index) => (
          <TableRow key={discipline.id+"-"+entry.id} component={DraggableComponent("entry-"+entry.id+"-"+entry.disciplineid, index)}  >
            <TableCell>{entry.competitionnumber}</TableCell>
            <TableCell>{entry.classname}</TableCell>
            <TableCell>{entry.name}</TableCell>
            <TableCell>{entry.horsename}</TableCell>
            <TableCell>{entry.arena}</TableCell>
            <TableCell>{getMultipleInfo(entry)}</TableCell>
            <TableCell>{entry.starttime}</TableCell>
            {otherDisciplines.map(d => {
              return (
                <TableCell key={'other-'+d.disciplineid}>{getDisciplineStartTime(d.disciplineid, entry.others)}</TableCell>
              )
            })}            
            <TableCell>{entry.note}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
        </React.Fragment>
    );
}


const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  )
}

const DroppableComponent = (
  onDragEnd: (result, provided) => void) => (props) =>
{
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'1'} direction="vertical">
        {(provided) => {
          return (
            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
              {props.children}
              {provided.placeholder}
            </TableBody>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}
