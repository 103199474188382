import * as React from 'react';
import {Grid } from '@mui/material';
import Panel from '../../components/containers/Panel';
import Report from './Report';

import {
    Typography
} from '@mui/material';
export default function ReportGroup({ group }) {
    
    return (
            <Grid container spacing={3}>
                {group.reports.map((report, index) => {
                    return (
                        <Grid key={"report-"+index} item xs={12} md={4}>
                            <Report report={report} />
                        </Grid>
                    )
                })}
            </Grid>
    )
}