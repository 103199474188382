import * as React from 'react';
import { MetaTable } from '../components/table/MetaTable';
import Panel from '../components/containers/Panel';
import { Typography } from '@mui/material';


export default function Payments() {
    const now = new Date();
    const thisyear = now.getFullYear();
    let yearoptions:any = [];
    for (let i = 2022; i <= thisyear; i++ ) {
        yearoptions.push({
            id: i,
            name: i
        });
    }

    const filterDef = () => {
        return [                  
            {
                id: "result",
                type: "togglegroup",
                options: [
                    {
                        id: 'notchecked',
                        name: 'Not Checked Yet'
                    },
                    {
                        id: 'ok',
                        name: 'Good'
                    },
                    {
                        id: 'notok',
                        name: 'Problem'
                    }
                ]
            },
            {
                id: "currency",
                type: "togglegroup",
                options: [
                    {
                        id: 'EUR',
                        name: '€'
                    },
                    {
                        id: 'GBP',
                        name: '£'
                    }
                ]
            },
            {
                id: "paymenttype",
                type: "togglegroup",
                options: [
                    {
                        id: 'CC',
                        name: 'CC'
                    },              
                    {
                        id: 'RF',
                        name: 'RF'
                    }
                ]
            },
            {
                id: "year",
                type: "togglegroup",
                options: yearoptions
            }
        ]
    };
    return (
        <Panel>
        <Typography>Payments Page</Typography>
        <MetaTable 
            stickyHeader={true}
            type="payment" 
            canCreate={false}
            filters={filterDef()}
            defaultFilter={{ year: thisyear}}
            rememberSearch="payments-table" 
        />
    </Panel>
    )
}
