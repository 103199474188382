import React from "react";
import _ from "lodash";
import axios from "axios";
import FormDialog from "../form/FormDialog";
import EditNoteIcon from '@mui/icons-material/EditNote';

export default function ChangeNote(props:any) {

    const changeNotesFields = [
        {
            attribute: "notes",
            label: "Entry New Note",
            component: 'text-field',
            validationType: "string",
            validations: {
              required: true,
            },
            col: {
              sm: 12,
            },
            props: {
              multiline: true,
              rows: 6
            }
          }
    ];

    const change = (form:any, callback:any) => {
        axios.post("/admin/evententrant/"+props.id+"/changenotes", { 
            notes: form['notes']
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: "Problem changing notes: " + response.data.message});
            }
            props.update(form['notes']);
            callback({ error: false, message: "Note changed " });
        })
        .catch(error => {
            return callback({ error: true, message: "Error note class: " + error});
        });

        
    };


    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Change Entry Note" 
            title="Enter Note Text"
            description={"Enter the updated notes text below" }
            saveLabel="Apply Change"
            fields={changeNotesFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<EditNoteIcon/>)}
            isSecondaryButton={true}
            form={{ notes: props.notes}}
       />
    )
}