import * as React from 'react';
import { Link } from 'react-router-dom';
import EnhancedTable from './EnhancedTable';
import { MetaStore } from '../../app/MetaStore';
import axios from 'axios';
import { format, parse } from "date-fns";
import { Typography, ToggleButtonGroup, ToggleButton, TextField, Stack, Button } from '@mui/material';
import { capitalizeFirstLetter, applyPermissions, getDisplayValue, pluralise, formatDate, formatTime, RootStyle, formatDateTime, formatSwitch } from '../../utils';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Loading from '../Loading';
import { selectUser } from '../../app/loginSlice';
import { useParams } from 'react-router';
import { hasPermission } from "../../utils";
import Title from '../../containers/Title';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useLocalStorage from '../../app/useLocalStorage';
import Lastcrumb from '../Lastcrumb';
import { API_ROOT } from '../../app/constants';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker,  LocalizationProvider } from '@mui/x-date-pickers';
import EventSelector from '../eventing/EventSelector';

// import { DemoItem } from '@mui/x-date-pickers/internals/demo';

// import { formatDate } from '../../utils';

interface MetaTableProps {
    type: any;
    baseUrl?: string;
    formatters?: any;
    canCreate?: boolean;
    createHandler?: Function;
    createLabel?: string;
    selectable?: boolean;
    multiselect?: boolean;
    canExport?: boolean;
    rowsPerPage?: number;
    title?: string;
    onSelect? : Function;   
    onDeselect? : Function;
    dataUrl?: string;
    menus?: any;
    idname?: string;
    filters?: any;
    placeholder?: string;
    defaultFilter?: any;
    force?: any;
    rememberSearch?: string;
    query?: any;
    idvalue?: any;     
    stickyHeader?: boolean;
    showResetFilter?: boolean;
}

interface MetaDef {
    title: string;
    fields: any;
    properties: any;
    actions?: any;
}

const initialMeta: MetaDef = {
    title: '',
    fields: [],
    properties: {
        orderBy: "name"
    }
};

export function MetaTable(props: MetaTableProps) {
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [meta, setMeta] = React.useState(initialMeta);
    const [rows, setRows] = React.useState([]);
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const rowsPerPage = props.rowsPerPage ? props.rowsPerPage : 10;
    const [title, setTitle] = React.useState(props.title);
    const [dataUrl, setDataUrl] = React.useState(props.dataUrl);
    const { id } = useParams();
    const [filterValues, setFilterValues] = useLocalStorage(props.type+"FilterValues", props.defaultFilter ? props.defaultFilter : {});
    const {enqueueSnackbar } = useSnackbar();

    
    const getDefinition = () => {
        MetaStore.get(props.type, ((definition: any) => {
            definition = addFormatters(definition);
            definition = applyPermissions(definition, user);
            if(!props.title && title != "") {
                setTitle(pluralise(definition.title));
            } else {
                setTitle(" ");
            }
            setMeta(definition);
            getData(props.filters ? filterValues : {});
        }));
    }

    const getFilterDefinition = (filterid:any) => {
        return !props.filters ? null : props.filters.find((f:any) => f.id == filterid);
    }

    const getQuery = (currentValues:any, dataUrl:string) => {
        let query = [];
        for (const querytype in currentValues) {
            let value = currentValues[querytype];
            if(value != null) {
                if(value.id) {
                    value = value.id
                }
                const filter = getFilterDefinition(querytype);
                if(filter && filter.type == 'dateselector' && value) {

                    if(typeof value == 'string') {
                        value = new Date(value);
                    }
                    value = format(value, "yyyy-MM-dd")
                }
                query.push(`${querytype}=${value}`);
            }
        }

        // add any atitional built in query
        if(props.query) {
            for (const querytype in props.query) {
                const value = props.query[querytype];
                if(value != null) {
                    query.push(`${querytype}=${value}`);
                }
            }
        }
        // let urlContainsQuestionMark = dataUrl.includes('?');
        let initialCharacter = !dataUrl.includes('?') ? '?' : '&' ;
        let newquery =  query.length > 0 ? initialCharacter+query.join("&") : "";
        console.debug(newquery);
        return newquery;
    }

    React.useEffect(() => {
        getDefinition();
    }, [dataUrl]);

   
    async function getData(currentValues:any) {
        const baseUrl = props.baseUrl ? props.baseUrl : "admin/";
        const dataUrl1 = dataUrl ? dataUrl : baseUrl + props.type;
        const query = getQuery(currentValues, dataUrl1);
        axios.get(dataUrl1+query)
            .then(response => {
                if (response.data.error) {
                    setError(response.data.error);
                    setLoading(false);
                    return;
                }
                setRows(response.data.records);
                setLoading(false);
            })
            .catch(error => {
                console.warn(error);
                setError(error);
                setLoading(false);
            });
    }

    const addFormatters = (definition: any) => {
        definition.fields.forEach((f: any) => {
            if (f.properties.options) {
                f['format'] = getDisplayValue;
            } else if(f.properties.component == 'date-picker') {
                f['format'] = formatDate;
            } else if(f.properties.component == 'date-time-picker') {
                f['format'] = formatDateTime;
            } else if(f.properties.component == 'time-picker') {
                f['format'] = formatTime;
            } else if(f.properties.component == 'switch') {
                f['format'] = formatSwitch;
            } 
        });

        return definition;
    }


    const handleChange = (filterid: any, newValue: any) => {
        let currentValues = structuredClone(filterValues);
        currentValues[filterid] = newValue;
        setFilterValues(currentValues);
        console.log(currentValues);
        enqueueSnackbar("updating table", { variant: 'success'});
        setLoading(true);
        getData(currentValues);        
    };

    const resetFilter = () => {
        setFilterValues({});
        enqueueSnackbar("updating table", { variant: 'success'});
        setLoading(true);
        getData({});        
    };

    const toggleoption = (o:any) => {
        return (
            <ToggleButton key={o.id} value={o.id}>{o.name}</ToggleButton>
        );
    }

    const togglegroup = (f:any) => {
        return (
                <ToggleButtonGroup
                    color="primary"
                    value={filterValues[f.id]}
                    exclusive
                    onChange={(e, newvalue) => handleChange(f.id, newvalue)}
                    aria-label="Platform"
                    size="small"
                    key={f.id}
                    >
                        {f.options.map((o:any) => {
                            return toggleoption(o)
                        })}
                </ToggleButtonGroup>            
        );
    }

    const getDateDefault = (f:any) => {
        const d = new Date();
        if(!f.default || f.default == 0) {
            return d;
        }
        return new Date(d.getDate() + f.default);
        
    }

    // const getFilterDateValue = (f:any) => {
    //     const d = getDateDefault(f);
    //     const value = filterValues[f.id] || d;
    //     return parse(value, "yyyy-MM-dd", new Date());
    // }

    const dateselector = (f:any) => {
        const d = getDateDefault(f);
        return (
            <LocalizationProvider key={f.id} dateAdapter={AdapterDateFns}>
                <DesktopDatePicker 
                    inputFormat="dd/MM/yyyy"
                    label={f.label}
                    value={filterValues[f.id] || d} 
                    // value={getFilterDateValue(f)} 
                    onChange={(newvalue) => handleChange(f.id, newvalue)} 
                    renderInput = {(params) => (
                        <TextField
                        fullWidth
                        size="small"
                        sx={{width: '100%'}}
                        {...params}
                        />
                    )}
                />
            </LocalizationProvider>  
        );
    }

    const eventselector = (f:any) => {
        
        return (
            <EventSelector 
                key={f.id}
                id={f.id}
                value={filterValues[f.id]}
                onChange={(newvalue:any) => handleChange(f.id, newvalue)}
                label={f.label}                
            />
        );
    }

    const filter = (f:any) => {
        switch(f.type) {
            case "togglegroup":
                return togglegroup(f);

            case "dateselector":
                return dateselector(f);

            case "event":
                return eventselector(f);

            // case "link":
            //     return (
            //         <Button component={Link} to={f.to} variant={f.variant ? f.variant : "contained"} color="primary">
            //             {f.label}
            //         </Button>
            //     )
    
        }    
        return;
    };

 
    const filters = () => {
        if(!props.filters || props.filters.length == 0 ) {
            return;
        }       

        return (
            <RootStyle disableGutters={true}><Stack direction="row" spacing={2}>
                <Title >Filter:</Title>
                {props.filters.map((f:any) => {
                        return filter(f);
                    })}
                {props.showResetFilter && <Button onClick={() => {resetFilter()}} variant="contained">Reset Filters</Button>}
            </Stack></RootStyle>
        )
    }

    const getName = (row:any) => {
        if(!row) {
            return '';
        }
        if(row.name) {
            return row.name;
        } 
        if(row.firstname) {
            return row.firstname + ' ' + row.lastname;
        } 

        if(row.daytickettype) {
            return row.daytickettype;
        } 
        return '';
    }

    const getActions = () => {
        if (!meta.actions) {
            return;
        }
        let menus: any[] = [];
        meta.actions.forEach((action: any) => {
            if ( typeof action === 'object') {
                let canAdd = true;
                if(action.permission) {
                    canAdd = hasPermission(user.permissions, action.permission);
                }
                if(canAdd) {
                    if(action.url) {
                        menus.push(
                            {
                                icon: action.type,
                                name: action.name,
                                handler: function (row: any) {
                                    const url = action.url.replace('::ROW_ID::', row.id);
                                    location.replace(`${API_ROOT}${url}`);
                                }
                            }
                        );        
                    } else {
                        menus.push(
                            {
                                icon: action.type,
                                name: action.name,
                                handler: function (row: any) {
                                    // let rowname = row.name.replaceAll('/', '-');
                                    let crumb = getName(row);
                                    if(crumb != '') {
                                        Lastcrumb.name = crumb;
                                    }                            
                                    navigate('/' + action.type + '/' + props.type + '/' + row.id,  {state: { prevPath: location.pathname } });
                                }
                            }
                        );        
                    }
                }
            } else {
                menus.push(
                    {
                        icon: action,
                        name: capitalizeFirstLetter(action),
                        handler: function (row: any) {
                            let crumb = getName(row);
                            if(crumb != '') {
                                Lastcrumb.name = crumb;
                            }
                            navigate('/' + action + '/' + props.type + '/' + row.id, {state: { prevPath: location.pathname } });
                        }
                    }
                );    
            }
        });
        return menus;
    }

    const getCreateAction = () => {
        if (!props.canCreate) {
            return;
        }

        if(props.createHandler) {
            return {
                handler: function () {
                    if(props.createHandler) {
                        props.createHandler();
                    }
                }
            };
        }

        // 
        let query = "";
        if (id && props.idname) {
            query = "?"+props.idname+"="+ (props.idvalue ? props.idvalue : id);
        }
        return {
            handler: function () {
                navigate('/create/' + props.type + query,  {state: { prevPath: location.pathname } });
            }
        }
    }


    const getTableFields = (fields:Array<any>) => {
        let tablefields: any[] = [];
        fields.forEach((field: any) => {
            if ( field.istable) { 
                tablefields.push(field);
            }
        });
        return tablefields;
    }

    return (
        <React.Fragment>
            {error != "" && <Typography>Error {error}</Typography>}
            {filters()}
            {loading && <Loading />}
            {!loading && 
                <EnhancedTable 
                    dataUrl={props.dataUrl} 
                    order={meta.properties.direction} 
                    columns={getTableFields(meta.fields)} 
                    rows={rows} 
                    orderBy={meta.properties.orderBy}
                    title={props.title} 
                    menu={getActions()} 
                    createAction={getCreateAction()}
                    createLabel={props.createLabel ? props.createLabel  : "Create"}
                    selectable={props.selectable ? props.selectable : false}
                    rowsPerPage={rowsPerPage} onSelect={props.onSelect} onDeselect={props.onDeselect}
                    multiselect={props.multiselect} placeholder={props.placeholder} loading={loading}
                    rememberSearch={props.rememberSearch}
                    canExport={props.canExport}
                    filterActions={props.filters && props.filters.filter((l:any) => l.type === 'link')}
                />
            }
        </React.Fragment>
    )
}