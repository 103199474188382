import React from "react";
import _ from "lodash";
import axios from "axios";
import LockIcon from '@mui/icons-material/Lock';
import { ChangePasswordFields } from "../../app/form/Definitions/ChangePassword";
import { passwordOk } from "../../utils";
import FormDialog from "../form/FormDialog";


export default function ChangePassword(props:any) {


    const change = (form:any, callback:any) => {
        axios.post("/admin/useraccess/"+props.id+"/cpwd", { 
            p2: _.get(form, 'newpassword'),
            sendemail: _.get(form, 'sendemail')
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: response.data.message});
            }
            callback({ error: false, message: "Password updated"});
        })
        .catch(error => {
            return callback({ error: true, message: "Error changing password: " + error});
        });

        
    };


    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
      
        let p1 = _.get(form, 'newpassword');
        let p2 = _.get(form, 'newpasswordconfirmed');

        if(!p1 || p1 == "" || !p2 || p2 == "") {
            return "You must provide a value for all fields";
        }
        // the second two must match
        if (p1 != p2) {
            return "The new passwords are not the same";
        }

        let ok = passwordOk(p1);
        if(ok != "") {
            return ok
        }
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Change Password" 
            title="Change Password Form"
            description="Please enter your old password, and then type your new password twice in the boxes below. If you also want an automatic email sent to the user then click that option."
            saveLabel="Apply Change"
            fields={ChangePasswordFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<LockIcon/>)}
       />
    )
}