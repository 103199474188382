import React, { Fragment } from "react";
import { Chip, TextField } from "@mui/material";
import { Autocomplete, Typography } from "@mui/material";
import { Title } from "../../app/form/Components/Widgets/Title";
import { useSnackbar } from 'notistack';
import axios from "axios";



function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function EventSelector({id, onChange, value, label }) {
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;
  const {enqueueSnackbar } = useSnackbar();

  let active = false;


  const request = async (url, params) => {
    return axios.get(url, {
            params
        }).then(response => {
            return response.data;
        }).catch(error => {
          enqueueSnackbar(error, { variant: 'error'})
          return [];
        });    
}

  const loadOptions = (newvalue) => {    
    if(active) {
      return;      
    }
    // fetch options and load into field.options    
    let min = 3;
    if(typeof newvalue == 'undefined' || newvalue == 'undefined' || !newvalue || newvalue.length < min) {
      active = false;
      return;
    }
    active = true;

    (async () => {
      let params = {
          name: newvalue
      };
      let options = await request("/admin/event/auto", params);
      setOptions(options.records);
      active = false;
    })(); 
  };

  
  const getValue = async (field, id) => {
    console.debug(`Get Value called with [${id}]`);
    if(id == -1) {
      return -1;
    }
    if(!options || options.length == 0) {
      // fetch the value directly from the remote system
      let result = await request(`${field.optionDef.url}?id=${id}`);
      if(result.error) {
        return "";
      }
      setOptions([ { id: id, name: result.record.name }]);
      return result.record.id;
    } else {
      return id;
    }
    
  }

  const componentProps = () => {
    console.log("componentProps", options);
    return {
      id: id,
      size: "small",
      fullWidth: true,
      // width: "600px",
      options: options,
      renderOption: (props, option) => {
        return <li {...props} key={option.id}>{option.name}</li>
      },
      getOptionLabel: (option) => option.name,
      // renderInput: (params) => <TextField {...params} label={field.name} />,
      renderInput: (params) => (
        <TextField
          {...params}
          // width="600px"
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            // autoComplete: "off", // disable autocomplete and autofill
          }}
          label={label}
        />
      ),
      renderTags: (value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.ref ? option.ref : option.id}
            variant="outlined"
            size="small"
            label={option.name}
          />
        )),
      value: value ,
      onInputChange: (event, newvalue) => {
        if(newvalue && newvalue != 'undefined') {
          loadOptions(newvalue);
        }
      },
      onChange: (event, option) => {
        console.log('onChange', option);
        onChange(option)
      },
    };
  };


  return (
    <Fragment>
      <Autocomplete {...componentProps()} open={open}
        loading={loading}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        sx={{minWidth: "300px"}}
        // filterOptions={(x) => filter(x)}
        />
    </Fragment>
  );

}


