import * as React from 'react';
import { useParams, useNavigate } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useRef } from 'react';
import { MetaTable } from '../../components/table/MetaTable';
import Back from '../../components/buttons/Back';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
export default function ViewTeamCompetitionDetails() {
    const { id } = useParams();

    return (
        <>
        <Panel>
            <Form 
                id={id} 
                objtype={"teamcompetition"} 
                readonly={true}
                bottomOnly={true}
                showbackbutton={false}
            />       
        </Panel>
        <Panel>
        <MetaTable 
            title="Teams"
            type="team" 
            canCreate={true}    
            dataUrl={"/admin/team?teamcompetitionid="+id} 
            idname="teamcompetitionid"
            createLabel={"Add Team"}
        />
        <Back/>
        </Panel>
        </>
    )
}