import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Add, Remove } from "@mui/icons-material";
import { Title } from "../Widgets/Title";
import { useValidation } from "../../Hooks/useValidation";
import ErrorText from "../Widgets/ErrorText";

const StandardCounter = forwardRef((props, ref) => {
  const theme = useTheme();
  const { field, value, updateForm, showTitle, readonly } = props;
  const { errors, validate, reset } = useValidation("number", field.validations);
  field.title=field.label;

  return (
    <div
      ref={(el) => {
        if (el && ref) {
          el.validate = (value) => validate(value);
          el.reset = () => reset();
          ref(el);
        }
      }}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {showTitle && field.title && <Title field={field} />}
      {!readonly && <Tooltip title="Reduce">
        <span>
          <IconButton
            aria-label="reduce"
            sx={{
              color: theme.palette.primary.light,
            }}
            onClick={() =>
              updateForm({
                [field.attribute]: Number(value || 0) - 1,
              })
            }
            disabled={
              field.props?.disabled ||
              Number(value || 0) <= Number(field.inputMin || 0)
            }
            size="large"
          >
            <Remove />
          </IconButton>
        </span>
      </Tooltip>}
      {!readonly && <div
        style={{
          border: "1px solid #b9b9b9",
          borderRadius: "4px",
          textAlign: "center",
          width: "60px",
          padding: "4px",
          color: "rgba(0, 0, 0, 0.87)"          
        }}
        {...field.props}
      >
        <Typography style={{ fontSize: field.fontSize }}>
          {Number(value || 0)}
        </Typography>
      </div>}
      {readonly && <div
        style={{
          textAlign: "center",
          width: "60px",
          padding: "4px",
          color: "rgba(0, 0, 0, 0.87)"          
        }}
        {...field.props}
      >
        <Typography style={{ fontSize: field.fontSize }}>
          {Number(value || 0)}
        </Typography>
      </div>}
      {!readonly && <Tooltip title="Add">
        <span>
          <IconButton
            aria-label="add"
            sx={{
              color: theme.palette.primary.main,
            }}
            onClick={() => {
              updateForm({
                [field.attribute]: Number(value || 0) + 1,
              });
            }}
            disabled={
              field.props?.disabled ||
              Number(value || 0) >= Number(field.inputMax || 1000000)
            }
            size="large"
          >
            <Add />
          </IconButton>
        </span>
      </Tooltip>}

      {errors?.length > 0 && <ErrorText error={errors[0]} />}
    </div>
  );
});

StandardCounter.displayName = "StandardCounter";

StandardCounter.defaultProps = {
  updateForm: () => {},
  value: 1,
  showTitle: true,
};

StandardCounter.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.number,
  updateForm: PropTypes.func,
  showTitle: PropTypes.bool,
};

export { StandardCounter };
