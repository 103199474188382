import * as React from 'react';
import { MetaTable } from '../table/MetaTable';

export default function EntriesTable(props:any) {
    const now = new Date();
    const thisyear = now.getFullYear();
    let yearoptions:any = [];
    for (let i = 2016; i <= thisyear; i++ ) {
        yearoptions.push({
            id: i,
            name: i
        });
    }
   
    let type = props.type ? props.type :  "riderentrant";
    return (
        <MetaTable 
            canExport
            type={type} 
            canCreate={props.canCreate ? props.canCreate : null}
            createLabel={props.createLabel ? props.createLabel : "Add Entry " }
            filters={props.filter ? props.filter : []}
            defaultFilter={props.defaultFilter ? props.defaultFilter : {}}
            dataUrl={props.dataUrl ? props.dataUrl : null}
            query={props.query ? props.query: {}}
            rememberSearch={props.idvalue+type+"-table-entries1"}
            idname={props.idname ? props.idname : null}
            idvalue={props.idvalue ? props.idvalue : null}
        />
    )
}
