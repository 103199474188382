import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import { theme } from './theme';

import { Provider } from 'react-redux'
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react'

// store
const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>  
)
