import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Title from './Title';
import { Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { menus } from '../util/menu';
import { selectUser } from "../app/loginSlice";
import { hasPermission } from "../utils";
import { useSelector } from 'react-redux';


export default function Welcome(props:any) {
  const user = useSelector(selectUser);

  return (
    <React.Fragment>
      <Title>Welcome</Title>
        <Typography>
          Welcome to the Eventing Ireland Administration system, from here you can use the following options.
        </Typography>
     
        <List dense={true}>
            {menus.map(menu => {
                if(user && hasPermission(user.permissions, menu.permission)) {
                  if(menu.id != 'dashboard') {
                    return (
                      <ListItem key={menu.id}>
                        <ListItemIcon>
                          {menu.icon("xxx")}
                        </ListItemIcon>
                        <ListItemText
                            primary={" "+menu.name}
                            secondary={menu.description}
                          />
                      </ListItem>
                    )
                  }
                }
            })}
            {/* <ListItem>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                primary="Events"
                secondary={'Add and manage all your events from this section'}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PlaceIcon />
              </ListItemIcon>
              <ListItemText
                primary="Venues"
                secondary={'Add and manage the complete set of venues where your Events take place'}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Tests"
                secondary={'Access all dressage tests configured and available for use in your Events'}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText
                primary="Users"
                secondary={'From here Administrators can add new user accounts and edit existing ones'}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                secondary={'Manage your personal settings, e.g. change your password, manage default values to be used for specific event disciplines'}
              />
            </ListItem> */}
        </List>

    </React.Fragment>
  );
}