import * as React from 'react';
import { MetaTable } from '../table/MetaTable';

export default function OrdersTable(props:any) {
    const now = new Date();
    const thisyear = now.getFullYear();
    let yearoptions:any = [];
    for (let i = 2016; i <= thisyear; i++ ) {
        yearoptions.push({
            id: i,
            name: i
        });
    }
    const filterDef = () => {
        return [
            {
                id: "year",
                type: "togglegroup",
                options: yearoptions
            },
            {
                id: "currency",
                type: "togglegroup",
                options: [
                    {
                        id: 'EUR',
                        name: '€'
                    },
                    {
                        id: 'GBP',
                        name: '£'
                    }
                ]
            },
            {
                id: "paymenttype",
                type: "togglegroup",
                options: [
                    {
                        id: 'CC',
                        name: 'CC'
                    },
                    {
                        id: 'CQ',
                        name: 'CQ'
                    },
                    {
                        id: 'RF',
                        name: 'RF'
                    }
                ]
            },
            {
                id: "orderstatusid",
                type: "togglegroup",
                options: [
                    {
                        "id": 1,
                        "name": "Complete"
                    },
                    {
                        "id": 8,
                        "name": "Incomplete"
                    }
                ]
            },
            {
                id: "reconciliatonlog",
                type: "link",
                label: "Reconciliations",
                to: '/reconciliationlog'
            }
        ]
    };

    return (
        <MetaTable 
            stickyHeader={true}
            type="order" 
            canCreate={false}
            filters={filterDef()}
            defaultFilter={{ archived: "0", year: thisyear}}
            dataUrl={props.dataUrl ? props.dataUrl : null}       
            rememberSearch="payments-table" 
        />
    )
}
