import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputLabel, TextField, InputAdornment } from '@mui/material';
import { pad, isInt } from '../../utils';

TimeStamp.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string
};
export default function TimeStamp({ value, onChange, label }) {
    
    const changeAllowedTime = (value, minutes=true) => {
        if(value !== "" && !isInt(value)) {
            return;
        }
        const num = parseInt(value, 10);

        if( num < 0 || num > 59) {
            return;
        }
        let newvalue = "";
        if(minutes) {
            const seconds = pad(allowedTimeGet(false), 2);
            newvalue = `${value}:${seconds}`;
        } else {
            const mm = allowedTimeGet(true);
            newvalue = `${mm}:${value}`;
        }
        
        onChange(newvalue);
        // setAllowedTime(newvalue);
        // isChanged({
        //     xcexptime: newvalue
        // });
    }

    const allowedTimeGet = (minutes=true) => {
        if(value === "") {
            return "";
        }
        const parts = value.split(":");
        if(parts.length !== 2) {
            return "";
        }
        return parts[minutes ? 0 : 1];
    }

    return (
        <Grid container sx={{pb:2}} >
            <Grid item>
                <InputLabel sx={{mb:1, fontWeight: 'bold'}} id="numjudges">{label}</InputLabel>
                <TextField
                    size="small"
                    // type="number"
                    label="Minutes"
                    id="outlined-start-adornment"
                    value={allowedTimeGet()}
                    onChange={(event) => changeAllowedTime(event.target.value, true)}
                    sx={{ width: '10ch', mr:1 }}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">m</InputAdornment>,
                    }}
                    />
                <TextField
                    size="small"
                    // type="number"
                    label="Seconds"
                    id="outlined-start-adornment"
                    sx={{ width: '10ch' }}
                    value={allowedTimeGet(false)}
                    onChange={(event) => changeAllowedTime(event.target.value, false)}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">ss</InputAdornment>,
                    }}
                    />
            </Grid> 
        </Grid>
    );
}
