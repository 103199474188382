import React, { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import _ from "lodash";
import { Grid } from "@mui/material";
import axios from "axios";
import { MetaStore } from "../../app/MetaStore";
import { getFieldDefinition, removeTableOnlyFields, getValue } from "../../utils";
import Loading from "../Loading";
import Title from "../../containers/Title";
import Save from "../buttons/Save";
import Back from "../buttons/Back";
import { useSnackbar } from 'notistack';
import Cancel from "../buttons/Cancel";
import Edit from "../buttons/Edit";
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
    loading: true,
    form: {},
    fields: new Array(),
    errors: {},
    groups: []
};

const Form = forwardRef((props, ref) => {
    const { id, objtype, bottomOnly, reloadOnUpdate} = props;
    const [formState, setFormState] = React.useState(props.record ? {
        loading:initialState.loading, form: props.record, fields: initialState.fields,
        errors: initialState.errors, groups: initialState.groups 
    } : initialState);
    const [saving, setSaving] = React.useState(false);
    const [definitionTitle, setDefinitionTitle] = React.useState(props.objtype);
    const [record, setRecord] = React.useState(props.record ? props.record : {});
    const [disabled, setDisabled] = React.useState(true);
    const [uploading, setUploading] = React.useState(false);
    const refs = React.useRef(new Array());
    const { enqueueSnackbar } = useSnackbar();    
    const navigate = useNavigate();
    const [readonly, setReadonly] = useState(props.readonly ? props.readonly : false);
    const [showbuttons, setShowbuttons] = useState(typeof props.showbuttons != 'undefined' ? props.showbuttons : true);
    const [showBackButton, setShowBackButton] = useState(typeof props.showbackbutton != 'undefined' ? props.showbackbutton : true);
    const [searchParams] = useSearchParams();
    const [originalFieldDefinitions, setOriginalFieldDefinitions] = React.useState(initialState.fields);

    useImperativeHandle(ref, () => ({
        updateFormValue(name, value) {
            getDefinition();
        },
    }));
  
  
    const updateForm = (updates, checkfiles = true) => {
        console.log(updates);

        // if the updated field is a file, then we upload the file in the 
        // background
        if(checkfiles) {
            for(const fieldid in updates) {
                let fielddef = getFieldDef(fieldid)
                if(fielddef && fielddef.component == 'file-upload') {
                    return uploadFile(fielddef, updates[fieldid]);
                }
            }    
        }
        const copy = copyForm();
        let formfields = []; // cloneDeep(formState.fields);
        let errorList = {};
        for (const [key, value] of Object.entries(updates)) {
            for(const field of formState.fields) {                
                // if it depends has a vdalition if and depends on this field
                if(skipValidation(field.id, updates)) {
                    refs.current[field.id].reset();
                    delete field['validations'];
                } else {
                    const originalField = getOriginalFieldDef(field.id);
                    field['validations'] = originalField['validations'];
                }
                formfields.push(cloneDeep(field));
            }
          
            if(refs.current[key] && !skipValidation(key, updates)) {
                refs.current[key].validate(value).then(isInvalid => {
                    if(isInvalid) {
                        errorList[key] = isInvalid[0];                    
                    } else {
                        errorList[key] = undefined;
                    }
                }
                );
            }
            _.set(copy, key, value);
        }
        setDisabled(false);
        setFormState({ disabled: false, loading: formState.loading, form: copy, fields: formfields.length == 0 ? formState.fields : formfields, groups: formState.groups, errors: errorList });
    };

    const uploadFile = (field, fineinfo) => {
        // upload file and when it is uploaded, store the file url in the formState.form
        setUploading(true);
        const formData = new FormData();
        formData.append('id', id);
        if(field.ispublic) {
            formData.append('ispublic', true);
        }
        // for now only supporting one file at a time
        formData.append('file', fineinfo.files[0]);
        try {

        axios({
            method: "post",
            url: "/admin/file/upload/"+objtype+"/"+field.id,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          }).then(result => {
            fineinfo.files[0] = result.data.record.url;
            updateForm({ [field.id] : fineinfo}, false );
            setUploading(false);
          });
        } catch(error) {
            enqueueSnackbar("Problem uploading file, please try again "  + error, { variant: 'error'})
            setUploading(false);
        }
        

    }

    const copyForm = () => {
        // const copy = JSON.parse(JSON.stringify(formState.form));
        // if there are 
        return cloneDeep(formState.form);
    }

    const getFieldDef = (fieldid) => {
        for(const field of formState.fields) {
            if(field.id == fieldid) {
                return field;
            }
        }
        return null;
    };

    const getOriginalFieldDef = (fieldid) => {
        for(const field of originalFieldDefinitions) {
            if(field.id == fieldid) {
                return field;
            }
        }
        return null;
    };

    const skipValidation = (fieldid, updates) => {
        const field = getFieldDef(fieldid);
        if(!field.validateif) {
            return false;
        }
        let skip = false;
        for (const condition in field.validateif) {
            if(updates && updates[condition] && updates[condition] != field.validateif[condition]) {
                skip = true;
            }
        }
        return skip;
    }

    const isFormValid = async () => {
        let errorList = {};
        setFormState({ disabled: false, loading: formState.loading, form: formState.form, groups: formState.groups, fields: formState.fields, errors: {} });
        for(const field of formState.fields) {
            if(field.component != 'display-text' && !skipValidation(field.id)) {
                const isInvalid = await refs.current[field.id].validate(formState.form[field.id]);
                if(isInvalid && isInvalid.length > 0) {
                    errorList[field.id] = isInvalid[0];
                }    
            }
        }
        setFormState({ disabled: false, loading: formState.loading, form: formState.form, groups: formState.groups, fields: formState.fields, errors: errorList });
        return errorList;
    };

    const save = async () => {

        // let errorList = stripUndefined(formState.errors);
        let errorList = await isFormValid();

        if(Object.keys(errorList).length > 0 ) {
            console.debug(errorList);
            return enqueueSnackbar("Please correct errors before submitting", { variant: 'error'})
        }
        if (props.isCreate) {
            create();
        } else {
            update();
        }

    };

    // const numNonSwitchFields = () => {
    //     let counts = 0;

    //     for (const field of formState.fields) {
    //       counts = field.component == 'switch' ? counts : counts+1;
    //     }

    //     return counts;
    // };

    const create = () => {
        setSaving(true);
        let extraparams= {};
        for (const entry of searchParams.entries()) {
            const [param, value] = entry;
            extraparams[param] = value;
        }
        axios.post("/admin/"+objtype, { ...formState.form, ...extraparams} )
            .then(response => {
                console.debug(response);
                if (response.data.error) {
                    setFormState({ disabled: false, loading: false, form: formState.form, fields: formState.fields, groups: formState.groups });
                    setSaving(false);
                    return enqueueSnackbar(response.data.message, { variant: 'error'})
                }
                setFormState({ disabled: false, loading: false, form: formState.form, fields: formState.fields, groups: formState.groups });                
                setSaving(false);
                setDisabled(true);
                enqueueSnackbar(objtype + " created", { variant: 'success'});
                navigate(-1);

            })
            .catch(error => {
                console.debug(error);
                setFormState({ disabled: false, loading: false, form: formState.form, fields: formState.fields, groups: formState.groups });
                setSaving(false);
                return enqueueSnackbar("Error creating " + objtype + ": " + error, { variant: 'error'})
            });

    };

    const getFormData = () => {
        let form = {};
        for(const field of formState.fields) {
            if(field.component != 'display-text') {
                if(field.component == 'file-upload' && formState.form[field.id] && formState.form[field.id].files) {
                    form[field.id] = formState.form[field.id].files[0];
                } else {
                    form[field.id] = formState.form[field.id];
                }
                
            }
        }
        return form;
    };

    const update = () => {
        if(!isChanged()) {
            return enqueueSnackbar("Nothing to change" , { variant: 'warning'})
        }
        setSaving(true);
        // let formData = getFormData();
        // formData.append("id", id);
        axios.put("/admin/"+objtype, { id: id, ...getFormData() } )
        // axios.put("/admin/"+objtype, { data: formData, headers: { "Content-Type": "multipart/form-data" }, } )
            .then(response => {
                console.debug(response);
                if (response.data.error) {
                    setFormState({ disabled: false, loading: false, form: formState.form, fields: formState.fields, groups: formState.groups});
                    setSaving(false);
                    return enqueueSnackbar(response.data.message, { variant: 'error'})
                }
                setFormState({ disabled: false, loading: false, form: getFormData(), fields: formState.fields, groups: formState.groups });                
                setSaving(false);
                setDisabled(true);
                enqueueSnackbar(objtype + " updated", { variant: 'success'});
                if(!props.readonly) {
                    navigate(-1);
                } else {
                    setReadonly(true);
                    if(reloadOnUpdate) {
                        navigate(0);
                    }
                }
            })
            .catch(error => {
                console.debug(error);
                setSaving(false);
                setFormState({ disabled: false, loading: false, form: formState.form, fields: formState.fields, groups: formState.groups});
                return enqueueSnackbar("Error updating " + objtype + ": " + error, { variant: 'error'})
            });

    };

    const isChanged = () => {
        for (const [key, value] of Object.entries(formState.form)) {
            if(record[key] != value) {
                return true;
            }
        }
        return false;
    };

    const getDefinition = () => {        
        MetaStore.get(objtype ? objtype : '', ((definition) => {
            // setMeta(definition);
            // definition = applyPermissions(definition, user);
            console.debug(definition.fields);
            definition.fields = removeTableOnlyFields(definition.fields);
            if(props.isCreate) {
                loadFields(record, definition);
            } else {
                getData(definition);
            }
        }));
    }

    React.useEffect(() => {
        getDefinition();
    }, []);

    async function getData(definition) {
        axios.get('/admin/' + objtype + "/id/" + id)
            .then(response => {
                if (response.data.error) {
                    setFormState({ loading: false, form: formState.form, fields: formState.fields, groups: definition.groups});
                    return;
                }
                console.debug("Fetched data", response.data);
                setRecord(response.data.record);
                loadFields(response.data.record, definition);
                if(props.onData) {
                    props.onData(response.data.record);
                }
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
                setFormState({ loading: false, form: formState.form, fields: formState.fields, groups: formState.groups});
            });
    }

    const loadFields = (record, definition) => {
        let fieldDefinitions = new Array();
        definition.fields.forEach((field) => {
            // if (field.permissions.write > 0) {
                // console.debug("Loading field " + field.label);
                fieldDefinitions.push(getFieldDefinition(field));
            // }
        });

        let groups = [];
        if(definition.groups) {
            groups = definition.groups;
        }
        setDefinitionTitle(definition.title);
        let values = props.isCreate && JSON.stringify(record) === '{}' ? getDefaults(fieldDefinitions) : getInitialData(record, fieldDefinitions);
        setFormState({ loading: false, form: values, fields: fieldDefinitions, groups: groups });
        setOriginalFieldDefinitions(fieldDefinitions);
    };

    const getDefaults = (fieldDefinitions) => {
        let defaultValues = {};
        fieldDefinitions.map(fd => {
           if(fd.defaultValue) {
               defaultValues[fd.id] = fd.defaultValue;
           }
        });
        return defaultValues;
    }

    const getInitialData = (record, fields) => {
        let initialValues = {};
        fields.forEach((field) => {
            initialValues[field.id] = getValue(record, field.id);
            if(initialValues[field.id] == null && field.defaultValue) {
                initialValues[field.id] = field.defaultValue;
            }
        });

        // add any extra fields if not already present
        for (const property in record) {
            if(!initialValues[property]) {
                initialValues[property] = getValue(record, property);
            }
        }
        return initialValues;
    };

    const getTitle = () => {
        if(props.noTitle) {
            return "";
        }
        let operation = (props.isCreate ? "Create " : (readonly ? "" : "Edit "));

        let t = operation + definitionTitle + (record.name ? ": " + record.name : "");
        console.debug("Setting title to " + t);
        return (
            <Title>{t}</Title>
        );
    };

    const cancel = () => {
        if (props.isCreate || !props.readonly) {
            return navigate(-1);
        }
        setReadonly(true);
    }

    if (formState.loading) {
        return (
            <React.Fragment>
                <Title>{"Loading"}</Title>
                <Loading />
            </React.Fragment>
        )
    }


    return (
        <Grid container >
        <Grid item xs={12} pb={2}>
            {getTitle()}

            <Grid container spacing={3} sx={{pt: 2}}   justifyContent="flex-end">
            {!readonly && !bottomOnly && <Grid item><Save
                disabled={disabled}
                loading={saving}
                onClick={save}
            /></Grid>}
            {showbuttons && !bottomOnly && !readonly && <Grid item><Cancel onClick={() => { cancel() }}/></Grid>}
            {showbuttons && !bottomOnly && showBackButton && readonly && <Grid item><Back/></Grid>}
            {showbuttons && !bottomOnly && readonly && <Grid item><Edit onClick={() => { setReadonly(false) }}/></Grid>}
            </Grid>
        <FormBuilder
                fields={formState.fields}
                form={formState.form}
                updateForm={(updates) => updateForm(updates)}        
                refs={refs}
                readonly={readonly}
                ref={ref}
                groups={formState.groups}
            />
            <Grid container spacing={3} sx={{pt: 2}}   justifyContent="flex-end">
                {!readonly && <Grid item><Save
                    disabled={disabled}
                    loading={saving}
                    onClick={save}
                /></Grid>}
                {showbuttons && !readonly && <Grid item><Cancel onClick={() => { cancel() }}/></Grid>}
                {showbuttons && showBackButton && readonly && <Grid item><Back/></Grid>}
                {showbuttons && readonly && <Grid item><Edit onClick={() => { setReadonly(false) }}/></Grid>}
            </Grid>
        </Grid>
        </Grid>
        
    );
});

export default Form ;