import * as React from 'react';
import { useState } from 'react';
import { MetaTable } from '../../components/table/MetaTable';
import Panel from '../../components/containers/Panel';
import { useParams } from 'react-router';
import InitialiseScheduling from '../components/InitialiseScheduling';
import CardLoading from '../components/CardLoading';
import axios from 'axios';



export default function Arenas() {
  const { id } = useParams();
  const [event, setEvent] = useState();

  React.useEffect(() => {
    console.debug("Id: " + id);
    // get the details for this event 
    axios.get("/admin/event/id/" + id)
        .then(response => {
        if (response.data.error) {
            return;
        }
        console.debug("Fetched data", response.data);
        setEvent(response.data.record);
    }).catch(error => {
        console.debug(error);
    });

}, [id]);

if(!event) {
    return <CardLoading message="Loading event..."/>
}

const onInitialised = (e) => {
  setEvent(e);
}

if(!event.migratedid ) {
    return (
      <Panel>
        <InitialiseScheduling id={id} onInitialised={onInitialised}/>
      </Panel>
    )
  }

  return (
    <Panel>
      <MetaTable type="arena" canCreate={true} dataUrl={"/admin/arena?eventid="+id} idname={"eventid"}/>
    </Panel>
  )
}