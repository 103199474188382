import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextField, ButtonGroup, Button, Stack } from '@mui/material';
const PREFIX = 'XcScore';

const classes = {
    notsetyet: `${PREFIX}-notsetyet`,
    notsureisok: `${PREFIX}-notsureisok`,
    looksok: `${PREFIX}-looksok`,
    hidewidgets: `${PREFIX}-hidewidgets`
};

const StyledStack = styled(Stack)((
    {
        theme
    }
) => ({
    [`& .${classes.notsetyet}`]: {
      background: "rgb(255,255,240)"
    },

    [`& .${classes.notsureisok}`]: {
        background: "rgb(255,228,225)"
    },

    [`& .${classes.looksok}`]: {
        background: "rgb(255,255,255)"
    },

    [`& .${classes.hidewidgets}`]: {
        
    }
}));

export default function  XcScore({fenceindex, fence, onChange, value, hasFocus, onFocus }) {


  const getValueClassName = () => {
      if(value == 0) {
          return classes.looksok;    
      }
      if(value == '') {
          return classes.notsetyet;
      }
      // if(movement.score < 2 || movement.score > 9) {
      //     return classes.notsureisok;
      // }
      return classes.looksok;
  }

  const handleChange = (v) => {
      console.log(v);
    //   const regex = new RegExp('^[0-9]+$');
    //   if(regex.test(v) || v == "") {
          onChange(v);
    //   }
      // if(v == " ") {
      //     return;
      // }
      
  }

  return (
      <StyledStack  
          alignItems="center"
          justifyContent="center" direction="column" sx={{mb:3, style: { textAlign: 'center' }}}>
          <TextField
            // variant={variant}
            // type="number"
            inputProps={{ 
                readOnly: true, 
                className:getValueClassName(), 
                min: 0, 
                style: { 
                    textAlign: 'center',
                    backgroundColor: hasFocus ? "green" : "",
                    color: hasFocus ? "white" : "black", 
                    padding: 5
                }
            }} 
            
            sx={{
                width: 50
            }}
            size="small"
            value={value}
            helperText={`#${fence}`}            
            onChange={(e) => handleChange(e.target.value)}
            onFocus={onFocus}
          />           
      </StyledStack>
  );
}

