import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import { SelectClassType } from "../../app/form/Definitions/SelectClassType";
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import Title from "../../containers/Title";
import Description from "../../containers/Description";
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import Primary from "../../components/buttons/Primary";
import { Alert } from '@mui/material';
import axios from "axios";
const store = sessionStorage;


export default function CreateEventClass() {
    const { id, type } = useParams();
    const [classType, setClassType] = React.useState(null);
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState("");
    // const [currentEventId, setCurrentEventId] = useLocalStorage('current-event-id');

    const updateForm = (updates) => {
        setError("");
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };

    const selectClassType = () => {
        if(form['classtypeid'] == null) {
            return setError("Please select class type to use");
        }

        // fetch the class type
        axios.get("/admin/classtype/id/"+form['classtypeid'])
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return setError("Problem getting class type: " + response.data.error)
            } 

            initialise(response.data.record);
        })
        .catch(error => {
            setError("Error getting class type: " + error);
        });

    }

    const initialise = (classtype) => {
        let currentEventId = store.getItem('current-event-id')
        // fetch the event details to pick up any defaults
        axios.get("/admin/event/id/"+currentEventId)
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return setError("Problem getting event defaults: " + response.data.error)
            } 
            let initialState = {
                drstarttime: "09:00",
                drinterval: "00:06",
                sjstarttime: "10:00",
                sjinterval: "00:02",
                xcstarttime: "12:00",
                xcinterval: "00:02",
                minentrants: 1,
                maxentrants: 999,
                trotup1time: "00:02",
                trotup2time: "00:02"
            };
            initialState.closingdate = response.data.record.closingdate;
            initialState.applclosedate = response.data.record.applclosedate
            initialState.eventid = response.data.record.id
            initialState.entrancefeeeuro = classtype.entrancefeeeuro;
            initialState.entrancefeegpb = classtype.entrancefeegbp;
            initialState.applicationentryeuro = classtype.applicationentryeuro;
            initialState.classtypeid = classtype.id;
            setForm(initialState);
            setClassType(classtype);

        })
        .catch(error => {
            setError("Problem getting event defaults: " + error);
        });
        
        
    };
  
    if(classType == null) {
        return (
            <Panel>
                <Title>Select Class Type To Use</Title>
                <Description>Please select the class type you would like to use, note: if you start typing the name then list will autocomplete.</Description>
                <FormBuilder fields={SelectClassType} form={form} updateForm={(updates) => updateForm(updates)} />
                {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
                {<Primary sx={{ mt: 2 }} startIcon={<DynamicFormIcon/>} title={"Select"} onClick={() => { selectClassType() }}/>}
            </Panel>
        );
    }
    return (
        <Panel>
            <Form isCreate={true} objtype={"eventclass"} record={form}/>
        </Panel>
    )
}