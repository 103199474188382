import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import Title from '../Title';
import axios from 'axios';
import Description from '../Description';
import { Typography, Link, Button, Card, Alert } from '@mui/material';
import FileUpload from 'react-material-file-upload';
import Loading from '../../components/Loading';
import { useSnackbar } from 'notistack';
import Back from '../../components/buttons/Back';
import { useNavigate } from 'react-router';


export default function CreateDressageTest() {
    const [loading, setLoading] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [error, setError] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const fileSelected = (file) => {
        setFiles(file);
        setError();
    }

    const upload = () => {
        setLoading(true);
        setError("");
        const formData = new FormData();
        console.log(files[0].File);
        formData.append('file', files[0]);
        axios({
            method: "post",
            url: `/admin/dressagetest/upload`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(result => {
            if(result.data.error) {
                setLoading(false);
                setError(result.data.message);
                return enqueueSnackbar(result.data.message, { variant: 'error'});
            }
            setLoading(false);
            enqueueSnackbar("Dressage Test Added", { variant: 'success'});
            navigate(-1);
        }).catch(error => {     
            setLoading(false);
            setError(error)
            return enqueueSnackbar(error, { variant: 'error'});
        });
    }

    return (
        <><Panel>
            <Title>Import Dressage Test</Title>
            <Description>From here you can add new Dressage Tests to the system, use the options below to select your Dressage Test file and import it into the system. 
                <Typography sx={{fontWeight: 'bold'}}>Note: </Typography>The imported file must be in a specific format, you can click on the following link to view/ download the template 
                    <Link rel="noopener" target="_blank" sx={{ml: 1}} href="https://docs.google.com/spreadsheets/d/1xjqz3vI01lV7drBCFuLpqpxJU-OTO-wQ5N2wYdCDdAo/edit#gid=0">View Template</Link>
                </Description>
            <Description>
                To see an example of how the template can be filled out click on the following link               
                <Link rel="noopener" target="_blank" sx={{ml: 1}} href="https://docs.google.com/spreadsheets/d/1E2tdmHkUqYbTBLslhMTWZApdllBKuUnvdUpnOuL9QMQ/edit?usp=sharing">View Example</Link>
            </Description>
            <Description>
                The uploaded file needs to be in Excel (.xlsx) format.
            </Description>

            {error && <Alert sx={{my: 1}}  severity="error" >{error}</Alert>}
            {!loading && <FileUpload 
                buttonText={"Select Dressage Test File To Upload"} 
                title="Select (or drag'n'drop) the dressage test file to upload" 
                value={files} 
                onChange={(f) => fileSelected(f)} 
            />}
            {loading && <Card variant="outlined"><Loading message="Please wait while we upload the file" /></Card>}
            <Button disabled={files.length != 1 || loading} sx={{mt:3}} variant="contained" onClick={upload} >Upload Selected File</Button>
        </Panel>
        <Back />
        </>
    )
}