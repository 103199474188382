export const ChangeIssueStatusFields = [
      {
        attribute: "description",
        label: "Description/ Explanation",
        col: {
          // Here you can specify how many Grid columns the field should take for the corresponding breakpoints
          sm: 12,
        },
        validationType: "string",
        validations: {
          required: true,
          max: 256,
        },
        props: {
          rows: "3",
          multiline: true
        }
      }
];
