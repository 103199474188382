import * as React from 'react';
import Panel from '../../components/containers/Panel';
import Box from '@mui/material/Box';
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import Title from "../Title";
import Description from "../Description";
import Primary from "../../components/buttons/Primary";
import { Alert } from '@mui/material';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Back from "../../components/buttons/Back";

export default function CreateLateEntry() {
    const [form, setForm] = React.useState({});
    const [error, setError] = React.useState("");
    const [added, setAdded] = React.useState(false);
    const [searchParams] = useSearchParams();

    if(!searchParams.has('eventid')) {
      return (
        <Alert severity="error">Missing eventid in request</Alert>
      )
    }

    const getFormFields = () => {
        return [    
            {
              attribute: "userid",
              label: "Select Member Name",
              component: 'autocomplete-remote',
              validationType: "string",
              validations: {
                required: true,
              },
              optionDef: {
                type: "server-search",
                name: "fullname",
                url: "/admin/user/auto",
                operation: "like",
                min: 2
              },
              optionConfig: {
                key: "id",
                value: "id",
                label: "name",
              },
              col: {
                sm: 12,
              },
            },      
            {
                attribute: "expirydate",
                label: "Expiry",
                component: "date-time-picker",
                validationType: "date",
                validations: {
                    required: "You must select an entries opening date/ time for this event"
                },
            }
          ];
    }
    
    const updateForm = (updates) => {
        setError("");
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    }

  
    const confirm = () => {
      // make sure the info has been provided
      if(!form['userid'] || !form['expirydate']) {
        return setError("Please fill out both fields");
      }

      axios.post("/admin/lateentry", {
        expirydate: form['expirydate'],
        userid: form['userid'],
        eventid: searchParams.get('eventid')
      })
      .then(response => {
          if (response.data.error) {
              return setError("Problem adding late entry permission: " + response.data.error)
          } 
          setAdded(true);
      })
      .catch(error => {
          setError("Error getting class type: " + error);
      });
    }      

    return (
        <Panel>
            <React.Fragment>            
                <Title>Select User and set Expiry</Title>
                <Description>Please select the rider to enter in this class. Note: if you start typing the riders name then the list will autocomplete.</Description>
                <FormBuilder fields={getFormFields()} form={form} updateForm={(updates) => updateForm(updates)} />
                {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
                {added != "" && <Alert severity="success" sx={{ mt:1}}>Permission for this the late entry has been added</Alert>}
                <Box sx={{mt:2}}><Back/>{!added && <Primary sx={{ ml: 2 }} startIcon={<AddIcon/>} title={"Add"} onClick={() => { confirm() }}/>}</Box>
            </React.Fragment>

        </Panel>
    );
   

}