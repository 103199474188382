import React from "react";
import Chip from '@mui/material/Chip';
import Avatar from "@mui/material/Avatar";
import {minutesToDuration, orderByTime, toMinutes} from "../../utils";

let thresholds = [
    {
      colour: 'secondary',
      value: 36
    },
    {
      colour: 'primary',
      value: 66
    }
  ];

export default function CompetitorTimeDifference(props) {

  const getLabel = (data) => {
    let className = data.classname;
    if (data.current) {
      className = "This Entry ";
    }

    if(!data.entryindex && data.disciplineshortname) {
      className = `${data.disciplineshortname} - ${className}`;
    }

    let timeToNext = !data.timetonext || data.timetonext == 0 ? "" : " (+" + minutesToDuration(data.timetonext) + ")";
    return className + " : " + data.starttime + timeToNext;
  };

  const getColor = (data, isLast) => {
    
    if (isLast ) {
        return 'default';
    }
    for (let threshold of props.thresholds) {
      if (data.timetonext < threshold.value) {
        return threshold.colour;
      }
    }
    return 'default';
  };

  const getData = () => {
    let data = [...props.data];
    if(props.show.length == 0) {
      return data;
    }

    props.show.map(disciplineid => {
      props.data.map(m => {
        const details = m.others.find(o => o.disciplineid == disciplineid);
        data.push(details);
      });
    });
    
    data = orderByTime(data);
    for (let i = 0; i < data.length-1; i++) {
      let t1 = toMinutes(data[i].starttime);
      let t2 = toMinutes(data[i+1].starttime);
      data[i].timetonext = t2-t1;
    }

    return data;
  }

  let displayData = getData();
  return (
    <ul component="ul" >
      {displayData.map((data, index) => {
        return (
          <li key={index + "-timediff-" +data.competitionnumber}>
            <Chip
              avatar={<Avatar>{"(" + data.competitionnumber + ")"}</Avatar>}
              label={getLabel(data)}
              color={getColor(data, index == displayData.length-1)}
              variant="outlined"
            />
          </li>
        );
      })}
    </ul>
  );
}