import React from "react";
import _ from "lodash";
import FormDialog from "../form/FormDialog";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_ROOT } from "../../app/constants";

export default function DownloadProgram({eventid}) {

    const fields = [
        {
            attribute: "includenationality",
            label: "Include Nationality",
            component: "switch",
            col: {
              sm: 12,
            }
        },
        {
            attribute: "includesjtime",
            label: "Include SJ Time",
            component: "switch",
            col: {
              sm: 12,
            }
        },
        {
            attribute: "includexctime",
            label: "Include XC Time",
            component: "switch",
            col: {
              sm: 12,
            }
        }
    ];

    
    const download = (form, callback) => {
        
        const link = document.createElement("a");
        link.download = "EventProgramTemplate";
        let query = [];
        if(form['includenationality']) {
            query.push(`includenationality=${form['includenationality']}`);
        }
        if(form['includesjtime']) {
            query.push(`includesjtime=${form['includesjtime']}`);
        }
        if(form['includexctime']) {
            query.push(`includexctime=${form['includexctime']}`);
        }
        link.href = `${API_ROOT}admin/event/${eventid}/downloadprogram?${query.join("&")}`;
        callback({ error: false, message: "Downloading data" });
        link.click();           
    };


    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form) => {
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Download Program Template" 
            title="Download Program Template Data"
            description={"Please select from the options below and then click Download, or click Cancel to return" }
            saveLabel="Download"
            fields={fields}
            isValid={isValid}
            onConfirm={download}
            icon={() => (<CloudDownloadIcon/>)}
            isSecondaryButton={true}
            size="normal"
       />
    )
}