import React from "react";
import _ from "lodash";
import axios from "axios";
import FormDialog from "../form/FormDialog";
import SwapCallsIcon from '@mui/icons-material/SwapCalls';

export default function ChangeClass(props:any) {

    const changeRiderFields = [
        {
            attribute: "eventclassid",
            label: "New Class",
            component: 'autocomplete-remote',
            validationType: "string",
            validations: {
              required: true,
            },
            optionDef: {
              type: "server-search",
              name: "name",
              url: "/admin/eventclass/auto",
              operation: "like",
              min: 2,
              filter: {
                riderid: props.riderid,
                horseid: props.horseid,
                eventid: props.eventid
              }
            },
            optionConfig: {
              key: "id",
              value: "id",
              label: "name",
            },
            col: {
              sm: 12,
            },
          }
    ];

    const change = (form:any, callback:any) => {
        axios.post("/admin/evententrant/"+props.id+"/changeeventclass", { 
            eventclassid: form['eventclassid']
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: "Problem changing class: " + response.data.message});
            }
            props.update(response.data.record.name);
            callback({ error: false, message: "Class changed " });
        })
        .catch(error => {
            return callback({ error: true, message: "Error changing class: " + error});
        });

        
    };


    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        if (!form['eventclassid'] || form['eventclassid'] == "") {
          return "Please select the new rider";
        }
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Change Class" 
            title="Select New Class"
            description={"Please select a new class to replace " + props.class  + " ridden by " + props.rider + " on " + props.horse }
            saveLabel="Apply Change"
            fields={changeRiderFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<SwapCallsIcon/>)}
            isSecondaryButton={true}
       />
    )
}