import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Button } from "@mui/material";


PublishButton.propTypes = {
    eventid: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.number,
    enable: PropTypes.string,
    disable: PropTypes.string,
    column: PropTypes.string,
    onChange: PropTypes.func,
};
  
export default function PublishButton({ eventid, type, value, column, onChange, enable="Publish", disable="Unpublish" }) {
    const [published, setPublished] = useState(value)
    const {enqueueSnackbar } = useSnackbar();

    const label = () => `${published ? disable : enable} ${type}`;

    const toggle = () => {
        axios.put("/admin/event", {
            id: eventid,
            [column]: published ? 0 : 1
        }).then(response => {
            if (response.data.error) {
                enqueueSnackbar(response.data.message, { variant: 'error'})
                return;
            }
            enqueueSnackbar("Status updated", { variant: 'success'})
            onChange(!published);
            setPublished(!published);
        
        }).catch(error => {
            enqueueSnackbar(error, { variant: 'error'})
            console.debug(error);
        });
    }

    return (
        <Button sx={{mt: 2}} variant={published ? "contained" : "outlined"} onClick={toggle}>{label()}</Button>
        
    );
}