import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { API_ROOT } from '../../app/constants';

import {Grid, Paper, Stack, Button } from '@mui/material';
import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import { MetaTable } from '../../components/table/MetaTable';
import CardLoading from '../../scheduler/components/CardLoading';
import Panel from '../../components/containers/Panel';
import Title from '../../containers/Title';
import Typography from '@mui/material/Typography';
import PublishButton from '../../scheduler/components/PublishButton';
import FileUploadDialog from '../../components/containers/FileUploadDialog';
import FullScreenDialog from '../../components/containers/FullScreenDialog';

function DashboardContent() {
    const { id } = useParams();
    // const [event, setEvent] = useLocalStorage("event", { status: 1, name: ""});
    const [event, setEvent] = useState();
    const [uploadOpen, setUploadOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [openUploadResults, setOpenUploadResults] = React.useState(false);
    const [uploadResults, setUploadResults]  = React.useState([]);

    React.useEffect(() => {
        console.debug("Id: " + id);
        // get the details for this event 
        axios.get("/admin/event/id/" + id)
            .then(response => {
            if (response.data.error) {
                return;
            }
            console.debug("Fetched data", response.data);
            setEvent(response.data.record);
        }).catch(error => {
            console.debug(error);
        });

    }, [id]);

    const updateStatus = (status, field) => {
        setEvent({ ...event, [field]: status ? 1 : 0});
    }

    const setPoints = (add) => {
        // call server to set points
        const action = add ? "applypoints" : "removepoints";
        setIsLoading(true);
        axios({
            method: "post",
            url: `/admin/event/${id}/${action}`,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(result => {
            if(result.data.error) {
                setIsLoading(false);
                return enqueueSnackbar(result.data.message, { variant: 'error'});
            }
            setIsLoading(false);
            setEvent({ ...event, publishedhorsepoints: (add? new Date(): null)});            
            enqueueSnackbar("Scores " + (add ? "added" : "removed"), { variant: 'success'});
        });
    }

    const uploadRechenstelle = () => {
        setIsLoading(false);
        setUploadOpen(true);
    } 
    
    const download = (name, urlpath, options) => {
        enqueueSnackbar("Generating file, please wait", { variant: 'warning'});
        const link = document.createElement("a");
        link.download = name;
        let query = new URLSearchParams(options).toString();
        link.href = `${API_ROOT}admin/event/${id}/${urlpath}` + (query && query.length > 0 ? `?${query}` : '');
        link.click();   
    }

    const getReportsUrl = () => {
        if(location.host.includes("localhost")) {
            return `http://localhost:8888/eiresults/report?id=${id}`;
        }
        if(location.host.includes("eventingireland")) { 
            return `https://results.eventingireland.com/report?id=${id}`;
        }
        return `https://results.unbridledresults.com/report?id=${id}`;
    }

    const reports = () => {
        // enqueueSnackbar("Generating file, please wait", { variant: 'warning'});
        const link = document.createElement("a");
        link.target="_blank";
        // let query = new URLSearchParams(options).toString();
        link.href = getReportsUrl()
        link.click();   
    }

    const confirmUploadFiles = (files) => {
        setIsLoading(true);
        const formData = new FormData();
        console.log(files[0].File);
        formData.append('file', files[0]);
        setUploadResults([]);
        axios({
            method: "post",
            url: `/admin/event/uploaddrscores/${id}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(result => {
            if(result.data.error) {
                setIsLoading(false);
                return enqueueSnackbar(result.data.message, { variant: 'error'});
            }
            setIsLoading(false);
            setUploadOpen(false);
            setOpenUploadResults(true);
            setUploadResults(result.data.records);
            enqueueSnackbar("DR Scores have been uploaded", { variant: 'success'});
        });
    }

    const getUploadResults = () => {
        if(!uploadResults.length) {
            return (
                <Typography >Nothing to load, either the file had no changes in it, or it was not a recognised file format</Typography>
            )
        }
        return uploadResults.map((r,i) => {
            return (
                <Typography key={`r-${i}`}>{r}</Typography>
            )
        });
    }
    if(!event) {
        return <CardLoading message="Loading event..."/>
    }

    // if(!event.migratedid) {
    //     return (
    //       <Panel>
    //         <InitialiseScheduling id={id} onInitialised={onInitialised}/>
    //       </Panel>
    //     )
    //   }
    
    const getTotalEntries = () => {
        return event.Eventclasses.reduce((a, e) => {
            return a + e.numentries
        }, 0);
    };

    return (
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}  order={{ xs: 2, sm: 1 }}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <MetaTable title={"Classes"} type={"scoringeventclasses"} dataUrl={"/admin/eventclass?eventid="+id+"&archived=0"}/>
                    </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3} order={{ xs: 1, sm: 2 }}>
                    {/* <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            // height: 240,
                        }}
                    >
                        <Title>{`${event.name} (${event.startdate})`}</Title>
                        <Typography color="text.secondary" >
                            {"Number Of Classes: " + event.Eventclasses.length}
                        </Typography>   
                        <Typography color="text.secondary" >
                            {"Total Number Of Entries: " + getTotalEntries()}
                        </Typography>   
                        <Button sx={{mt: 2}} variant="outlined" onClick={() => download('DownloadInitialFile', 'downloadscoringfile', { initial: true })}>Download New Scoring File</Button>
                        <Button sx={{mt: 2}} variant="outlined" onClick={() => download('DownloadInProgressFile', 'downloadscoringfile', { initial: false })}>Download In Progress Scoring File</Button>
                        <Button sx={{mt: 2}} variant="contained" onClick={uploadTimes}>Upload Scoring File</Button>

                    </Paper> */}
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Title>{`${event.name} (${event.startdate})`}</Title>
                        <Typography color="text.secondary" >
                            {"Number Of Classes: " + event.Eventclasses.length}
                        </Typography>   
                        <Typography color="text.secondary" >
                            {"Total Number Of Entries: " + getTotalEntries()}
                        </Typography>   
                        <PublishButton 
                            key="pub-1"
                            eventid={id} 
                            type="Results" 
                            column="publishresults" 
                            value={event.publishresults}
                            onChange={(value) => updateStatus(value, "publishresults")}
                        />                        
                        <PublishButton 
                            key="score-1"
                            eventid={id} 
                            type="Live Scoring" 
                            column="canscore" 
                            value={event.canscore}
                            onChange={(value) => updateStatus(value, "canscore")}
                            enable="Enable"
                            disable="Disable"
                        />                        
                    </Paper>
                    <Paper
                        sx={{
                            mt: 2,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Button sx={{mt: 2}} variant="outlined" onClick={() => download('SpecialResults', 'downloadresults')}>Download Special Results As CSV</Button>
                        <Button sx={{mt: 2}} variant="outlined" onClick={() => reports()}>Additional Result Reports</Button>
                        {event && !event.publishedhorsepoints && <Button sx={{mt: 2}} variant="contained" onClick={() => setPoints(true)}>Set Points</Button>}
                        {event && event.publishedhorsepoints && <Button sx={{mt: 2}} variant="contained" onClick={() => setPoints(false)}>Remove Points</Button>}
                    </Paper>
                    <Paper
                        sx={{
                            mt: 2,
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Title>Millstreet Only</Title>
                        <Typography color="text.secondary" >
                            Only use this option for Millstreet to load Dressage scores from Rechenstelle when the scoring application is NOT being used.
                        </Typography> 
                        <Button sx={{mt: 2}} variant="contained" onClick={uploadRechenstelle}>Upload Rechenstelle Dressage Score File</Button>
                    </Paper>

                    <FileUploadDialog 
                        title="Select Or Drag Drop File"
                        description="Please click below to select the latest Rechenstelle dressage scoring file to upload or drag and drop the file into the box below."
                        open={uploadOpen}
                        confirmLabel="Upload DR Scoring File"
                        uploadButtonText="Select Rechenstelle DR Scoring File"
                        onCancel={() => setUploadOpen(false)}
                        onConfirm={confirmUploadFiles}
                        loading={isLoading}
                        loadingMessage="Please wait while we upload the file"
                    />

                    <FullScreenDialog 
                        title="Score Update Results" 
                        open={openUploadResults} 
                        onClose={() => { setOpenUploadResults(false)}} 
                        contents={getUploadResults()}
                    />
                </Grid>               
            </Grid>

    );
}

export default function ScoringDashboard() {
    return <DashboardContent />;
}