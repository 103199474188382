import * as React from 'react';
import { useParams, useNavigate } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useRef } from 'react';
import { MetaTable } from '../../components/table/MetaTable';
import Back from '../../components/buttons/Back';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
export default function ViewTeamDetails() {
    const { id } = useParams();
    const {enqueueSnackbar } = useSnackbar();
    const [teamCompetition, setTeamCompetition] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const navigate = useNavigate();

    const formRef = useRef()

    return (
        <>
        <Panel>
            <Form 
                id={id} 
                objtype={"team"} 
                readonly={true}
                showbackbutton={false}
                bottomOnly={true}
            />       
        </Panel>
        <Panel>
        <MetaTable 
            type="teammember" 
            canCreate={true}    
            title="Team Members"
            dataUrl={`/admin/teammember?teamid=${id}`}
            idname="teamid"
            createLabel={"Add Team Member"}
        />
        <Back/>
        </Panel>
        </>
    )
}