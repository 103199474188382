import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import TestForm from '../../components/form/TestForm';
import Panel from '../../components/containers/Panel';

export default function CreateCompetency() {
    const { id } = useParams();

    return (
        <Panel>
            <Form isCreate={true} objtype="competency" />
        </Panel>
    )
}