import { createSlice} from '@reduxjs/toolkit'
import { RootState } from './store';

interface LoginState  {
    isLoggedIn: boolean;
    token: string;
    refreshtoken: string;
    user: any
};

interface LoginAction {
    payload: LoginState
}

const initialState: LoginState = {
    isLoggedIn: false,
    user: null,
    token: '',
    refreshtoken: ''
};

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        loggedin: (state: LoginState, action: LoginAction ) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.token = action.payload.token;                        
            state.refreshtoken = action.payload.refreshtoken;
        },
        loggedout: (state: LoginState ) => {
            state.isLoggedIn = false;
            state.token = '';
            state.refreshtoken = '';
            state.user = null;
        },
        tokenexpired: (state: LoginState ) => {
            state.isLoggedIn = false;
            state.token = '';
            state.refreshtoken = '';
            state.user = null;
        }
    }
})

export const { loggedin, loggedout, tokenexpired } = loginSlice.actions
export const selectIsLoggedIn = (state: RootState ) => {
    const loginState = <LoginState>state.login;
    return loginState.isLoggedIn;
}
export const selectToken = (state: RootState ) => {
    const loginState = <LoginState>state.login;
    return loginState.token;
}

export const selectRefreshToken = (state: RootState ) => {
    const loginState = <LoginState>state.login;
    return loginState.refreshtoken;
}

export const selectUser = (state: RootState ) => {
    const loginState = <LoginState>state.login;
    return loginState.user;
}

export default loginSlice.reducer