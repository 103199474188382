import * as React from 'react';
import Typography from '@mui/material/Typography';

interface TitleProps {
  children?: React.ReactNode;
}

export default function Description(props: TitleProps) {
  return (
    <Typography color={"gray"} variant="body2" gutterBottom sx={{ml:0, pl:0}}>
      {props.children}
    </Typography>
  );
}