import * as React from 'react';
import Typography from "@mui/material/Typography";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
export default function Break(props) {

    return (
        <Typography
        className={"MuiTypography--subheading"}
        variant={"caption"} style={{display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'}}
      >
      <AccessAlarmIcon color="info" style={{transform: "scale(0.8)"}}/>

      {" Break " + props.minutes + " minutes"}

        </Typography>        
    );
}