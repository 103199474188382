import * as React from 'react';
import { useParams } from 'react-router';
import { MetaTable } from '../../components/table/MetaTable';
import Panel from '../../components/containers/Panel';
import { pluralise, capitalizeFirstLetter } from '../../utils';
import Back from '../../components/buttons/Back';
import { pages } from '../../util/generalpages';

export default function GenericTable() {
    const { type } = useParams();

    let title = "All " + capitalizeFirstLetter(pluralise(type));
    let filter = null;
    let canCreate = false;
    let defaultFilter = null;

    let pageDef = pages.filter(({ id }) => id == type);

    if(pageDef.length > 0) {
        title = pageDef[0].title;
        if(pageDef[0].filter) {
            filter = pageDef[0].filter
        }
        if(pageDef[0].canCreate) {
            canCreate = pageDef[0].canCreate
        }
        if(pageDef[0].defaultFilter) {
            defaultFilter = pageDef[0].defaultFilter
        }
    }
    
   
    return (
        <Panel>
            <MetaTable 
                title={title} 
                type={type}    
                filters={filter}       
                canCreate={canCreate}
                defaultFilter={defaultFilter}
            />
            <Back/>
        </Panel>
    )
}