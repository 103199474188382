import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Panel from '../../components/containers/Panel';
import TabPanel from '../../components/containers/TabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useLocalStorage from '../../app/useLocalStorage';
import { MetaTable } from '../../components/table/MetaTable';
import { CurrencyFilter }  from '../../app/form/Definitions/CurrencyFilter';
import Back from "../../components/buttons/Back";

const store = sessionStorage;

export default function ViewReconciliationLogDetails() {
    const { id } = useParams();
    const [value, setValue] = useLocalStorage('view-reconciliationlog-tab', 0);

    store.setItem('current-reconciliationlog-id', id);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
    const dataUsed = (log) => {
        setReconciliationLog(log);
    }   

    const a11yProps = (index) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    };
      
  
    /**
     * Foaled 2007 Height 163cm Colour Chestnut Total Points = 2
     * Owned By Harry Rowcliffe Bred By Mrs Pippa Wiegersma Gender Gelding
     * Sire Graf Quidam Dam Mazey Day
     */
    return (
        <React.Fragment>
            <Panel>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                        variant="scrollable" 
                        scrollButtons
                        value={value}   
                        onChange={handleChange} 
                        aria-label="event details"
                    >
                    <Tab label="Summary" {...a11yProps(0)} />
                    <Tab label="Card Payments" {...a11yProps(1)} />
                    <Tab label="Refunds" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Form 
                        id={id} objtype={"reconciliationlog"} 
                        readonly={true} 
                        showbuttons={false} noTitle={true}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MetaTable 
                        type="reconciliations" 
                        canCreate={false}    
                        dataUrl={"/admin/reconciliations?type=CC&reconciliationlogid="+id} 
                        filters={CurrencyFilter}
                        defaultFilter={{ }}                   
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MetaTable 
                        type="reconciliations" 
                        canCreate={false}    
                        dataUrl={"/admin/reconciliations?type=RF&reconciliationlogid="+id} 
                        filters={CurrencyFilter}
                        defaultFilter={{ }}                   
                    />
                </TabPanel>
                <Back/> 
            </Panel>              
            
        </React.Fragment>
    )
}