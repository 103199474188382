import * as React from 'react';
import { useParams } from 'react-router';
import Form from '../../components/form/Form';
import Title from '../../containers/Title';
import Panel from '../../components/containers/Panel';
import Primary from '../../components/buttons/Primary';
import Back from '../../components/buttons/Back';
import { Grid, Typography } from "@mui/material";
import ConfirmationDialog from '../../components/collections/ConfirmationDialog';
import { useSnackbar } from 'notistack';
// import Grid2 from '@mui/material/Unstable_Grid'; // Grid version 2
// import Grid from '@mui/material/Grid'; 
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import axios from "axios";
import OrderDetails from './OrderDetails';
import Loading from '../../components/Loading';
import Description from '../Description';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
export default function WithdrawEntryDetails() {
    const { id } = useParams();
    const {enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = React.useState();
    const [error, setError] = React.useState();
    const navigate = useNavigate();    

    React.useEffect(() => {
        getData();
    }, []);
    
    
    async function getData() {
        axios.get('/admin/evententrant/' + id + "/order")
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message);
                    return enqueueSnackbar(response.data.message, { variant: 'error'});
                }
                setOrder(response.data.record);
            })
            .catch(error => {
                enqueueSnackbar("Problem fetching data: " + error, { variant: 'error'})
            });
    }

    const refunded = (refundDetails) => {
        // mark the entry as withdrawn
        console.debug(refundDetails);
        navigate(-1);
    }
 

    return (
        <React.Fragment>
        <Panel>
            {error && 
                <Alert severity="error">{error}</Alert>}
            {!order && !error && <Loading/>}
            {order && 
                <React.Fragment>
                    <Title>{"Confirm Withdraw Entry (" + order.entry.User.firstname + " " + order.entry.User.lastname + " / " + order.entry.Horse.name + ")"}</Title>
                    <Description>Please select the items to refund and confirm that you want to withdraw 
                    this entry by clicking the refund button. Note: the refund button is only displayed after you 
                    have selected the item(s) to refund, alternatively to cancel click the back button. </Description>
                    <Description>
                        {order.entry.User.firstname + " " + order.entry.User.lastname + " riding " 
                        + order.entry.Horse.name + " in the " + order.entry.Eventclass.Classtype.name + " class."}
                    </Description>
                    <Alert severity="warning">{"Please remember that this only relates to the withdrawal of " 
                        + order.entry.User.firstname + " " + order.entry.User.lastname + " riding " + order.entry.Horse.name 
                        + " so when selecting items to refund please ensure that you only select those that are linked to this entry."}
                    </Alert>
                    <Alert sx={{mt:1, mb:1}} severity="warning">When you have clicked the Refund button and confirmed the request, 
                    the entry will also be marked as withdrawn in the Event and an email will be automatically sent to the 
                    rider.</Alert>
                </React.Fragment>
            }
        </Panel>
         {order && <OrderDetails id={order.id} order={order} onRefund={refunded} withdraw={true} />}
        </React.Fragment>
    )
}

