import * as React from 'react';
import { useParams } from 'react-router';
import { MetaTable } from '../../components/table/MetaTable';
import Panel from '../../components/containers/Panel';
import { pluralise, capitalizeFirstLetter } from '../../utils';
import Back from '../../components/buttons/Back';

export default function TableListing() {
    const { id, type, action, title } = useParams();

    let usetitle = title;
    if(!title) {
        usetitle = "All " + pluralise(action) + " in " + capitalizeFirstLetter(type);
    } else {
        usetitle = capitalizeFirstLetter(type) + ": " +title;
    }
    return (
        <Panel>
            <MetaTable title={usetitle} type={action} dataUrl={"/" + type + "/"+id+"/"+action}/>
            <Back/>
        </Panel>
    )
}