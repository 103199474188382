import * as React from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export default function Edit(props:any) {
    return (
        <Button
        color="primary"
        startIcon={<EditIcon />}
        variant="contained"
        {...props}
      >
        Edit
      </Button>
    )
}


