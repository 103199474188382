import React from "react";
import _ from "lodash";
import axios from "axios";
import FormDialog from "../form/FormDialog";
import Groups2Icon from '@mui/icons-material/Groups2';

export default function AssignToTeam(props:any) {

    const assignTeamsFields = [
        {
            attribute: "teamid",
            label: "Select Team, start typing name",
            component: 'autocomplete-remote',
            validationType: "string",
            validations: {
              required: true,
            },
            optionDef: {
              type: "server-search",
              name: "name",
              url: "/admin/team/auto",
              operation: "like",
              min: 2,
              filter: {
                archived: 0
              }
            },
            optionConfig: {
              key: "id",
              value: "id",
              label: "name",
            },
            col: {
              sm: 12,
            },
          }
    ];

    const change = (form:any, callback:any) => {
        axios.put("/admin/entrantresults/", { 
            teamid: form['teamid'],
            id: props.id
        } )
        .then(response => {
            console.debug(response);
            if (response.data.error) {
                return callback({error: true, message: "Problem assigning team: " + response.data.message });
            }
            props.update(response.data.record.name);
            callback({ error: false, message: "Team assigned" });
        })
        .catch(error => {
            return callback({ error: true, message: "Error assigning team: " + error});
        });        
    }

    /**
     * at least n characters
     * combination of upper- and lower-case characters(*)
     * one or more digits(*)
     * not related to other user data (name, address, username, ...)
     * not a dictionary word
     */
    const isValid = (form:any) => {
        if (!form['teamid'] || form['teamid'] == "") {
          return "Please select a team or click cancel";
        }
        return "";
    }

    return (
       <FormDialog 
            buttonLabel="Assign Team" 
            title="Select Team To Assign"
            description={"Please select the team to assign to " + props.rider + " on " + props.horse + " for the class " + props.class +". Just start typing the team name and the list will autocomplete."}
            saveLabel="Apply Change"
            fields={assignTeamsFields}
            isValid={isValid}
            onConfirm={change}
            icon={() => (<Groups2Icon/>)}
            isSecondaryButton={true}
       />
    )
}