import * as React from 'react';
import { MetaTable } from '../components/table/MetaTable';
import { useSelector } from 'react-redux';
import { selectUser } from '../app/loginSlice';
import Panel from '../components/containers/Panel';
import { RootStyle } from '../utils';
import { useNavigate } from 'react-router';
import useLocalStorage from '../app/useLocalStorage';
import Title from './Title';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';

export default function Events() {
    const user = useSelector(selectUser);
    const navigate = useNavigate();
    const [international, setInternational] = useLocalStorage("isinternational", "all");
    const [pastfuture, setPastfuture] = useLocalStorage("pastfuture", "all");
    // const [dataUrl, setDataurl] = useLocalStorage('eventsDataUrl', '/admin/event');
    

    const filterDef = () => {
        return [
            {
                id: "type",
                type: "togglegroup",
                options: [
                    {
                        id: "international",
                        name: "International"
                    },
                    {
                        id: "national",
                        name: "National"
                    },
                    {
                        id: "hp",
                        name: "High Performance"
                    },
                    {
                        id: "training",
                        name: "Training"
                    }
                ]
            },
            {
                id: "when",
                type: "togglegroup",
                options: [
                    {
                        id: "allpast",
                        name: "All Past"
                    },
                    {
                        id: "pastyear",
                        name: "Past Year"
                    },
                    {
                        id: "pastmonth",
                        name: "Past Month"
                    },
                    {
                        id: "future",
                        name: "Future"
                    }
                ]
            },
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }
        ];
    };
    return (
       
            <Panel>
                
                <MetaTable type="event"  
                    canCreate={true}
                    // dataUrl={'/event/admn'}
                    filters={filterDef()}
                    placeholder="Search event name ..."
                    defaultFilter={{ when: "future"}}
                    rememberSearch="events-table" 
                    createLabel={"Add Event"}
                />
            </Panel>
       
    )
}
