import * as React from 'react';
import Panel from '../../components/containers/Panel';
import useLocalStorage from '../../app/useLocalStorage';
import { useParams } from 'react-router';
import InitialiseScheduling from '../components/InitialiseScheduling';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Loading from '../../components/Loading';
import { Box } from '@mui/system';
import { Tab, Tabs } from '@mui/material';
import DisciplineRiders from '../components/DisciplineRiders'
import { getEntriesByDiscipline } from '../../utils';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Riders(props) {
  const { id } = useParams();
  // const [event, setEvent] = useLocalStorage("event", { status: 1, name: ""});
  // const [event, setEvent] = useState();
  const [busy, setBusy] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [disciplines, setDisciplines] = React.useState([]);
  const [value, setValue] = useLocalStorage("riders.scheduling.selectedTab", 0);
  const [thresholds, setThresholds] = React.useState([]);

  const loadThresholds = () => {
    setBusy(true);
    axios.get("/admin/disciplinedefaults")
      .then(response => {
        if (response.data.error) {
          enqueueSnackbar("Problem getting thresholds: " + response.data.error, { variant: 'error' })
          setBusy(false);
          return;
        }
        console.debug("Fetched data", response.data);
        setThresholds(response.data.records);
        console.debug(response.data.records);
        loadEntries();
      }).catch(error => {
        console.log(error.stack);
        enqueueSnackbar("Problem getting discipline defaults: " + error, { variant: 'error' })
        setBusy(false);
      });
  };
  
  const loadEntries = () => {
    setBusy(true);
    axios.get("/admin/event/" + id + "/riders")
      .then(response => {
        if (response.data.error) {
          enqueueSnackbar("Problem getting rider details: " + response.data.error, { variant: 'error' })
          setBusy(false);
          return;
        }
        console.debug("Fetched data", response.data);
        setBusy(false);
        let d = getEntriesByDiscipline(response.data.records);
        console.log("Fetched disciplines");
        setDisciplines(d);
      }).catch(error => {
        enqueueSnackbar("Problem getting entry details: " + error, { variant: 'error' })
        setBusy(false);
      });
  };

  const getThresholds = (disciplineid) => {
    for(let i = 0; i < thresholds.length; i++ ) {
      if(thresholds[i].disciplineid = disciplineid) {
        return [{
          colour: 'error',
          value: thresholds[i].impossiblethreshold
        },
        {
          colour: 'warning',
          value: thresholds[i].difficultthreshold
        },
        {
          colour: 'tertiary',
          value: thresholds[i].optimumtime
        }]
      }
    }
  };

  React.useEffect(() => {
    console.debug("Id: " + id);
    // get the details for this event 
    loadThresholds();
  }, []);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  if(busy) {
    return (
      <Panel>
        <Loading message="Please wait while we load the riders details"/>
      </Panel>
    )
  }
  // if(!event.migratedid) {
  //   return (
  //     <Panel>
  //       <InitialiseScheduling />
  //     </Panel>
  //   )
  // }

  return (
    <Panel>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs aria-label="basic tabs " value={value} onChange={handleChange} >
          {disciplines.map((discipline, index) => {
              return (
                <Tab label={discipline.name} {...a11yProps(index)} key={"tab-header-" + discipline.id} />
              )
          })}
        </Tabs>
      </Box>
      {disciplines.map((discipline, index) => {
        return (
          <TabPanel value={value} key={"riders-panel-" + discipline.id} index={index} id={discipline.id} >
            <DisciplineRiders busy={busy} discipline={discipline} thresholds={getThresholds(discipline.id)}  />
          </TabPanel>
        )
      })}
    </Panel>
  )
}