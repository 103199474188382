import * as React from 'react';
import { Grid, Stack, Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Save from '../../components/buttons/Save';
import Fence from './Fence';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { isCombination, getFenceNumber, getCombinationLetter, isSecondCombination } from '../../utils';

export default function FenceSetup(
    { fences, onChangeFences }) {

    const removeLastFence = () => {
        let fencelist = fences.split(",");
        fencelist.pop();
        const fenceliststr = fencelist.join(",")
        onChangeFences(fenceliststr);
    }

    const addFence = (n) => {
        let fencelist = fences.split(",");
        if (fencelist.length == 0) {
            return onChangeFences("1");
        }
        let lastFence = fencelist[fencelist.length - 1];
        if (isCombination(lastFence)) {
            lastFence = getFenceNumber(lastFence);
        }
        lastFence = parseInt(lastFence) + 1;
        onChangeFences(`${fences},${lastFence}`);
    }

    const numfences = () => {
        const fencelist = fences.split(",");
        return fencelist.length;
    }

    const addCombination = (fence, index) => {
        let newFence = ""; 
        let fencelist = fences.split(",");
        const currentFenceNumber = getFenceNumber(fence);
        if(isCombination(fence)) {
            const letter = getCombinationLetter(fence);
            const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
            newFence = `${currentFenceNumber}${nextLetter}`;
            // insert new fence in list
            fencelist.splice(index+1, 0, newFence);
        } else {
            // add A to the current fence and insert a B
            fencelist[index] = `${fence}A`;
            fencelist.splice(index+1, 0, `${fence}B`);
        }
        onChangeFences(fencelist.join(","));
    }

    const removeCombination = (fence, index) => {
        let fencelist = fences.split(",");
        fencelist.splice(index, 1);
        if(isSecondCombination(fence)) {
            // remove A from previous fence
            const fenceNumber = getFenceNumber(fence);
            fencelist[index-1] = fenceNumber;
        }
        onChangeFences(fencelist.join(","));
    }

    const getfences = () => {
        let fencelist = fences.split(",");
        return fencelist.map((f, i) => {
            return (
                <Grid key={i} item lg={12}>
                    <Fence next={i < fencelist.length-1 ? fencelist[i+1] : null } number={f} addCombination={() => addCombination(f, i)} removeCombination={() => removeCombination(f, i)}/>
                </Grid>
            );
        });
    }
   
    return (
        <Grid container spacing={1}>
            {getfences()}
            <Grid item>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={addFence} startIcon={<AddIcon />}>
                        Add Fence
                    </Button>
                    {numfences() > 0 && <Button variant="contained" onClick={removeLastFence} startIcon={<RemoveIcon />}>
                        Remove Last Fence
                    </Button>}

                </Stack>

            </Grid>
        </Grid>

    );
}
