import { isInt } from "../../utils";

export const hasSjRefusal = (update) => {
    let has = -1;
    update.sjjumpscores.map((f, index) => {
        if(f == '1R' || f == '2R' || f == '1R+4') {
            has = index;
        }
    });
    return has;
}

export const hasXcRefusal = (update) => {
    let numRefusals = 0;
    update.xcfences.map((f, index) => {
        if(f == '1R' || f == '2R' ) {
            numRefusals++;
        }
    });
    return numRefusals;
}

export const canScoreSjFence = (update, f) => {
    let canscore = true;
    update.sjjumpscores.map((f, index) => {
      if(!isInt(f) ) {
        // console.log("Not int: " + f);
        canscore = false;
      }
    });
    return canscore;
}

export const canScoreXcFence = (update, f) => {
    let canscore = true;
    update.xcfences.map((f, index) => {
      if(!isInt(f) ) {
        // console.log("Not int: " + f);
        canscore = false;
      }
    });
    return canscore;
}


export const clearSjCommentsAndStatus = (update) => {
    // only one refusal and all other scores are numeric
    console.log(update.sjjumpscores);
    let canclear = 0;
    update.sjjumpscores.map((f, index) => {
      if(!isInt(f) && f != '1R' || f == '1R+4') {
        console.log("Not int: " + f);
        canclear = 2;
      }
      if(f == '1R' || f == '1R+4') {
        canclear++;
      }
    });
    return canclear;
}

export const clearXcCommentsAndStatus = (update) => {
    // only one refusal and all other scores are numeric
    console.log(update.xcfences);
    let canclear = 0;
    update.xcfences.map((f, index) => {
      if(!isInt(f) && f != '1R' && f != '2R') {
        console.log("Not int: " + f);
        canclear = 4;
      }
      if(f == '1R') {
        canclear++;
      }
      if(f == '2R') {
        canclear = canclear + 2;
      }
      if(f == '11' || f == '15') {
        canclear = 11;
      }
    });

    return canclear;
}


export const sjscorebuttons = [
    {
        hotkeys: [ 'ctrl+0', 'F10'],
        shortname: 'CLR',
        score: 0,
        name: 'Clear the score on the selected fence, and update comments and status for this rider',
        handler: (e, fences, fence) => {
            e.sjcomments = '';
            e.sjreason = '';
            e.sjstatus = 'OK';  
            return e;
        }
    }, 
    {
        hotkeys: [ 'ctrl+1', 'F1'],
        shortname: '1R',
        score: '1R',
        name: '1 Refusal at the selected fence',
        handler: (e, fences, fence) => {
            const refusal = hasSjRefusal(e);
            if(refusal != -1) {
              e.sjcomments = `Eliminated after 2 refusals, one at fence ${fences[refusal]} and one at fence ` + fences[fence];
              e.sjreason = 'R';
              e.sjstatus = 'EL';
            } else {
              e.sjcomments = '';
              e.sjreason = '';
              e.sjstatus = 'OK';  
            }
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+2', 'F2'],
        shortname: '2R',
        score: '2R',
        name: '2 Refusals at the selected fence and elimination',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Eliminated with 2 refusals at fence ' + fences[fence];
            e.sjreason = 'R';
            e.sjstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+4', 'F4'],
        shortname: '1R+4',
        score: '1R+4',
        name: '1 Refusal at the current selected fence and then 4 faults on second attempt (8 faults in total at fence)',
        handler: (e, fences, fence) => {
            e.sjcomments = `1 Refusal at fence ${fences[fence]} and then 4 faults on second attempt (8 faults in total at fence)`;
            e.sjreason = 'OT';
            e.sjstatus = 'EL';  
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+7', 'F7'],
        shortname: '1RR',
        score: '1RR',
        name: '1 refusal at the current selected fence and then retired',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Retired after refusal at fence ' + fences[fence];
            e.sjreason = '';
            e.sjstatus = 'RET';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+3', 'F3'],
        shortname: '20',
        score: '20',
        name: '20 jumping faults, eliminated because SJ is before XC ',
        // conditional: (e) => {
        //     return (e.Eventclass.phaseorder == 0)
        // },
        handler: (e, fences, fence) => {
            e.sjcomments = 'Eliminated because 20 SJ faults with SJ before XC';
            e.sjreason = 'OT';
            e.sjstatus = 'EL';  
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+5', 'F5'],
        shortname: 'FR',
        score: 'FR',
        name: 'Fall of rider at the current selected fence ',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Eliminated with fall of rider at fence ' + fences[fence];
            e.sjreason = 'FR';
            e.sjstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+6', 'F6'],
        shortname: 'FH',
        score: 'FH',
        name: 'Fall of horse at the current selected fence ',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Eliminated with fall of horse at fence ' + fences[fence];
            e.sjreason = 'FH';
            e.sjstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+9', 'F9'],
        shortname: '1RFR',
        score: '1RFR',
        name: 'Has 1 refusal at the current selected fence and then the rider fell at that fence',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Had 1 refusal and then rider fell at fence ' + fences[fence];
            e.sjreason = 'FR';
            e.sjstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+9'],
        shortname: '1RFH',
        score: '1RFH',
        name: 'Has 1 refusal at the current selected fence and then the horse fell at that fence',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Had 1 refusal and then horse fell at fence ' + fences[fence];
            e.sjreason = 'FH';
            e.sjstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+3'],
        shortname: 'MJ',
        score: 'MJ',
        name: 'Eliminated because they missed the current selected fence',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Eliminated, missed fence ' + fences[fence];
            e.sjreason = 'OT';
            e.sjstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+5'],
        shortname: 'RET',
        score: 'RET',
        name: 'Retired at the current selected fence',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Retired at fence ' + fences[fence];
            e.sjreason = '';
            e.sjstatus = 'RET';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+6'],
        shortname: 'EL',
        score: 'EL',
        name: 'Eliminated at the current selected fence',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Eliminated at fence ' + fences[fence];
            e.sjreason = 'OT';
            e.sjstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+8'],
        shortname: 'WD',
        score: 'WD',
        name: 'Withdrawn',
        handler: (e, fences, fence) => {
            e.sjcomments = 'Withdrawn';
            e.sjreason = '';
            e.sjstatus = 'WD';
            return e;
        }
    }
];

export const xcscorebuttons = [
    {
        hotkeys: [ 'ctrl+0', 'F10'],
        shortname: 'CLR',
        score: 0,
        name: 'Clear the score on the selected fence, and update comments and status for this rider',
        handler: (e, fences, fence) => {
            e.xccomments = '';
            e.xcreason = '';
            e.xcstatus = 'OK';  
            return e;
        }
    }, 
    {
        hotkeys: [ 'ctrl+1', 'F1'],
        shortname: '1R',
        score: '1R',
        name: '1 Refusal at the selected fence',
        handler: (e, fences, fence) => {
            const numRefusals = hasXcRefusal(e);
            if(numRefusals >= 2) {
              e.xccomments = `Eliminated after 3 consecutive refusals, final one at fence ` + fences[fence];
              e.xcreason = 'R';
              e.xcstatus = 'EL';
            } else {
              e.xccomments = '';
              e.xcreason = '';
              if(e.xcstatus == '') {
                e.xcstatus = 'OK';  
              }
            }
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+2', 'F2'],
        shortname: '2R',
        score: '2R',
        name: '2 Refusals at the selected fence',
        handler: (e, fences, fence) => {
            // e.xccomments = 'Eliminated with 2 refusals at fence ' + fences[fence];
            // e.xcreason = 'R';
            // e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+3', 'F3'],
        shortname: '3R',
        score: '3R',
        name: '3 Refusals at the selected fence, combination eliminated',
        handler: (e, fences, fence) => {
            e.xccomments = 'Eliminated with 3 refusals at fence ' + fences[fence];
            e.xcreason = 'R';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+5', 'F5'],
        shortname: 'FR',
        score: 'FR',
        name: 'Fall of rider at the current selected fence ',
        handler: (e, fences, fence) => {
            e.xccomments = 'Eliminated with fall of rider at fence ' + fences[fence];
            e.xcreason = 'FR';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+6', 'F6'],
        shortname: 'FH',
        score: 'FH',
        name: 'Fall of horse at the current selected fence ',
        handler: (e, fences, fence) => {
            e.xccomments = 'Eliminated with fall of horse at fence ' + fences[fence];
            e.xcreason = 'FH';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+F5'],
        shortname: 'FF',
        score: 'FF',
        name: 'Fall of on the flat before the current selected fence ',
        handler: (e, fences, fence) => {
            e.xccomments = 'Eliminated with fall of on the flat before fence ' + fences[fence];
            e.xcreason = 'FF';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+7', 'F7'],
        shortname: '1RR',
        score: '1RR',
        name: '1 refusal at the current selected fence and then retired',
        handler: (e, fences, fence) => {
            e.xccomments = 'Retired after one refusal at fence ' + fences[fence];
            e.xcreason = '';
            e.xcstatus = 'RET';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+8', 'F8'],
        shortname: '2RR',
        score: '2RR',
        name: '2 refusals at the current selected fence and then retired',
        handler: (e, fences, fence) => {
            e.xccomments = 'Retired after two refusals at fence ' + fences[fence];
            e.xcreason = '';
            e.xcstatus = 'RET';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+9', 'F9'],
        shortname: '1RFR',
        score: '1RFR',
        name: 'Has 1 refusal at the current selected fence and then the rider fell at that fence',
        handler: (e, fences, fence) => {
            e.xccomments = 'Had 1 refusal and then rider fell at fence ' + fences[fence];
            e.xcreason = 'FR';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'F11'],
        shortname: '2RFR',
        score: '2RFR',
        name: 'Has 2 refusals at the current selected fence and then the rider fell at that fence',
        handler: (e, fences, fence) => {
            e.xccomments = 'Had 2 refusals and then rider fell at ' + fences[fence];
            e.xcreason = 'FR';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+F9'],
        shortname: '1RFH',
        score: '1RFH',
        name: 'Has 1 refusal at the current selected fence and then the horse fell at that fence',
        handler: (e, fences, fence) => {
            e.xccomments = 'Had 1 refusal and then horse fell at fence ' + fences[fence];
            e.xcreason = 'FH';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'ctrl+F11'],
        shortname: '2RFH',
        score: '2RFH',
        name: 'Has 2 refusals at the current selected fence and then the horse fell at that fence',
        handler: (e, fences, fence) => {
            e.xccomments = 'Had 2 refusals at fence ' + fences[fence] + ' and then the horse fall';
            e.xcreason = 'FH';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+3', 'alt+f3'],
        shortname: 'MJ',
        score: 'MJ',
        name: 'Eliminated because they missed the current selected fence',
        handler: (e, fences, fence) => {
            e.xccomments = 'Eliminated, missed fence ' + fences[fence];
            e.xcreason = 'OT';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+4', 'alt+F4'],
        shortname: 'MF',
        score: '15',
        name: '15 penalties for missing a flag on the current selected fence ',
        handler: (e, fences, fence) => {
            e.xccomments += (e.xccomments == '' ? "" : ", ") + '15 penalties for missing a flag on fence ' + fences[fence];
            // e.xcreason = 'OT';
            // e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+5', 'alt+F5'],
        shortname: 'RET',
        score: 'RET',
        name: 'Retired at the current selected fence',
        handler: (e, fences, fence) => {
            e.xccomments = 'Retired at fence ' + fences[fence];
            e.xcreason = '';
            e.xcstatus = 'RET';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+F6', 'alt+6'],
        shortname: 'EL',
        score: 'EL',
        name: 'Eliminated at the current selected fence',
        handler: (e, fences, fence) => {
            e.xccomments = 'Eliminated at fence ' + fences[fence];
            e.xcreason = 'OT';
            e.xcstatus = 'EL';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+8', 'alt+F8'],
        shortname: 'WD',
        score: 'WD',
        name: 'Withdrawn',
        handler: (e, fences, fence) => {
            e.xccomments = 'Withdrawn';
            e.xcreason = '';
            e.xcstatus = 'WD';
            return e;
        }
    },
    {
        hotkeys: [ 'alt+f10'],
        shortname: 'BD',
        score: '11',
        name: '11 Penalties for Broken Pin at the current selected fence',
        handler: (e, fences, fence) => {
            e.xccomments += (e.xccomments == '' ? "" : ", ") + '11 penalties for broken pin at fence ' + fences[fence];
            // e.xccomments = '11 penalties for broken pin at fence ' + fences[fence];
            // e.xcreason = '';
            // e.xcstatus = '';
            return e;
        }
    }
];

export const sjshortcuts = () => {
    console.log(sjscorebuttons);
    let list = [];
    for(let i = 0; i < sjscorebuttons.length; i++) {
        list = list.concat(sjscorebuttons[i].hotkeys);
    }
    // console.log("returned list", list);
    return list;
}

export const xcshortcuts = () => {
    console.log(xcscorebuttons);
    let list = [];
    for(let i = 0; i < xcscorebuttons.length; i++) {
        list = list.concat(xcscorebuttons[i].hotkeys);
    }
    // console.log("returned list", list);
    return list;
}

export const getSjShortCutAction = (handler) => {
    return getShortCutAction(handler, sjscorebuttons);
}

export const getXcShortCutAction = (handler) => {
    return getShortCutAction(handler, xcscorebuttons);
}

export const getShortCutAction = (handler, scorebuttons) => {
    let key = handler.keys.join('');
    if(key[0] == 'f') {
        key = key.toUpperCase();
    }
    if(handler.alt) {
        key = `alt+${key}`
    }
    if(handler.ctrl) {
        key = `ctrl+${key}`
    }

    
    console.log('Looking for hotkey ' + key);
    // go through shortcuts and see if any have this key as a hotkey
    for(let i = 0; i < scorebuttons.length; i++) {
        if(scorebuttons[i].hotkeys.includes(key)) {
            return scorebuttons[i];
        }
    }
    return null;
}

export const getShortCutHandler = (score, scorebuttons) => {    
    console.log('Looking for handler for ' + score);
    // go through shortcuts and see if any have this key as a hotkey
    for(let i = 0; i < scorebuttons.length; i++) {
        if(scorebuttons[i].score == score) {
            return scorebuttons[i].handler;
        }
    }
    return null;
}
export const hotkeyTooltip = (def) => {
    if(def.hotkeys.length > 0) {
        return `. You can also use the following hotkey${def.hotkeys.length == 1 ? '' : 's'} [${def.hotkeys.join(" or ")}]`;
    }
    return '';
}