import * as React from 'react';
import useLocalStorage from '../../app/useLocalStorage';
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import _ from "lodash";
import { DisciplineSettings } from "../../app/form/Definitions/DisciplineSettings";
import Title from "../../containers/Title";


export default function InitialiseDiscipline(props) {
    const [disciplineDefaults, setDisciplineDefaults] = React.useState(props.defaults);    
    const [form, setForm] = React.useState(props.defaults);


    const updateForm = (updates) => {
        // console.debug(updates);
        const copy = JSON.parse(JSON.stringify(form));
        let errorList = {};
        for (const [key, value] of Object.entries(updates)) {
            props.refs.current[key].validate(value).then(isInvalid => {
                if(isInvalid) {
                    errorList[key] = isInvalid[0];                    
                } 
            }
            );
            _.set(copy, key, value);
        }
        props.updateForm(props.disciplineid, updates)
        setForm(copy);
    };

    const getFields = () => {
        let fields = JSON.parse(JSON.stringify(DisciplineSettings));
        return fields;
    };

    return (
        <React.Fragment>
        <Title small={true} >{props.title}</Title>
        <FormBuilder
                fields={getFields()}
                form={form}
                updateForm={(updates) => updateForm(updates)}        
                refs={props.refs}
            />
        
        </React.Fragment>
    );
}