import * as React from 'react';
import Typography from "@mui/material/Typography";
import { pad } from '../../utils';
// import { useStyles } from '../../styles';

export default function ClassHeader(props) {
    // const classes = useStyles();

    const getClassName = () => {        
        return props.eventclassScheduling.Eventclass.sponsortext || props.eventclassScheduling.Eventclass.Classtype.name ;
    }

    return (
        //<div className={classes.cardfooter}>
        <div >
            <Typography
                className={"MuiTypography--subheading"}
                variant={"caption"}
            >
                {getClassName()  + " #: " + props.eventclassScheduling.Eventclass.numentries + " (" + props.eventclassScheduling.ordernumber + ")"}
            </Typography>        
        </div>                
    );
}