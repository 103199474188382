import React from "react";
import { FormBuilder } from "../../app/form/Components/FormBuilder";
import _ from "lodash";
import Primary from "../buttons/Primary";
import ResponsiveDialog from "../containers/ResponsiveDialog";
import SaveIcon from '@mui/icons-material/Save';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import { Alert } from "@mui/material";
import { useSnackbar } from 'notistack';
import Secondary from "../buttons/Secondary";


export default function FormDialog(props:any) {
    const [form, setForm] = React.useState(props.form ? props.form : {});
    const [changing, setChanging] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [error, setError] = React.useState("");
    const {enqueueSnackbar } = useSnackbar();
    
    const updateForm = (updates: any) => {
        setError("");
        const copy = JSON.parse(JSON.stringify(form));
        for (const [key, value] of Object.entries(updates)) {
            _.set(copy, key, value);
        }
        setForm(copy);
    };

    const change = () => {
        setError("");
        const valid = props.isValid(form);
        if(valid != "") {
            setError(valid);
            return;
        }

        setSaving(true);
        props.onConfirm(form, changed);        
    };

    const changed = (result:any) => {
        if(!result.error ) {
            enqueueSnackbar(result.message, { variant: 'success' });
            setSaving(false);
            setChanging(false);
        } else {
            setSaving(false);
            enqueueSnackbar(result.message, { variant: 'error' });
            setError(result.message);
        }
    }
    const showDialog = () => {
        setError("");
        setForm({});
        setChanging(true);
    };

 
    return (
        <React.Fragment>
            {!changing && !props.isSecondaryButton && <Primary  size={props.size || "small"} sx={{ mr: 2 }} startIcon={props.icon ? props.icon() : <DynamicFormIcon/>} onClick={() => { showDialog(); }} title={props.buttonLabel ? props.buttonLabel : "Apply"} />}
            {!changing && props.isSecondaryButton && <Secondary  size={props.size || "small"} sx={{  }} startIcon={props.icon ? props.icon() : <DynamicFormIcon/>} onClick={() => { showDialog(); }} title={props.buttonLabel ? props.buttonLabel : "Apply"} />}
            {changing &&  <ResponsiveDialog title={props.title ? props.title : "Provide Details"} onPrimaryClick={change} onCloseClick={() => setChanging(false)}
                primaryButtonIcon={props.saveIcon ? props.saveIcon : <SaveIcon/>} primaryButtonLabel={props.saveLabel ? props.saveLabel : "Apply"} open={changing}
                description={props.description ? props.description : "Please provide the details below:"}
                loading={saving} error={error}>
                <FormBuilder fields={props.fields} form={form} updateForm={(updates) => updateForm(updates)} />
                {error != "" && <Alert severity="error" sx={{ mt:1}}>{error}</Alert>}
            </ResponsiveDialog>}
        </React.Fragment>
    )
}