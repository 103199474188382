import * as React from 'react';
import { MetaTable } from '../components/table/MetaTable';
import Panel from '../components/containers/Panel';

export default function Venue() {
    const filterDef = () => {
        return [
            {
                id: "location",
                type: "togglegroup",
                options: [
                    {
                        id: 'home',
                        name: 'Home'
                    },{
                        id: 'abroad',
                        name: 'Abroad'
                    }
                ]
            },
            {
                id: "archived",
                type: "togglegroup",
                options: [
                    {
                        id: "0",
                        name: "Not Archived"
                    },
                    {
                        id: "1",
                        name: "Archived"
                    }
                ]
            }]
        }

    return (
        <Panel>
            <MetaTable type="venue" 
                canCreate={true}
                createLabel={"Add Venue"}
                filters={filterDef()}
                defaultFilter={{ location: "home", archived: "0"}}
                rememberSearch="venues-table" 
            />
        </Panel>
    )
}
