import React, { forwardRef, Fragment, useMemo } from "react";
import { Chip, TextField } from "@mui/material";
import { Autocomplete, Typography } from "@mui/material";
import get from "lodash/get";
import isObject from "lodash/isObject";
import PropTypes from "prop-types";
import { useValidation } from "../../Hooks/useValidation";
import { Title } from "../Widgets/Title";
import { getValidationType, shuffleArray } from "../Utils/helpers";
import { useSnackbar } from 'notistack';
import axios from "axios";



function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const StandardAutocompleteRemote = forwardRef((props, ref) => {
  const { field, value, updateForm, showTitle, readonly } = props;
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [readOnlyValue, setReadOnlyValue] = React.useState();
  const loading = open && options.length === 0;
  const {enqueueSnackbar } = useSnackbar();
  let active = false;

  const { errors, validate, reset } = useValidation(
    getValidationType(field),
    field.validations
  );

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([{id: -1, name: 'Unknown'}]);
  //   }
  // }, [open]);

  const optionConfig = useMemo(
    () => (option) => {
      const config = {
        value: option,
        label: option,
      };

      if (!field.optionConfig) {
        return config;
      }

      if (field.optionConfig.value) {
        // This is to account for the quirky behaviour of onChange returning an array
        if (field.props && field.props.multiple && Array.isArray(option)) {
          const value = [];
          for (const item of option) {
            if (isObject(item)) {
              value.push(get(item, field.optionConfig.value));
            } else {
              value.push(item);
            }
          }
          config.value = value;
        } else {
          config.value = get(option, field.optionConfig.value);
        }
      }

      if (field.optionConfig.label) {
        // This is to account for the quirky behaviour of onChange returning an array
        if (field.props && field.props.multiple && Array.isArray(option)) {
          const label = [];
          for (const item of option) {
            if (isObject(item)) {
              label.push(item);
            } else {
              label.push(get(item, field.optionConfig.label));
            }
          }
          config.label = label;
        } else {
          config.label = get(option, field.optionConfig.label);
        }
      }

      return config;
    },
    [field]
  );

  const request = async (url, params) => {
    return axios.get(url, {
            params
        }).then(response => {
            return response.data;
        }).catch(error => {
          enqueueSnackbar(error, { variant: 'error'})
          return [];
        });    
}

  const loadOptions = (newvalue) => {    
    if(active) {
      return;      
    }
    active = true;
    // fetch options and load into field.options    
    let min = field.optionDef.min ? field.optionDef.min : 3;
    if(typeof newvalue == 'undefined' || newvalue == 'undefined' || !newvalue || newvalue.length < min) {
      active = false;
      return [];
    }


    (async () => {
      let params = {
          [field.optionDef.name]: newvalue, 
          operation: field.optionDef.operation ? field.optionDef.operation : "equal"
      };
      if(typeof field.optionDef.filter != 'undefined') {
        // add additional filter parameters
        for(const param in field.optionDef.filter) {
          params[param] = field.optionDef.filter[param];
        }
      }
      let options = await request(field.optionDef.url, params);
      if(field.props && field.props.freeSolo && options.records.length == 0) {
        setOptions([
          {
            id: newvalue,
            name: newvalue
          }
        ]);
        return updateForm({[field.attribute]: newvalue});
      }
      setOptions(options.records);
      active = false;
    })(); 
  };

  /* 
  Required to handle quirky behaviour of Autocomplete component
  where it returns the option object when opening the selection box
  and returns the option value upon selection 
  */
  function getLabel(option) {
    if(option == -1) {
      return "";
    }
    if (isObject(option)) {
      return String(optionConfig(option).label);
    }
    if ((field.optionConfig || {}).value) {
      const o =
        options?.find((o) => optionConfig(o).value === option) || {};
      return String(optionConfig(o)?.label);
    }
    return String(option);
  }

  // const options = useMemo(() => {
  //   if (field.randomizeOptions) {
  //     return shuffleArray(field.options || []);
  //   }
  //   return field.options || [];
  // }, [field.options]);

  const getValue = async (field, id) => {
    console.debug(`Get Value called with [${id}]`);
    if(id == -1) {
      return -1;
    }
    if(!options || options.length == 0) {
      // fetch the value directly from the remote system
      let result = await request(`${field.optionDef.url}?id=${id}`);
      if(result.error) {
        return "";
      }
      setOptions([ { id: id, name: result.record.name }]);
      return result.record.id;
    } else {
      return id;
    }
    // let obj = options.find(o => o.id == id);
    
  }

  const componentProps = (field) => {
    console.log("componentProps", options);
    return {
      id: field.id || field.attribute,
      size: "small",
      fullWidth: true,
      options: options,
      
      renderOption: (props, option) => {
        return <li {...props} key={option.id}>{option.name}</li>
      },
      isOptionEqualToValue: (option, value) => {
        /* 
        Required to handle the quirky behaviour of Autocomplete component
        where it returns the value object sometimes and value value sometimes
        */
        return isObject(value)
          ? optionConfig(option).value === optionConfig(value).value
          : optionConfig(option).value === value;
      },
      getOptionLabel: (option) => getLabel(option),
      // renderInput: (params) => <TextField {...params} label={field.name} />,
      renderInput: (params) => (
        <TextField
          {...params}
          inputRef={(el) => {
            if (el && ref) {
              el.validate = (value) => validate(value);
              el.reset = () => reset();
              ref(el);
            }
          }}
          variant="outlined"
          size="small"
          inputProps={{
            ...params.inputProps,
            // autoComplete: "off", // disable autocomplete and autofill
          }}
          label={field.label}
          error={errors && errors.length > 0}
          helperText={errors[0]}
          // key={option.ref ? option.ref : option.id}
        />
      ),
      renderTags: (value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.ref ? option.ref : option.id}
            variant="outlined"
            size="small"
            label={getLabel(option)}
            {...getTagProps({ index })}
          />
        )),
      value: value || (field.props && field.props.multiple ? [] : null),
      onInputChange: (event, newvalue) => {
        if(newvalue && newvalue != 'undefined') {
          loadOptions(newvalue);
        }
      },
      onChange: (event, option) => {
        console.log('onChange', option);
        updateForm({
          [field.attribute]: optionConfig(option).value,
        });
      },
      onBlur: () => validate(value),
      ...field.props,
    };
  };

  const filter = (x) => {
    console.debug(x);
  };

  const componentReadOnlyProps = async (field) => {
    // let v = await getValue(field, value);
    // loadOptions(value);
    console.log("componentReadOnlyProps", field);
    console.log("componentReadOnlyProps", options);
    // let v = "";
    // if(options && options.length == 1) {
    //   // valid = options[0].name;
    //   setReadOnlyValue(options[0].name);
    // }
    return {
      id: field.id || field.attribute,
      size: "small",
      fullWidth: true,
      variant: "standard",      
      label: field.label,
      // getOptionLabel: (option) => getLabel(option),
      value: await getValue(field, value) || (field.props && field.props.multiple ? [] : null),
      // value: value,
      ...field.props
    };
  };


  // if(readonly) {
  //   console.log(field)
  //   return (
  //     <Typography>{field.label}: {field.readonlyvalue}</Typography>
  //   )
  // }
  if(readonly) {
    return (
      <Fragment>
      {showTitle && field.title && <Title field={field} />}
      {value && <Title field={value} />}
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>{field.label}:</Typography><Typography > {field.readonlyvalue}</Typography>
      <TextField
        InputProps={{
          readOnly: true,
        }}
        {...componentReadOnlyProps(field)}
        variant="standard"
        sx={{m:0, p:0}}
      />
    </Fragment>
    )
  }
  return (
    <Fragment>
      {showTitle && field.title && <Title field={field} />}
      <Autocomplete {...componentProps(field)} open={open}
        loading={loading}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // filterOptions={(x) => filter(x)}
        />
    </Fragment>
  );
});

StandardAutocompleteRemote.displayName = "StandardAutocompleteRemote";

StandardAutocompleteRemote.defaultProps = {
  updateForm: () => {},
  showTitle: true,
};

StandardAutocompleteRemote.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  updateForm: PropTypes.func,
  showTitle: PropTypes.bool,
};

export { StandardAutocompleteRemote };
