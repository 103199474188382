import * as React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

export default function Export(props:any) {

    return (
        <Button
        color="primary"
        startIcon={<DownloadIcon />}
        variant="outlined"
        {...props}
        sx={{ml: 1}} 
      >
        {props.label ? props.label : "Export"} 
      </Button>
    )
}