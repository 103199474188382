export const CurrencyFilter = [    
    {
        id: "currency",
        type: "togglegroup",
        options: [
            {
                id: 'EUR',
                name: 'EURO'
            },
            {
                id: 'GBP',
                name: 'POUND'
            }
        ]
    },
    {
        id: "status",
        type: "togglegroup",
        options: [
            {
                id: 'open',
                name: 'Open'
            },
            {
                id: 'closed',
                name: 'Closed'
            }
        ]
    }
];