import * as React from 'react';
import { useParams } from 'react-router';
import { MetaTable } from '../table/MetaTable';
const filterDef = () => {
    return [     
        {
            id: "mer",
            type: "togglegroup",
            options: [
                {
                    id: '1',
                    name: 'MER'
                },
                {
                    id: '0',
                    name: 'No MER'
                }
            ]
        },
        {
            id: "type",
            type: "togglegroup",
            options: [
                {
                    id: "international",
                    name: "International"
                },
                {
                    id: "national",
                    name: "National"
                },
                {
                    id: "training",
                    name: "Training"
                }
            ]
        }
    ]
};

export default function HorseResults(props:any) {
    
    return (
        <MetaTable type="horseresults" 
            canCreate={false}
            filters={filterDef()}
            title={props.name}
            dataUrl={`/admin/horse/${props.id}/results`}
    />
    )
}