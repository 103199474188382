import * as React from 'react';
import { Stack, Typography } from "@mui/material";
import { getTotal, getPercentage } from '../../utils';


export default function DrTotal({ scores, test }) {
    
    return (
        <Stack direction="column" spacing={1}>            
            <Stack direction="row">
                <Typography sx={{pr:1, fontWeight: 'bold'}}>Total: </Typography>
                <Typography>{getTotal(scores.DressageMovementScores, scores.penalty, scores.othererror)} / {test.totalpoints}</Typography>
            </Stack>
            <Stack direction="row">
                <Typography sx={{pr:1, fontWeight: 'bold'}}>Perc(%): </Typography>
                <Typography>{getPercentage(scores.DressageMovementScores, test, scores.penalty, scores.othererror)}</Typography>
            </Stack>            
            <Stack direction="row">
                <Typography sx={{pr:1, fontWeight: 'bold'}}>Score: </Typography>
                <Typography>{(100 - getPercentage(scores.DressageMovementScores, test, scores.penalty, scores.othererror)).toFixed(1)}</Typography>
            </Stack>
        </Stack>
    );
}
